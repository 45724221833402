import { Injectable } from '@angular/core';
import { MyHttpClient } from '../../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

const cookies = document.cookie.split(';');

const userCookie = cookies.find(function (item) {
  return item.includes('_id');
});

const httpOptions = {
  headers: new HttpHeaders({
    'user': userCookie
  })
};

@Injectable()
export class GstCertificateService {

  server: string = environment.SERVER;
  merchantURL = process.env.SERVER + '/api/merchant/';

  constructor(private http: MyHttpClient) { }

  updateGstNumber(gstNumber, merchantId) {
    const payload = {
      merchantId: merchantId,
      gstNumber: gstNumber
    };

    return this.http.post(
      this.merchantURL + 'updateGstNumber', payload).pipe(catchError(this.handleError)
    );
  }

  getGstImage(merchantId, filePath) {
    const data = {
      merchantId: merchantId,
      filePath: filePath
    };
    return this.http.post(
      this.merchantURL + 'getGstCertificate', data, { responseType: 'blob' } ).pipe(catchError(this.handleError));
  }

  approveGst(merchantId) {
    return this.http.post(
      this.merchantURL + 'approveGst/' + merchantId, '').pipe(catchError(this.handleError)
    );
  }

  disapproveGst(merchantId, rejectRemarks, userIdentity) {
    const payload = {
      userId: merchantId,
      data: rejectRemarks,
      userIdentity: userIdentity
    };

    return this.http.post(
      this.merchantURL + 'gst/rejectRemarks' , payload ).pipe(catchError(this.handleError)
    );
  }



  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }
}
