import { Injectable } from '@angular/core';
import { MyHttpClient } from '../../../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable()
export class FaceAuthService {
  authResults = process.env.SERVER + '/api/captain/faceauth-result';

  constructor(private http: MyHttpClient) {
  }


  async getFaceAuthResults(userId, pageNumber){
    const data = await  this.http.get(`${this.authResults}/${userId}?pageNumber=${pageNumber}`).toPromise();
    return data;
  }

}
