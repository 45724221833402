import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { RiderListService } from './rider-list.service';
import {FormControl, Validators} from '@angular/forms';

import {MatPaginator, MatTableDataSource} from '@angular/material';
import {MatSort} from '@angular/material';
import {Sort} from '@angular/material';
import { ToastMessage } from '../toast-message/toast-message.service';

import {Router, ActivatedRoute} from '@angular/router';

import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/filter';


function getCookie(name, position) {
  const cookies = document.cookie.split(';');
  let finalCookie;
    const userCookie = cookies.find(function (item) {
      return item.includes(name);
    });
    if (userCookie) {
      finalCookie = Object.values(JSON.parse(userCookie))[position];
    }
    return finalCookie;
}

@Component({
  selector: 'rider-list',
  template: require('./rider-list.component.html'),
  providers: [RiderListService, ToastMessage]
})
export class RiderListComponent implements OnInit {
  mobileFormControl = new FormControl('', [
    Validators.pattern('[0-9]{10}')
  ]);
  public riderTableData: any;
  public riderList: any;
  public riderCount: any;
  public showRiderTable = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public riderDataSource = [];
  public numberOfPages: any;
  public currentPage: any;
  public startLimit = 50;
  public startOffset = 0;
  public screenBreakpoint = false;
  public phoneNumberSearch: number = null;
  public searchType = 'all';
  dataSource = new MatTableDataSource(this.riderDataSource);
  displayedColumns: string[] = ['serial', 'firstName', 'mobile', 'city', 'status', 'active',
                                'activatedBy', 'more'];


  constructor(private _riderListService: RiderListService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              private _toastMessage: ToastMessage,
              private _hotkeysService: HotkeysService,
              private spinnerService: Ng4LoadingSpinnerService) {

                const userCookie = getCookie('_id', 0);
                if (userCookie) {

                 } else {
                  alert('Please login again. Cookies not found');
                  this.router.navigate(['/']);
                }

                this._hotkeysService.add(new Hotkey(['s', 'S'], (event: KeyboardEvent): boolean => {
                  document.getElementById('search-input').click();
                  return false; // Prevent bubbling
                }));
                this._hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
                  this.paginator.previousPage();
                  return false; // Prevent bubbling
                }));
                this._hotkeysService.add(new Hotkey('shift+left', (event: KeyboardEvent): boolean => {
                  this.paginator.firstPage();
                  return false; // Prevent bubbling
                }));
                this._hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
                  this.paginator.nextPage();
                  return false; // Prevent bubbling
                }));
                this._hotkeysService.add(new Hotkey('shift+right', (event: KeyboardEvent): boolean => {
                  this.paginator.lastPage();
                  return false; // Prevent bubbling
                }));
              }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    if (localStorage.getItem('permissions')) {
      console.log('has supply dashboard permission', localStorage.getItem('permissions').includes('supply_dashboard'));
    }

    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }

    let key, value;
    this.route.queryParams
    .subscribe(params => {
      key = Object.keys(params);
      value = Object.values(params);
    });
    if (key.length > 0) {
      if (key == 'dl') {
        this.searchType = 'dl';
        this.getDlFilteredList(value[0]);
      } else if (key == 'rc') {
        this.searchType = 'rc';
        this.getRcFilteredList(value[0]);
      } else { this._toastMessage.openSnackBarError('Please check the query params again. Url is incorrect'); }
    } else {
      this.searchType = 'all';
      this.getRiderList(this.startLimit, this.startOffset);
      this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 760) ? true : false;
      if (this.screenBreakpoint) {
        this.displayedColumns = ['firstName', 'mobile', 'more'];
      } else {
          this.displayedColumns = ['serial', 'firstName', 'mobile', 'city', 'registeredOn', 'status', 'active', 'activatedBy', 'more'];
      }
    }
  }

  getDlFilteredList(dl) {
    let response;
    this.spinnerService.show();
    this._riderListService.getRiderByDl(dl).subscribe(res => {
      this.spinnerService.hide();
      response = {...res};
      if (response.data.length < 1) {
        this._toastMessage.openSnackBarError('No rider found!');
        throw new Error('No rider found');
      }
      this.riderList = response.data;
      this.getTableData();
    },
    err => {
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError('Could not fetch captains by DL');
      console.log(err);
    });
  }

  getRcFilteredList(rc) {
    let response;
    this.spinnerService.show();
    this._riderListService.getRiderByRc(rc).subscribe(res => {
      this.spinnerService.hide();
      response = {...res};
      if (response.data.length < 1) {
        this._toastMessage.openSnackBarError('No rider found!');
        throw new Error('No rider found');
      }
      this.riderList = response.data;
      this.getTableData();
    },
    err => {
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError('Could not fetch captains by RC');
      console.log(err);
    });
  }


  getRiderList(limit, offset) {
    let response;
    this.spinnerService.show();
    this._riderListService.getAllRiders(limit, offset).subscribe(res => {
      this.spinnerService.hide();
      response = {...res};

      this.riderList = response.data.riders;

      this.getTableData();
    },
    err => {
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError('Could not fetch rider list');
    });
  }

  getTableData() {
    this.riderTableData = this.riderList.reduce((acc, rider) => {
      if (rider.vehiclesAssignable[0] && rider.vehiclesAssignable[0]._id != null) {

       let vehicleIndex = 0;
        if (rider.vehiclesAssignable.length > 1) {
          vehicleIndex = rider.vehiclesAssignable.length;
        }

        if (rider.statusUpdates && rider.statusUpdates.activatedBy && rider.statusUpdates.activatedBy.firstName) {
         acc[rider._id] = {
           'id': rider._id,
           'firstName': rider.firstName,
           'mobile': rider.mobile,
           'email': rider.email,
           'active': rider.active,
           'registeredOn': rider.createdOn,
           'city': rider.registeredCity,
           'ppVerified': rider.profilePicture.verified,
           'dlVerified': rider.license.verified,
           'activatedBy': rider.statusUpdates.activatedBy.firstName,
           'userId': rider.userId
         };
         if (
            rider.vehiclesAssignable[vehicleIndex]
            && rider.vehiclesAssignable[vehicleIndex].id
            && rider.vehiclesAssignable[vehicleIndex].id.rc
          ) {
            acc[rider._id].rcVerified = rider.vehiclesAssignable[vehicleIndex].id.rc.verified;
          }
        } else {
         acc[rider._id] = {
           'id': rider._id,
           'firstName': rider.firstName,
           'mobile': rider.mobile,
           'email': rider.email,
           'active': rider.active,
           'registeredOn': rider.createdOn,
           'city': rider.registeredCity,
           'ppVerified': rider.profilePicture.verified,
           'dlVerified': rider.license.verified,
           'activatedBy': '',
           'userId': rider.userId
         };
         if (
              rider.vehiclesAssignable[vehicleIndex]
              && rider.vehiclesAssignable[vehicleIndex].id
              && rider.vehiclesAssignable[vehicleIndex].id.rc
          ) {
              acc[rider._id].rcVerified = rider.vehiclesAssignable[vehicleIndex].id.rc.verified;
          }
        }

      }
         return acc;
     }, {});

     for (const rider in this.riderTableData) {
       if (this.riderTableData[rider]) { this.riderDataSource.push(this.riderTableData[rider]); }
     }
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  pageChanged(evt: any) {
    this.currentPage = this.paginator.pageIndex;
    this.numberOfPages = this.paginator.getNumberOfPages();

    if (this.currentPage === this.numberOfPages) {
      this.startOffset = this.startOffset + 20;
      this.getRiderList(this.startLimit, this.startOffset);
    }
  }

  tableRowClicked(data) {
    this.router.navigate(['/onboarding'], {queryParams: {id: data}});
  }

  searchByPhoneNumber() {
    let response;
    let userId = '';

    if (this.mobileFormControl.errors && this.mobileFormControl.errors.pattern) {
      this._toastMessage.openSnackBarError('Please enter a valid number');
    } else {
      this._riderListService.getRiderDetails(this.phoneNumberSearch).subscribe(res => {
        response = {...res};
        if (response.data) {
          this._toastMessage.openSnackBarSuccess('Rider found');
          userId = response.data.userId._id;
          this.router.navigate(['/onboarding'], {queryParams: {id: userId}});
        } else {
          this._toastMessage.openSnackBarError('Please enter a correct mobile number');
        }
      },
      err => {
        this._toastMessage.openSnackBarError('Could not fetch captain by mobile number.');
        console.log(err);
      });
    }
  }

  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 760) ? true : false;
    if (this.screenBreakpoint) {
      this.displayedColumns = ['mobile', 'status', 'more'];
    } else {
        this.displayedColumns = ['serial', 'firstName', 'mobile', 'city', 'registeredOn', 'status',
                                 'active', 'activatedBy', 'more'];
    }
  }

  backToSearchPage() {
    this.router.navigate(['/search']);
  }

  logout() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    this._toastMessage.openSnackBarSuccess('You have been logged out');
    if (window['fcWidget']) {
      window['fcWidget'].destroy();
    }
    this.router.navigate(['/']);
  }

  sortData(sort: Sort) {
    this.paginator.firstPage();
  }
}
