import { Injectable } from '@angular/core';
import { HttpErrorResponse} from '@angular/common/http';

import { environment } from '../../../environments/environment';


import { MyHttpClient } from '../../http-client';
import { forkJoin, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable()
export class DriversLicenseService {
  server: string = environment.SERVER;
  configUrl = '../src/app/onboarding-page/basic-info/riderDetails.json';
  updateRemarksUrl = process.env.SERVER + '/api/update/dl/remarks';
  updateDLNumberUrl = process.env.SERVER + '/api/update/dl/number';
  updateFatherHusbandUrl = process.env.SERVER + '/api/updateDl/fatherHusband';
  updateCompleteAddressUrl = process.env.SERVER + '/api/updateDl/completeAddress';
  updateDistrictUrl = process.env.SERVER + '/api/updateDl/district';
  updatePinUrl = process.env.SERVER + '/api/updateDl/pin';
  updateStateUrl = process.env.SERVER + '/api/updateDl/state';
  updateRejectRemarksUrl = process.env.SERVER + '/api/update/dl/rejectRemarks';

  rotateUrl = process.env.SERVER + '/api/imageRotate';
  deleteAllUrl = process.env.SERVER + '/api/profile/deleteTempFiles';
  updateImageUrl = process.env.SERVER + '/api/update/dl/dlImage';
  approveDLUrl = process.env.SERVER + '/api/approve/license';
  expiresOnDLUrl = process.env.SERVER + '/api/update/dl/expiresOn';
  checkIfDLExist = process.env.SERVER + '/api/check/dl/number';
  checkIfDlNudgeExist = process.env.SERVER + '/api/nudge/dl';


  constructor(private http: MyHttpClient) { }
  approveDL(id, license, updatedDlInfo) {
    const expiresOn = new Date(Number(license.expiresOn));
    const payload = license;
    payload.expiresOn = expiresOn;
    payload.id = id;
    payload.verified = true;
    payload.rejectRemarks = null;
    payload.updateData = updatedDlInfo
    
    return this.http.post(this.approveDLUrl, payload).pipe(catchError(this.handleError));
  }

  updateRemarks(id, remarks) {
    const payload = {
      'userId': id,
      'data': remarks
    };
    return this.http.post(this.updateRemarksUrl, payload).pipe(catchError(this.handleError));
  }

  updateNumber(id, remarks) {
    const payload = {
      'userId': id,
      'data': remarks
    };
    return this.http.post(this.updateDLNumberUrl, payload).pipe(catchError(this.handleError));
  }

  updateFatherHusband(id, data) {
    const payload = {
      'userId': id,
      'data': data
    };
    return this.http.post(this.updateFatherHusbandUrl, payload).pipe(catchError(this.handleError));
  }

  updateCompleteAddress(id, data) {
    const payload = {
      'userId': id,
      'data': data
    };
    return this.http.post(this.updateCompleteAddressUrl, payload).pipe(catchError(this.handleError));
  }

  updateDistrict(id, data) {
    const payload = {
      'userId': id,
      'data': data
    };
    return this.http.post(this.updateDistrictUrl, payload).pipe(catchError(this.handleError));
  }

  updatePin(id, data) {
    const payload = {
      'userId': id,
      'data': data
    };
    return this.http.post(this.updatePinUrl, payload).pipe(catchError(this.handleError));
  }

  updateState(id, data) {
    const payload = {
      'userId': id,
      'data': data
    };
    return this.http.post(this.updateStateUrl, payload).pipe(catchError(this.handleError));
  }

  updateExpiresOn(id, expiresOn) {
    const payload = {
      'userId': id,
      'data': expiresOn
    };
    return this.http.post(this.expiresOnDLUrl, payload).pipe(catchError(this.handleError));
  }

  deleteTempFiles() {
    return this.http.get(this.deleteAllUrl)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  checkIfLicenseExist(userId, licenseNumber) {
    return this.http.get(this.checkIfDLExist + '/' + licenseNumber + '/user/' + userId).pipe(catchError(this.handleError));
  }

  checkIfDlNudgeExists(userId) {
    return this.http.get(this.checkIfDlNudgeExist + '/' + userId).pipe(catchError(this.handleError));
  }

  rotateImage(link, degrees) {
    return this.http.post(this.rotateUrl, {
      link: link,
      degrees: degrees
    }).pipe(catchError(this.handleError));
  }

  updateImage(image, url, id, imageType, view) {
    let uploadUrl;
    uploadUrl = this.updateImageUrl;
    return this.http.post(uploadUrl, {
      'data': image,
        'url': url,
        'userId': id,
        'mode': 'rotate',
        'folder': imageType,
        'view': view
    }).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }
}
