const ACTOR_TYPE = {
    AGENT: 'Agent',
    AUTO: 'Auto'
}

const SERVICE_TYPE_MAPPING = {
    bikeTaxi: 'app',
    delivery: 'delivery',
    auto: 'auto'
}

const PERMISSION_MAPPING = {
    EDIT_RIDER_ACCESS: ['edit_captain'],
    VIEW_ACCESS: ['view_captain'],
    EDIT_MOBILE_ACCESS: ['edit_captain_mobile'],
    EDIT_FLEET_ATTACH_DETACH_ACCESS: ['edit_fleet_attach_detach_access']
}

const ORDER_TYPE = {
    SME: 'sme'
};

const FILE_TYPE = {
    IMAGE: 'image'
}

const SUPPLY_PERMISSIONS = [
    'edit_captain', 'view_captain', 'edit_captain_mobile',
    'edit_merchant', 'view_merchant', 'edit_fleet_attach_detach_access'
];

const SUPPLY_DASHBOARD = {
    SOURCE: 'supply-dashboard'
}

const SUB_SERVICE_TYPES_MAPPING = {
    app: 'Bike Taxi',
    food: 'Food Delivery',
    alcohol: 'Alcohol Delivery',
    grocery: 'Grocery Delivery',
    others: 'Other Delivery',
    auto: 'Auto',
    premium: 'Rapido Plus',
    rental: 'Rental'
}

const SERVICE_MANAGER_ASSETS = {
    app: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/service-icons/captain_service_manager_bike_taxi.png',
    rental: 'https://cust-rapido-images.s3.amazonaws.com/70e6aa700f99c8d1f3469a9452efb675_1615192380837.png',
    food: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/service-icons/captain_service_manager_food.png',
    alcohol: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/service-icons/captain_service_manager_alcohol.png',
    grocery: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/service-icons/captain_service_managet_grocery.png',
    others: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/service-icons/captain_service_manager_package.png',
    auto: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/service-icons/captain_service_manager_auto.png',
    suspended: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/alert_message_icon_warning.png',
    premium: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/service-icons/captain_service_manager_bike_taxi.png',
    suspensionBottomSheet: 'https://cust-rapido-images.s3.ap-south-1.amazonaws.com/Group+2842.png'
}

const SERVICE_STATUS = {
    ACTIVE: 'active',
    SUSPENDED: 'suspended',
    INACTIVE: 'inactive',
    PROCESSING: 'processing',
}

const SUSPENSION_REASONS = {
    RIDER_REJECTED: 'rider_rejected',
    RIDER_REJECT: 'rider_reject',
    RIDER_BUSY: 'rider_busy',
    RATINGS: 'ratings',
    CUSTOMER_CANCELLED: 'customer_cancelled',
    CAPTAIN_DENIED_DUTY: 'captain_denied_duty',
    CHANGE_PAYMENT_MODE: 'change_payment_mode'
}

const LMS_TRAINING = {
    complete: 'complete',
    inComplete: 'incomplete'
}

const DOC_TYPES = {
    DL: 'DL',
    RC: 'RC',
    AADHAR: 'AADHAR',
    PAN: 'PAN'
}

const DOC_TYPES_MAPPING = {
    driverLicense: 'license',
    aadhar: 'aadhar',
    panCard: 'panCard',
    profilePicture: 'profilePicture'
}

const SUPPLY_DASHBOARD_OPERATION_TYPE = "agent-operation";

const DOC_VERIFICATION_STATUS ={
    WARNING    : "Warning",
    ACCEPTED   : "Accepted",
    REJECTED   : "rejected",
    FAILED     : "Failed",
    IN_PROGRESS: "In Progress",
    VERIFIED   : "Verified",
    INITIATED  : "Initiated",
    PROCESSING : "processing",
    APPROVED   : "approved",
    ON_HOLD    : "onHold",
}


module.exports = {
    PERMISSION_MAPPING,
    ORDER_TYPE,
    FILE_TYPE,
    SUPPLY_PERMISSIONS,
    ACTOR_TYPE,
    SERVICE_TYPE_MAPPING,
    SUPPLY_DASHBOARD,
    SUB_SERVICE_TYPES_MAPPING,
    SERVICE_MANAGER_ASSETS,
    SERVICE_STATUS,
    SUSPENSION_REASONS,
    LMS_TRAINING,
    DOC_TYPES:DOC_TYPES,
    SUPPLY_DASHBOARD_OPERATION_TYPE,
    DOC_VERIFICATION_STATUS:DOC_VERIFICATION_STATUS,
    DOC_TYPES_MAPPING:DOC_TYPES_MAPPING,
}
