import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client';
import {HttpErrorResponse} from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { DataService } from '../data.service';
import {BlockedRoles} from './signin.model';


const httpOptions = {
  headers: new HttpHeaders({
    'user': '{"_id":"' + localStorage.getItem('userId') + '"}'
  })
};

export interface Config {
  heroesUrl: string;
  textfile: string;
}

@Injectable()
export class SigninService {
  server: string = environment.SERVER;

  loginUrl = process.env.SERVER + '/api/login';
  userUrl = process.env.SERVER + '/api/getUser';
  rolesUrl = process.env.SERVER + '/api/getAccessRoles';
  blockedRolesUrl = process.env.SERVER + '/api/blockedUsersFromEditingCaptain';
  userAccessUrl = process.env.SERVER + '/api/auth/userAccess';
  private accessRoles = [];

  constructor(private http: MyHttpClient,
              private dataService: DataService) {}


  getLoginResponse(data) {
    return this.http.post(
        this.loginUrl, data).pipe(catchError(this.handleError));
  }

  getUserAccessToken(token) {
    return this.http.post(this.userAccessUrl, {'accessToken': token});
  }

  getUserInfo(email) {
    return this.http.post(this.userUrl, {'email': email});
  }

  setAccessRoles(roles) {
    this.accessRoles = roles;
  }

  getBlockedRoles(): Observable<BlockedRoles> {
    return this.http.get<BlockedRoles>(this.blockedRolesUrl);
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  fetchAccessRoles(roles) {
    return this.http.post(this.rolesUrl, {'roles' : roles});
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }

  makeIntentionalError() {
    return this.http.get('not/a/real/url')
      .pipe(
        catchError(this.handleError)
      );
  }
}
