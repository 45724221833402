import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  _toBeReload = false;
  _merchantToBeReload = false;
  captainCount = 0;
  merchantCount = 0;

  set toBeReload(value: boolean) {
    this.captainCount++;
  }
  get toBeReload() {
    if (this.captainCount === 10) {
      return true;
    }
    return false;
  }

  set merchantToBeReload(value: boolean) {
    this.merchantCount++;
  }
  get merchantToBeReload() {
    if (this.merchantCount === 10) {
      return true;
    }
    return false;
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }
  constructor() { }
}
