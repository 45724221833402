import { Injectable } from '@angular/core';

import { ToastMessage } from '../../toast-message/toast-message.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantInfoValidationService {

  constructor(private toastMessage: ToastMessage) { }

  validateStoreName(storeName) {
    const onlyAlphaRegex = /^[A-Za-z0-9 ]+$/;
    if (!storeName) {
      this.toastMessage.openSnackBarError('Store name is a mandatory field');
      return false;
    }
    if (storeName.length < 5) {
      this.toastMessage.openSnackBarError('Store name should have minimum of five characters.Please try again');
      return false;
    }
    if (!onlyAlphaRegex.test(storeName)) {
      this.toastMessage.openSnackBarError('Store name contains invalid characters. Please enter a valid store name');
      return false;
    }
    return true;
  }

  validateOwnerName(ownerName) {
    const onlyAlphaRegex = /^[A-Za-z ]+$/;
    if (!ownerName) {
      this.toastMessage.openSnackBarError('Owner name is a mandatory field');
      return false;
    }
    if (ownerName.length < 5) {
      this.toastMessage.openSnackBarError('Owner name should have minimum of five characters. Please try again.');
      return false;
    }
    if (!onlyAlphaRegex.test(ownerName)) {
      this.toastMessage.openSnackBarError('Owner name contains invalid characters. Please enter a valid owner name.');
      return false;
    }
    return true;
  }

  validateStoreAddress(address) {
    const onlyAlphaRegex = /^[A-Za-z0-9 ,\n()_:#-/]+$/;
    if (!address.trim()) {
      this.toastMessage.openSnackBarError('Store Address is a mandatory field');
      return false;
    }
    if (address.length <  10) {
      this.toastMessage.openSnackBarError('Address should have minimum of 10 characters. Please try again.');
      return false;
    }
    if (!onlyAlphaRegex.test(address.trim())) {
      this.toastMessage.openSnackBarError('Address contains invalid characters. Please enter a valid store address');
      return false;
    }
    return true;
  }
}
