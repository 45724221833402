import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';


import { MyHttpClient } from '../../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MoreInfoService {
  server: string = environment.SERVER;
  riderDetailsJSON = '../src/stubs/riderDetails.json';
  vehiclesListJSON = '../src/stubs/vehiclesList.json';
  citiesListJSON = '../src/stubs/cities.json';

  getShiftsURL = process.env.SERVER + '/api/get/shifts';

  rejectRiderURL = process.env.SERVER + '/api/rejectProfile';
  checkSlab = process.env.SERVER + '/api/checkSlab';
  getB2bShiftsURL = process.env.SERVER + '/api/get/b2b/shifts';
  getCUSelectorsURL = process.env.SERVER + '/api/crossUtilization/selectors/';
  getCURiderSelectorsURL = process.env.SERVER + '/api/crossUtilization/selectors/rider';
  getCURemoveRiderSelectorURL = process.env.SERVER + '/api/crossUtilization/selectors/rider/remove';
  getCUAddRiderSelectorURL = process.env.SERVER + '/api/crossUtilization/selectors/rider/add';

  captainUrl = process.env.SERVER + '/api/captain';
  riderUrl = process.env.SERVER + '/api/rider';
  paymentLinkUrl = process.env.SERVER + '/api/sendPaymentLink';
  fetchBalnceUrl = process.env.SERVER + '/api/fetchBalance';

  getCabDataUrl = process.env.SERVER + '/api/cab';
  getVehiclesListURL = process.env.SERVER + '/api/get/vehicleModel';

  constructor(private http: MyHttpClient) {}

  sendPaymentLink(mobile, riderId){
    const payload = { mobile,  riderId};
    return this.http.post(`${this.paymentLinkUrl}`, payload).pipe(catchError(this.handleError));
  }

  fetchBalance(riderId){
    return this.http.get(`${this.fetchBalnceUrl}/${riderId}`).pipe(catchError(this.handleError));
  }
  
  getShiftsList(city) {
    return this.http.get(this.getShiftsURL + '/' + city).pipe(catchError(this.handleError));
  }

  getB2bShiftList(city) {
    return this.http.get(this.getB2bShiftsURL + '/' + city).pipe(catchError(this.handleError));
  }

  getTLList() {
    return this.http.get(`${this.captainUrl}/tlList`).pipe(catchError(this.handleError));
  }

  getServicesList(city, mode) {
    const payload = { city, mode };
    return this.http.post(`${this.captainUrl}/serviceList`, payload).pipe(catchError(this.handleError));
  }

  getVehiclesList(modeId) {
    return this.http.get(`${this.getVehiclesListURL}?modeId=${modeId}`).pipe(catchError(this.handleError));
  }

  storeRiderActivateKafka(riderActivateObj) {
   return this.http.post(`${this.captainUrl}/servicesAssigned/kafkaEvent`, riderActivateObj).pipe(catchError(this.handleError));
  }

  assignTL(userId, tl, shiftName) {
    const payload = { userId, tl, shift: { 'name': shiftName, 'from': null, 'to': null }};
    return this.http.post(`${this.captainUrl}/assign/tl`, payload)
                .pipe(catchError(this.handleError));
  }

  assignServicesTlShift(userId, city, services, previousSelectedCity, tl, shift) {
    const payload = { userId, city, services, lyte: { added: [], removed: [] }, previousSelectedCity, tl, shift };
    return this.http.post(`${this.captainUrl}/assign/services`, payload)
                .pipe(catchError(this.handleError));
  }

  enableRedeem(riderId, shift, city) {
    const payload = { riderId, shift, city };
    return this.http.post(`${this.captainUrl}/enable/redeem`, payload);
  }

  activateRider(userId, reason, remarks, selectorList, actor, city) {
    const payload = { userId, reason, remarks, selectorList, actor, city };
    return this.http.post(`${this.captainUrl}/activateCaptain`, payload)
                .pipe(catchError(this.handleError));
  }
   activateFleetOfRider(riderId, reason, remarks, userId) {
    const payload = {
      "reason": reason,
      "remarks": remarks,
      "actor": userId
  }
    return this.http.put(`${this.riderUrl}/activateFleetRider/${riderId}`, payload)
                .pipe(catchError(this.handleError));
   }

  removeService(userId, serviceDetailId, city) {
    const payload = { userId, serviceDetailId, city };
    return this.http.post(`${this.captainUrl}/removeService`, payload)
                .pipe(catchError(this.handleError));
  }

  getUserPreference(userId, cityId) {
    const payload = { userId, cityId };
    return this.http.post(`${this.captainUrl}/userPreferences`, payload)
                .pipe(catchError(this.handleError));
  }

  deactivateRider(userId, message, remarks, city) {
    const payload = { userId, message, remarks, city};
    return this.http.post(`${this.captainUrl}/deactivateCaptain`, payload )
                .pipe(catchError(this.handleError));
  }

  checkSlabExist(service, shift) {
    return this.http.get(`${this.captainUrl}/checkSlab/${service}/${shift}`).pipe(catchError(this.handleError));
  }

  getCabData(userId) {
    return this.http.get(this.getCabDataUrl + `/${userId}`).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }
}
