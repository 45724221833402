import { Component, OnInit, Input, OnChanges, EventEmitter, SimpleChanges, Output } from '@angular/core';
import { MoreInfoService } from './more-info.service';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { BasicInfoService } from '../basic-info/basic-info.service';
import { PopUpMessageComponent } from '../../pop-up-message/pop-up-message.component';
import { MatDialog } from '@angular/material';

import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { LogService } from '../../log-service.service';
import { ConstantsService } from '../../constants.service';
const _ = require('lodash');


@Component({
  selector: 'more-info',
  template: require('./more-info.component.html'),
  providers: [MoreInfoService, ToastMessage, LogService, BasicInfoService]
})
export class MoreInfoComponent implements OnInit, OnChanges {
  @Input() data: any;

  @Input() TLList: any;
  @Input() cities: any;
  @Input() allowEditing: any;
  @Input() allowFleetEdit: any
  @Input() userpreferences : any;

  @Output() riderActive: EventEmitter<any> = new EventEmitter();
  @Output() fetchData: EventEmitter<any> = new EventEmitter();
  @Input() userIdentity: any = {};
  public citiesList = [];
  public cityListArray = [];
  public cityNames = [];
  public teamLeads = [];
  public teamLeadsArray = [];
  public infoData: any;
  public services = [];
  public servicesList = [];
  public citySelected: any;
  public serviceSelected = [];
  public tlSelected: any;
  public tlName: any;
  public tlSelectedName: any;
  public shiftSelected: any;
  public shifts = [];
  public currentCity: any;
  public vehicleData: any;
  public assignedServices = [];
  public assignedServiceId = [];
  public servicesToBeRemoved = [];
  public assignedCity: any;
  public userInitialData: any = {};
  public b2bShifts = [];
  public kafkaServiceEvent = false;
  public kafkaShiftEvent = false;
  public teamLeader;
  public riderId;
  public isModeCar = false;
  public permitApproved = false;
  public fitnessApproved = false;
  public insuranceApproved = false;
  public trainingCompleted = false;
  public trainingInitiated = false;
  public foodDelivery = "Food Delivery"
  public showCabPremium = false;

  public userSelectorList = []; // Cross utilization user selector list
  public userSelectorListOfRider = []; // List of user selectors a rider is part of (cross utilization)

  public workingTypes = Object.values(this.constantsService.mapWorkType);
  public mapWorkingTypes = this.constantsService.workingTypes;
  public availableTime = this.constantsService.availableTimeForCaptain;
  public availableDays = this.constantsService.availableDaysForCaptain;

  public occupationList = [];
  public workType: string;
  public occupation: string;
  public otherOccupationRemarks: string;
  public preferredDay: string;
  public preferredTime: string;

  public userServicePreference = {};
  public vehiclesList = [];
  public showCaptainPreferences = false;
  public subServiceTypeMapping = this.constantsService.subServiceTypeMapping;
  public inactiveServices = [];
  public suspendedServices = [];
  public requestedServices = [];
  public previousSelectedCity: any;
  public newPhoneNumber: string;
  public showBalance = false;
  public balance = "";
  public isAssureServiceEnabled = false;
  public assureDetails = {};
  public assureFees;

  deactivationReasons = ['Don’t have a valid DL', 'Don’t have a helmet', 'Don’t have RC', 'Don’t have bike insurance',
    'Don’t have a bike', 'Don’t have NOC', 'Don’t have android phone', 'Mobile device doesn’t support the app',
    'Captain not interested', 'Captain has issues with salary', 'Captain has absconded', 'Captain not responding', 'Captain got another job',
    'Captan moved out of Bangalore', 'Captain started their own business', 'Captain hasn’t attended training', 'Captain joined another company',
    'Quality issue', 'Operational... ',
    'Police verification not available', 'Pan Card not available', 'Address proof not available',
    'Vehicle pollution check not available', 'Mandatory documents expired',
    'Vehicle Issue', 'Wrong Number/Invalid Number', 'Others'
  ];

  activationReasons = ['Captain Hub', 'Hotel Branding', 'Juice center Branding', 'Condiments Branding',
    'Instagram', 'Playstore', 'Bulk SMS', 'Indeed Ad', 'Bike Tag', 'Parking ticket - Theatre',
    'Indeed Ad', 'Cloth Banner', 'Sunpac', 'Sunboard', 'No Smoking Poster', 'Cloth Banner 3/2', 'Olx',
    'FB', 'Walk In', 'Referral', 'Google', 'Quikr', 'Banner', 'Poster', 'Cine slide',
    'Friend (Without Referral code)', 'News paper add', 'Customer App', 'YouTube', 'Just Dial', 'Others'];

  terminationReasons = ['Behavioral Issues', 'Fake Rides', 'Order cancellations', 'Irregular in Job',
    'Breaching Code of Conduct', 'Duplicate Profiles', 'Harassment case'];
  rejectReasons = ['Vehicle Not matching Criteria', 'Vehicle Already assigned', 'No DL', 'No Bike', 'No Android Mobile',
    'Duplicate Registration', 'Fake Documents', 'Not Interested', 'North Bangalore', 'Not Happy with Payment Structure',
    'Pooling Facility', 'Out Of Operation Area', 'Just Enquiry', 'Just need to Rent their bike', 'Hailing Service',
    'Registered By Mistake (Instead of Customer App Registered in Captain App)'];
  
  disableAssignShiftServiceBtn: boolean;

  constructor(
    private _moreInfoService: MoreInfoService,
    private _toastMessage: ToastMessage,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private _basicInfoService: BasicInfoService,
    private _logService: LogService,
    private constantsService: ConstantsService,
    private dialog: MatDialog,
  ) {
    const cookies = document.cookie.split(';');
    const userCookie = cookies.find(function (item) {
      return item.includes('_id');
    });
    if (!userCookie) {
      alert('Please login again. Cookies not found');
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }
    this.getShifts(this.cityListArray[this.assignedCity]);
    let city = this.infoData && this.infoData.city && this.infoData.city._id;

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.userpreferences){
      this.getUserPreference();
    }
    if (changes.data) {
      this.infoData = changes.data.currentValue || {};
      if (this.infoData.modeId && (this.infoData.modeId.mode == "Car")){
        this.isModeCar = true;
        this.getCabData(this.infoData.userId._id)
      }
      this.userInitialData = _.cloneDeep(changes.data.currentValue);
      this.tlSelected = this.infoData.tl;
      this.tlName = this.tlSelected ? this.tlSelected.firstName : '';
     
      if (this.infoData.shift && this.infoData.shift.name) {
        this.shiftSelected = this.infoData.shift.name;
      }
      if (this.infoData.vehiclesAssignable.length > 1) {
        this.vehicleData = this.infoData.vehiclesAssignable[this.infoData.vehiclesAssignable.length - 1];
      }
      if (this.infoData.vehiclesAssignable.length === 1) {
        this.vehicleData = this.infoData.vehiclesAssignable[0];
      }
      if (this.infoData.city && this.infoData.city._id) {
        // populate list of services on load if registered city has a value
        const mode = this.infoData && this.infoData.modeId && this.infoData.modeId._id || ""
        this.getServicesList(this.infoData.city._id, mode);
        this.riderId = this.infoData.userId._id;
        this.currentCity = this.infoData.city._id;
        this.assignedCity = this.infoData.city._id;
      }
    }
    if (changes.TLList) {
      for (const key in changes.TLList.currentValue) {
        if (changes.TLList.currentValue[key]) {
          this.teamLeads.push(changes.TLList.currentValue[key]);
        }
      }
      this.teamLeadsArray = this.teamLeads.reduce((acc, item) => {
        acc[item._id] = item.firstName;
        return acc;
      }, {});

      this.tlSelectedName = this.infoData.tl ? this.teamLeadsArray[this.infoData.tl._id] : '';
      if (this.infoData.tl && !this.tlSelectedName) {
        this.tlSelectedName = this.infoData.tl;
      }
    }

    if (changes.cities) {
      for (const key in changes.cities.currentValue) {
        if (changes.cities.currentValue[key]) {
          this.citiesList.push(changes.cities.currentValue[key]);
        }
      }
      this.cityListArray = this.citiesList.reduce((acc, item) => {
        acc[item._id] = item.displayName;
        return acc;
      }, []);
      this.cityNames = this.citiesList.reduce((acc, item) => {
        acc.push(item.displayName);
        return acc;
      }, []);
      this.fetchData.emit(true);
    }
    this.setTrainingStatus();
  }

  setTrainingStatus() {
    let statusUpdates = this.infoData && this.infoData.statusUpdates || {}
    if (statusUpdates.lmsCompleted || statusUpdates.videoSeen) { this.trainingCompleted = true; }
    if (statusUpdates.lmsStarted) { this.trainingInitiated = true; }
  }

  getUserPreference() {

    return new Promise((resolve, reject) => {
      let response: any;
      response = this.userpreferences;

      if (response) {
        if (response.message === 'success') {
          this.userServicePreference = response.data && response.data.serviceTypePreferences
            && response.data.serviceTypePreferences || {};
          this.inactiveServices = this.userServicePreference["inactiveServices"].map(
            (inactiveService) => inactiveService.displayName
          );
          this.suspendedServices = this.userServicePreference["suspendedServices"].map(
            (suspendedService) => suspendedService.displayName
          );
          this.requestedServices = this.userServicePreference["requestedServices"].map(
            (requestedService) => requestedService.displayName
          );
          //disabling assure details on supply dashboard
          //this.filterAssureDetails(response);
        }

        if (
          this.inactiveServices.length > 0
          || this.suspendedServices.length > 0
          || this.requestedServices.length > 0
        ) {
          this.showCaptainPreferences = true;
        }
        resolve('success');
      }

      reject(new Error("something went wrong while fetching userpreferences"));

    }
    )
  };
  
  getVehiclesList() {
    let response;
    const modeId = this.infoData && this.infoData.modeId && this.infoData.modeId._id || ""
    this._moreInfoService.getVehiclesList(modeId).subscribe(resp => {
      response = resp;
      this.vehiclesList = response.data.vehicles;
    },
      err => {
        this._toastMessage.openSnackBarError('Could not retrieve list of vehicles');
        console.log(err);
      });
  }

  filterAssureDetails(result) {
    this.assureDetails = result && result.data && result.data.serviceOnboardingConfig && result.data.serviceOnboardingConfig &&
      result.data.serviceOnboardingConfig.serviceTypeProgress && result.data.serviceOnboardingConfig.serviceTypeProgress.delivery &&
      result.data.serviceOnboardingConfig.serviceTypeProgress.delivery.food || '';
      
      
    if (this.assureDetails && this.assureDetails['joiningFeeInfo'] && this.assureDetails['joiningFeeInfo']['baseAmount']) {
      const joiningFeeInfo = this.assureDetails['joiningFeeInfo'] || {};
      const len = joiningFeeInfo && joiningFeeInfo['transactionProgress'] && joiningFeeInfo['transactionProgress'].length || 0;
      const paymentStatus = len > 0 ? joiningFeeInfo['transactionProgress'][len - 1].status : " ";
      this.assureFees = this.calculateFinalAmount(parseInt(joiningFeeInfo['baseAmount']), parseInt(joiningFeeInfo['discountPercentage']));
      this.isAssureServiceEnabled = paymentStatus == "completed" ? true : false;
    }
  }
  calculateFinalAmount(baseAmount, discount) {
    return baseAmount - (baseAmount * discount) / 100;
  }


  getServicesList(city: any, mode: string) {
    let response;
    this.serviceSelected = [];
    this.assignedServices = [];
    this.assignedServiceId = [];
    if (this.cityNames.indexOf(city) >= 0) {
      this.citiesList.forEach((item) => {
        if (this.assignedCity && item.displayName === this.assignedCity) {
          city = item._id;
        }
      });
    }
    if(this.isModeCar){
      this.getVehiclesList();
      this.vehiclesList.forEach(vehicle=>{
        if (vehicle._id && vehicle._id == this.vehicleData.id.vehicleModel){
          if(vehicle.type == "premium"){
            this.showCabPremium = true
            return;
          }
        }
      })
    }
    this.spinnerService.show();
    const modeId = this.infoData && this.infoData.modeId && this.infoData.modeId._id || ""
    this._moreInfoService.getServicesList(city, modeId).subscribe(resp => {
      response = resp;
      this.services = response.data && response.data.data || [];
      this.servicesList = [];
      this.services.forEach(service => {
        if (service && service.service) {
          if (this.isModeCar && !this.showCabPremium){
            if (service.serviceType == "cab_premium" && service.subServiceType == "cab_premium"){
              return;
            }
          }
          this.servicesList.push({
            _id: service._id,
            name: service.service.name,
            description: service.service.description,
            serviceType: service.serviceType || '',
            subServiceType: service.subServiceType || '',
            active: service.service.active || false
          });
        }
        this.infoData.services.forEach((item) => {
          if (
            item._id === service._id && service && service.service
            && this.assignedServices.indexOf(service.service.name) < 0
          ) {
            this.assignedServices.push(service.service.name);
            if (this.assignedServiceId.indexOf(service._id) < 0) {
              this.assignedServiceId.push(service._id);
            }
          }
        });
      });
      this.serviceSelected = this.assignedServiceId;
      this.spinnerService.hide();
    },
      error => {
        this.spinnerService.hide();
        const errorMessage = error && error.error && error.error.message || 'Unable to fetch list of services';
        this._toastMessage.openSnackBarError(errorMessage);
      });
  }

  getSelectedCity(evt: any) {
    this.previousSelectedCity = this.infoData.city && this.infoData.city._id;
    const currentCityId = this.assignedCity;
    if (this.assignedCity && this.assignedCity.length > 0) {
      this.currentCity = this.cityListArray[this.assignedCity];
      if (this.assignedCity) {
        const mode = this.infoData && this.infoData.modeId && this.infoData.modeId._id || ""
        this.getServicesList(this.assignedCity, mode);
        this.getShifts(this.currentCity);
      }
    }
  }


  getCabData(riderId) {
    let doc;
    this._moreInfoService.getCabData(riderId).subscribe(
    response => {
        doc = response;
        if (doc.permit && doc.permit.approvalInfo && doc.permit.approvalInfo.approvedAt) {
          this.permitApproved = true;
        }
        if (doc.fitness && doc.fitness.approvalInfo && doc.fitness.approvalInfo.approvedAt) {
          this.fitnessApproved = true;
        }
        if (doc.insurance && doc.insurance.approvalInfo && doc.insurance.approvalInfo.approvedAt) {
          this.insuranceApproved = true;
        }
    },
    error => {
        const errorMessage = error && error.error && error.error.message || 'Could not fetch cab data';
        this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  getB2bShifts(city) {
    this._moreInfoService.getB2bShiftList(city).subscribe(response => {
      if (response && response['data'] && response['data'].length > 0) {
        this.b2bShifts = response['data'];
      }
    }, error => {
      const errorMessage = error && error.error && error.error.message || 'Could not fetch B2B shifts';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  getShifts(city) {
    let response;
    this.shiftSelected = '';
    this._moreInfoService.getShiftsList(city).subscribe(resp => {
      response = resp;
      if (response.data.length > 0) {
        this.shifts = response.data;
      } else {
        this._toastMessage.openSnackBarError('No shifts found for this city. Please select another city!');
        this.shifts = [];
      }
    },
      error => {
        const errorMessage = error && error.error && error.error.message || 'Could not fetch shifts';
        this._toastMessage.openSnackBarError(errorMessage);
      });
  }

  validateBeforeActivation() {
    const captain = this.infoData || {};
    const gender = [0, 1, 2];
    const mode = captain.modeId && captain.modeId.mode;
    const selectedGender = captain.userId && captain.userId.gender;
    const activatedOn = captain.statusUpdates && captain.statusUpdates.activatedOn;
    const isPanUploaded = captain.panCard && captain.panCard.link;
    const isPanVerified = captain.panCard && captain.panCard.verified;
    const isAadharVerified = captain.aadhar && captain.aadhar.verified;
    const isAadharFrontUploaded = captain.aadhar && captain.aadhar.frontLink;
    const isAadharBackUploaded = captain.aadhar && captain.aadhar.backLink;
    const  vehiclesAssignable = captain.vehiclesAssignable[0] && captain.vehiclesAssignable[0].id
    && captain.vehiclesAssignable[0].id || {};
    const rc = vehiclesAssignable.rc || {};

    if (!captain.firstName) {
      this._toastMessage.openSnackBarError('Please enter first name before activating captain');
      return false;
    } else if (!captain.birthDate) {
      this._toastMessage.openSnackBarError('Please enter birth date before activating captain');
      return false;
    } else if (!gender.includes(selectedGender)) {
      this._toastMessage.openSnackBarError('Please select gender before activating captain');
      return false;
    } else if (!mode) {
      this._toastMessage.openSnackBarError('Please select mode before activating the captain');
      return false;
    } else if (captain.profilePicture && !captain.profilePicture.verified) {
      this._toastMessage.openSnackBarError(
        'Please upload & approve profile picture before activating the captain'
      );
      return false;
    } else if (captain.license && !captain.license.verified) {
      this._toastMessage.openSnackBarError(
        'Please upload & approve driving license before activating the captain'
      );
      return false;
    } else if (!rc.verified) {
      this._toastMessage.openSnackBarError(
        'Please upload & approve registration card before activating the captain'
      );
      return false;
    } else if (this.infoData && this.infoData.userId && !this.infoData.mobileVerified) {
      this._toastMessage.openSnackBarError('Please verify captain mobile number to activate');
      return false;
    } else if (!this.infoData.tl || this.infoData.tl === '') {
      this._toastMessage.openSnackBarError('Please assign a TL before activating rider');
      return false;
    } else if (!this.infoData.shift || !this.infoData.shift.name || this.infoData.shift.name.length === 0) {
      this._toastMessage.openSnackBarError('Please assign a shift before activating rider');
      return false;
    } else if (!this.infoData.services) {
      this._toastMessage.openSnackBarError('Please assign services before activating rider');
      return false;
    } else if (this.isPanOrAadharNotVerified(activatedOn, isPanUploaded, isPanVerified,isAadharFrontUploaded, isAadharBackUploaded, isAadharVerified)) {
      this._toastMessage.openSnackBarError('PAN/Aadhar not uploaded or not approved by Captain.');
      return false;
    } else  if (!vehiclesAssignable.ownership) {
      this._toastMessage.openSnackBarError('Please select vehicle ownership before activating the captain');
      return false;
    } else  if (!vehiclesAssignable.fuelType) {
      this._toastMessage.openSnackBarError('Please select vehicle fuel type before activating the captain');
      return false;
    }

    return true;
  }
private isPanOrAadharNotVerified(activatedOn, isPanUploaded, isPanVerified,isAadharFrontUploaded, isAadharBackUploaded, isAadharVerified){
  if(this.areBothPanAndAadharUploaded(isPanUploaded, isAadharFrontUploaded, isAadharBackUploaded)){
    return ((this.isPanNotUploaded(activatedOn, isPanUploaded, isPanVerified)) && 
    (this.isAadharNotUploaded(activatedOn, isAadharFrontUploaded, isAadharBackUploaded, isAadharVerified)));
  }else {
    return ((this.isPanNotUploaded(activatedOn, isPanUploaded, isPanVerified)) ||
    (this.isAadharNotUploaded(activatedOn, isAadharFrontUploaded, isAadharBackUploaded, isAadharVerified)));
  }
}

  private isAadharNotUploaded(activatedOn: any, isAadharFrontUploaded: any, isAadharBackUploaded: any, isAadharVerified: any) {
      if(!activatedOn && isAadharFrontUploaded && isAadharBackUploaded && !isAadharVerified){
        return true;
      }else {
        return false;
      }
  }

  private areBothPanAndAadharUploaded(isPanUploaded, isAadharFrontUploaded,isAadharBackUploaded){
    if (isPanUploaded && isAadharFrontUploaded && isAadharBackUploaded){
      return true;
    }else {
      return false;
    }
  }

  private isPanNotUploaded(activatedOn: any, isPanUploaded: any, isPanVerified: any) {
    if(!activatedOn && isPanUploaded && !isPanVerified){
      return true;
    } else {
      return false;
    }
  }

  activateFleetRider() {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Are you sure you want to activate the fleet for captain?',
        notes: [
          'Account will be activated with fleet',
        ],
        reason: 'activateFleet',
        buttonText: 'Activate',
        cancelText: 'Cancel',
        styles: {
          title: 'font-size-20 margin-bottom-30',
          actionClass: 'doc-details-change-confirm',
          note: 'margin-bottom-12 margin-top-30 color-grey'
        },
      },
    })

    dialogRef.afterClosed().subscribe((activateFleet) => {
      if (activateFleet) {
        this.activateFleetOfRider()
      }
    })
  }

  activateFleetOfRider() {
    this.spinnerService.show();
    this._moreInfoService.activateFleetOfRider(this.infoData._id, this.infoData.statusUpdates.activationReason,
      this.infoData.statusUpdates.activationRemarks, this.infoData.userId._id).subscribe((res: any) => {
      if (res && res.data) {
        this.infoData.active = true;
        this.riderActive.emit(true);
        this.spinnerService.hide();
      }
    }, error => {
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not complete activation of fleet of captain';
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }
    
  activateUser() {
    console.log(this.userSelectorListOfRider);
    const selectorList = [];
    if (this.validateBeforeActivation()) {
      this.userSelectorListOfRider.map((selector) => selectorList.push(selector._id));
      this.spinnerService.show();
      this._moreInfoService.activateRider(
        this.infoData.userId._id, this.infoData.statusUpdates.activationReason,
        this.infoData.statusUpdates.activationRemarks, selectorList, 'Agent', this.infoData.city._id
      ).subscribe(resp => {
        this.infoData.active = true;
        this.riderActive.emit(true);
        this.spinnerService.hide();
        const metadata = Object.assign({}, this.userIdentity, {
          reason: this.infoData.statusUpdates.activationRemarks,
          type: 'profileDetail',
        });
        this._logService.insertEvent(5, this.infoData._id, { active: false },
          {
            active: true,
            activationRemarks: this.infoData.statusUpdates.activationRemarks,
            message: this.infoData.statusUpdates.activationReason
          },
          metadata
        ).subscribe();
        this._toastMessage.openSnackBarSuccess('Captain activation successful!');
      },
        error => {
          this.spinnerService.hide();
          const errorMessage = error && error.error && error.error.message || 'Could not activate captain';
          this._toastMessage.openSnackBarError(errorMessage);
        });
    }
  }

  deactivateUser() {
    let response;
    if (
      this.infoData.statusUpdates && this.infoData.statusUpdates.deActivateMessage
      && this.infoData.statusUpdates.deActivateRemarks
      && this.infoData.statusUpdates.deActivateMessage.length > 0
      && this.infoData.statusUpdates.deActivateRemarks.length > 0
    ) {
      this.spinnerService.show();
      this._moreInfoService.deactivateRider(this.infoData.userId._id, this.infoData.statusUpdates.deActivateMessage,
        this.infoData.statusUpdates.deActivateRemarks, this.infoData.city._id).subscribe(resp => {
          response = resp;
          this.spinnerService.hide();
          this.infoData.active = false;
          this.riderActive.emit(false);
          const metadata = Object.assign({}, this.userIdentity, {
            reason: this.infoData.statusUpdates.deActivateRemarks,
            type: 'profileDetail',
          });
          this._logService.insertEvent(5,
            this.infoData._id,
            { active: true },
            {
              active: false,
              remarks: this.infoData.statusUpdates.deActivateRemarks,
              message: this.infoData.statusUpdates.deActivateMessage
            },
            metadata).subscribe();
          this._toastMessage.openSnackBarSuccess('Deactivation successful. Please reload.');
          this._basicInfoService.logoutCaptain(this.infoData.userId._id).subscribe(res => {
            this.spinnerService.hide();
            this._logService.insertEvent(1, this.infoData._id, { logout: false }, { logout: true }).subscribe();
            this._toastMessage.openSnackBarSuccess('Logged out captain');
          }, error => {
            this.spinnerService.hide();
            const errorMessage = error.error && error.error.message ? error.error.message : 'Could not logout captain';
            this._toastMessage.openSnackBarError(errorMessage);
          });
        },
          error => {
            this.spinnerService.hide();
            const errorMessage = error && error.error && error.error.message || 'Could not deactivate captain';
            this._toastMessage.openSnackBarError(errorMessage);
          });
    } else {
      this._toastMessage.openSnackBarError('Make sure you have provided a reason and some remarks for deactivation');
    }
  }

  getTLNameFromID(evt) {
    let tlId;
    for (let teamLeadIndex = 0; teamLeadIndex < this.teamLeads.length; teamLeadIndex++) {
      if (this.tlName === this.teamLeads[teamLeadIndex].firstName) {
        this.tlSelected = this.teamLeads[teamLeadIndex];
        this.tlSelected._id;
      }
    }
    this.tlSelectedName = this.teamLeadsArray[tlId];
  }

  triggerKafkaEvent() {
    if (this.kafkaServiceEvent && this.kafkaShiftEvent) {
      this.kafkaServiceEvent = false;
      this.kafkaShiftEvent = false;
      const service = this.serviceSelected.length > 0 ? this.serviceSelected : this.assignedServiceId;
      const kafkaObj = {
        'userId': this.infoData && this.infoData.userId._id ? this.infoData.userId._id : '',
        'city': this.assignedCity ? this.assignedCity : '',
        'services': service ? service : '',
        'shift': this.shiftSelected ? this.shiftSelected : '',
        'tl': this.teamLeader ? this.teamLeader : '',
        'active': this.data && this.data.active ? this.data.active : false,
        'actor': 'Agent'
      };
      this._moreInfoService.storeRiderActivateKafka(kafkaObj).subscribe((result: any) => {
        if (result && result.data && result.data.info && result.data.info.status === 'success') {
          console.log('Service shift details updated to kafka');
        }
      }, err => {
        console.log('err in updating service shift details to kafka : ', err);
      });
    }
  }

  async assignTLandShift() {
    let response;
    for (let teamLeadIndex = 0; teamLeadIndex < this.teamLeads.length; teamLeadIndex++) {
      if (this.tlName === this.teamLeads[teamLeadIndex].firstName) {
        this.teamLeader = this.teamLeads[teamLeadIndex]._id;
      }
    }
    if (!this.tlName || !this.shiftSelected) {
      this._toastMessage.openSnackBarError('Select TL and Shift');
    } else {
      this._moreInfoService.assignTL(this.infoData.userId._id, this.teamLeader, this.shiftSelected).subscribe(resp => {
        response = resp;
        this._logService.insertEvent(5, this.infoData._id, {
          tl: this.userInitialData.tl,
          shift: this.userInitialData.shift && this.userInitialData.shift.name || ''
        },
          { tl: this.tlSelected, shift: this.shiftSelected }).subscribe();
        this.userInitialData.tl = this.tlSelected;
        this.userInitialData['shift'] = { name: this.shiftSelected };
        this._toastMessage.openSnackBarSuccess('Selected TL and Shift has been assigned to rider. You may reload using Shift + R');
        this.kafkaShiftEvent = true;
        this.triggerKafkaEvent();
      },
        error => {
          const errorMessage = error && error.error && error.error.message || 'Could not assign TL and shift';
          this._toastMessage.openSnackBarError(errorMessage);
        });
    }
  }

  getCityName(cityId) {
    let cityName;
    for (let index = 0; index < this.citiesList.length; index++) {
      if (this.citiesList[index]._id === cityId) {
        cityName = this.citiesList[index].displayName;
        break;
      }
    }
    return cityName;
  }

  getServiceName(serviceId) {
    let serviceName = [];
    for (let index = 0; index < this.servicesList.length; index++) {
      if (serviceId.includes(this.servicesList[index]._id)) {
        serviceName.push(this.servicesList[index].name);
      }
    }

    return serviceName;
  }

  confirmationForRemovingServics() {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Are you sure you want to remove all services for captain?',
        message: '',
        reason: 'removeServices',
        buttonText: 'Remove',
        styles: {
          title: 'deactivate-title',
          actionClass: 'deactivate-merchant-popup',
        }
      }
    });

    dialogRef.afterClosed().subscribe(removeServices => {
      if (removeServices) {
        this.removeAllServices();
      } else {
        const mode = this.infoData && this.infoData.modeId && this.infoData.modeId._id || ""
        this.getServicesList(this.assignedCity, mode);
      }
    });
  }

  removeAllServices() {
    let shouldRemoveServices = true;
    const removedServiceId = [], removedSubServiceType = [];
    this.servicesList.map(service => {
      if (this.assignedServices.includes(service.name)) {
        removedServiceId.push(service._id);
        if (!removedSubServiceType.includes(service.subServiceType)) {
          removedSubServiceType.push(service.subServiceType);
        }
        return;
      }
    });

    removedSubServiceType.map((type) => {
      const subServiceMapping = this.subServiceTypeMapping[type];
      if (this.suspendedServices.includes(subServiceMapping)) {
        shouldRemoveServices = false;
        const mode = this.infoData && this.infoData.modeId && this.infoData.modeId._id || ""
        this.getServicesList(this.assignedCity, mode);
        this._toastMessage.openSnackBarError(
          `${subServiceMapping} is suspended for this captain. You can not remove all services`
        );
      }
    });

    if (!shouldRemoveServices) { return; }
    this.assignedServices = [];
    this.assignedServiceId = [];
    this.servicesToBeRemoved.concat(removedServiceId);
  }

  checkForServicesInSlab(serviceDetailIdsForCaptain = []){

      return new Promise((resolve, reject) => {
        if (!this.shiftSelected) {
          reject("Please select Shift");
        }

        if(serviceDetailIdsForCaptain && serviceDetailIdsForCaptain.length < 0) {
          reject("Atlease one service to be added must be selected");
        }

        let shift = this.shiftSelected || '';
        this._moreInfoService.checkSlabExist(serviceDetailIdsForCaptain, shift).subscribe((res: any) => {
        const servicesNotAvailable = res && res.data && res.data.data || [];
        resolve(servicesNotAvailable) ;
        }, error => {
          console.log(error)
          reject("something went wrong while adding services")
        });
      });
  }

  addSelectedService(serviceAdded){

      const deliveryService = this.servicesList.find((service)=>service._id===serviceAdded && service.serviceType === "delivery");
      const subServiceTypeToBeAdded = deliveryService ? deliveryService.subServiceType : null;

      if (subServiceTypeToBeAdded) {
        this.spinnerService.show();
        const selectedServicesIds = [];
        const serviceNameMap = {};
        this.servicesList.map((service) => {
            if (service.subServiceType === subServiceTypeToBeAdded && service.active === true) {
              selectedServicesIds.push(service._id);
              serviceNameMap[service._id] = service.name
            }
        })
  
        this.checkForServicesInSlab(selectedServicesIds).then((servicesNotAvailable : any)=> {

          if( servicesNotAvailable.length === selectedServicesIds.length) {
            this.spinnerService.hide();
            this._toastMessage.openSnackBarError('Selected service is not active under the selected shift.');
          }
          servicesNotAvailable.map(sId => {
            const sIndex = selectedServicesIds.indexOf(sId);
            if (sIndex > -1) {
              selectedServicesIds.splice(sIndex, 1);
            }
  
            const sIdx = this.serviceSelected.indexOf(sId);
            if (sIdx > -1) {
              this.serviceSelected.splice(sIdx, 1);
            }
          })
  
          let serviceDetailIdsForCaptain = [...new Set([...this.serviceSelected.concat(selectedServicesIds)])];
  
          const selectedServicesNames = [];
          serviceDetailIdsForCaptain.forEach(sId => {
            selectedServicesNames.push(serviceNameMap[sId])
          })
          
          setTimeout(() => {
              this.serviceSelected = serviceDetailIdsForCaptain;
              this.assignedServiceId = serviceDetailIdsForCaptain;
              this.updateInfoDataServices(serviceDetailIdsForCaptain);
              // this.assignedServices = [...new Set([...this.assignedServices.concat(...selectedServicesNames)])];;
              this.spinnerService.hide();
          }, 0);
        }).catch(error => {
          this.spinnerService.hide();
          this._toastMessage.openSnackBarError(error);
          console.log("something went wrong while adding services", error)
        })
      }
      
  }


  removeSelectedService() {
    const selectedServiceName = [];
    let serviceRemoved, removedServiceId, removedSubServiceType;
    this.spinnerService.show()
    let city = this.infoData && this.infoData.city && this.infoData.city._id;
    this.getUserPreference().then(() => {
      this.serviceSelected.map((service => selectedServiceName.push(...this.getServiceName(service))));
      this.assignedServices.map(serviceName => {
        if (!selectedServiceName.includes(serviceName)) { serviceRemoved = serviceName; }
      });
      
      this.servicesList.map(service => {
        if (service.name === serviceRemoved) {
          removedServiceId = service._id;
          removedSubServiceType = service.subServiceType;
          return;
        }
      });
       
      const subServiceMapping = this.subServiceTypeMapping[removedSubServiceType];
      if (this.suspendedServices.includes(subServiceMapping)) {
        const mode = this.infoData && this.infoData.modeId && this.infoData.modeId._id || ""
        this.getServicesList(this.assignedCity, mode);
        this.spinnerService.hide()
        this._toastMessage.openSnackBarError(
          `${subServiceMapping} is suspended for this captain. You can not remove ${serviceRemoved}`
        );
        return;
      }

      if (this.assignedServices.indexOf(serviceRemoved) > -1) {
          this.assignedServices.splice(this.assignedServices.indexOf(serviceRemoved), 1);
      }

      if (this.assignedServiceId.indexOf(removedServiceId) > -1) {
          this.assignedServiceId.splice(this.assignedServiceId.indexOf(removedServiceId), 1);
      }
      
      this.spinnerService.hide()
      this.servicesToBeRemoved.push(removedServiceId)
  }).catch(err => {
    this.spinnerService.hide()
    this._toastMessage.openSnackBarError(
      `Oops ! Something went wrong. Please try again.`
    );
  })
  }

  removeServicesForCaptain(removedServiceId) {
    let city = this.assignedCity;
    if (this.cityNames.indexOf(city) >= 0) {
      this.citiesList.forEach((item) => {
        if (item.displayName === this.infoData.city._id) {
          city = item._id;
        }
      });
    }
    if (removedServiceId && removedServiceId.length > 0) {
      this._moreInfoService.removeService(this.infoData.userId._id, removedServiceId, city)
        .subscribe(() => {}, error => {
          console.log('Failed to remove service ' + error);
        });
    }
  }

  removeServices(removedServiceId, assignedSevices) {
    return new Promise((resolve, reject) => {
      let city = this.assignedCity;
      let finalServices = [];
      let assignedServiceMap = {};
      if (this.cityNames.indexOf(city) >= 0) {
        this.citiesList.forEach((item) => {
          if (item.displayName === this.infoData.city._id) {
            city = item._id;
          }
        });
      }

      if (removedServiceId.length === 0) {
       return resolve('no services to remove')
      }

      // remove union set from remove service list
      assignedSevices.forEach(service => {
        assignedServiceMap[service] = service
      });

      removedServiceId.forEach(service => {
        if (!assignedServiceMap[service]) {
            finalServices.push(service)
        }
      });

      console.log("services to be removed", finalServices)
      if (finalServices && finalServices.length > 0) {
        this._moreInfoService.removeService(this.infoData.userId._id, finalServices, city)
          .subscribe((result: any) => {
            if (result.message === 'success') {
              return resolve("success")
            }
          }, error => {
            console.log('Failed to remove service ' + error);
            return reject(error)
          });
      }

    });
  }

  getSelectedServiceNames(services) {
    const serviceNames = [];
    // tslint:disable-next-line: forin
    for (const sIndex in services) {
      for (const index in this.servicesList) {
        if (
          services[sIndex] === this.servicesList[index]._id
          && !serviceNames.includes(this.servicesList[index].name)
        ) {
          serviceNames.push(this.servicesList[index].name);
        }
      }
    }
    return serviceNames;
  }

  async assignCityServicesTlShift() {
    let response;
    let city = this.assignedCity;
    if (this.cityNames.indexOf(city) >= 0) {
      this.citiesList.forEach((item) => {
        if (item.displayName === this.infoData.city._id) {
          city = item._id;
        }
      });
    }
    this.serviceSelected = this.removeDuplicates(this.serviceSelected);
    for (let teamLeadIndex = 0; teamLeadIndex < this.teamLeads.length; teamLeadIndex++) {
      if (this.tlName === this.teamLeads[teamLeadIndex].firstName) {
        this.teamLeader = this.teamLeads[teamLeadIndex]._id;
      }
    }
    if (!this.tlName || !this.shiftSelected) {
      return this._toastMessage.openSnackBarError('Select TL and Shift');
    }
    if ((city && (this.serviceSelected.length > 0) || this.assignedServices.length > 0) && this.tlName && this.shiftSelected) {
      const service = this.serviceSelected.length > 0 ? this.serviceSelected : this.assignedServiceId;
      let isRemoveServiceApiFailed = false;
      // First we are removing service from userPreference collection to handle mutually exclusive services check.
      try {
        this.servicesToBeRemoved = this.removeDuplicates(this.servicesToBeRemoved);
        // assignServicesTlShift takes care of removing service as well
        // this.servicesToBeRemoved = this.servicesToBeRemoved.filter(ser => ser);
        // await this.removeServices(this.servicesToBeRemoved, service);
        this.servicesToBeRemoved = [];
      } catch (err) {
        isRemoveServiceApiFailed = true;
        const errorMessage = err && err.error && err.error.message || 'Could not remove services';
        this._toastMessage.openSnackBarError(errorMessage);
      }
      // Assigning service will happen only if when remove service api call response is success, this is as temp fix
      // to maintain service in rider collection document and userPreference collection document to be in sync
      if (!isRemoveServiceApiFailed) {
        try {
          await this._moreInfoService.assignServicesTlShift(this.infoData.userId._id, city, service, this.previousSelectedCity, this.tlSelected, this.shiftSelected)
              .toPromise();
          this.previousSelectedCity = '';
          this.infoData.city._id = city;
          this.infoData.city.displayName = this.getCityName(city);
          this.assignedServices = [];
          service.map((serviceId) => this.assignedServices.push(...this.getServiceName(serviceId)));
          this._logService.insertEvent(5, this.infoData._id,
              {city: this.userInitialData.city._id, service: this.userInitialData.services.map(s => s.service.name),
                tl: this.userInitialData.tl,
                shift: this.userInitialData.shift && this.userInitialData.shift.name || ''
              },
              {city: this.getCityName(city), service: this.getServiceName(service), tl: this.tlSelected, shift: this.shiftSelected},
              { source: 'supply-dashboard'}).subscribe();
          this.userInitialData.city = city;
          this.userInitialData.services = this.infoData.services;
          this.updateInfoDataServices(service);
          this._toastMessage.openSnackBarSuccess('Rider services, TL, and Shift have been updated in the system. You may reload using Shift + R');
          this.userInitialData.tl = this.tlSelected;
          this.userInitialData['shift'] = { name: this.shiftSelected };
          this.kafkaServiceEvent = true;
          this.triggerKafkaEvent();
        } catch (error) {
          const errorMessage = error && error.error && error.error.message || 'Could not assign city and services';
          this._toastMessage.openSnackBarError(errorMessage);
        }
      }
    } else {
      this._toastMessage.openSnackBarError('Please select a service, TL, and Shift before you assign services, TL and Shift');
    }
  }

  removeDuplicates(arr) {
    const arrCpy = [];
    for (let i = 0; i < arr.length; i++) {
      if (!arrCpy.includes(arr[i])) {
        arrCpy.push(arr[i]);
      }
    }
    return arr;
  }

  updateInfoDataServices (serviceDetailIds = []) {
    const newServices = [];
    this.services.map((servicedetail) => {
      if (serviceDetailIds.includes(servicedetail._id)) {
        newServices.push(servicedetail);
      }
    });
    this.infoData.services = newServices;
    this.userInitialData.services = newServices;
  }

  checkAppVersionForLogout() {
    let currentVersion =  parseInt(this.infoData.applicationVersion)
    if (
      currentVersion >= this.constantsService.appVersion.minAppVersionToAvoidLogout  && currentVersion < 299
      && this.infoData.active 
    ) {
        this.checkIfServicesAreEligible();
    } else if (currentVersion >= 299) {
        this.checkIfServicesAreEligible();
    } else {
      this.logoutCaptain();
    }
  }

  sendPaymentLinkToExistingNumber(){
    this.sendPaymentLink(this.infoData.mobile)
  }

  sendPaymentLinkToNewNumber(){
    this.sendPaymentLink(this.newPhoneNumber)
  }

  sendPaymentLink(mobile){
    this._moreInfoService.sendPaymentLink(mobile, this.infoData.userId._id)
      .subscribe(
        response => {
          if (response && response['message'] && response['message']['resp']) {
            this._toastMessage.openSnackBarSuccess('Payment link sent successfully');
          } else {
            this._toastMessage.openSnackBarError('Could not send payment link');
            console.error('Could not send payment link: ', response);
          }
        },
        error => {
          const errorMessage = error && error.error && error.error.message || 'Could not send payment link';
          this._toastMessage.openSnackBarError(errorMessage);
        }
      );
  }

  fetchWalletBalance(evt) {
      this.showBalance = evt.checked;
      if (this.showBalance){
        this._moreInfoService.fetchBalance(this.infoData.userId._id).subscribe(
          response => {
            this.balance = response['message'].balance
          },
          error => {
            const errorMessage = error && error.error && error.error.message || 'Could not fetch balance';
            this._toastMessage.openSnackBarError(errorMessage);
          }
        );
      }
  }

  logoutCaptain() {
    // 1. logout captain 2. Check if slab exist for service and shift 3. If yes assign service and shift
    return this._basicInfoService.logoutCaptain(this.infoData.userId._id).subscribe(res => {
      this.spinnerService.hide();
      this.checkIfServicesAreEligible();
      this._logService.insertEvent(1, this.infoData.userId._id, { logout: false }, { logout: true }).subscribe();
    }, error => {
      this.spinnerService.hide();
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not logout captain. Please try again.';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  checkIfServicesAreEligible() {
    const newlyAddedServices = [];
    const selectedServiceName = [];
    this.spinnerService.show();
    const city = this.assignedCity ? this.assignedCity : this.infoData && this.infoData.city && this.infoData.city._id;
    this.disableAssignShiftServiceBtn = true;

    this.getUserPreference().then(() => {
      this.disableAssignShiftServiceBtn = false;
      
      this.serviceSelected.map((service => selectedServiceName.push(...this.getServiceName(service))));
      selectedServiceName.map(serviceName => {
        if (!this.assignedServices.includes(serviceName)) {
          newlyAddedServices.push(serviceName);
        }
      });
      for (const newService in newlyAddedServices) {
        if (newlyAddedServices[newService]) {
          const serviceInfo = this.servicesList.filter((service) => service.name === newlyAddedServices[newService])[0] || {};
          const subService = this.subServiceTypeMapping[serviceInfo.subServiceType];
          this.spinnerService.hide()
          if (this.suspendedServices.includes(subService)) {
            this._toastMessage.openSnackBarError(
              `${subService} is suspended for the captain. Please remove ${newlyAddedServices[newService]}`
            );
            return;
          }
        }
      }
      
      this.spinnerService.hide()
      this.checkForConflictServices();

    }).catch(err => {
      this.disableAssignShiftServiceBtn = false;
      this.spinnerService.hide()
      this._toastMessage.openSnackBarError('Something went wrong! Please try again')
    }) 
  }

  checkForConflictServices() {
    const serviceNames = [];
    serviceNames.push(...(this.getSelectedServiceNames(this.serviceSelected)));
    serviceNames.push(...(this.getSelectedServiceNames(this.assignedServiceId)));
    const serviceMapping = this.constantsService.serviceMappings;
    if (
      (serviceNames.includes(serviceMapping.LINK)
        || serviceNames.includes(serviceMapping.HIRE))
      && serviceNames.includes(serviceMapping.AUTO)
    ) {
      this._toastMessage.openSnackBarError(
        'Bike taxi and Auto services cannot be assigned to the captain'
      );
      return;
    }
    this.checkSlab();
  }

  checkSlab() {
    if (!this.assignedCity) {
      this._toastMessage.openSnackBarError('Please select city');
      return;
    }
    if (!this.tlName) {
      this._toastMessage.openSnackBarError('Please select TL');
      return;
    }
    if (!this.shiftSelected) {
      this._toastMessage.openSnackBarError('Please select Shift');
      return;
    }
    let service = [], shift = '';
    const serviceName = [];
    if (this.serviceSelected.length > 0) {
      service = this.serviceSelected;
    } else if (this.assignedServices.length > 0) {
      service = this.assignedServiceId;
    }
    shift = this.shiftSelected;
    if (shift && service.length > 0) {
      this._moreInfoService.checkSlabExist(service, shift).subscribe((res: any) => {
        const data = res && res.data && res.data.data || [];
        if (data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            if (data[index]) {
              for (let i = 0; i < this.servicesList.length; i++) {
                if (this.servicesList[i]._id === data[index]) {
                  serviceName.push(this.servicesList[i].name);
                  break;
                }
              }
            }
          }
          this._toastMessage.openSnackBarError(
            `There is no slab available for shift ${this.shiftSelected} and service ${serviceName}`
          );
        } else {
          this.assignCityServicesTlShift();
        }
      }, error => {
        const errorMessage = error && error.error && error.error.message ||
          'Could not check if there is a slab for selected service and shift';
        this._toastMessage.openSnackBarError(errorMessage);
      });
    } else {
      this._toastMessage.openSnackBarError('Shift and Service are mandatory');
    }
  }

  changeOccupation(occupation) {
    this.otherOccupationRemarks = '';
  }
}