import { Injectable } from '@angular/core';
import { MyHttpClient } from '../../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

const cookies = document.cookie.split(';');

const userCookie = cookies.find(function (item) {
  return item.includes('_id');
});

const httpOptions = {
  headers: new HttpHeaders({
    'user': userCookie
  })
};

@Injectable()
export class MerchantInfoService {

  server: string = environment.SERVER;
  merchantURL = process.env.SERVER + '/api/merchant/';

  constructor(private http: MyHttpClient) { }

  updateStoreName(storeName, merchantId) {
    const payload = {
      merchantId: merchantId,
      storeName: storeName
    };

    return this.http.post(
      this.merchantURL + 'updateStoreName', payload).pipe(catchError(this.handleError)
    );
  }

  updateStoreOwner(storeOwner, merchantId) {
    const payload = {
      merchantId: merchantId,
      storeOwner: storeOwner
    };

    return this.http.post(
      this.merchantURL + 'updateStoreOwner', payload).pipe(catchError(this.handleError)
    );
  }

  updateStoreAddress(address, merchantId) {
    const payload = {
      merchantId: merchantId,
      address: address
    };

    return this.http.post(
      this.merchantURL + 'updateStoreAddress', payload).pipe(catchError(this.handleError)
    );
  }

  updateStoreEmail(storeEmail, merchantMobile, merchantId) {
    const payload = {
      email: storeEmail,
      merchantMobile: merchantMobile,
      merchantId: merchantId
    };

    return this.http.post(
      this.merchantURL + 'updateStoreEmail', payload).pipe(catchError(this.handleError)
    );
  }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    if (error && error.error && error.error.data && error.error.data.data) {
      return throwError(error.error.data.data);
    }

    if (error && error.error && error.error.data) {
      return throwError(error.error.data);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  makeIntentionalError() {
    return this.http.get('not/a/real/url')
      .pipe(
        catchError(this.handleError)
      );
  }

}
