import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectivePreloadingStrategy } from './selective-preloading-strategy';

import { OnboardingPageComponent } from './onboarding-page/onboarding-page.component';
import { SigninComponent } from './signin/signin.component';
// import { RiderListComponent } from './rider-list/rider-list.component';
import { SearchRiderComponent } from './search-rider/search-rider.component';
import { ModelSelectionComponent } from './model-selection/model-selection.component';
import { SearchMerchantComponent } from './onboarding-merchant/search-merchant/search-merchant.component';
import { OnboardingMerchantComponent } from './onboarding-merchant/onboarding-merchant.component';
import { RiderLogsComponent } from './rider-logs/rider-logs.component';

const appRoutes: Routes = [
  {
    path: 'onboarding',
    component: OnboardingPageComponent
  },
  {
    path: '',
    component: SigninComponent
  },
  // {
  //   path: 'riders',
  //   component: RiderListComponent
  // },
  {
    path: 'search',
    component: SearchRiderComponent
  },
  {
    path: 'modelSelection',
    component: ModelSelectionComponent
  },
  {
    path: 'searchMerchant',
    component: SearchMerchantComponent
  },
  {
    path: 'onboardingMerchant',
    component: OnboardingMerchantComponent
  },
  {
    path: 'riderLogs/:riderId',
    component: RiderLogsComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
        preloadingStrategy: SelectivePreloadingStrategy,

      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule { }
