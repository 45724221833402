import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

const cookies = document.cookie.split(';');

const userCookie = cookies.find(function (item) {
  return item.includes('_id');
});
if (userCookie) {
  const httpOptions = {
    headers: new HttpHeaders({
      'user': userCookie
    })
  };

}


@Injectable()
export class RiderListService {
  server: string = environment.SERVER;

  riderDetailsJSON = '../src/stubs/riderDetails.json';

  getAllRidersURL = process.env.SERVER + '/api/getAll';

  getRiderURL = process.env.SERVER + '/api/show';
  getRiderDl = process.env.SERVER + '/api/dl';
  getRiderRc = process.env.SERVER + '/api/rc';
  constructor(private http: MyHttpClient) {
}




  getAllRiders(limit, offset) {
    const query = '?limit=' + limit + '&offset=' + offset;
    return this.http.get(this.getAllRidersURL + query).pipe(catchError(this.handleError));

  }
  getRiderDetails(id) {
    return this.http.get(
        this.getRiderURL + '/' + id).pipe(catchError(this.handleError));
  }
  getRiderByDl(dl) {
    return this.http.get(
        this.getRiderDl + '/' + dl).pipe(catchError(this.handleError));
  }
  getRiderByRc(rc) {
    return this.http.get(
        this.getRiderRc + '/' + rc).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      if (typeof error === 'string') {
        return throwError(error);
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


}
