import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { ToastMessage } from '../../toast-message/toast-message.service';
import { MerchantInfoService } from '../merchant-info/merchant-info.service';
import { MerchantInfoValidationService } from './merchant-info-validation.service';
import { LogService } from '../../log-service.service';
const _ = require('lodash');

@Component({
  selector: 'app-merchant-info',
  template: require('./merchant-info.component.html'),
  providers: [ToastMessage, MerchantInfoService, MerchantInfoValidationService, LogService]
})
export class MerchantInfoComponent implements OnInit, OnChanges {

  public screenBreakpoint = false;
  public merchantBasicInfo: any;
  public merchantInitialInfo: any;
  public storeAddress: any;
  public city: any;
  public userId: any;
  public merchantInfoLogNumber = 7;

  @Input() merchantInfo: any;
  @Input() isValidToEditMerchant: any;

  storeNameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z ]{1, 25}')
  ]);

  storeOwnerNameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z ]{1, 18}')
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  mobileFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]{10}')
  ]);

  constructor(
    private toastMessage: ToastMessage,
    private merchantService: MerchantInfoService,
    private merchantInfoValidationService: MerchantInfoValidationService,
    private logService: LogService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
     if (changes && changes.merchantInfo && changes.merchantInfo.currentValue) {
        this.merchantBasicInfo = changes.merchantInfo.currentValue;
        if (this.merchantBasicInfo && !this.merchantBasicInfo.location) {
          this.merchantBasicInfo['location'] = {
            address: ''
          };
        }

        this.storeAddress = this.merchantBasicInfo && this.merchantBasicInfo.location && this.merchantBasicInfo.location.address;
        this.city = this.merchantBasicInfo && this.merchantBasicInfo.location && this.merchantBasicInfo.location.city;
        this.userId = this.merchantBasicInfo && this.merchantBasicInfo.user && this.merchantBasicInfo.user._id;
        this.merchantInitialInfo = _.cloneDeep(this.merchantInfo);
     }

     if (changes.isValidToEditMerchant && changes.isValidToEditMerchant.currentValue === false) {
      this.storeNameFormControl.disable();
      this.storeOwnerNameFormControl.disable();
      this.emailFormControl.disable();
    }
  }

  ngOnInit() {
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }
    if (window && window['jQuery'] && window['jQuery'](window)) {
      this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
    }
  }

  addEventsToLogService(oldData, newData) {
    this.logService.insertEvent(
      this.merchantInfoLogNumber,
      this.merchantBasicInfo._id,
      oldData, newData
    ).subscribe();
  }


  updateStoreName() {
    if (this.merchantInitialInfo.name.trim() === this.merchantBasicInfo.name.trim()) {
      this.toastMessage.openSnackBarError('Please change the value to update');
      return;
    }
    if (this.merchantInfoValidationService.validateStoreName(this.merchantBasicInfo.name.trim())) {
      this.merchantService.updateStoreName(
        this.merchantBasicInfo.name.trim(), this.merchantBasicInfo._id).subscribe(res => {
        this.addEventsToLogService(
          { name: this.merchantInitialInfo.name.trim() },
          { name: this.merchantBasicInfo.name.trim() }
        );
        this.merchantInitialInfo.name = this.merchantBasicInfo.name.trim();
        this.toastMessage.openSnackBarSuccess('Store name is updated');
      },
      error => {
        const errorMessage = error && error.error && error.error.message;
        this.toastMessage.openSnackBarError(errorMessage || 'Could not update store name');

      });
    }
  }

  updateOwnerName() {
    if (this.merchantInitialInfo.ownerName.trim() === this.merchantBasicInfo.ownerName.trim()) {
      this.toastMessage.openSnackBarError('Please change the value to update');
      return;
    }
    if (this.merchantInfoValidationService.validateOwnerName(this.merchantBasicInfo.ownerName.trim())) {
      this.merchantService.updateStoreOwner(
        this.merchantBasicInfo.ownerName.trim(), this.merchantBasicInfo._id
      ).subscribe(res => {
        this.addEventsToLogService(
          { ownerName: this.merchantInitialInfo.ownerName.trim() },
          { ownerName: this.merchantBasicInfo.ownerName.trim() }
        );
        this.merchantInitialInfo.ownerName = this.merchantBasicInfo.ownerName.trim();
        this.toastMessage.openSnackBarSuccess('Owner name is updated');
      },
      error => {
        const errorMessage = error && error.error && error.error.message;
        this.toastMessage.openSnackBarError(errorMessage || 'Could not update owner name');

      });
    }
  }

  updateEmail() {
    if (this.merchantInitialInfo.email === this.merchantBasicInfo.email) {
      this.toastMessage.openSnackBarError('Please change the value to update');
    } else {
      if (!this.merchantBasicInfo.email) {
        this.toastMessage.openSnackBarError('EmailId is mandatory');
      } else {
        this.merchantService.updateStoreEmail(this.merchantBasicInfo.email, this.merchantBasicInfo.mobile,
          this.merchantBasicInfo._id).subscribe(res => {
          this.addEventsToLogService(
              { email: this.merchantInitialInfo.email.trim() },
              { email: this.merchantBasicInfo.email.trim() }
          );
          this.merchantInitialInfo.email = this.merchantBasicInfo.email;
          this.toastMessage.openSnackBarSuccess('Email id is updated');
        },
        error => {
          const errorMessage = error && error.error && error.error.message;
          this.toastMessage.openSnackBarError(errorMessage || 'Could not update email id');

        });
      }
    }
  }

  updateStoreAddress() {
    if (this.merchantInitialInfo.location.address.trim() === this.merchantBasicInfo.location.address.trim()) {
      this.toastMessage.openSnackBarError('Please change the value to update');
    }
    if (this.merchantInfoValidationService.validateStoreAddress(this.merchantBasicInfo.location.address.trim())) {
      this.merchantService.updateStoreAddress(
        this.merchantBasicInfo.location.address.trim(), this.merchantBasicInfo._id
      ).subscribe(res => {
        this.addEventsToLogService(
          { address: this.merchantInitialInfo.location.address.trim() },
          { address: this.merchantBasicInfo.location.address.trim() }
        );
        this.merchantInitialInfo.location.address = this.merchantBasicInfo.location.address.trim();
        this.toastMessage.openSnackBarSuccess('Store Address is updated');
      },
      error => {
        this.toastMessage.openSnackBarError(error);

      });
    }
  }

  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;
  }

}
