import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class MyHttpClient {
  public cookies: any;
  public userCookie: any;
  public userIdCookie: any;
  public userId: any;
  private _headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient) { }

  setHeaderData() {

    //populates data for headers with latest cookies
    this.cookies = document.cookie.split(";");
    if(this.cookies){
      this.userCookie = this.cookies.find(function (item) {
        return item.includes('_id');
      })
    }
    if (this.userCookie) {
      this.userIdCookie = Object.values(JSON.parse(this.userCookie))[0];
    }
    if(this.userIdCookie){
      this.userId = '{"_id":"' + this.userIdCookie + '"}'
    }

  }


  get<T>(url, options = {}) {
    this.setHeaderData();
    const headers = this._headers.append('user', this.userId ? this.userId : '')
      .append('Expires', '0')
      .append('Cache-control', 'no-cache')
      .append('Cache-control', 'no-store')
      .append('Pragma', 'no-cache');

    return this.http.get<T>(url, { headers: headers, ...options });
  }

  post(url, data, config = {}) {
    this.setHeaderData();
    const headers = this._headers.append('user', this.userId ? this.userId : '')
      .append('Expires', '0')
      .append('Cache-control', 'no-cache')
      .append('Cache-control', 'no-store')
      .append('Pragma', 'no-cache');
    return this.http.post(url, data, { headers: headers, ...config });
  }

  put(url, data, config = {}) {
    this.setHeaderData();
    const headers = this._headers.append('user', this.userId ? this.userId : '')
      .append('Expires', '0')
      .append('Cache-control', 'no-cache')
      .append('Cache-control', 'no-store')
      .append('Pragma', 'no-cache');
    return this.http.put(url, data, { headers: headers, ...config });
  }

  getWithCache<T>(url, options = {}) {
    this.setHeaderData();
    const headers = this._headers.append('user', this.userId ? this.userId : '')
        .append('Cache-control', 'max-age=1200, private');
    return this.http.get<T>(url, { headers: headers, ...options });
  }
}
