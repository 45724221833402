import { Injectable } from '@angular/core';
import { MyHttpClient } from './http-client';
const typeMap = {
    1: 'basic-info',
    2: 'profile-picture',
    3: 'drivers-license',
    4: 'registration-card',
    5: 'more-info',
    6: 'image-view',
    7: 'merchant-info',
    8: 'gst-certificate',
    9: 'merchant-more-info',
    10: 'pan',
    11: 'aadhar',
    12: 'services',
    13: 'agent-operation',
    14: 'permit',
    15: 'fitness',
    16: 'insurance'
  };
@Injectable()
export class LogService {
  constructor(
    private http: MyHttpClient) {
  }
  logServiceCall = process.env.SERVER + '/api/logService';

    insertEvent(type, id, prev, changed, metadata = {}) {
        const payload = {
            type: typeMap[type],
            id: id,
            previous: prev,
            changed: changed,
            metadata: metadata,
            timeStamp: new Date().getTime()
        };
        return this.http.post(this.logServiceCall, payload);
    }

    insertEventWithType(type, id, prev, changed, metadata = {}) {
        const payload = {
            type: type,
            id: id,
            previous: prev,
            changed: changed,
            metadata: metadata,
            timeStamp: new Date().getTime()
        };
        return this.http.post(this.logServiceCall, payload);
    }
}
