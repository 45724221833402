import { Component, OnChanges, SimpleChanges, OnInit, EventEmitter, Input, Output, ViewChild,
  ElementRef, OnDestroy } from '@angular/core';

import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { ToastMessage } from '../../toast-message/toast-message.service';

import { LogService } from '../../log-service.service';

import { ConstantsService } from '../../constants.service';
import {ImageViewService} from '../../image-view/image-view.service';

@Component({
  selector: 'rapido-image',
  template: require('./rapido-image.component.html'),
  providers: [ToastMessage, ImageViewService, LogService, ConstantsService],
  styles: [`
    img{
      object-fit: cover;
      width: 100%;
      height: 150px;
    }
`]
})
export class RapidoImageComponent implements OnChanges, OnInit {

  public imagePublicUrl: string;

  @Input() radius: string;
  @Input() title: string;
  @Input() privateUrl: string;
  @Input() height: string;
  private S3_URL_REGEX: RegExp;
  constructor(private _hotkeysService: HotkeysService,
    private imageViewService: ImageViewService,
    private _toastMessage: ToastMessage,
    private spinnerService: Ng4LoadingSpinnerService,
    private _logService: LogService ) {
    this.S3_URL_REGEX = new RegExp(/(s3-|s3\.)?(.*)\.amazonaws\.com/);
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnInit() {
    console.log(this.privateUrl)
    if (!this.privateUrl) {
      return;
    }
    if (this.isS3Url(this.privateUrl)) {
      this.imagePublicUrl  = this.privateUrl;
      return;
    }
    const imageUrl = new URL(this.privateUrl)
    this.imageViewService.getPreSignedImageUrl(imageUrl.pathname + imageUrl.search).subscribe(response=>{
      this.imagePublicUrl = response['url'];
    }, error => {
      const errorMessage = error && error.error && error.error.message || 'Failed to image from filestore';
      this._toastMessage.openSnackBarError(errorMessage);
    });

  }

  isS3Url(url) {
    return this.S3_URL_REGEX.test(url);
  }
}
