// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { isNullOrUndefined } from 'util';

// The list of file replacements can be found in `angular.json`.
const env: any = window['__env'] || {};

export const environment = {
  production: false,
  googleAccountKey: '924479965589-7e64mcomrnv5ekb5gr1kjdja488qfp8f.apps.googleusercontent.com',
  SERVER: isNullOrUndefined(env.SERVER) ? 'http://localhost:8081' : env.SERVER,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
