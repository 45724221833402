import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ToastMessage} from '../../../toast-message/toast-message.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';

import {LogService} from '../../../log-service.service';
import {ConstantsService} from '../../../constants.service';
import {Subject} from 'rxjs';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {FaceAuthService} from './face-auth.service';

// @ts-ignore
const _ = require('lodash');
@Component({
    // tslint:disable-next-line:component-selector
  selector: 'face-auth',
  template: require('./face-auth.component.html'),
  styles:[``],
  providers: [FaceAuthService,ToastMessage, LogService, ConstantsService]
})
export class FaceAuthComponent implements OnInit, OnChanges {

  private authResultPageNumer = 0;
  private authResults: any;


  constructor(
    private _faceauthService: FaceAuthService,
    private _hotkeysService: HotkeysService,
    private _toastMessage: ToastMessage,
    private spinnerService: Ng4LoadingSpinnerService,
    private _logService: LogService,
    private constantService: ConstantsService) { }

  @Input() userIdentity: any = {};
  public screenBreakpoint = false;
  public allowEditing = true;
  public riderId: any;

  public asyncRefreshTrigger: Subject<void> = new Subject<void>();
  public attempts = {
    '1': '1st',
    '2': '2nd',
    '3': '3rd'
  };


  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;
  }

  setPageNumber(event){
    this.authResultPageNumer = event.pageIndex;
    console.log(event.pageIndex);
    this.getAuthResults();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.riderId = changes.userIdentity ? changes.userIdentity.currentValue.userId : this.riderId;
    if(this.riderId){
      this.getAuthResults();
    }
  }

  ngOnInit() {

  }

  getAuthResults() {
    this._faceauthService.getFaceAuthResults(this.riderId, this.authResultPageNumer).then(res => {
      // this.enrollmentInfo = e
      this.authResults = res;
    });
  }


}
