import { Component, OnInit } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { FormControl, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-search-merchant',
  template: require('./search-merchant.component.html'),
  providers: [ToastMessage, DataService]
})
export class SearchMerchantComponent implements OnInit {

  public merchantMobile: any;
  public overHeadPanelTitle = 'Merchant Onboarding';
  mobileFormControl = new FormControl('', [
    Validators.pattern('[0-9]{10}')
  ]);

  constructor(
    private router: Router,
    private toastMessage: ToastMessage,
    private dataService: DataService,
    private hotkeysService: HotkeysService
  ) {
    if (this.dataService.merchantToBeReload) {
      window.location.reload();
    }
    this.hotkeysService.add(new Hotkey(['m', 'M'], (event: KeyboardEvent): boolean => {
      document.getElementById('search-mobile').click();
      return false;
    }));
  }

  ngOnInit() {
  }

  goBack() {
    this.router.navigate(['/modelSelection']);
  }

  searchMerchantByMobile() {
    if (this.mobileFormControl.errors && this.mobileFormControl.errors.pattern) {
      this.toastMessage.openSnackBarError('Please enter a valid mobile number');
    } else {
      this.router.navigate(
          ['/onboardingMerchant'],
          { queryParams: { phone: this.merchantMobile }}
      );
    }
  }

}
