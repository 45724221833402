import {
    Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LogService } from '../../log-service.service';
import { ConstantsService } from '../../constants.service';
import { AadharService } from '../aadhar/aadhar.service';
import { OnboardingPageService } from '../onboarding-page.service';
import { Subject } from 'rxjs';
const constants = require('../../../../server/utils/constants');

const _ = require('lodash');
@Component({
    selector: 'app-aadhar-otp',
    template: require('./aadhar-otp.component.html'),
    providers: [AadharService]
})
export class AadharOtpComponent implements OnInit, OnChanges {
    aadharNumberFormControl = new FormControl('', [
        Validators.pattern('[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}'),
        Validators.required
    ]);
    verfiedMessage: string;

    constructor(private _aadharService: AadharService,
        private _toastMessage: ToastMessage,
        private spinnerService: Ng4LoadingSpinnerService,
        private _logService: LogService,
        private constantService: ConstantsService,
        private _onboardingService: OnboardingPageService) {
    }
    @Input() data: any;
    @Input() showFront = false;
    @Input() showBack = false;
    @Input() userIdentity: any;
    @Input() existingAadharStatus: any;
    @Input() allowEditing: any;
    @Input() aadharDoc: any;
    @Input() isAadharRejected: any;

    @Output() aadharStatus: EventEmitter<any> = new EventEmitter();
    @Output() fetchData: EventEmitter<any> = new EventEmitter();
    @Output() aadharFrontImage: EventEmitter<any> = new EventEmitter();
    @Output() aadharBackImage: EventEmitter<any> = new EventEmitter();
    @Output() addAadharToRejectList: EventEmitter<any> = new EventEmitter();

    public screenBreakpoint = false;
    public aadharData: any;
    public updateImageEnabled = false;
    public config: any;
    public userId: any;
    public aadharNumber: String;
    public name: String;
    public dob: String;
    public gender: String;
    public aadharAutoApproved = false;
    public existingAadharNumber: String;
    public remarks: String;
    public riderId: any;
    public imgFront: any;
    public imgBack: any;
    public imgFrontData: any;
    public imgBackData: any;
    public showApproveButton = false;
    public aadharRemarks = [];
    public aadharApprovalStatus = 'Approved';
    public aadharApprovalMessage: any;
    public isAadharApproved = false;
    public APPROVAL_MESSAGE = this.constantService.approvalMessage;

    public isAadharDocStatusAvailable: boolean;
    public isAadharVerified = false;
    public aadharVerifyStatus: any;
    public aadharVerifyMessage: any;
    public verificationPlatform: any;
    public verifiedOn: any;
    public thirdPartyMessage: any;
    public thirdPartyStatus: any;
    public isVerifiedViaOtp: Boolean = false;

    public verificationStatus = {
        ACCEPTED: 'Accepted',
        WARNING: 'Warning',
        REJECTED: 'Rejected',
        VERIFIED: 'Verified'
    };

    public asyncRefreshTrigger: Subject<void> = new Subject<void>();

    private invalidAadhar = 'Aadhar is a mandatory field. It can not contain any' +
    ' special characters and has a limit of 12 characters';
    private isExistingAadhar = 'Aadhar number already exists.';

    private rotateFrontImageDegrees = 0;
    private rotateBackImageDegrees = 0;
    onResize(event) {
        this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;

    }

    addToBulkRejection(event) {
        this.addAadharToRejectList.emit(event);
    }

    updateAadharNumber() {
        return new Promise(() => {
        if (this.isInvalid()) {
            this._toastMessage.openSnackBarError(this.invalidAadhar);
            this.spinnerService.hide();
        } else if (this.isExisting()) {
            this._toastMessage.openSnackBarError(this.isExistingAadhar);
            this.spinnerService.hide();
        } else {
            this._aadharService.updateNumber(this.data.userId._id, this.aadharNumber).subscribe(res => {
                this.showApproveButton = true;
                this.isAadharApproved = false;
                this.isAadharVerified = false;
                this.spinnerService.hide();
                this._logService.insertEvent(11, this.data._id, { number: this.existingAadharNumber },
                    { number: this.aadharNumber }).subscribe();
                this.existingAadharNumber = res["data"]["aadhar"]["number"];
                this._toastMessage.openSnackBarSuccess('Aadhar number is updated!');
            },
                error => {
                    this.spinnerService.hide();
                    const errorMessage = error && error.error && error.error.message || 'Could not update aadhar number';
                    this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
                });
        }
    });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.riderId = changes.data.currentValue.userId._id;
            await this._onboardingService.getRiderDetails(this.riderId).subscribe((res: any) => {
            this.aadharData = res.data;
            this.isVerifiedViaOtp = !!(this.aadharData && this.aadharData.aadhar && this.aadharData.aadhar.isOtpRequired)
            this.aadharNumber = this.aadharData.aadhar.number;
            if (this.isVerifiedViaOtp) {
                this.name = this.getFullNameFromAadhar()
                this.dob = this.getBirthDateFromAadhar()
                this.gender = this.getGenderFromAadhar()
            }

            this.existingAadharNumber = this.aadharData.aadhar.number;
            this.remarks = this.aadharData.aadhar && (this.aadharData.aadhar.remarks || this.aadharData.aadhar.rejectRemarks)
            this.isAadharDocStatusAvailable = this.isAadharDocStatusPresent(this.aadharData.docVerificationStatus);
            this.thirdPartyMessage = this.aadharData.docVerificationStatus && this.isAadharDocStatusAvailable ? this.aadharData.docVerificationStatus.aadhar.message : '';
            this.thirdPartyStatus = this.aadharData.docVerificationStatus && this.isAadharDocStatusAvailable ? this.aadharData.docVerificationStatus.aadhar.status : '';
            this.isAadharVerified = this.thirdPartyStatus === this.verificationStatus.VERIFIED || this.thirdPartyStatus === this.verificationStatus.ACCEPTED;
            this.verificationPlatform = this.aadharData.docVerificationStatus && this.isAadharDocStatusAvailable ? this.aadharData.docVerificationStatus.aadhar.verifiedBy : '';
            this.verifiedOn = this.aadharData.docVerificationStatus && this.isAadharDocStatusAvailable ? this.aadharData.docVerificationStatus.aadhar.verifiedOn : '';
            this.riderId = this.aadharData.userId._id;
            this.imgFront = this.isVerifiedViaOtp ? this.getImageFromAadhar() : this.aadharData.aadhar.frontLink;
            this.imgBack = this.isVerifiedViaOtp ? "" : this.aadharData.aadhar.backLink;
            if ((this.isVerifiedViaOtp && this.aadharData.aadhar && this.aadharData.aadhar.number) && (this.aadharData.aadhar.verified === false && this.aadharData.aadhar.status != "rejected")){
                this.showApproveButton = true;
            }
            if ((!this.isVerifiedViaOtp && this.aadharData.aadhar.frontLink && this.aadharData.aadhar.backLink) && (this.aadharData.aadhar.verified === false)) {
                this.showApproveButton = true;
            }
            if ((changes && changes.allowEditing && changes.allowEditing.currentValue === false) || this.isVerifiedViaOtp) {
                this.aadharNumberFormControl.disable();
            }
            if (this.aadharData && this.aadharData.aadhar && this.aadharData.aadhar.verified) {
                this.formApprovalMessage();
            }
        });
        }
    }

    ngOnInit() {
        if (document.getElementById('BlowupLens')) {
            if (window) {
                window['jQuery']('#BlowupLens').show();
            }
        }
        this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
    }

    formApprovalMessage() {
        const aadharAutoApprovalStatus = this.aadharData['autoApprovalStatus'] || {};
        this.isAadharApproved = true;
        if (  aadharAutoApprovalStatus['aadharVerificationStatus']
              && aadharAutoApprovalStatus['aadharVerificationStatus'].autoApproved
          ) {
            this.aadharAutoApproved = true;
            this.aadharApprovalMessage = this.APPROVAL_MESSAGE.autoApproved;
        } else {
          this.aadharApprovalMessage = this.APPROVAL_MESSAGE.agentApproved;
        }
    }

    isAadharDocStatusPresent(docVerificationStatus) {
        if (docVerificationStatus) {
            return docVerificationStatus.aadhar ? true : false;
        }
    }

    rotateImage(link, degrees, key) {
        if (key === 'front') {
            this.rotateFrontImageDegrees += degrees;
            degrees = this.rotateFrontImageDegrees;
        } else {
            this.rotateBackImageDegrees += degrees;
            degrees = this.rotateBackImageDegrees;
        }
        let response;
        this.spinnerService.show();
        this._aadharService.rotateImage(link, degrees).subscribe(resp => {
            response = resp;
            if (key === 'front') {
                this.imgFrontData = response.data;
                setTimeout(() => {
                    this.updateImageEnabled = true;
                    window['jQuery'](document).ready(function () {
                        window['jQuery']('#displayImage').attr('src', response.data).blowup({
                            'width': 400,
                            'height': 400,
                            'border': '1px solid black',
                            'round': false
                        });
                    });
                }, 300);
            }
            if (key === 'back') {
                this.imgBackData = response.data;
                setTimeout(() => {
                    this.updateImageEnabled = true;
                    window['jQuery'](document).ready(function () {
                        window['jQuery']('#displayImage').attr('src', response.data).blowup({
                            'width': 400,
                            'height': 400,
                            'border': '1px solid black',
                            'round': false
                        });
                    });
                }, 300);
            }
        },
            error => {
                this.spinnerService.hide();
                const errorMessage = error && error.error && error.error.message || 'Could not rotate image';
                this._toastMessage.openSnackBarError(errorMessage);
            });
    }

    updateAadharFrontImage() {
        let response;
        const riderId = this.data.userId._id;
        this._aadharService.updateImage('front', this.imgFront, riderId, this.imgFrontData).subscribe(resp => {
            this.fetchData.emit(true);
            response = resp;
            this._toastMessage.openSnackBarSuccess('Image has been updated');
            this._toastMessage.openSnackBarSuccess('Image Saved. Please reload.');
            this.updateImageEnabled = false;
            this.rotateFrontImageDegrees = 0;
            this.refreshDisplayImage();
        },
            error => {
                const errorMessage = error && error.error && error.error.message || 'Could not update Aadhar front image';
                this._toastMessage.openSnackBarError(errorMessage);
            });
    }

    updateAadharBackImage() {
        let response;
        const riderId = this.data.userId._id;
        this._aadharService.updateImage('back', this.imgBack, riderId, this.imgBackData).subscribe(resp => {
            this.fetchData.emit(true);
            response = resp;
            this._toastMessage.openSnackBarSuccess('Image has been updated');
            this._toastMessage.openSnackBarSuccess('Image Saved. Please reload.');
            this.updateImageEnabled = false;
            this.rotateBackImageDegrees = 0;
            this.refreshDisplayImage();
        },
            error => {
                const errorMessage = error && error.error && error.error.message || 'Could not update Aadhar back image';
                this._toastMessage.openSnackBarError(errorMessage);
            });
    }

    refreshDisplayImage() {
        if (window) {

            window['jQuery'](document).ready(function () {
                window['jQuery']('#displayImage').blowup({
                    'width': 400,
                    'height': 400,
                    'border': '1px solid black',
                    'round': false
                });
            });

        }
        if (document.getElementById('BlowupLens')) {
            if (window) {
                window['jQuery']('#BlowupLens').show();
            }
        }
        this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
    }

    updateImageUrl(evt: any) {
        this.fetchData.emit(true);
        if (evt.view === 'front') {
            this.imgFront = evt.url;
            this.aadharFrontImage.emit(this.imgFront);
        } else if (evt.view === 'back') {
            this.imgBack = evt.url;
        }
        if (evt.mode === 'delete') {
            const aadharStatus = {
                'data': {},
                'status': this.verificationStatus.WARNING,
                'message': null,
                'isAadharAccepted': false,
                verifiedBy: null,
                verifiedOn: null
            };
            const condition = {
                'docVerificationStatus.aadhar': aadharStatus
            };

            this.updateRider(condition);
            this.aadharStatus.emit(false);
            this.showApproveButton = false;
            this.updateImageEnabled = false;
            if (document.getElementById('BlowupLens')) {
                if (window) {
                    window['jQuery']('#BlowupLens').hide();
                }
            }
        }
        if (evt.mode === 'update') {
            this.showApproveButton = true;
            this._toastMessage.openSnackBarSuccess('Image Saved. Please reload using Shift + R .');
        }
    }

    getGenderFromAadhar(){
        let genderCode = (
            this.aadharData &&
            this.aadharData.docVerificationStatus &&
            this.aadharData.docVerificationStatus.aadhar &&
            this.aadharData.docVerificationStatus.aadhar.data &&
            this.aadharData.docVerificationStatus.aadhar.data.gender
        )
        if (genderCode === 'M') return "Male"
        if (genderCode === 'F') return "Female"
        return ""
    }

    getBirthDateFromAadhar(){
        return this.aadharData &&
            this.aadharData.docVerificationStatus &&
            this.aadharData.docVerificationStatus.aadhar &&
            this.aadharData.docVerificationStatus.aadhar.data &&
            this.aadharData.docVerificationStatus.aadhar.data.dob || "";
    }

    getFullNameFromAadhar(){
        return this.aadharData &&
            this.aadharData.docVerificationStatus &&
            this.aadharData.docVerificationStatus.aadhar &&
            this.aadharData.docVerificationStatus.aadhar.data &&
            this.aadharData.docVerificationStatus.aadhar.data.name || "";
    }

    getImageFromAadhar(){
        return this.aadharData &&
            this.aadharData.docVerificationStatus &&
            this.aadharData.docVerificationStatus.aadhar &&
            this.aadharData.docVerificationStatus.aadhar.data &&
            this.aadharData.docVerificationStatus.aadhar.data.image || ""
    }

    async approveAadhar() {
        this.spinnerService.show();
         if (this.aadharNumber && (this.existingAadharNumber !== this.aadharNumber)) {
            await this.updateAadharNumber();
            await this.updateAadharVerifiedAndRemarks();
        } else if (this.existingAadharNumber) {
            await this.updateAadharVerifiedAndRemarks();
        } else {
            this.spinnerService.hide();
            this._toastMessage.openSnackBarError('Make sure you have entered all the mandatory data');
        }
    }

    private updateRiderAndAddLog(result: any) {
        this.addAadharLog(result);

        this.aadharVerifyStatus = result.data.verificationStatus;
        this.aadharVerifyMessage = result.data.message;
        this.verificationPlatform = 'IN-D';
        this.verifiedOn = result.data.updatedAt;
        this.thirdPartyStatus = this.aadharData.status;
        this.thirdPartyMessage = result.data.message;
    }

    private updateRider(condition) {
        this._aadharService.updateRider(this.riderId, condition)
            .subscribe((result: any) => {
                console.log('Updated rider aadhar status.');
            }, error => {
                console.log('Error - Updated rider aadhar status.', error);
            });
    }

    private addAadharLog(result) {
        this._logService.insertEvent(10, this.riderId, {},
        { number : this.aadharNumber || null,
            message: result.data.message || null,
            verifiedBy: result.data.type,
            verifiedOn: result.data.updatedAt
        }).subscribe();
    }

    private updateAadharVerifiedAndRemarks() {
        return new Promise(() => {
        this.formApprovalMessage();
        const remarks = this.remarks ? this.remarks : null;
        this.updateAadharVerified();
        if (remarks) {
            this._aadharService.updateRemarks(this.data.userId._id, remarks).subscribe(res => {
                
                this.spinnerService.hide();
                this._logService.insertEvent(11, this.data._id, { remarks: this.data.aadhar.remarks },
                    { remarks: remarks }).subscribe();
                this._toastMessage.openSnackBarSuccess('Remarks is updated!');
            },
                error => {
                    this.spinnerService.hide();
                    const errorMessage = error && error.error && error.error.message || 'Could not update remarks';
                    this._toastMessage.openSnackBarError(errorMessage);
                });
        }
        this.aadharData.status = this.verificationStatus.ACCEPTED;
    });
    }

    private updateAadharVerified() {
        const isVerifiedViaOtp  = Boolean("true") === this.isVerifiedViaOtp;
        this._aadharService.updateVerified(this.data.userId._id, 'true', isVerifiedViaOtp)
            .subscribe(res => {
                this.aadharStatus.emit(true);
                this.showApproveButton = false;
                this.updateImageEnabled = false;
                this.spinnerService.hide();
                const view = this.showFront ? 'front' : this.showBack ? 'back' : '';
                this._logService.insertEvent(11, this.data._id, { aadharApproved: false }, { aadharApproved: true, aadharData: this.data.aadhar, aadharSide: view }).subscribe();
                this._toastMessage.openSnackBarSuccess('Aadhar has been approved');
                this.formApprovalMessage();
            }, error => {
                this.showApproveButton = true;
                this.spinnerService.hide();
                this._toastMessage.openSnackBarError('Could not approve Aadhar');
            });
    }

    private isInvalid() {
        return (this.aadharNumberFormControl.errors && this.aadharNumberFormControl.errors.required) ||
            (this.aadharNumberFormControl.errors && this.aadharNumberFormControl.errors.pattern);
    }

    private isExisting() {
        return this.existingAadharNumber === this.aadharNumber;
    }

    private createAadharStatus(result) {
            const aadharStatus = this.aadharData.status ? this.aadharData.status : result.data.verificationStatus;
            const isAadharAccepted = this.aadharData.status === this.verificationStatus.ACCEPTED;
            const message = result.data.message || '';
            const aadharData = {name : result.data.requestName, image: result.data.requestImage} || {};
            const verifiedBy = result.data.type || '';
            const verifiedOn = result.data.updatedAt || '';

        return {
            'data': aadharData,
            'status': aadharStatus,
            message,
            isAadharAccepted,
            verifiedBy,
            verifiedOn
        };
}
    updateVerificationCard(verificationStatus) {
        this.thirdPartyStatus = verificationStatus.status;
        this.thirdPartyMessage = verificationStatus.message;
        this.verificationPlatform = verificationStatus.verifiedBy;
        this.verifiedOn = verificationStatus.verifiedOn;
        this.isAadharVerified = verificationStatus.status === this.verificationStatus.ACCEPTED
            || verificationStatus.status === this.verificationStatus.VERIFIED;
    }

}
