import { Injectable } from '@angular/core';
import { MyHttpClient } from '../../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable()
export class ProfilePictureService {
  server: string = environment.SERVER;
  rotateUrl = process.env.SERVER + '/api/imageRotate';
  deleteAllUrl = process.env.SERVER + '/api/profile/deleteTempFiles';
  updateImageUrl = process.env.SERVER + '/api/update/profile/profilePicture';
  approvePPUrl = process.env.SERVER + '/api/approve/profilePicture';
  updatePPRemarksUrl = process.env.SERVER + '/api/update/pp/remarks';
  enrollmentUrl = process.env.SERVER + '/api/captain/faceauth-enrollment';
  authResults = process.env.SERVER + '/api/captain/faceauth-results';
  faceAuthURL = process.env.SERVER + '/api/captain/faceauth';

  constructor(private http: MyHttpClient) {
  }

  updateRemarks(id, remarks) {
    const payload = {
      'userId': id,
      'data': remarks.trim()
    };
    return this.http.post(this.updatePPRemarksUrl, payload).pipe(catchError(this.handleError));
  }

  approvePP(userId, ppLink, ppRemarks) {
    const payload = {
      'id': userId,
      'link': ppLink,
      'remarks': ppRemarks,
      'verified': true
    };
    return this.http.post(this.approvePPUrl, payload).pipe(catchError(this.handleError));
  }

  deleteTempFiles(userId) {
    const payload = {
      'id': userId
    };
    return this.http.post(this.deleteAllUrl, payload)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  async rotateImage(link, degrees) {
    const data = await this.http.post(this.rotateUrl, {
      link: link,
      degrees: degrees
    })
      .toPromise();
    return data;
  }


  async getEnrollmentInfo(userId){
    const data = await  this.http.get(`${this.enrollmentUrl}/${userId}`).toPromise();
    return data;
  }



  updateImage(image, link, id) {
    const payload = {
      'data': image,
      'url': link,
      'userId': id,
      'mode': 'rotate'
    };
    return this.http.post(this.updateImageUrl, payload).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }

  disableFaceAuth(reason, userId) {
    const payload = { reason };
    return this.http.post(`${this.faceAuthURL}/${userId}/disable`, payload).pipe(catchError(this.handleError));
  }
}
