import { Injectable } from '@angular/core';

import { MyHttpClient } from '../../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';




@Injectable()
export class AadharService {
  updateRemarksUrl = `${process.env.SERVER}/api/update/aadhar/remarks`;
  updateNumberUrl = `${process.env.SERVER}/api/update/aadhar/number`;
  updateRejectRemarksUrl = `${process.env.SERVER}/api/update/aadhar/rejectRemarks`;
  setVerified = `${process.env.SERVER}/api/update/aadhar/verified`;

  rotateUrl = `${process.env.SERVER}/api/imageRotate`;
  deleteAllUrl = `${process.env.SERVER}/api/profile/deleteTempFiles`;
  updateImageUrl = `${process.env.SERVER}/api/update/aadhar/link`;
  updateAadharVerified = `${process.env.SERVER}/api/update/aadhar/verified`;
  verifyAadharUrl = `${process.env.SERVER}/api/verify/aadhar`;
  updateRiderUrl = `${process.env.SERVER}/api/rider`;

  constructor(private http: MyHttpClient) { }

  verifyAadhar(payload) {
    return this.http.post(this.verifyAadharUrl, payload).pipe(catchError(this.handleError));
  }

  updateVerified(userId, verified, isVerifiedViaOtp= false, isAadharInKyc = false) {
    return this.http.put(this.setVerified, {'verified' : verified, 'userId' : userId, isVerifiedViaOtp, isAadharInKyc}).pipe(catchError(this.handleError));

  }

  updateRemarks(userId, remarks){
    return this.http.put(this.updateRemarksUrl, {'remarks' : remarks, 'userId' : userId}).pipe(catchError(this.handleError));
  }

    updateRider(riderId, condition) {
      return this.http.put(`${this.updateRiderUrl}/${riderId}`, condition).pipe(catchError(this.handleError));
    }


  updateNumber(userId, number){
    return this.http.put(this.updateNumberUrl, {'number' : number, 'userId' : userId}).pipe(catchError(this.handleError));
  }

  updateRejectRemarks(id, remarks) {
    const payload = {'userId': id, 'rejectRemarks': remarks};
    return this.http.post(this.updateRejectRemarksUrl, payload).pipe(catchError(this.handleError));
  }

  deleteTempFiles() {
    return this.http.get(this.deleteAllUrl)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  rotateImage(link, degrees) {
    return this.http.post(this.rotateUrl, {
      link: link,
      degrees: degrees
    }).pipe(catchError(this.handleError));
  }

  updateImage(view, url, id, data) {
    let uploadUrl;
    uploadUrl = this.updateImageUrl;

    return this.http.post(uploadUrl, {
      'data': data,
      'view': view,
      'link': url,
      'userId': id
    }).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }
}
