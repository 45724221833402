import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

const cookies = document.cookie.split(';');

const userCookie = cookies.find(function (item) {
  return item.includes('_id');
});

const httpOptions = {
  headers: new HttpHeaders({
    'user': userCookie
  })
};

@Injectable()
export class OnboardingMerchantService {

  server: string = environment.SERVER;
  merchantURL = process.env.SERVER + '/api/merchant/';

  constructor(private http: MyHttpClient) { }

  getMerchantDetails(merchantMobile) {
    return this.http.get(
      this.merchantURL + 'findMerchant/' + merchantMobile).pipe(catchError(this.handleError)
    );
  }

  activateMerchant(merchantId, userId) {
    return this.http.post(
      this.merchantURL + 'activateMerchant/' + merchantId + '/' + userId, '').pipe(catchError(this.handleError)
    );
  }

  deactivateMerchant(merchantId, deactivateInfo, userId) {
    const body = {
      deActivateMessage: deactivateInfo.deActivateMessage,
      deActivateRemarks: deactivateInfo.deActivateRemarks
    };
    return this.http.post(
      this.merchantURL + 'deactivateMerchant/' + merchantId + '/' + userId, body).pipe(catchError(this.handleError)
    );
  }

  logoutUser(userId) {
    return this.http.post(
      this.merchantURL + 'logoutUser/' + userId, '').pipe(catchError(this.handleError)
    );
  }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    if (error && error.error && error.error.data && error.error.data.data) {
      return throwError(error.error.data.data);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  makeIntentionalError() {
    return this.http.get('not/a/real/url')
      .pipe(
        catchError(this.handleError)
      );
  }

}
