export class HelperService {

  static setCookie(data: any = {}) {
    Object.keys(data).map(key => {
        let value = data[key];

        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }

        document.cookie = `${key}=${value};path=/`;
    });
  }

  static accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');

    for (let i = 0; i < allCookieArray.length; i++) {
      const temp = allCookieArray[i].trim();
      if (temp.indexOf(name) === 0) {
        return temp.substring(name.length, temp.length);
      }
    }
    return '';
  }

  formatTime(timestamp) {
    const hour = timestamp.getHours();
    const minutes = timestamp.getMinutes();

    const time = hour >= 12 && (hour - 12 || 12).toString() + ':' + (minutes.toString()).padStart(2, 0) + ' PM'
                || ((hour.toString()).padStart(2, 0) || 12) + ':' + (minutes.toString()).padStart(2, 0) + ' AM';

    return time;
  }

  formatDate(timestamp) {
    const date = (timestamp.getDate().toString()).padStart(2, 0);
    let month = timestamp.getMonth() + 1;
    month = month.toString().padStart(2, 0);
    const year = (timestamp.getFullYear().toString());


    return date + '-' + month + '-' + year;
  }

  getCookie(name, position) {
    const cookies = document.cookie.split(';');
    let finalCookie;
      const userCookie = cookies.find(function (item) {
        return item.includes(name);
      });
      if (userCookie) {
        finalCookie = Object.values(JSON.parse(userCookie))[position];
      }
      return finalCookie;
  }
}
