import { Injectable } from '@angular/core';

import { MyHttpClient } from '../../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class CabService {
  // @ts-ignore
  getCabDataUrl = process.env.SERVER + '/api/cab';
  // @ts-ignore
  rejectCabDocsUrl = process.env.SERVER + '/api/cab/bulkRejectDocs';
  // @ts-ignore
  rotateUrl = process.env.SERVER + '/api/imageRotate';

  constructor(private http: MyHttpClient) { }

  getCabData(userId) {
    return this.http.get(this.getCabDataUrl + `/${userId}`).pipe(catchError(this.handleError));
  }

  rejectCabDocs(docType, body) {
    return this.http.post(this.rejectCabDocsUrl, body).pipe(catchError(this.handleErrorForReject));
  }

  approve(docType, riderId) {
    const payload = {
      userId: riderId,
      remarks: ''
    };
    // @ts-ignore
    const approveUrl = `${process.env.SERVER}/api/cab/approve/${docType}`;
    return this.http.post(approveUrl, payload).pipe(catchError(this.handleError));
  }

  updateInfo(docType, riderId, expiry) {
    const payload = {
      userId: riderId,
      expiresOn: expiry
    };
    // @ts-ignore
    const updateInfoUrl = `${process.env.SERVER}/api/cab/${docType}/info`;
    return this.http.post(updateInfoUrl, payload).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }

  private handleErrorForReject(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    if (error && error.error && error.error.data && error.error.data.data) {
      return throwError(error.error.data.data);
    }

    if (error && typeof error === 'string') {
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  rotateImage(link, degrees) {
    return this.http.post(this.rotateUrl, {
      link: link,
      degrees: degrees
    }).pipe(catchError(this.handleError));
  }

  updateImage(data, riderId, name) {
    return this.http.post(process.env.SERVER + `/api/cab/${name}/image`, {
      'data': data,
      'riderId': riderId
    }).pipe(catchError(this.handleError));
  }
}
