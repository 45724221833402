import { Component, OnInit } from '@angular/core';
import {SigninService} from './signin.service';
import {Router} from '@angular/router';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment';

import { ToastMessage } from '../toast-message/toast-message.service';
import { Observable } from 'rxjs/Rx';
import { OnboardingPageService } from '../onboarding-page/onboarding-page.service';
import { HelperService } from '../helper.service';
import {BlockedRoles} from './signin.model';

declare let navigator;
declare let google: any;
@Component({
  selector: 'signin',
  template: require('./signin.component.html'),
  providers: [SigninService, ToastMessage, OnboardingPageService, DataService]
})
export class SigninComponent implements OnInit {
  config: any;
  loginInfo: any;
  public token: any;
  private auth2: any;
  public user: any = {};
  public profile: any = {};
  public authResponseObj: any = {};
  public internetSpeed: any;
  public internetSubscriber: any;
  public slowInternet = false;
  public noInternet = false;
  public internetSpeeds = ['4g', '3g'];
  constructor(
    private _signinService: SigninService,
    private router: Router,
    private _toastMessage: ToastMessage,
    private dataService: DataService,
    private _onboardingService: OnboardingPageService) {
      const signInJS = document.createElement('script');
      signInJS.async = true;
      //signInJS.src = 'https://rapido-generic-assets.s3.ap-south-1.amazonaws.com/gsi-client.js';
      signInJS.src = 'https://accounts.google.com/gsi/client';
      signInJS.onload = () => {
        this.auth2 = google.accounts.oauth2.initTokenClient({
          client_id: environment.googleAccountKey,
          scope: 'email',
          callback: (tokenResponse) => {
            const accessToken = tokenResponse.access_token;
            this.signInCallback(accessToken);
          },
        });
      };
      document.head.appendChild(signInJS);
  }
    
  ngOnInit() {
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }
    this.checkInternet();
  }

  checkInternet() {
    this.internetSubscriber = Observable.interval(2000).subscribe(() => {
      if (!navigator.onLine) {
        this.noInternet = true;
      } else {
        this.noInternet = false;
      }

      if (this.internetSpeeds.indexOf(navigator.connection.effectiveType) < 0) {
        this.slowInternet = true;
        this.internetSpeed = navigator.connection.effectiveType;
      } else {
            this.slowInternet = false;
           }
    });
  }

  addRolestoCookies(accessRoles) {
    if (
      accessRoles && accessRoles.length > 0
      && (accessRoles.indexOf('edit_captain') > -1 || accessRoles.indexOf('view_captain') > -1
      || accessRoles.indexOf('edit_merchant') > -1 || accessRoles.indexOf('view_merchant') > -1 || accessRoles.indexOf('edit_fleet_attach_detach_access') > -1)
    ) {
        document.cookie = 'accessRoles=' + accessRoles.toString();
        this._toastMessage.openSnackBarSuccess('logged in successfully');
        return true;
    } else {
      this.router.navigate(['']);
      this._toastMessage.openSnackBarError('You do not have access for this dashboard. Please contact support');
      return false;
    }
  }

  showUserData(data) {
    this._signinService.getLoginResponse(data).subscribe(resp => {

      this.config = resp;
      this.loginInfo = this.config.data;
        if (this.config.info && this.config.info.status) {
          if (this.config.info.status ===  'success') {
            const token = this.config.info.token;

            if (token) {
              HelperService.setCookie({
                token
              });
            }
            let response;
            this._signinService.getUserInfo(data.email).subscribe(result => {
              response = result;
              const email = response && response.data && response.data.email ? response.data.email : '';
              const roles = response && response.data && response.data.roles ? response.data.roles : [];

              this._signinService.getBlockedRoles().subscribe((brResponse: BlockedRoles) => {

                let isUserBlockedFromEditingCaptain = false;
                const blockedRoles = brResponse && brResponse.blockedRoles ? brResponse.blockedRoles : [];
                for (let i = 0; i < blockedRoles.length; i++) {
                  isUserBlockedFromEditingCaptain = isUserBlockedFromEditingCaptain || roles.includes(blockedRoles[i]);
                }

                let accessRoles;
                this._signinService.fetchAccessRoles(roles).subscribe(accessRolesResponse => {
                  accessRoles = accessRolesResponse && accessRolesResponse['roles'] || [];

                  if (isUserBlockedFromEditingCaptain) {
                    accessRoles = accessRoles.filter(role => !role.includes('edit_captain'));
                  }

                  if (this.addRolestoCookies(accessRoles) ) {
                    this.router.navigate(['/modelSelection']);
                  }
                });
                response = response.data;
                if (response && response._id) {
                  localStorage.setItem('userId', response._id);
                  localStorage.setItem('emailId', response.email);
                  localStorage.setItem('firstName', response.firstName);
                  localStorage.setItem('isUserAnAdmin', response.isUserAnAdmin);
                  document.cookie = 'name=' + response.email;
                  document.cookie = '{"_id":"' + response._id + '", "firstName":"' +
                      response.firstName + '", "emailId":"' + response.email + '"}';
                } else {
                  this._toastMessage.openSnackBarError('User not found! Please contact support.');
                }
              });
            },
            err => {
              const errorMessage = err.error && err.error.message || err;
              this._toastMessage.openSnackBarError(errorMessage);
            });
          }
        }
    }, error => {
      const errorMessage = error && error.error && error.error.message || 'Could not login';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  signInCallback(token) {
    this._signinService.getUserAccessToken(token).subscribe((result: any)=> {
        const profile = result.info.result;
        this.user.id = profile.sub;
        this.user.name = profile.name;
        this.user.accessToken = token;
        this.user.image = profile.picture;
        this.user.email = profile.email;
        this.user.hd = profile.hd;
        this.showUserData(this.user);
    });
  }

  public socialSignIn() {
    this.auth2.requestAccessToken();
  }
}
