import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${HelperService.accessCookie('token')}`)
    });
    
    return next.handle(request).pipe(catchError((err: any) => {
      if (err.status === 401) {
          this.router.navigate(['/']);
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
  }));
  }
}
