import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HelperService } from '../helper.service';
import { ToastMessage } from '../toast-message/toast-message.service';
import { DataService } from '../data.service';
import { ConstantsService } from '../constants.service';

@Component({
  selector: 'app-model-selection',
  template: require('./model-selection.component.html'),
  providers: [HelperService, ToastMessage, DataService, ConstantsService]
})
export class ModelSelectionComponent implements OnInit {

  public userEmail: any;
  public permissions: any;

  constructor(
    private helperService: HelperService,
    private toastMessage: ToastMessage,
    private router: Router,
    private dataService: DataService,
    private constantService: ConstantsService
  ) { }

  ngOnInit() {
    this.userEmail = this.helperService.getCookie('emailId', 2);
    const accessRoles = this.dataService.accessCookie('accessRoles');
    this.permissions = accessRoles.split(',');
  }

  onBoardCaptain() {
    const captainRoles = this.constantService.captainPermissions;
    if (
      this.permissions.indexOf(captainRoles.viewCaptain) > -1
      || this.permissions.indexOf(captainRoles.editCaptain) > -1
    ) {
      this.router.navigate(['/search']);
    } else {
      this.toastMessage.openSnackBarError('You dont have access to onboard captain. Please contact support');
    }
  }

  onBoardMerchant() {
    const merchantRoles = this.constantService.merchantPermissions;
    if (
      this.permissions.indexOf(merchantRoles.editMerchant) > -1
      || this.permissions.indexOf(merchantRoles.viewMerchant) > -1
    ) {
      this.router.navigate(['/searchMerchant']);
    } else {
      this.toastMessage.openSnackBarError('You dont have access to onboard merchant. Please contact support');
    }
  }

  logoutUser() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    this.toastMessage.openSnackBarSuccess('You have been logged out');
    this.router.navigate(['/']);
  }
}
