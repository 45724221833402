import { Component, OnInit, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {ImageViewService} from '../image-view/image-view.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ToastMessage} from '../toast-message/toast-message.service';

@Component({
  selector: 'app-image-dialog',
  template: require('./image-dialog.component.html')
})
export class ImageDialogComponent implements OnInit {

  public imageLinks: any;
  public showNext: Boolean;
  public showPrevious: Boolean;
  public imageToDisplay: any;
  public displayedImageIndex: any;
  private S3_URL_REGEX: RegExp;

  constructor(public dialogRef: MatDialogRef<ImageDialogComponent>,
              private imageViewService: ImageViewService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _toastMessage: ToastMessage,
    @Inject(MAT_DIALOG_DATA) public imageInfo: any) {
    this.S3_URL_REGEX = new RegExp(/(s3-|s3\.)?(.*)\.amazonaws\.com/);
  }

  ngOnInit() {
    console.log('Image Info : ', this.imageInfo);
    this.imageLinks = this.imageInfo.imageLinks;
    this.showPrevious = false;
    this.showNext = this.imageLinks.length > 1 ? true : false;
    this.displayedImageIndex = 0;
    this.setImage(this.imageLinks[this.displayedImageIndex]);
  }

  setImage(url) {
    const urlObject = new URL(url);
    if (urlObject.protocol === 'data:' || this.isS3Url(url)) {
      this.imageToDisplay  = url;
      return;
    }
    this.spinnerService.show();
    this.imageViewService.getPreSignedImageUrl(urlObject.pathname)
        .subscribe(preSignedUrl => {
              this.imageToDisplay = preSignedUrl['url'];
              this.spinnerService.hide();
            }
            , error => {
              this.spinnerService.hide();
              const errorMessage = error.error && error.error.message ? error.error.message : 'Could not load image';
              this._toastMessage.openSnackBarError(errorMessage);
            });
  }

  closeImage() {
    this.dialogRef.close();
  }

  viewPreviousImage() {
    if ( (this.displayedImageIndex - 1) <= this.imageLinks.length - 1 ) {
      this.displayedImageIndex--;
      this.setImage(this.imageLinks[this.displayedImageIndex]);
    }

    this.showNext = (this.displayedImageIndex + 1) <= this.imageLinks.length - 1 ? true : false;
    this.showPrevious = !this.showNext;
  }

  viewNextImage() {
    if ( (this.displayedImageIndex + 1) <= this.imageLinks.length - 1 ) {
      this.displayedImageIndex++;
      this.setImage(this.imageLinks[this.displayedImageIndex]);
    }

    this.showNext = (this.displayedImageIndex + 1) <= this.imageLinks.length - 1 ? true : false;
    this.showPrevious = !this.showNext;
  }

  isS3Url(url) {
    return this.S3_URL_REGEX.test(url);
  }

}
