import {
  Component,
  OnInit,
  Input,
  Inject,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core'
import { MatRadioChange } from '@angular/material/radio'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { FormControl, Validators } from '@angular/forms'
import { RegistrationCardService } from './registration-card.service'

import { ToastMessage } from '../../toast-message/toast-message.service'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'
import { MatDialog } from '@angular/material';
import { PopUpMessageComponent } from '../../pop-up-message/pop-up-message.component';
import { LogService } from '../../log-service.service'
import { ConstantsService } from '../../constants.service'
import { Subject } from 'rxjs'

const _ = require('lodash')
const moment = require('moment');
@Component({
  selector: 'registration-card-optional-image',
  template: require('./registration-card.component.html'),
  providers: [
    ToastMessage,
    RegistrationCardService,
    LogService,
    ConstantsService,
  ],
})
export class RegistrationCardOptionalImageComponent
  implements OnInit, OnChanges
{
  vehicleNumberFormControl = new FormControl('', [
    Validators.pattern('[a-zA-Z0-9]{1,20}'),
  ])

  vehicleOwnerNameFormControl = new FormControl('', [
    Validators.pattern('[a-zA-Z ]{1,64}'),
  ])

  constructor(
    private _hotkeysService: HotkeysService,
    private _registrationCardService: RegistrationCardService,
    private _toastMessage: ToastMessage,
    private spinnerService: Ng4LoadingSpinnerService,
    private _logService: LogService,
    private dialog: MatDialog,
    private constantService: ConstantsService
  ) {}

  @Input() data: any
  @Input() cities: any[]
  @Input() allowEditing: any

  @Input() showFront = false
  @Input() showBack = false
  @Input() vehicleRegData: any
  @Input() showRCVerify: any
  @Input() radioEvent: MatRadioChange

  @Output() rcStatus: EventEmitter<any> = new EventEmitter()
  @Output() registrationNumber: EventEmitter<any> = new EventEmitter()
  @Output() fetchData: EventEmitter<any> = new EventEmitter()
  @Output() addRegistrationCardToRejectList: EventEmitter<any> =
    new EventEmitter()
  @Input() userIdentity: any = {}

  @Output() rcFrontImg: EventEmitter<any> = new EventEmitter()
  @Output() rcBackImg: EventEmitter<any> = new EventEmitter()

  @Input() isRCRejected: any
  @Output() rcDoc: EventEmitter<any> = new EventEmitter()
  public screenBreakpoint = false

  public updateImageEnabled = false
  public rcData: any
  public vehicleList = []
  public vehicleListArray = []
  public displayNames = {}
  public vehicleTypesForCab = {};
  public cityList = []
  public cityArray = []
  public userCity = ''
  public imgFront: any
  public imgBack: any
  public riderId: any
  public imgFrontData: any
  public imgBackData: any
  public vehicles: any[]
  public vehicleData: any
  public currentCity: any
  public oldVehicleNumber: any
  public oldVehicleOwnership: any
  public oldVehicleColor: any
  public oldVehicleFuelType: any
  public oldVehicleMake: any
  public oldVehicleModel: any
  public oldRemarks: any
  public vehicleOwnerName: any
  public oldRegistrationDate: any
  public userInitialData: any
  public showVehicleData = false
  public changedDetailsOfDoc = [];
  public rcDefaulter = false;
  public rcDefaulterNumberChanged = false;
  public rcRejected = false;
  public rcReuploaded = false;
  public isModeCar = false;

  // response from third party
  public thirdPartyVehicle: any
  public thirdPartyStatus: any
  public thirdPartyMessage: any
  public isRcAccepted: any
  public verificationPlatform: any
  public verifiedOn: any

  public showApproveButton = false
  public showButtonIcon = false;
  public rcRemarks = [
    'Verified online',
    'NOC uploaded',
    'Friend vehicle',
    'Relatives vehicle',
    'Rented vehicle',
    'Tax invoice uploaded (TL approved)',
    'Vehicle Changed',
  ]
  public showSubmitButton = false

  public rcApprovalMessage: any
  public isRcApproved = false
  public isRcAutoApproved = false
  public rcApprovalStatus = 'Approved'
  public APPROVAL_MESSAGE = this.constantService.approvalMessage
  public vehicleMode: any
  public isActive = false
  public asyncRefreshTrigger: Subject<void> = new Subject<void>()

  private rotateFrontImageDegrees = 0
  private rotateBackImageDegrees = 0
  private isRcImageOptional = false
  private registrationDateUpdated = false
  public vehicleTypes: string[] = ['MCWOG', 'MCWG']
  public vehicleType: undefined | string
  public rcOlderThan10Years: undefined | boolean
  public isPillionRider: undefined | boolean
  public expiresOnFormControl = new FormControl(new Date())

  onResize(event) {
    this.screenBreakpoint = event.target.innerWidth <= 1040 ? true : false
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.vehicleRegData && changes.vehicleRegData.currentValue){
      if(changes.vehicleRegData.currentValue.status == "Verified"){
        changes.vehicleRegData.currentValue.status = "Accepted";
      }
      this.updateVerificationCard(changes.vehicleRegData.currentValue) ;
    }
    if (
      changes &&
      changes.vehicleRegData &&
      changes.vehicleRegData.currentValue
    ) {
      this.showVehicleData = true
      this.thirdPartyVehicle = changes.vehicleRegData.currentValue.data
      this.isRcAccepted = changes.vehicleRegData.currentValue.isRcAccepted
      this.verificationPlatform = changes.vehicleRegData.currentValue.verifiedBy
      this.verifiedOn = changes.vehicleRegData.currentValue.verifiedOn
    }
    if (
      changes.data &&
      changes.data.currentValue &&
      changes.data.currentValue.modeId &&
      changes.data.currentValue.modeId.mode
    ) {
      this.isActive = changes.data.currentValue.active
      this.vehicleMode = changes.data.currentValue.modeId.mode
    }
    if (changes.cities) {
      for (const key in changes.cities.currentValue) {
        if (changes.cities.currentValue[key]) {
          this.cityList.push(changes.cities.currentValue[key])
        }
      }
      this.cityArray = this.cityList.reduce((acc, item) => {
        acc[item._id] = item.displayName

        return acc
      }, {})
    }

    if (changes.data) {
      this.rcData = changes.data.currentValue
      this.userInitialData = _.cloneDeep(changes.data.currentValue)
      if (!this.rcData.vehiclesAssignable[0] && !this.vehicleData) {
        this.vehicleData = []
        this.showSubmitButton = true
      }

      if (
        this.rcData &&
        this.rcData.vehiclesAssignable &&
        this.rcData.vehiclesAssignable[0]
      ) {
        this.vehicleData = this.rcData.vehiclesAssignable[0]
      }

      if (this.vehicleData.id) {
        this.oldVehicleNumber = this.vehicleData.id.vehicleNumber
        this.oldVehicleColor = this.vehicleData.id.color;
        this.oldVehicleOwnership = this.vehicleData.id.ownership
        this.oldVehicleFuelType = this.vehicleData.id.fuelType
        this.oldRemarks = this.vehicleData.id.rc.remarks
        this.vehicleOwnerName = this.vehicleData.id.rc.owner 
        this.currentCity = this.cityArray[this.vehicleData.id.city]
        this.imgFront = this.vehicleData.id.rc.frontLink
        this.imgBack = this.vehicleData.id.rc.backLink
        this.isRcImageOptional = !!this.vehicleData.id.rc.isImageOptional
        this.vehicleType = this.vehicleData.id.vehicleCategory
        this.oldRegistrationDate = this.vehicleData.id.rc.registrationDate 
          ? this.vehicleData.id.rc.registrationDate : ''
        this.isPillionRider = this.vehicleData.id.seatCapacity
          ? (this.vehicleData.id.seatCapacity || -1) > 1
          : undefined
        const registrationDate = new Date(
          this.vehicleData.id.rc.registrationDate
        )
        this.rcOlderThan10Years = this.isRcOlderThan10Years(registrationDate)
        if (this.vehicleData.id.vehicleNumber) {
          this.vehicleNumberFormControl.disable()
        }
      }

      this.riderId = this.rcData.userId._id
      this.fetchRcDefaulter(this.riderId)
      if (
        this.vehicleData.id &&
        this.vehicleData.id.rc &&
        this.hasRcImageIfRequired() &&
        this.vehicleData.id.rc.verified === false
      ) {
        this.showApproveButton = true
      }

      if (this.vehicleData.id == null) {
        this.vehicleData.id = {
          vehicleNumber: '',
          color: '',
          city: '',
          rc: {
            frontLink: '',
            backLink: '',
            remarks: '',
          },
        }
      }

      if (this.vehicleData.vehicleNumber == null) {
        this.vehicleData.vehicleNumber = ''
        this.vehicleNumberFormControl.enable()
      }

      if (this.rcData.currentVehicle.make == null) {
        this.rcData.currentVehicle.make = ''
      }

      if (this.vehicleData.color == null) {
        this.vehicleData.color = ''
      }

      if (this.vehicleData.id.city == null) {
        this.vehicleData.id.city = ''
      }

      this.userCity = this.vehicleData.id.city
      if (this.vehicleData.ownership == null) {
        this.vehicleData.ownership = ''
      }
      if (this.vehicleData.fuelType == null) {
        this.vehicleData.fuelType = ''
      }
    }

    if (this.vehicleData.id) {
      this.oldVehicleModel = this.vehicleData.id.vehicleModel;
      this.oldVehicleMake = this.getVehicleName(this.vehicleData.id.vehicleModel);
    }

    if (
      changes &&
      changes.allowEditing &&
      changes.allowEditing.currentValue === false
    ) {
      this.vehicleNumberFormControl.disable()
      this.vehicleOwnerNameFormControl.disable()
    }

    if (
      this.vehicleData &&
      this.vehicleData.id &&
      this.vehicleData.id.rc &&
      this.vehicleData.id.rc.verified
    ) {
      this.formApprovalMessage()
    }
    if (this.isRcApproved) {
      this.showRCVerify = false
    }
    if(this.rcData.modeId && this.rcData.modeId.mode === 'Car'){
      this.isModeCar = true
    }
  }

  ngOnInit() {
    this.getVehiclesList();
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').show()
      }
    }
    this.screenBreakpoint =
      window['jQuery'](window).innerWidth() <= 1040 ? true : false
  }

  getVehiclesList() {
    let response;
    this._registrationCardService.getVehiclesList(this.data.modeId._id).subscribe(resp => {
      response = resp;
      this.vehicles = response.data.vehicles;
      for (const key in this.vehicles) {
        if (this.vehicles[key]) {
          this.vehicleList.push(this.vehicles[key]);
        }
      }
      this.vehicleListArray = this.vehicleList.reduce((acc, item) => {
        acc[item._id] = item.model;
        return acc;
      }, []);
      this.displayNames = this.vehicleList.reduce((acc, item) => {
        acc[item._id] = item.displayName || "";
        return acc;
      }, {});
      this.vehicleTypesForCab = this.vehicleList.reduce((acc, item) => {
        acc[item._id] = item.type || "economy";;
        return acc;
      }, {});
    },
      err => {
        this._toastMessage.openSnackBarError('Could not retrieve list of vehicles');
        console.log(err);
      });
  }

  formApprovalMessage() {
    const rcAutoApprovalStatus = this.rcData['autoApprovalStatus'] || {}
    this.isRcApproved = true
    if (
      rcAutoApprovalStatus['vehicleVerificationStatus'] &&
      rcAutoApprovalStatus['vehicleVerificationStatus'].autoApproved
    ) {
      this.isRcAutoApproved = true
      this.rcApprovalMessage = this.APPROVAL_MESSAGE.autoApproved
    } else {
      this.rcApprovalMessage = this.APPROVAL_MESSAGE.agentApproved
    }
  }

  addToBulkRejection(event) {
    this.addRegistrationCardToRejectList.emit(event)
  }

  fetchRcDefaulter(riderId){
    let result = this._registrationCardService.fetchRcDefaulter(riderId).subscribe(
      response => {
        this.rcDefaulter = response['defaulter']
        this.rcDefaulterNumberChanged = response['rcNumberChanged'] ? response['rcNumberChanged'] : false;
        this.rcReuploaded = response['rcReuploaded'] ? response['rcReuploaded'] : false;
        this.rcRejected = response['rejected'] ? response['rejected'] : false;
      },
      error => {
        const errorMessage = error && error.error && error.error.message || 'Could not fetch balance';
        this._toastMessage.openSnackBarError(errorMessage);
      }
    );
  }

  showDocDetailsChangeTypePanel() {
    if (this.oldVehicleNumber !== undefined && this.oldVehicleNumber !== null && this.oldVehicleNumber.trim() !== '') {
      if (this.oldVehicleNumber.toUpperCase() !== this.vehicleData.id.vehicleNumber.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing Number: ${this.oldVehicleNumber.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New Number: ${this.vehicleData.id.vehicleNumber.toUpperCase()}`)
      } 
    } else if (this.vehicleData.id.vehicleNumber) {
      this.changedDetailsOfDoc.push(`New Number: ${this.vehicleData.id.vehicleNumber.toUpperCase()}`)
    }

    if (this.oldRemarks !== undefined && this.oldRemarks !== null && this.oldRemarks.trim() !== '') {
      if (this.oldRemarks !== this.vehicleData.id.rc.remarks) {
        this.changedDetailsOfDoc.push(`Existing Remarks: ${this.oldRemarks}`)
        this.changedDetailsOfDoc.push(`New Remarks: ${this.vehicleData.id.rc.remarks}`)
      }
    } else if (this.vehicleData.id.rc.remarks) {
      this.changedDetailsOfDoc.push(`New Remarks: ${this.vehicleData.id.rc.remarks}`)
    }

    if (this.oldVehicleOwnership !== undefined && this.oldVehicleOwnership !== null && this.oldVehicleOwnership.trim() !== '') {
      if (this.oldVehicleOwnership !== this.vehicleData.id.ownership) {
        this.changedDetailsOfDoc.push(`Existing VehicleOwnership: ${this.oldVehicleOwnership}`)
        this.changedDetailsOfDoc.push(`New VehicleOwnership: ${this.vehicleData.id.ownership}`)
      }
    } else if (this.vehicleData.id.ownership) {
      this.changedDetailsOfDoc.push(`New VehicleOwnership: ${this.vehicleData.id.ownership}`)
    }

    if (this.oldVehicleFuelType !== undefined && this.oldVehicleFuelType !== null && this.oldVehicleFuelType.trim() !== '') {
      if (this.oldVehicleFuelType !== this.vehicleData.id.fuelType) {
        this.changedDetailsOfDoc.push(`Existing VehicleFuelType: ${this.oldVehicleFuelType}`)
        this.changedDetailsOfDoc.push(`New VehicleFuelType: ${this.vehicleData.id.fuelType}`)
      }
    } else if (this.vehicleData.id.fuelType) {
      this.changedDetailsOfDoc.push(`New VehicleFuelType: ${this.vehicleData.id.fuelType}`)
    }

    let vehicleMake = ''
    if (this.oldVehicleMake !== undefined && this.oldVehicleMake !== null && this.oldVehicleMake.trim() !== '') {
      if (this.oldVehicleMake !== vehicleMake) {
        vehicleMake = this.getVehicleName(this.vehicleData.id.vehicleModel)
        this.changedDetailsOfDoc.push(`Existing VehicleMake: ${this.oldVehicleMake}`)
        this.changedDetailsOfDoc.push(`New VehicleMake: ${vehicleMake}`)
      }
    } else if (this.vehicleData.id.vehicleModel) {
      vehicleMake = this.getVehicleName(this.vehicleData.id.vehicleModel)
      this.changedDetailsOfDoc.push(`New VehicleMake: ${vehicleMake}`)
    }
    
    if (this.oldVehicleColor !== undefined && this.oldVehicleColor !== null && this.oldVehicleColor.trim() !== '') {
      if (this.oldVehicleColor.toUpperCase() !== this.vehicleData.id.color.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing VehicleColor: ${this.oldVehicleColor.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New VehicleColor: ${this.vehicleData.id.color.toUpperCase()}`)
      }
    } else if (this.vehicleData.id.color) {
      this.changedDetailsOfDoc.push(`New VehicleColor: ${this.vehicleData.id.color.toUpperCase()}`)
    }
    
    if (this.vehicleOwnerName !== undefined && this.vehicleOwnerName !== null && this.vehicleOwnerName.trim() !== '') {
      if (this.vehicleOwnerName.toUpperCase() !== this.vehicleData.id.rc.owner.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing VehicleOwnerName: ${this.vehicleOwnerName.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New VehicleOwnerName: ${this.vehicleData.id.rc.owner.toUpperCase()}`)
      }
    } else if (this.vehicleData.id.rc.owner) {
      this.changedDetailsOfDoc.push(`New VehicleOwnerName: ${this.vehicleData.id.rc.owner.toUpperCase()}`)
    }

    if (this.vehicleType !== undefined && this.vehicleType !== null && this.vehicleType.trim() !== '') {
      if (this.vehicleType !== this.vehicleData.id.vehicleCategory) {
        this.changedDetailsOfDoc.push(`Existing VehicleCategory: ${this.vehicleType}`)
        this.changedDetailsOfDoc.push(`New VehicleCategory: ${this.vehicleData.id.vehicleCategory}`)
      }
    } else if (this.vehicleData.id.vehicleCategory) {
      this.changedDetailsOfDoc.push(`New VehicleCategory: ${this.vehicleData.id.vehicleCategory}`)
    }

    const pillionSeat = this.vehicleData.id.seatCapacity ? (this.vehicleData.id.seatCapacity || -1) > 1 : undefined
    if (this.isPillionRider !== undefined && this.isPillionRider !== null) {
      if (this.isPillionRider !== pillionSeat) {
        this.changedDetailsOfDoc.push(`Existing pillion rider availability: ${this.isPillionRider}`)
        this.changedDetailsOfDoc.push(`New pillion rider availability: ${pillionSeat}`)
      }
    } else {
      this.changedDetailsOfDoc.push(`New pillion rider availability: ${pillionSeat}`)
    }

    let newRCDate = ''
    if (this.oldRegistrationDate !== undefined && this.oldRegistrationDate !== null && this.oldRegistrationDate !== '') {
      if (this.oldRegistrationDate !== this.vehicleData.id.rc.registrationDate) {
        newRCDate = moment(this.vehicleData.id.rc.registrationDate).format('YYYY-MM-DD');
        const oldRCDate = moment(this.oldRegistrationDate).format('YYYY-MM-DD');
        this.changedDetailsOfDoc.push(`Existing Registration date: ${oldRCDate}`)
        this.changedDetailsOfDoc.push(`New Registration date: ${newRCDate}`)
      }
    } else if (this.vehicleData.id.rc.registrationDate) {
      newRCDate = moment(this.vehicleData.id.rc.registrationDate).format('YYYY-MM-DD');
      this.changedDetailsOfDoc.push(`New Registration date: ${newRCDate}`)
    }
  }

  private openChangeDetailsDialog(actionFunction: (confirm: boolean) => void): void {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Please confirm for the changed details in vehicle registration card',
        message: '',
        data: {
          type: 'orderedList',
          info: this.changedDetailsOfDoc,
          note: 'Are you sure you want to update above details',
        },
        reason: 'changeDocDetails',
        buttonText: 'Yes',
        cancelText: 'No',
        styles: {
          title: 'font-size-20 margin-bottom-30',
          actionClass: 'doc-details-change-confirm',
          note: 'margin-bottom-12 margin-top-30 color-grey'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe(confirm => {
      actionFunction(confirm);
      this.changedDetailsOfDoc.splice(0, this.changedDetailsOfDoc.length);
    });
  }
  
  private restoreOriginalValues(): void {
    if (this.oldVehicleNumber) {
      this.vehicleData.id.vehicleNumber = this.oldVehicleNumber;
    }
    if (this.oldRemarks) {
      this.vehicleData.id.rc.remarks = this.oldRemarks;
    }
    if (this.oldVehicleFuelType) {
      this.vehicleData.id.ownership = this.oldVehicleOwnership;
    }
    if (this.oldVehicleOwnership) {
      this.vehicleData.id.fuelType = this.oldVehicleFuelType;
    }
    if (this.oldVehicleColor) {
      this.vehicleData.id.color = this.oldVehicleColor;
    }
    if (this.oldVehicleModel) {
      this.vehicleData.id.vehicleModel = this.oldVehicleModel;
    }
    if (this.vehicleOwnerName) {
      this.vehicleData.id.rc.owner = this.vehicleOwnerName;
    }
    if (this.vehicleType) {
      this.vehicleData.id.vehicleCategory = this.vehicleType;
    }
    if (this.isPillionRider) {
      this.vehicleData.id.seatCapacity = this.isPillionRider;
    }
    if (this.oldRegistrationDate) {
      this.vehicleData.id.rc.registrationDate = new Date(this.oldRegistrationDate);
    }
  }

  viewDetailsForChangedDocs() {
    this.showDocDetailsChangeTypePanel()
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.submitAndApproveRC();
        } else {
          this.restoreOriginalValues()
        }
      });
    } else {
      this.submitAndApproveRC();
    }
  }

  viewDetailsForChangedDocsForRCUpdate() {
    if (this.oldVehicleNumber !== undefined && this.oldVehicleNumber !== null && this.oldVehicleNumber.trim() !== '') {
      if (this.oldVehicleNumber.toUpperCase() !== this.vehicleData.id.vehicleNumber.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing Number: ${this.oldVehicleNumber.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New Number: ${this.vehicleData.id.vehicleNumber.toUpperCase()}`)
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateVehicleNumber();
          } else {
            this.vehicleData.id.vehicleNumber = this.oldVehicleNumber;
          }
        });
      } else {
        this.vehicleData.id.vehicleNumber = this.oldVehicleNumber;
      }
    } else {
      this.updateVehicleNumber();
    }
  }

  viewDetailsForVehicleOwnershipUpdate() {
    if (this.oldVehicleOwnership !== undefined && this.oldVehicleOwnership !== null && this.oldVehicleOwnership.trim() !== '' && this.oldVehicleOwnership !== this.vehicleData.id.ownership) {
      this.changedDetailsOfDoc.push(`Existing VehicleOwnership: ${this.oldVehicleOwnership}`)
      this.changedDetailsOfDoc.push(`New VehicleOwnership: ${this.vehicleData.id.ownership}`)
    }
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.updateVehicleOwnership();
        } else {
          this.vehicleData.id.ownership = this.oldVehicleOwnership;
        }
      });
    } else {
      this.updateVehicleOwnership();
    }
  }

  viewDetailsForVehicleFuelTypeUpdate() {
    if (this.oldVehicleFuelType !== undefined && this.oldVehicleFuelType !== null && this.oldVehicleFuelType.trim() !== '' && this.oldVehicleFuelType !== this.vehicleData.id.fuelType) {
      this.changedDetailsOfDoc.push(`Existing VehicleFuelType: ${this.oldVehicleFuelType}`)
      this.changedDetailsOfDoc.push(`New VehicleFuelType: ${this.vehicleData.id.fuelType}`)
    }
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.updateVehicleFuelType();
        } else {
          this.vehicleData.id.fuelType = this.oldVehicleFuelType;
        }
      });
    } else {
      this.updateVehicleFuelType();
    }
  }

  viewDetailsForVehicleColorUpdate() {
    if (this.oldVehicleColor !== undefined && this.oldVehicleColor !== null && this.oldVehicleColor.trim() !== '' ) {
      if (this.oldVehicleColor.toUpperCase() !== this.vehicleData.id.color.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing VehicleColor: ${this.oldVehicleColor.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New VehicleColor: ${this.vehicleData.id.color.toUpperCase()}`)
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateVehicleColor();
          } else {
            this.vehicleData.id.color = this.oldVehicleColor;
          }
        });
      } else {
        this.vehicleData.id.color = this.oldVehicleColor;
      }   
    } else {
      this.updateVehicleColor();
    }
  }

  viewDetailsForVehicleMakeUpdate() {
    const vehicleMake = this.getVehicleName(this.vehicleData.id.vehicleModel)
    if (this.oldVehicleMake !== undefined && this.oldVehicleMake !== null && this.oldVehicleMake.trim() !== '' && this.oldVehicleMake !== vehicleMake) {
      this.changedDetailsOfDoc.push(`Existing VehicleMake: ${this.oldVehicleMake}`)
      this.changedDetailsOfDoc.push(`New VehicleMake: ${vehicleMake}`)
    }
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.updateVehicleMake();
        } else {
          this.vehicleData.id.vehicleModel = this.oldVehicleModel;
        }
      });
    } else {
      this.updateVehicleMake();
    }
  }

  viewDetailsForVehicleOwnerNameUpdate() {
    if (this.vehicleOwnerName !== undefined && this.vehicleOwnerName !== null && this.vehicleOwnerName.trim() !== '') {
      if (this.vehicleOwnerName.toUpperCase() !== this.vehicleData.id.rc.owner.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing VehicleOwnerName: ${this.vehicleOwnerName.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New VehicleOwnerName: ${this.vehicleData.id.rc.owner.toUpperCase()}`)
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateVehicleOwnerName();
          } else {
            this.vehicleData.id.rc.owner = this.vehicleOwnerName;
          }
        });
      } else {
        this.vehicleData.id.rc.owner = this.vehicleOwnerName;
      }
    } else {
      this.updateVehicleOwnerName();
    }
  }

  viewDetailsForVehicleCategoryUpdate(event: MatRadioChange) {
    if (this.vehicleType !== undefined && this.vehicleType !== null && this.vehicleType.trim() !== '' && this.vehicleType !== this.vehicleData.id.vehicleCategory) {
      this.changedDetailsOfDoc.push(`Existing VehicleCategory: ${this.vehicleData.id.vehicleCategory}`)
      this.changedDetailsOfDoc.push(`New VehicleCategory: ${this.vehicleType}`)
    }
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.updateVehicleCategory(event);
        } else {
          this.vehicleType = this.vehicleData.id.vehicleCategory;
        }
      });
    } else {
      this.updateVehicleCategory(event);
    }
  }

  viewDetailsForSeatCapacityUpdate(event: MatRadioChange) {
    const pillionSeat = this.vehicleData.id.seatCapacity ? (this.vehicleData.id.seatCapacity || -1) > 1 : undefined
    if (this.isPillionRider !== undefined && this.isPillionRider !== null && this.isPillionRider !== pillionSeat) {
      this.changedDetailsOfDoc.push(`Existing pillion rider availability: ${pillionSeat}`)
      this.changedDetailsOfDoc.push(`New pillion rider availability: ${this.isPillionRider}`)
    }
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.onSeatCapacityChange(event);
        } else {
          this.isPillionRider = pillionSeat;
        }
      });
    } else {
      this.onSeatCapacityChange(event);
    }
  }

  viewDetailsForExpiresOnUpdate() {
    if (this.oldRegistrationDate !== undefined && this.oldRegistrationDate !== null && this.oldRegistrationDate !== '' && this.oldRegistrationDate !== this.vehicleData.id.rc.registrationDate) {
      const oldRCDate = moment(this.oldRegistrationDate).format('YYYY-MM-DD');
      const newRCDate = moment(this.vehicleData.id.rc.registrationDate).format('YYYY-MM-DD');
      this.changedDetailsOfDoc.push(`Existing Registration date: ${oldRCDate}`)
      this.changedDetailsOfDoc.push(`New Registration date: ${newRCDate}`)
    }
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.updateExpiresOn();
        } else {
          this.vehicleData.id.rc.registrationDate = new Date(this.oldRegistrationDate);
          this.showButtonIcon = false;
        }
      });
    } else {
      this.updateExpiresOn();
    }
  }

  updateRemarks() {
    let response, oldRemarks
    this.spinnerService.show()
    this._registrationCardService
      .updateRemarks(this.rcData.userId._id, this.vehicleData.id.rc.remarks)
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            oldRemarks =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id &&
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.rc &&
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.rc.remarks
                ? this.userInitialData.vehiclesAssignable[
                    this.rcData.vehiclesAssignable.length - 1
                  ].id.rc.remarks
                : ''
          } else {
            oldRemarks = ''
          }

          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { remarks: oldRemarks },
              { remarks: this.vehicleData.id.rc.remarks }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.rc.remarks = this.vehicleData.id.rc.remarks
          }
          this._toastMessage.openSnackBarSuccess('Remarks is updated')
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update remarks'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  updateImageUrl(evt: any) {
    this.fetchData.emit(true)
    if (evt.view === 'front') {
      this.imgFront = evt.url
      this.vehicleData.id.rc.frontLink = this.imgFront
      this.rcFrontImg.emit(this.imgFront)
    } else if (evt.view === 'back') {
      this.imgBack = evt.url
      this.vehicleData.id.rc.backLink = this.imgBack
      this.rcBackImg.emit(this.imgBack)
    }

    if (evt.mode === 'delete') {
      this.rcStatus.emit(false)
      this.showApproveButton = false
      this.updateImageEnabled = false
      this.isRcApproved = false
      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').hide()
        }
      }
    }

    if (evt.mode === 'update' && !this.showSubmitButton) {
      this.showApproveButton = true
      this._toastMessage.openSnackBarSuccess(
        'Image Saved. Please reload using Shift + R .'
      )
    }
  }

  rotateImage(link, degrees, key) {
    if (key === 'front') {
      this.rotateFrontImageDegrees += degrees
      degrees = this.rotateFrontImageDegrees
    } else {
      this.rotateBackImageDegrees += degrees
      degrees = this.rotateBackImageDegrees
    }

    let response
    this.spinnerService.show()
    this._registrationCardService.rotateImage(link, degrees).subscribe(
      (resp) => {
        response = resp
        if (key === 'front') {
          setTimeout(() => {
            this.imgFrontData = response.data
            this.updateImageEnabled = true
            window['jQuery'](document).ready(function () {
              window['jQuery']('#displayImage')
                .attr('src', response.data)
                .blowup({
                  width: 400,
                  height: 400,
                  border: '1px solid black',
                  round: false,
                })
            })
          }, 300)
        }

        if (key === 'back') {
          setTimeout(() => {
            this.imgBackData = response.data
            this.updateImageEnabled = true
            window['jQuery'](document).ready(function () {
              window['jQuery']('#displayImage')
                .attr('src', response.data)
                .blowup({
                  width: 400,
                  height: 400,
                  border: '1px solid black',
                  round: false,
                })
            })
          }, 300)
        }
      },
      (error) => {
        const errorMessage =
          (error && error.error && error.error.message) || 'Could not rotate Rc'
        this._toastMessage.openSnackBarError(errorMessage)
        this.spinnerService.hide()
      }
    )
  }

  updateRCImage(view) {
    let response
    const riderId = this.rcData.userId._id
    if (view === 'front') {
      this._registrationCardService
        .updateImage(
          this.imgFrontData,
          this.imgFront,
          riderId,
          'vehicleRcPic',
          view
        )
        .subscribe(
          (resp) => {
            response = resp
            this._toastMessage.openSnackBarSuccess(
              'updated image has been saved'
            )
            this._toastMessage.openSnackBarSuccess(
              'Image Saved. Please reload.'
            )
            this.fetchData.emit(true)
            this.updateImageEnabled = false
            this.rotateFrontImageDegrees = 0
            this.refreshDisplayImage()
          },
          (error) => {
            const errorMessage =
              (error && error.error && error.error.message) ||
              'Could not update Rc image'
            this._toastMessage.openSnackBarError(errorMessage)
          }
        )
    } else if (view === 'back') {
      this._registrationCardService
        .updateImage(
          this.imgBackData,
          this.imgBack,
          riderId,
          'vehicleRcPic',
          view
        )
        .subscribe(
          (resp) => {
            response = resp
            this._toastMessage.openSnackBarSuccess(
              'updated image has been saved'
            )
            this._toastMessage.openSnackBarSuccess(
              'Image Saved. Please reload.'
            )
            this.fetchData.emit(true)
            this.updateImageEnabled = false
            this.rotateBackImageDegrees = 0
            this.refreshDisplayImage()
          },
          (error) => {
            const errorMessage =
              (error && error.error && error.error.message) ||
              'Could not update Rc image'
            this._toastMessage.openSnackBarError(errorMessage)
          }
        )
    }
  }

  refreshDisplayImage() {
    if (window) {
      window['jQuery'](document).ready(function () {
        window['jQuery']('#displayImage').blowup({
          width: 400,
          height: 400,
          border: '1px solid black',
          round: false,
        })
      })

      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').show()
        }
      }
      this.screenBreakpoint =
        window['jQuery'](window).innerWidth() <= 1040 ? true : false
    }
  }

  submitAndApproveRC() {
    let response
    this.spinnerService.show()
    const userId = this.rcData.userId._id
    if (this.vehicleData && this.vehicleData.id) {
      if (!this.vehicleData.id.rc.remarks) {
        this._toastMessage.openSnackBarError('Please enter Rc Remarks')
        this.spinnerService.hide()
        return
      }
      if (!this.vehicleData.id.color) {
        this._toastMessage.openSnackBarError('Please enter vehicle color')
        this.spinnerService.hide()
        return
      }
      if (!this.vehicleData.id.vehicleModel) {
        this._toastMessage.openSnackBarError('Please select vehicle model')
        this.spinnerService.hide()
        return
      }
      if (!this.vehicleData.id.vehicleNumber) {
        this._toastMessage.openSnackBarError('Please enter vehicle number')
        this.spinnerService.hide()
        return
      }
      if (!this.vehicleData.id.fuelType) {
        this._toastMessage.openSnackBarError('Please select vehicle fuelType')
        this.spinnerService.hide()
        return
      }
      if (!this.isRcImageOptional) {
        if (!this.vehicleData.id.ownership) {
          this._toastMessage.openSnackBarError(
            'Please select vehicle ownership'
          )
          this.spinnerService.hide()
          return
        }
      } else {
        if (!this.vehicleData.id.rc.owner) {
          this._toastMessage.openSnackBarError(
            'Please enter vehicle onwer name'
          )
          this.spinnerService.hide()
          return
        }
        if (!this.rcOlderThan10Years) {
          this._toastMessage.openSnackBarError(
            'RC must be less than 10 years old'
          )
          this.spinnerService.hide()
          return
        }
        if (!this.isPillionRider && this.vehicleMode === 'Bike') {
          this._toastMessage.openSnackBarError('Must be a pillion rider')
          this.spinnerService.hide()
          return
        }
        if (!this.vehicleType && this.vehicleMode === 'Bike') {
          this._toastMessage.openSnackBarError('Please select vehicle type')
          this.spinnerService.hide()
          return
        }
      }
      this.vehicleData.id.city =
        this.rcData && this.rcData.city && this.rcData.city._id
      const mode = this.data.modeId && this.data.modeId.mode
      this.updateRcHelper()
    } else {
      this.spinnerService.hide()
    }
  }

  updateRcHelper() {
    for (let i = 0; i < this.vehicleList.length; i++) {
      if (
        this.vehicleList &&
        this.vehicleList[i]['_id'] === this.vehicleData.id.vehicleModel
      ) {
        this.vehicleData.id['make'] = this.vehicleList[i]['model']
        break
      }
    }
    this._registrationCardService
      .submitRC(this.rcData.userId._id, this.vehicleData.id)
      .subscribe(
        (res) => {
          const response = res
          if (
            response &&
            response['info'] &&
            response['info'].status === 'success'
          ) {
            this.rcStatus.emit(true)
            this.showApproveButton = false
            this.showRCVerify = false
            this.updateImageEnabled = false
            this.spinnerService.hide()
            const view = this.showFront ? 'front' : this.showBack ? 'back' : ''
            this._logService
              .insertEvent(
                4,
                this.rcData._id,
                { rcApproved: false },
                { rcApproved: true, rcDetails: this.vehicleData, rcSide: view }
              )
              .subscribe()
            this.formApprovalMessage()
            this._toastMessage.openSnackBarSuccess('RC updated')
          } else {
            this.spinnerService.hide()
            this._toastMessage.openSnackBarError('Problem in RC updation')
          }
        },
        (error) => {
          this.showSubmitButton = true
          this.showApproveButton = true
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not approve RC'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  getSelectedCity(evt: any) {
    this.currentCity = this.cityArray[this.vehicleData.id.city]
  }

  updateVehicleNumber() {
    if (this.rcDefaulter && !this.rcDefaulterNumberChanged) {
      this._toastMessage.openSnackBarError('Cannot update vehicle number for defaulter' );
      return;
    }
    if (this.vehicleData.id.vehicleNumber === this.oldVehicleNumber) {
      this._toastMessage.openSnackBarError(
        'No update required, Vehicle number is the same'
      )
    } else {
      let response, vehicleNumber
      if (
        this.vehicleNumberFormControl.errors &&
        this.vehicleNumberFormControl.errors.pattern
      ) {
        this._toastMessage.openSnackBarSuccess(
          'Vehicle number cannot contain spaces or special characters. Max length 12 characters!'
        )
      } else {
        this.spinnerService.show()

        this._registrationCardService
          .updateVehicleNumber(
            this.rcData.userId._id,
            this.vehicleData.id.vehicleNumber.replace(/\s/g, '').toUpperCase()
          )
          .subscribe(
            (res) => {
              response = res
              this.showRCVerify = true
              this.isRcApproved = false
              this.showRCVerify = true
              this.spinnerService.hide()
              this.registrationNumber.emit(this.vehicleData.id.vehicleNumber)
              this.oldVehicleNumber = this.vehicleData.id.vehicleNumber
              if (
                this.userInitialData.vehiclesAssignable[
                  this.rcData.vehiclesAssignable.length - 1
                ] &&
                this.userInitialData.vehiclesAssignable[
                  this.rcData.vehiclesAssignable.length - 1
                ].id
              ) {
                vehicleNumber =
                  this.userInitialData.vehiclesAssignable[
                    this.rcData.vehiclesAssignable.length - 1
                  ].id.vehicleNumber
              } else {
                vehicleNumber = ''
              }
              this._logService
                .insertEvent(
                  4,
                  this.rcData._id,
                  { vehicleNumber: vehicleNumber },
                  { vehicleNumber: this.vehicleData.id.vehicleNumber }
                )
                .subscribe()
              if (
                this.userInitialData.vehiclesAssignable[
                  this.rcData.vehiclesAssignable.length - 1
                ] &&
                this.userInitialData.vehiclesAssignable[
                  this.rcData.vehiclesAssignable.length - 1
                ].id
              ) {
                this.userInitialData.vehiclesAssignable[
                  this.rcData.vehiclesAssignable.length - 1
                ].id.vehicleNumber = this.vehicleData.id.vehicleNumber
              }
              this.vehicleNumberFormControl.disable()
              this._toastMessage.openSnackBarSuccess(
                'Vehicle number is updated'
              )
            },
            (error) => {
              this.spinnerService.hide()
              const errorMessage =
                (error && error.error && error.error.message) ||
                'Could not update vehicle number'
              this._toastMessage.openSnackBarError(errorMessage)
            }
          )
      }
    }
  }

  updateVehicleMake() {
    let response, vehicleModel
    this.spinnerService.show()
    this._registrationCardService
      .updateVehicleModel(
        this.rcData.userId._id,
        this.vehicleData.id.vehicleModel
      )
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            vehicleModel =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.vehicleModel
          } else {
            vehicleModel = ''
          }
          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { vehicleModel: this.getVehicleName(vehicleModel) },
              {
                vehicleModel: this.getVehicleName(
                  this.vehicleData.id.vehicleModel
                ),
              }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ] &&
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.vehicleModel = this.vehicleData.id.vehicleModel
          }

          this._toastMessage.openSnackBarSuccess('Vehicle model updated')
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update vehicle model'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  getVehicleName(vehicleModelId) {
    let vehicleName
    for (let i = 0; i < this.vehicleList.length; i++) {
      if (this.vehicleList && this.vehicleList[i]['_id'] === vehicleModelId) {
        vehicleName = this.vehicleList[i]['model']
        break
      }
    }
    return vehicleName
  }

  updateVehicleColor() {
    let response, oldColor
    this.spinnerService.show()
    if (!this.vehicleData.id.color) {
      this._toastMessage.openSnackBarError('Vehicle color is a mandatory field.');
      this.spinnerService.hide();
    } else {
      this._registrationCardService
      .updateVehicleColor(this.rcData.userId._id, this.vehicleData.id.color)
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            oldColor =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.color
          } else {
            oldColor = ''
          }

          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { vehicleColor: oldColor },
              { vehicleColor: this.vehicleData.id.color }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ] &&
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.color = this.vehicleData.id.color
          }
          this._toastMessage.openSnackBarSuccess('Vehicle color updated')
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update vehicle color'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
    }
  }

  updateVehicleOwnerName() {
    if (this.rcDefaulter && !this.rcDefaulterNumberChanged) {
      this._toastMessage.openSnackBarError('Cannot update vehicle owner name for defaulter' );
      return;
    }
    if (
      this.vehicleOwnerNameFormControl.errors &&
      this.vehicleOwnerNameFormControl.errors.pattern
    ) {
      this._toastMessage.openSnackBarSuccess(
        'Vehicle owner name cannot contain number or special characters. Max length 64 characters!'
      )
    } else {
      let response, ownerName
      this.spinnerService.show()
      this._registrationCardService
        .updateVehicleOwnerName(
          this.rcData.userId._id,
          this.vehicleData.id.rc.owner
        )
        .subscribe(
          (res) => {
            response = res
            this.spinnerService.hide()
            if (
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id
            ) {
              ownerName =
                this.userInitialData.vehiclesAssignable[
                  this.rcData.vehiclesAssignable.length - 1
                ].id.rc.owner
            } else {
              ownerName = ''
            }
            this._logService
              .insertEvent(
                4,
                this.rcData._id,
                { 'rc.owner': this.getVehicleName(ownerName) },
                {
                  'rc.owner': this.getVehicleName(this.vehicleData.id.rc.owner),
                }
              )
              .subscribe()
            if (
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ] &&
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id
            ) {
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.rc.owner = this.vehicleData.id.rc.owner
            }

            this._toastMessage.openSnackBarSuccess('Vehicle owner updated')
          },
          (error) => {
            this.spinnerService.hide()
            const errorMessage =
              (error && error.error && error.error.message) ||
              'Could not update vehicle model'
            this._toastMessage.openSnackBarError(errorMessage)
          }
        )
    }
  }

  verifyRcDoc() {
    this.rcDoc.emit()
    this.asyncRefreshTrigger.next()
  }

  updateVerificationCard(verificationStatus) {
    this.thirdPartyStatus = verificationStatus.status
    this.thirdPartyMessage = verificationStatus.message
    this.verificationPlatform = verificationStatus.verifiedBy
    this.verifiedOn = verificationStatus.verifiedOn
    this.isRcAccepted = verificationStatus.status === 'Accepted'
    this.showRCVerify = !this.isRcAccepted
    if (this.isRcApproved) {
      this.showRCVerify = false
    }
  }

  updateVehicleFuelType() {
    let response, previousFuelType
    this.spinnerService.show()
    this._registrationCardService
      .updateVehicleFuelType(
        this.rcData.userId._id,
        this.vehicleData.id.fuelType
      )
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            previousFuelType =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.fuelType
          } else {
            previousFuelType = ''
          }

          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { vehicleFuelType: previousFuelType },
              { vehicleFuelType: this.vehicleData.id.fuelType }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ] &&
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.fuelType = this.vehicleData.id.fuelType
          }
          this._toastMessage.openSnackBarSuccess('Vehicle FuelType updated')
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update vehicle fuel type'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  updateVehicleOwnership() {
    let response, oldOwnerShip
    this.spinnerService.show()
    this._registrationCardService
      .updateVehicleOwnership(
        this.rcData.userId._id,
        this.vehicleData.id.ownership
      )
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            oldOwnerShip =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.ownership
          } else {
            oldOwnerShip = ''
          }

          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { rented: oldOwnerShip },
              { rented: this.vehicleData.id.ownership }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ] &&
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.ownership = this.vehicleData.id.ownership
          }
          this._toastMessage.openSnackBarSuccess('Vehicle ownership updated')
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update vehicle ownership'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  onRegistrationDateChange(event: MatRadioChange) {
    this.registrationDateUpdated = true
  }

  onSeatCapacityChange(event: MatRadioChange) {
    this.vehicleData.id.seatCapacity = event.value ? 2 : 1
    this.updateVehicleSeatCapacity()
  }

  dateChanged(evt: any) {
    this.registrationDateUpdated = true
    console.log('new date: ', evt.target.value.getTime())
  }

  updateExpiresOn() {
    let response, oldRegistrationDate
    this.spinnerService.show()
    this._registrationCardService
      .updateVehicleRegistrationDate(
        this.rcData.userId._id,
        this.vehicleData.id.rc.registrationDate
      )
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            oldRegistrationDate =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.rc.registrationDate
          } else {
            oldRegistrationDate = ''
          }

          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { 'rc.registrationDate': oldRegistrationDate },
              { 'rc.registrationDate': this.vehicleData.id.rc.registrationDate }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ] &&
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.rc.registrationDate = this.vehicleData.id.rc.registrationDate
          }
          this.registrationDateUpdated = false
          this.rcOlderThan10Years = this.isRcOlderThan10Years(
            this.vehicleData.id.rc.registrationDate
          )
          this.showButtonIcon = false;
          this._toastMessage.openSnackBarSuccess(
            'Vehicle registration date updated'
          )
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update vehicle registration date'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  updateVehicleSeatCapacity() {
    let response, previousSeatCapacity
    this.spinnerService.show()
    this._registrationCardService
      .updateVehicleSeatCapacity(
        this.rcData.userId._id,
        this.vehicleData.id.seatCapacity
      )
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            previousSeatCapacity =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.seatCapacity
          } else {
            previousSeatCapacity = ''
          }

          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { seatCapacity: previousSeatCapacity },
              { seatCapacity: this.vehicleData.id.seatCapacity }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ] &&
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.seatCapacity = this.vehicleData.id.seatCapacity
          }
          this.isPillionRider = this.vehicleData.id.seatCapacity
            ? (this.vehicleData.id.seatCapacity || -1) > 1
            : undefined
          this._toastMessage.openSnackBarSuccess(
            'Vehicle seat capacity updated'
          )
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update vehicle seat capacity'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  updateVehicleCategory(event: MatRadioChange) {
    let response, previousVehicleCategory
    this.spinnerService.show()
    this._registrationCardService
      .updateVehicleCategory(this.rcData.userId._id, event.value)
      .subscribe(
        (res) => {
          response = res
          this.spinnerService.hide()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            previousVehicleCategory =
              this.userInitialData.vehiclesAssignable[
                this.rcData.vehiclesAssignable.length - 1
              ].id.vehicleCategory
          } else {
            previousVehicleCategory = ''
          }

          this._logService
            .insertEvent(
              4,
              this.rcData._id,
              { vehicleCategory: previousVehicleCategory },
              { vehicleCategory: event.value }
            )
            .subscribe()
          if (
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ] &&
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id
          ) {
            this.userInitialData.vehiclesAssignable[
              this.rcData.vehiclesAssignable.length - 1
            ].id.vehicleCategory = event.value
          }
          this.vehicleType = event.value
          this._toastMessage.openSnackBarSuccess('Vehicle category updated')
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not update vehicle category'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  hasRcImageIfRequired(): boolean {
    const vehicleNumber =
      this.vehicleData &&
      this.vehicleData.id &&
      this.vehicleData.id.vehicleNumber
    return !!(
      (this.isRcImageOptional && vehicleNumber) ||
      (this.imgFront && this.imgBack)
    )
  }

  isRcOlderThan10Years(registrationDate: Date): boolean {
    if (Number.isNaN(registrationDate.getTime())) return undefined
    const today = new Date()
    return (
      today.setFullYear(today.getFullYear() - 10) < registrationDate.getTime()
    )
  }
  datePickerOpened(evt: any) {
    this._hotkeysService.add(
      new Hotkey('left', (event: KeyboardEvent): boolean => {
        event.preventDefault()
        return false // Prevent bubbling
      })
    )
    this._hotkeysService.add(
      new Hotkey('right', (event: KeyboardEvent): boolean => {
        event.preventDefault()
        return false // Prevent bubbling
      })
    )
  }

  datePickerClosed(evt: any) {
    this.registrationDateUpdated = true
    this.showButtonIcon = true;
    this._hotkeysService.add(
      new Hotkey('left', (event: KeyboardEvent): boolean => {
        document.getElementById('backButton').click()
        return false // Prevent bubbling
      })
    )
    this._hotkeysService.add(
      new Hotkey('right', (event: KeyboardEvent): boolean => {
        document.getElementById('nextButton').click()
        return false // Prevent bubbling
      })
    )
  }
}
