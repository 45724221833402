import {
    Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HotkeysService } from 'angular2-hotkeys';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LogService } from '../../log-service.service';
import { HelperService } from '../../helper.service';
import { ConstantsService } from '../../constants.service';
import { PanService } from './pan.service';
import { OnboardingPageService } from '../onboarding-page.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { PopUpMessageComponent } from '../../pop-up-message/pop-up-message.component';
const constants = require('../../../../server/utils/constants');

const _ = require('lodash');

@Component({
    selector: 'app-pan',
    template: require('./pan.component.html'),
    providers: [PanService]
})
export class PanComponent implements OnInit, OnChanges {
    panNumberFormControl = new FormControl('', [
        Validators.pattern('^([a-zA-Z]){3}[pP][a-zA-Z]([0-9]){4}([a-zA-Z]){1}$'),
        Validators.required
    ]);
    verfiedMessage: string;

    constructor(private _panService: PanService,
        private _hotkeysService: HotkeysService,
        private _toastMessage: ToastMessage,
        private spinnerService: Ng4LoadingSpinnerService,
        private _logService: LogService,
        private helperService: HelperService,
        private constantService: ConstantsService,
        private dialog: MatDialog,
        private _onboardingService : OnboardingPageService) {
    }
    @Input() data: any;
    @Input() userIdentity: any;
    @Input() showFront = false;
    @Input() showBack = false;
    @Input() existingPanStatus: any;
    @Input() allowEditing: any;
    @Input() panDoc: any;
    @Input() isPanRejected: any;

    @Output() panStatus: EventEmitter<any> = new EventEmitter();
    @Output() fetchData: EventEmitter<any> = new EventEmitter();
    @Output() panImage: EventEmitter<any> = new EventEmitter();
    @Output() addPanToRejectList: EventEmitter<any> = new EventEmitter();
    @Output() showChangeDetails: EventEmitter<any> = new EventEmitter();

    public screenBreakpoint = false;
    public panData: any;
    public updateImageEnabled = false;
    public config: any;
    public userId: any;
    public panAutoApproved = false;
    public panNumber: String;
    public existingPanNumber: String;
    public remarks: String;
    public riderId: any;
    public imgFront: any;
    public imgBack: any;
    public imgFrontData: any;
    public imgBackData: any;
    public showApproveButton = false;
    public showVerifyButton = false;
    public showButtonIcon = false;
    public panRemarks = [];
    public panApprovalMessage: any;
    public isPanApproved = false;
    public panApprovalStatus = 'Approved';
    public isPanAutoApproved = false;
    public APPROVAL_MESSAGE = this.constantService.approvalMessage;
    public isActive = false;
    public isPanVerified = false;
    public isPanDocStatusAvailable : boolean;
    public showBulkDocDetailsChange = false;
    public panVerifyStatus: any;
    public panVerifyMessage: any;
    public verificationPlatform: any;
    public verifiedOn: any;
    public thirdPartyMessage: any;
    public thirdPartyStatus: any;
    public changedDetailsOfDoc = [];
    public panVerificationDetails = {};

    public verificationStatus = {
        ACCEPTED: 'Accepted',
        WARNING: 'Warning',
        REJECTED: 'Rejected',
        VERIFIED: 'Verified'
    };

    public asyncRefreshTrigger: Subject<void> = new Subject<void>();

    private invalidPan = 'Pan Number is a mandatory field. It can not contain any' +
    ' special characters and has a limit of 10 characters and look like - CVCPK2345K.';
    private isExistingPan = 'Pan Number already exists.';

    private rotateFrontImageDegrees = 0;
    private rotateBackImageDegrees = 0;

    onResize(event) {
        this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;

    }

    showDocDetailsChangeTypePanel() {
        if (this.existingPanNumber !== undefined && this.existingPanNumber !== null && this.existingPanNumber.trim() !== '' && this.existingPanNumber.toUpperCase() !== this.panNumber.toUpperCase()) {
            this.changedDetailsOfDoc.push(`Existing Number: ${this.existingPanNumber.toUpperCase()}`)
            this.changedDetailsOfDoc.push(`New Number: ${this.panNumber.toUpperCase()}`)
            this.showButtonIcon = true;
        }
        if (this.remarks !== undefined && this.remarks !== null && this.remarks.trim() !== ''  && this.remarks !== this.panData.panCard.remarks) {
            this.changedDetailsOfDoc.push(`Existing Remarks: ${this.remarks}`)
            this.changedDetailsOfDoc.push(`New Remarks: ${this.panData.panCard.remarks}`)
        }
    }

    private openChangeDetailsDialog(actionFunction: () => void): void {
        const dialogRef = this.dialog.open(PopUpMessageComponent, {
          width: '550px',
          data: {
            options: [],
            title: 'Please confirm for the changed details in pan',
            message: '',
            data: {
              type: 'orderedList',
              info: this.changedDetailsOfDoc,
              note: 'Are you sure you want to update above details',
            },
            reason: 'changeDocDetails',
            buttonText: 'Yes',
            cancelText: 'No',
            styles: {
              title: 'font-size-20 margin-bottom-30',
              actionClass: 'doc-details-change-confirm',
              note: 'margin-bottom-12 margin-top-30 color-grey'
            }
          }
        });
      
        dialogRef.afterClosed().subscribe(confirm => {
          if (confirm) {
            actionFunction();
          } else {
            this.panNumber = this.existingPanNumber;
            this.showButtonIcon = false;
            this.showVerifyButton = false;
          }
          this.changedDetailsOfDoc.splice(0, this.changedDetailsOfDoc.length);
        });
    }
      
    viewDetailsForChangedDocs() {
        this.showDocDetailsChangeTypePanel()
        if (this.changedDetailsOfDoc.length > 0) {
            this.openChangeDetailsDialog(() => this.approvePan());
        } else {
            this.approvePan();
        }
    }
      
    viewDetailsForChangedDocsForPanUpdate() {
        if (this.existingPanNumber !== undefined && this.existingPanNumber !== null && this.existingPanNumber.trim() !== '') {
            if (this.existingPanNumber.toUpperCase() !== this.panNumber.toUpperCase()) {
                this.changedDetailsOfDoc.push(`Existing Number: ${this.existingPanNumber.toUpperCase()}`)
                this.changedDetailsOfDoc.push(`New Number: ${this.panNumber.toUpperCase()}`)
                this.showButtonIcon = true;
                this.openChangeDetailsDialog(() => this.updatePanCardNumber());
            } else {
                this.showVerifyButton = false;
            }  
        } else {
            this.updatePanCardNumber();
        }
    }

    updatePanCardNumber() {
        return new Promise(() => {
        this.spinnerService.show();
        if (this.isInvalid()) {
            this._toastMessage.openSnackBarError(this.invalidPan);
            this.spinnerService.hide();
        } else if(this.isExisting()) {
            this._toastMessage.openSnackBarError(this.isExistingPan);
            this.spinnerService.hide();
        } else {
        this._panService.updateNumber(this.data.userId._id, this.panNumber.toUpperCase()).subscribe(res => {
            this.showVerifyButton = true;
            this.isPanApproved = false;
            this.isPanVerified = false;
            this.showButtonIcon = false;
            this.showApproveButton = true;
            this.spinnerService.hide();
            this._logService.insertEvent(10, this.data._id, { number: this.existingPanNumber },
                { number: this.panNumber }).subscribe();
            this.existingPanNumber = res["data"]["panCard"]["number"];
            this._toastMessage.openSnackBarSuccess('Pan card number is updated!');
        },
            error => {
                this.spinnerService.hide();
                const errorMessage = error && error.error && error.error.message || 'Could not update pan card number';
                this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
            });
        }
    });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.riderId = changes.data.currentValue.userId._id;
            await this._onboardingService.getRiderDetails(this.riderId).subscribe((res : any) => {
                this.panData = res.data;
                this.panNumber = this.panData.panCard.number;
                this.existingPanNumber = this.panData.panCard.number;
                this.remarks = this.panData.panCard.remarks;
                this.isPanDocStatusAvailable = this.isPanDocStatusPresent(this.panData.docVerificationStatus);
                this.thirdPartyMessage = this.panData.docVerificationStatus && this.isPanDocStatusAvailable ? this.panData.docVerificationStatus.pan.message : '';
                this.thirdPartyStatus = this.panData.docVerificationStatus && this.isPanDocStatusAvailable? this.panData.docVerificationStatus.pan.status: '';
                this.isPanVerified = this.thirdPartyStatus && (this.thirdPartyStatus !== this.verificationStatus.WARNING && this.thirdPartyStatus !== this.verificationStatus.REJECTED);
                this.verificationPlatform = this.panData.docVerificationStatus && this.isPanDocStatusAvailable? this.panData.docVerificationStatus.pan.verifiedBy: '';
                this.verifiedOn = this.panData.docVerificationStatus && this.isPanDocStatusAvailable ? this.panData.docVerificationStatus.pan.verifiedOn: '';
                this.isActive = this.panData.active;
                this.riderId = this.panData.userId._id;
                this.imgFront = this.panData.panCard.link;
                this.imgBack = "";
                if (this.panData.panCard.link && this.panData.panCard.verified === false) {
                    this.showApproveButton = true;
                    this.showVerifyButton = true;
                }
                if (changes && changes.allowEditing && changes.allowEditing.currentValue === false) {
                    this.panNumberFormControl.disable();
                }
                if (this.panData && this.panData.panCard && this.panData.panCard.verified) {
                    this.formApprovalMessage();
                }
                if(this.panAutoApproved || this.isPanVerified){
                    this.showVerifyButton = false;
                }
            });
        }
    }

    ngOnInit() {
        if (document.getElementById('BlowupLens')) {
            if (window) {
                window['jQuery']('#BlowupLens').show();
            }
        }
        this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
    }

    formApprovalMessage() {
    const panAutoApprovalStatus = this.panData['autoApprovalStatus'] || {};
    this.isPanApproved = true;
    if (  panAutoApprovalStatus['panVerificationStatus']
          && panAutoApprovalStatus['panVerificationStatus'].autoApproved
      ) {
        this.panAutoApproved = true;
        this.panApprovalMessage = this.APPROVAL_MESSAGE.autoApproved;
    } else {
      this.panApprovalMessage = this.APPROVAL_MESSAGE.agentApproved;
    }
}

    isPanDocStatusPresent(docVerificationStatus){
        if(docVerificationStatus){
            return docVerificationStatus.pan ? true : false;
        }
    }

    rotateImage(link, degrees, key) {
        if (key === 'front') {
            this.rotateFrontImageDegrees += degrees;
            degrees = this.rotateFrontImageDegrees;
        } else {
            this.rotateBackImageDegrees += degrees;
            degrees = this.rotateBackImageDegrees;
        }
        let response;
        this.spinnerService.show();
        this._panService.rotateImage(link, degrees).subscribe(resp => {
            response = resp;
            if (key === 'front') {
                this.imgFrontData = response.data;
                setTimeout(() => {
                    this.imgFront = response.path;
                    this.updateImageEnabled = true;
                    window['jQuery'](document).ready(function () {
                        window['jQuery']('#displayImage').attr('src', response.data).blowup({
                            'width': 400,
                            'height': 400,
                            'border': '1px solid black',
                            'round': false
                        });
                    });
                }, 300);
            }
            if (key === 'back') {
                this.imgBackData =  response.data;
                setTimeout(() => {
                    this.imgBack = response.path;
                    this.updateImageEnabled = true;
                    window['jQuery'](document).ready(function () {
                        window['jQuery']('#displayImage').attr('src', response.data).blowup({
                            'width': 400,
                            'height': 400,
                            'border': '1px solid black',
                            'round': false
                        });
                    });
                }, 300);
            }
        },
            error => {
                this.spinnerService.hide();
                const errorMessage = error && error.error && error.error.message || 'Could not rotate image';
                this._toastMessage.openSnackBarError(errorMessage);
            });
    }

    updatePanImage() {
        let response;
        const riderId = this.data.userId._id;
        this._panService.updateImage(this.imgFront, this.imgFrontData, riderId).subscribe(resp => {
            this.fetchData.emit(true);
            response = resp;
            this._toastMessage.openSnackBarSuccess('Image has been updated');
            this._toastMessage.openSnackBarSuccess('Image Saved. Please reload.');
            this.updateImageEnabled = false;
            this.rotateBackImageDegrees = 0;
            this.rotateFrontImageDegrees = 0;
            this.refreshDisplayImage();
        },
            error => {
                const errorMessage = error && error.error && error.error.message || 'Could not update Pan image';
                this._toastMessage.openSnackBarError(errorMessage);
            });
    }

    refreshDisplayImage() {
        if (window) {

            window['jQuery'](document).ready(function () {
                window['jQuery']('#displayImage').blowup({
                    'width': 400,
                    'height': 400,
                    'border': '1px solid black',
                    'round': false
                });
            });

        }

        if (document.getElementById('BlowupLens')) {
            if (window) {
                window['jQuery']('#BlowupLens').show();
            }
        }
        this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
    }

    updateImageUrl(evt: any) {
        this.fetchData.emit(true);
        if (evt.view === 'front') {
            this.imgFront = evt.url;
            this.panData.panCard.link == this.imgFront;
            this.panImage.emit(this.imgFront);
        } else if (evt.view === 'back') {
            this.imgBack = evt.url;
            this.panData.license.backLink == this.imgBack;
        }
        if (evt.mode === 'delete') {
            const panStatus = { 
                "data": {}, 
                "status": this.verificationStatus.REJECTED, 
                "message": null,
                "isPanAccepted": false,
                verifiedBy: null,
                verifiedOn: null
            };
            const condition = {
                "docVerificationStatus.pan": panStatus
            };
    
            this.updateRider(condition);
            this.panStatus.emit(false);
            this.showApproveButton = false;
            this.showVerifyButton = false;
            this.updateImageEnabled = false;
            if (document.getElementById('BlowupLens')) {
                if (window) {
                    window['jQuery']('#BlowupLens').hide();
                }
            }
        }
        if (evt.mode === 'update') {
            this.showApproveButton = true;
            this.showVerifyButton = true;
            this._toastMessage.openSnackBarSuccess('Image Saved. Please reload using Shift + R .');
        }
    }

    async approvePan() {
        this.spinnerService.show();
         if(this.panNumber && (this.existingPanNumber.toUpperCase() !== this.panNumber.toUpperCase())) {
            await this.updatePanCardNumber();
            await this.updatePanVerifiedAndRemarks();
        } else if (this.existingPanNumber) {
            await this.updatePanVerifiedAndRemarks();
        } else {
            this.spinnerService.hide();
            this._toastMessage.openSnackBarError('Make sure you have entered all the mandatory data');
        }
    }

    onVerifyPan = () => {
        this.spinnerService.show();
        const captainData = this.data;
        const payload = {
            image: this.imgFront,
            name: `${captainData.firstName} ${captainData.lastName}`,
            referenceId: this.data.userId && this.data.userId._id,
            documentType: 'pan',
            verificationType: 'ocrgc',
            actorId: this.panDoc.actorId,
            actorType: constants.ACTOR_TYPE.AGENT,
            docNumber: this.panNumber
        }
        this.showVerifyButton = false;
        this._panService.verifyPan(payload)
            .subscribe((result: any) => {
                this.asyncRefreshTrigger.next();
                this.spinnerService.hide();
                if (result && result['status']==='success') {
                    this._toastMessage.openSnackBarSuccess(result['data']);
                    this.showVerifyButton = false;
                } else {
                    this.showVerifyButton = true;
                    this._toastMessage.openSnackBarError('Faced problem in verification of docs');
                }
            },
            error => {
                this.showVerifyButton = true;
                this._toastMessage.openSnackBarError(error.message);
            });
    }

    private updateRiderAndAddLog(result: any) {
        this.addPanLog(result);

        this.panVerifyStatus = result.data.verificationStatus;
        this.panVerifyMessage = result.data.message;
        this.verificationPlatform = 'IN-D';
        this.verifiedOn = result.data.updatedAt;
        this.thirdPartyStatus = this.panData.status;
        this.thirdPartyMessage = result.data.message;
    }

    private updateRider(condition) {
        this._panService.updateRider(this.riderId, condition)
            .subscribe((result: any) => {
                console.log('Updated rider pan status.');
            }, error => {
                console.log('Error - Updated rider pan status.', error);
            });
    }

    private addPanLog(result) {
        console.log(result);
        this._logService.insertEvent(10, this.riderId, {},
        { number : this.panNumber || null,
            message: result.data.message || null,
            verifiedBy: result.data.type,
            verifiedOn: result.data.updatedAt
        }).subscribe();
    }

    private updatePanVerifiedAndRemarks() {
        return new Promise(() => {
        this.formApprovalMessage();
        const remarks = this.remarks ? this.remarks : null;
        this.updatePanVerified();
        if (remarks) {
            this._panService.updateRemarks(this.data.userId._id, remarks).subscribe(res => {
                ;
                this.spinnerService.hide();
                this._logService.insertEvent(10, this.data._id, { remarks: this.data.panCard.remarks },
                    { remarks: remarks }).subscribe();
                this._toastMessage.openSnackBarSuccess('Remarks is updated!');
            },
                error => {
                    this.spinnerService.hide();
                    const errorMessage = error && error.error && error.error.message || 'Could not update remarks';
                    this._toastMessage.openSnackBarError(errorMessage);
                });
        }
        this.panData.status = this.verificationStatus.ACCEPTED;
    });
    }

    private updatePanVerified() {
        this._panService.updateVerified(this.data.userId._id, 'true')
            .subscribe(res => {
                this.panStatus.emit(true);
                this.showApproveButton = false;
                this.updateImageEnabled = false;
                this.showVerifyButton = false;
                this.spinnerService.hide();
                const view = this.showFront ? 'front' : this.showBack ? 'back' : '';
                this._logService.insertEvent(10, this.data._id, { panApproved: false }, { panApproved: true, panData: this.data.panCard, panSide: view }).subscribe();
                this._toastMessage.openSnackBarSuccess('Pan has been approved');
                this.formApprovalMessage();
            }, error => {
                this.showApproveButton = true;
                this.showVerifyButton = true;
                this.spinnerService.hide();
                const errorMessage = 'Could not approve Pan';
                this._toastMessage.openSnackBarError(errorMessage);
            });
    }

    private isInvalid() {
        return (this.panNumberFormControl.errors && this.panNumberFormControl.errors.required) ||
            (this.panNumberFormControl.errors && this.panNumberFormControl.errors.pattern);
    }

    private isExisting() {
        return this.existingPanNumber.toUpperCase() === this.panNumber.toUpperCase();
    }
    
    createPanStatus(result) {
        const panStatus = this.panData.status ? this.panData.status : result.data.verificationStatus;
        const isPanAccepted = this.panData.status === this.verificationStatus.ACCEPTED;
        const message = result.data.message || '';
        const panData = {name : result.data.requestName, image: result.data.requestImage} || {};

        return { 
            "data": panData, 
            "status": panStatus, 
            "message": message,
            "isPanAccepted": isPanAccepted,
            verifiedBy: result.data.type,
            verifiedOn: result.data.updatedAt
        };
    }

    addToBulkRejection(event) {
        event.view = 'front';
        this.addPanToRejectList.emit(event);
    }


  updateVerificationCard(verificationStatus) {
     
    this.thirdPartyStatus = verificationStatus.status;
    this.thirdPartyMessage = verificationStatus.message;
    this.verificationPlatform = verificationStatus.verifiedBy;
    this.verifiedOn = verificationStatus.verifiedOn;
    this.isPanVerified = verificationStatus.status === this.verificationStatus.ACCEPTED
        || verificationStatus.status === this.verificationStatus.VERIFIED;
    this.showVerifyButton = !this.isPanVerified;
    if(this.isPanApproved) {
        this.showVerifyButton = false;
    }
  }

}


