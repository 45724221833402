import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CabService} from './cab.service';
import {ToastMessage} from '../../toast-message/toast-message.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import { MatDialog } from '@angular/material';
import { PopUpMessageComponent } from '../../pop-up-message/pop-up-message.component';
import {LogService} from '../../log-service.service';
import {ConstantsService} from '../../constants.service';
import {Subject} from 'rxjs';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';

// @ts-ignore
const _ = require('lodash');
const moment = require('moment');
@Component({
    // tslint:disable-next-line:component-selector
  selector: 'cab-doc',
  template: require('./cab.component.html'),
  providers: [ToastMessage, CabService, LogService, ConstantsService]
})
export class CabComponent implements OnInit, OnChanges {


  constructor(
    private _cabService: CabService,
    private _hotkeysService: HotkeysService,
    private _toastMessage: ToastMessage,
    private spinnerService: Ng4LoadingSpinnerService,
    private _logService: LogService,
    private dialog: MatDialog,
    private constantService: ConstantsService) { }

  @Input() name: string;
  @Input() data: any;
  @Input() isCabDoc = true;
  @Input() userIdentity: any = {};
  @Input() isRejected: any;
  @Input() isApproved: any;
  @Output() addCardToRejectList: EventEmitter<any> = new EventEmitter();
  @Output() rejected = new EventEmitter();
  @Output() approved = new EventEmitter();
  public screenBreakpoint = false;

  public updateImageEnabled = false;
  public allowEditing = true;
  public minDate = new Date();
  public riderId: any;
  public changedDetailsOfDoc = [];
  public showApproveButton = false;
  public showSubmitButton = false;
  public showButtonIcon = false;
  public isActive = false;
  public docType: any;
  public cabData: any;
  public APPROVAL_MESSAGE = this.constantService.approvalMessage;
  public approvalMessage = '';
  public imgFront: any;
  public expiresOn: Date;
  public oldExpireOn: any;
  public asyncRefreshTrigger: Subject<void> = new Subject<void>();

  private rotateFrontImageDegrees = 0;
  public docFrontImageData: any;

  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.riderId = changes.userIdentity ? changes.userIdentity.currentValue.userId : this.riderId;
    if (changes.data) {
      this.isActive = changes.data.currentValue.active
    }
    this.docType = changes.name ? changes.name.currentValue : "cab";
    this.getCabData(this.riderId);
  }

  ngOnInit() {
    this.getCabData(this.riderId);
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').show();
      }
    }
    this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
  }

  getCabData(riderId) {
    let doc;
    this._cabService.getCabData(riderId).subscribe(
    response => {
        doc = response;
        const document = doc[this.name];
        this.cabData = document;
        const expiresOn = document && document.info && document.info.expiresOn;
        this.expiresOn = new Date(expiresOn);
        if (document && document.approvalInfo && document.approvalInfo.approvedAt) {
            this.showApproveButton = false;
            this.showSubmitButton = false;
            this.isApproved = true;
            this.approvalMessage = this.APPROVAL_MESSAGE.agentApproved;
        }
        if (document && document.rejectionInfo && document.rejectionInfo.front &&  document.rejectionInfo.front.timestamp) {
            this.showApproveButton = false;
            this.showSubmitButton = false;
        }
        if (document && document.images && document.images.front && document.images.front.path) {
            this.imgFront = document.images.front.path;
            if (!(document && document.approvalInfo && document.approvalInfo.approvedAt)) {
              this.showApproveButton = true;
            }
        } else {
          this.imgFront = null;
        }
    },
    error => {
        const errorMessage = error && error.error && error.error.message || 'Could not fetch cab data';
        this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  addToBulkRejection(event) {
    this.addCardToRejectList.emit(event);
    this.isApproved = false;
  }

  private openChangeDetailsDialog(actionFunction: () => void, docType: string): void {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: `Please confirm for the changed details in ${docType}`,
        message: '',
        data: {
          type: 'orderedList',
          info: this.changedDetailsOfDoc,
          note: 'Are you sure you want to update above details',
        },
        reason: 'changeDocDetails',
        buttonText: 'Yes',
        cancelText: 'No',
        styles: {
          title: 'font-size-20 margin-bottom-30',
          actionClass: 'doc-details-change-confirm',
          note: 'margin-bottom-12 margin-top-30 color-grey'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        actionFunction();
      } else {
        this.expiresOn = new Date(this.cabData.info.expiresOn);
        this.showButtonIcon = false;
      }
      this.changedDetailsOfDoc.splice(0, this.changedDetailsOfDoc.length);
    });
  }

  viewDetailsForExpiresOnUpdate() {
    const expiretime = moment(this.expiresOn).format('YYYY-MM-DD');
    if (this.isNotExisting()) {
      const evtTime = moment(this.cabData.info.expiresOn).format('YYYY-MM-DD');
      if (expiretime !== evtTime) {
        this.changedDetailsOfDoc.push(`Existing Expiry date: ${evtTime}`)
        this.changedDetailsOfDoc.push(`New Expiry date: ${expiretime}`)
      }
    }
    if (this.changedDetailsOfDoc.length > 0 && this.isActive) {
      this.openChangeDetailsDialog(() => this.updateExpiresOn(),this.docType);
    } else {
      this.updateExpiresOn();
    }
  }

  viewDetailsForChangedDocs() {
    const expiretime = moment(this.expiresOn).format('YYYY-MM-DD');
    if (this.isNotExisting()) {
      const evtTime = moment(this.cabData.info.expiresOn).format('YYYY-MM-DD');
      if (expiretime !== evtTime) {
        this.changedDetailsOfDoc.push(`Existing Expiry date: ${evtTime}`)
        this.changedDetailsOfDoc.push(`New Expiry date: ${expiretime}`)
      }
    }
    if (this.changedDetailsOfDoc.length > 0 && this.isActive) {
      this.openChangeDetailsDialog(() => this.approve(), this.docType);
    } else {
      this.approve();
    }
}

  approve() {
    if (isNaN(this.expiresOn.getTime())){
      this._toastMessage.openSnackBarError('Please add an expiry');
      return;
    }
    if ((!this.cabData.info || !this.cabData.info.expiresOn) || this.isNotExisting()) {
        this.updateExpiresOn();
      }
    this._cabService.approve(this.name, this.riderId).subscribe(
      response => {
        this._toastMessage.openSnackBarSuccess(`Successfully approved ${this.name}`);
        this.isApproved = true;
        this.approvalMessage = this.APPROVAL_MESSAGE.agentApproved;
        this.showApproveButton = false;
        this.approved.emit();
        this._logService.insertEventWithType(this.name, this.riderId, {}, {
            [`${this.name}Approved`]: true
        }).subscribe();
      },
      err => {
        console.log('Error : ', err);
        this._toastMessage.openSnackBarError('Successfully rejected permit');
      }
    );
  }

  private isNotExisting() {
    return this.cabData && this.cabData.info && 
    this.cabData.info.expiresOn && 
    this.cabData.info.expiresOn !== undefined && 
    this.cabData.info.expiresOn !== null;
  }
  updateImageUrl(evt: any) {
    if (evt.mode === 'delete') {
      this.showApproveButton = false;
      this.updateImageEnabled = false;
      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').hide();
        }
      }
      this.getCabData(this.riderId) ;
    }
    if (evt.mode === 'update') {
        this.getCabData(this.riderId);
        this.rejected.emit(false);
        this.approved.emit(false);
    }
  }

  datePickerOpened(evt: any) {
    this._hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      event.preventDefault();
       return false; // Prevent bubbling
     }));
     this._hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
       event.preventDefault();
       return false; // Prevent bubbling
     }));
  }

  datePickerClosed(evt: any) {
    this._hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      document.getElementById('backButton').click();
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
      document.getElementById('nextButton').click();
      return false; // Prevent bubbling
    }));
    this.showButtonIcon = true;
  }


  dateChanged(evt: any) {
    console.log('new date: ', evt.target.value.getTime());
  }

  updateExpiresOn(){
    const ts = this.expiresOn.getTime();
    this._cabService.updateInfo(this.name, this.riderId, ts).subscribe(
      response => {
        this._toastMessage.openSnackBarSuccess('Successfully Updated Expiry');
        this.showButtonIcon = false;
        this._logService.insertEventWithType(this.name, this.riderId, {},
          {'ChangedExpiry': ts}).subscribe();
      },
      err => {
        console.log('Error : ', err);
        this._toastMessage.openSnackBarError('Error while updating expiry');
      }
    );
  }

  rotateImage(link, degrees, key) {
    if (key === 'front') {
      this.rotateFrontImageDegrees += degrees;
      degrees = this.rotateFrontImageDegrees;
    }
    let response;
    this.spinnerService.show();
    this._cabService.rotateImage(link, degrees).subscribe(resp => {
      response = resp;
      if (key === 'front') {
        setTimeout(() => {
          this.docFrontImageData =  response.data;
          this.updateImageEnabled = true;
          window['jQuery'](document).ready(function () {
            window['jQuery']('#displayImage').attr('src', response.data).blowup({
              'width': 400,
              'height': 400,
              'border': '1px solid black',
              'round': false
            });
          });
        }, 300);
      }
    },
    error => {
      const errorMessage = error && error.error && error.error.message || `Could not rotate ${this.name}`;
      this._toastMessage.openSnackBarError(errorMessage);
      this.spinnerService.hide();
    });
  }

  updateImage(view) {
    let response;
    const riderId = this.riderId;
    if (view === 'front') {
      this._cabService.updateImage(this.docFrontImageData, this.riderId, this.name).subscribe(resp => {
        response = resp;
        this._toastMessage.openSnackBarSuccess('updated image has been saved');
        this._toastMessage.openSnackBarSuccess('Image Saved. Please reload.');
        this.updateImageEnabled = false;
        this.rotateFrontImageDegrees = 0;
        this.refreshDisplayImage();
      },
      error => {
        const errorMessage = error && error.error && error.error.message || `Could not update ${this.name} image`;
        this._toastMessage.openSnackBarError(errorMessage);
      });
    }
  }

  refreshDisplayImage() {
    if (window) {

      window['jQuery'](document).ready(function () {
        window['jQuery']('#displayImage').blowup({
          'width': 400,
          'height': 400,
          'border': '1px solid black',
          'round': false
        });
      });

      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').show();
        }
      }
      this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
    }
  }
}
