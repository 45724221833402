import { Component, OnChanges, SimpleChanges, OnInit, EventEmitter, Input, Output, ViewChild,
  ElementRef, OnDestroy } from '@angular/core';

import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { FormControl, Validators } from '@angular/forms';
import {DriversLicenseService} from './drivers-license.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { ToastMessage } from '../../toast-message/toast-message.service';
import { HelperService } from '../../helper.service';

import { MatDialog } from '@angular/material';
import { PopUpMessageComponent } from '../../pop-up-message/pop-up-message.component';
import { OnboardingPageComponent } from '../../onboarding-page/onboarding-page.component';
import { LogService } from '../../log-service.service';
import { from, Subject } from 'rxjs';

import { ConstantsService } from '../../constants.service';

const _ = require('lodash');
const moment = require('moment');
export interface UpdateDlInfo {
  number?: string;
  fatherHusband?: string;
  completeAddress?: string;
  district?: string;
  pin?: string;
  state?: string;
  expiresOn?: Date;
}
@Component({
  selector: 'drivers-license',
  template: require('./drivers-license.component.html'),
  providers: [ToastMessage, DriversLicenseService, LogService, ConstantsService]
})
export class DriversLicenseComponent implements OnChanges, OnInit {
  dlNumberFormControl = new FormControl('', [
    Validators.pattern('[a-zA-Z0-9]{1,20}'),
    Validators.required
  ]);

  fatherHusbandFormControl = new FormControl('', [
    Validators.required
  ]);

  completeAddressFormControl = new FormControl('', [
    Validators.required
  ]);

  districtFormControl = new FormControl('', [
    Validators.required
  ]);

  pinFormControl = new FormControl('', [
    Validators.required
  ]);

  stateFormControl = new FormControl('', [
    Validators.required
  ]);

  constructor(private _hotkeysService: HotkeysService,
    private _driverslicenseService: DriversLicenseService,
    private _toastMessage: ToastMessage,
    private spinnerService: Ng4LoadingSpinnerService,
    private _logService: LogService,
    private helperService: HelperService,
    private dialog: MatDialog,
    private onboardingPage: OnboardingPageComponent,
    private constantService: ConstantsService) {
  }

  expiresOnFormControl = new FormControl(new Date());
  @Input() data: any;
  @Input() showFront = false;
  @Input() showBack = false;
  @Output() dlStatus: EventEmitter<any> = new EventEmitter();
  @Output() fetchData: EventEmitter<any> = new EventEmitter();
  @Output() dlUploaded: EventEmitter<any> = new EventEmitter();
  @Output() dlFrontImg: EventEmitter<any> = new EventEmitter();
  @Output() dlBackImg: EventEmitter<any> = new EventEmitter();
  @Output() profileDLDocs: EventEmitter<any> = new EventEmitter();
  @Output() addDLToRejectList: EventEmitter<any> = new EventEmitter();
  @Input() licenseStatus: any;
  @Input() allowEditing: any;
  @Input() showDLVerify: any;
  @Input() isDlRejected: any;
  public showLicenseStatus = false;
  @Input() userIdentity: any = {};
  public screenBreakpoint = false;
  public dlData: any;
  public updateImageEnabled = false;
  public config: any;

  public riderId: any;
  public imgFront: any;
  public imgBack: any;
  public imgFrontData: any;
  public imgBackData: any;
  public minDate = new Date();
  public oldDlNumber: any;
  public oldFatherHusband: any;
  public oldCompleteAddress: any;
  public oldDistrict: any;
  public oldPin: any;
  public oldState: any;
  public oldRemarks: any;
  public oldExpiredOn: any;
  public userInitialData: any = {};
  public changedDetailsOfDoc = [];
  public isDlOnHold: boolean;
  public isActive = false;
  public isrestrictDLEditEnabled = false;
  public showApproveButton = false;
  public showButtonIcon = false;
  public showDlNudgeData = false;
  public dlNudgeMessage: any = "";
  public dlRemarks = ['Verified online', 'Expires in less than year'];
  public mappedStatusToMessageMap = {
    "expired": "DL expired",
    "approved": "DL about to expire",
    "about_to_expire": "DL about to expire",
    "rejected": "DL expired and rejected",
    "default": "DL uploaded under verification",
  }
  public expiryDate: any;
  public updateDlInfo: any;

   // response from third party
   public thirdPartyStatus: any;
   public thirdPartyMessage: any;
   public isDlAccepted: any;
   public verificationPlatform: any;
   public verifiedOn: any;

   public licenseApprovalMessage: any;
   public isLicenseApproved = false;
   public licenseApprovalStatus = 'Approved';
   public licenseAutoApproved = false;
   public APPROVAL_MESSAGE = this.constantService.approvalMessage;

   public asyncRefreshTrigger: Subject<void> = new Subject<void>();
   private rotateFrontImgDegrees = 0;
   private rotateBackImgDegrees = 0;
  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {

      this.dlData = changes.data.currentValue;
      this.dlData.license.mappedStatus
      this.userInitialData = _.cloneDeep(changes.data.currentValue);
      this.oldDlNumber = this.dlData.license.number;
      this.oldFatherHusband = this.dlData.license.fatherHusband;
      this.oldCompleteAddress = this.dlData.license.completeAddress;
      this.oldPin = this.dlData.license.pin;
      this.oldDistrict = this.dlData.license.district;
      this.oldState = this.dlData.license.state;
      this.oldRemarks = this.dlData.license.remarks;
      this.riderId = this.dlData.userId._id;
      this.imgFront = this.dlData.license.frontLink;
      this.imgBack  = this.dlData.license.backLink;
      this.isDlOnHold = this.dlData.license.status === 'onHold';
      this.isActive = this.dlData.active;
      this.oldExpiredOn = this.dlData.license.expiresOn ? this.dlData.license.expiresOn : null
      if (this.dlData.license.frontLink && this.dlData.license.backLink && this.dlData.license.verified === false) {
        this.showApproveButton = true;
      }
    }
    if (changes && changes.licenseStatus && changes.licenseStatus.currentValue) {
      this.showLicenseStatus = true;
      if (changes.licenseStatus.currentValue.expiryDate && moment(changes.licenseStatus.currentValue.expiryDate).isValid()) {
        this.dlData.license.expiresOn = changes.licenseStatus.currentValue.expiryDate;
      }
      this.isDlAccepted = changes.licenseStatus.currentValue.isDlAccepted;
      this.verificationPlatform = changes.licenseStatus.currentValue.verifiedBy;
      this.verifiedOn = changes.licenseStatus.currentValue.verifiedOn;
    } 
    if (
      this.dlData && this.dlData.license &&  this.dlData.license.expiresOn &&
      (new Date(this.dlData.license.expiresOn)).getTime() <= 0) {
      this.dlData.license.expiresOn = null;
    }
    if (this.dlData && this.dlData.toggles) {
      this.isrestrictDLEditEnabled = this.dlData.toggles.restrictEditDlEnabled;
    }
    if (changes && changes.allowEditing && changes.allowEditing.currentValue === false) {
      this.dlNumberFormControl.disable();
      this.expiresOnFormControl.disable();
    }
    if (this.dlData && this.dlData.license && this.dlData.license.verified) {
      this.formApprovalMessage();
    }
    if(this.isLicenseApproved || this.isDlAccepted){
      this.showDLVerify = false;
    }
    if (this.isDlRejected) {
      this.enableFieldsFormControl();
    }
    if (this.shouldRestrcictEditToVerifiedDocs()) {
      this.disableFieldsFormControl()
    }
    this.fetchDlNudgeData()
  }

  disableFieldsFormControl() {
    if (this.dlData.license.number) {
      this.dlNumberFormControl.disable();
    }
    if (this.dlData.license.fatherHusband) {
      this.fatherHusbandFormControl.disable();
    }
    if (this.dlData.license.completeAddress) {
      this.completeAddressFormControl.disable();
    }
    if (this.dlData.license.district) {
      this.districtFormControl.disable();
    }
    if (this.dlData.license.pin) {
      this.pinFormControl.disable();
    }
    if (this.dlData.license.state) {
      this.stateFormControl.disable();
    }
    if (this.dlData.license.expiresOn) {
      this.expiresOnFormControl.disable();
    }
  }

  enableFieldsFormControl() {
    this.dlNumberFormControl.enable();
    this.fatherHusbandFormControl.enable();
    this.completeAddressFormControl.enable();
    this.districtFormControl.enable();
    this.pinFormControl.enable();
    this.stateFormControl.enable();
    this.expiresOnFormControl.enable();
  }

  ngOnInit() {
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').show();
      }
    }
    this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
  }

  formApprovalMessage() {
    const dlAutoApprovalStatus = this.dlData['autoApprovalStatus'] || {};
    this.isLicenseApproved = true;
    if (  dlAutoApprovalStatus['profileVerificationStatus']
          && dlAutoApprovalStatus['profileVerificationStatus'].autoApproved
      ) {
        this.licenseAutoApproved = true;
        this.licenseApprovalMessage = this.APPROVAL_MESSAGE.autoApproved;
    } else {
      this.licenseApprovalMessage = this.APPROVAL_MESSAGE.agentApproved;
    }
  }

  showDocDetailsChangeTypePanel() {
    if (this.oldDlNumber !== undefined && this.oldDlNumber !== null && this.oldDlNumber.trim() !== '') {
      if (this.oldDlNumber.toUpperCase() !== this.dlData.license.number.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing Number: ${this.oldDlNumber.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New Number: ${this.dlData.license.number.toUpperCase()}`)
      }
    } else if (this.dlData.license.number) {
      this.changedDetailsOfDoc.push(`New Number: ${this.dlData.license.number.toUpperCase()}`)
    }
    
    if (this.oldRemarks !== undefined && this.oldRemarks !== null && this.oldRemarks.trim() !== '') {
      if (this.oldRemarks !== this.dlData.license.remarks) {
        this.changedDetailsOfDoc.push(`Existing Remarks: ${this.oldRemarks}`)
        this.changedDetailsOfDoc.push(`New Remarks: ${this.dlData.license.remarks}`)
      }
    } else if (this.dlData.license.remarks) {
      this.changedDetailsOfDoc.push(`New Remarks: ${this.dlData.license.remarks}`)
    }

    if (this.oldFatherHusband !== undefined && this.oldFatherHusband !== null && this.oldFatherHusband.trim() !== '') {
      if (this.dlData.license.fatherHusband.toUpperCase() !== this.oldFatherHusband.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing Father/Husband name: ${this.oldFatherHusband.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New Father/Husband name: ${this.dlData.license.fatherHusband.toUpperCase()}`)
      }
    } else if (this.dlData.license.fatherHusband) {
      this.changedDetailsOfDoc.push(`New Father/Husband name: ${this.dlData.license.fatherHusband.toUpperCase()}`)
    }
    
    if (this.oldCompleteAddress !== undefined && this.oldCompleteAddress !== null && this.oldCompleteAddress.trim() !== '') {
      if (this.dlData.license.completeAddress.toUpperCase() !== this.oldCompleteAddress.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing CompleteAddress: ${this.oldCompleteAddress}`)
        this.changedDetailsOfDoc.push(`New CompleteAddress: ${this.dlData.license.completeAddress}`)
      }
    } else if(this.dlData.license.completeAddress) {
      this.changedDetailsOfDoc.push(`New CompleteAddress: ${this.dlData.license.completeAddress}`)
    }
    
    if (this.oldDistrict !== undefined && this.oldDistrict !== null && this.oldDistrict.trim() !== '') {
      if (this.dlData.license.district.toUpperCase() !== this.oldDistrict.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing District: ${this.oldDistrict.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New District: ${this.dlData.license.district.toUpperCase()}`)
      }
    } else if (this.dlData.license.district) {
      this.changedDetailsOfDoc.push(`New District: ${this.dlData.license.district.toUpperCase()}`)
    }
    
    if (this.oldPin !== undefined && this.oldPin !== null && this.oldPin.trim() !== '') {
      if (this.oldPin !== this.dlData.license.pin) {
        this.changedDetailsOfDoc.push(`Existing Pin: ${this.oldPin}`)
        this.changedDetailsOfDoc.push(`New Pin: ${this.dlData.license.pin}`)
      }
    } else if (this.dlData.license.pin) {
      this.changedDetailsOfDoc.push(`New Pin: ${this.dlData.license.pin}`)
    }
    
    if (this.oldState !== undefined && this.oldState !== null && this.oldState.trim() !== '') {
      if (this.dlData.license.state.toUpperCase() !== this.oldState.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing State: ${this.oldState.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New State: ${this.dlData.license.state.toUpperCase()}`)
      }
    } else if (this.dlData.license.state) {
      this.changedDetailsOfDoc.push(`New State: ${this.dlData.license.state.toUpperCase()}`)
    }
    
    let newExpireDate = '';
    if (this.oldExpiredOn !== undefined && this.oldExpiredOn !== null) {
      if (this.oldExpiredOn !== this.dlData.license.expiresOn) {
        newExpireDate = moment(this.dlData.license.expiresOn).format('YYYY-MM-DD');
        const oldExpiryDate = moment(this.oldExpiredOn).format('YYYY-MM-DD');
        this.changedDetailsOfDoc.push(`Existing Expiry date: ${oldExpiryDate}`)
        this.changedDetailsOfDoc.push(`New Expiry date: ${newExpireDate}`)
      }
    } else if (this.dlData.license.expiresOn) {
      newExpireDate = moment(this.dlData.license.expiresOn).format('YYYY-MM-DD');
      this.changedDetailsOfDoc.push(`New Expiry date: ${newExpireDate}`)
    }
  }

  private restoreOriginalValues(): void {
    if (this.oldDlNumber) {
      this.dlData.license.number = this.oldDlNumber;
    }
    if (this.oldRemarks) {
      this.dlData.license.remarks = this.oldRemarks;
    }
    if (this.oldFatherHusband) {
      this.dlData.license.fatherHusband = this.oldFatherHusband;
    }
    if (this.oldCompleteAddress) {
      this.dlData.license.completeAddress = this.oldCompleteAddress;
    }
    if (this.oldDistrict) {
      this.dlData.license.district = this.oldDistrict;
    }
    if (this.oldPin) {
      this.dlData.license.pin = this.oldPin;
    }
    if (this.oldState) {
      this.dlData.license.state = this.oldState;
    }
    if (this.oldExpiredOn) {
      this.dlData.license.expiresOn = this.oldExpiredOn;
    }
  }

  private openChangeDetailsDialog(actionFunction: (confirm: boolean) => void): void {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Please confirm for the changed details in driving license',
        message: '',
        data: {
          type: 'orderedList',
          info: this.changedDetailsOfDoc,
          note: 'Are you sure you want to update above details',
        },
        reason: 'changeDocDetails',
        buttonText: 'Yes',
        cancelText: 'No',
        styles: {
          title: 'font-size-20 margin-bottom-30',
          actionClass: 'doc-details-change-confirm',
          note: 'margin-bottom-12 margin-top-30 color-grey'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe(confirm => {
      actionFunction(confirm);
      this.changedDetailsOfDoc.splice(0, this.changedDetailsOfDoc.length);
    });
  }
  
  viewDetailsForChangedDocs() {
    this.showDocDetailsChangeTypePanel()
    if (this.changedDetailsOfDoc.length > 0) {
      this.openChangeDetailsDialog((confirm) => {
        if (confirm) {
          this.approveDL();
        } else {
          this.restoreOriginalValues()
        }
      });
    } else {
      this.approveDL();
    }
  }
  
  viewDetailsForChangedDocsForDLUpdate() {
    if (this.oldDlNumber !== undefined && this.oldDlNumber !== null && this.oldDlNumber.trim() !== '') {
      if (this.oldDlNumber.toUpperCase() !== this.dlData.license.number.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing Number: ${this.oldDlNumber.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New Number: ${this.dlData.license.number.toUpperCase()}`)
        this.showButtonIcon = true;
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateDLNumber();
          } else {
            this.dlData.license.number = this.oldDlNumber;
            this.showButtonIcon = false;
          }
        });
      } else {
        this.dlData.license.number = this.oldDlNumber;
      }  
    } else {
      this.updateDLNumber();
    }
  }

  fetchDlNudgeData(){
    this._driverslicenseService.checkIfDlNudgeExists(this.dlData.userId._id,).subscribe(res => {
      this.showDlNudgeData = res["dlNudge"] || false;
      this.dlNudgeMessage = this.mappedStatusToMessageMap[this.dlData.license.mappedStatus] || this.mappedStatusToMessageMap.default
    },
    error => {
      const errorMessage = error && error.error && error.error.message || 'Could not fetch dl nudge data';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  viewDetailsForFatherHusbandUpdate() {
    if (this.oldFatherHusband !== undefined && this.oldFatherHusband !== null && this.oldFatherHusband.trim() !== '') {
      if (this.oldFatherHusband.toUpperCase() !== this.dlData.license.fatherHusband.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing Father/Husband name: ${this.oldFatherHusband.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New Father/Husband name: ${this.dlData.license.fatherHusband.toUpperCase()}`)
        this.showButtonIcon = true;
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateFatherHusband();
          } else {
            this.dlData.license.fatherHusband = this.oldFatherHusband;
            this.showButtonIcon = false;
          }
        });
      } else {
        this.dlData.license.fatherHusband = this.oldFatherHusband;
      } 
    } else {
      this.updateFatherHusband();
    }
  }

  viewDetailsForCompleteAddressUpdate() {
    if (this.oldCompleteAddress !== undefined && this.oldCompleteAddress !== null && this.oldCompleteAddress.trim() !== '') {
      if (this.oldCompleteAddress.toUpperCase() !== this.dlData.license.completeAddress.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing CompleteAddress: ${this.oldCompleteAddress}`)
        this.changedDetailsOfDoc.push(`New CompleteAddress: ${this.dlData.license.completeAddress}`)
        this.showButtonIcon = true;
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateCompleteAddress();
          } else {
            this.dlData.license.completeAddress = this.oldCompleteAddress;
            this.showButtonIcon = false;
          }
        });
      } else {
        this.dlData.license.completeAddress = this.oldCompleteAddress;
      }
    } else {
      this.updateCompleteAddress();
    }
  }

  viewDetailsForDistrictUpdate() {
    if (this.oldDistrict !== undefined && this.oldDistrict !== null && this.oldDistrict.trim() !== '') {
      if (this.oldDistrict.toUpperCase() !== this.dlData.license.district.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing District: ${this.oldDistrict.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New District: ${this.dlData.license.district.toUpperCase()}`)
        this.showButtonIcon = true;
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateDistrict();
          } else {
            this.dlData.license.district = this.oldDistrict;
            this.showButtonIcon = false;
          }
        });
      } else {
        this.dlData.license.district = this.oldDistrict;
      }
    } else {
      this.updateDistrict();
    }
  }

  viewDetailsForPinUpdate() {
    if (this.oldPin !== undefined && this.oldPin !== null && this.oldPin.trim() !== '') {
      if (this.oldPin !== this.dlData.license.pin) {
        this.changedDetailsOfDoc.push(`Existing Pin: ${this.oldPin}`)
        this.changedDetailsOfDoc.push(`New Pin: ${this.dlData.license.pin}`)
        this.showButtonIcon = true;
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updatePin();
          } else {
            this.dlData.license.pin = this.oldPin;
            this.showButtonIcon = false;
          }
        });
      } else {
        this.dlData.license.pin = this.oldPin;
      }
    } else {
      this.updatePin();
    }
  }

  viewDetailsForStateUpdate() {
    if (this.oldState !== undefined && this.oldState !== null && this.oldState.trim() !== '') {
      if (this.oldState.toUpperCase() !== this.dlData.license.state.toUpperCase()) {
        this.changedDetailsOfDoc.push(`Existing State: ${this.oldState.toUpperCase()}`)
        this.changedDetailsOfDoc.push(`New State: ${this.dlData.license.state.toUpperCase()}`)
        this.showButtonIcon = true;
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateState();
          } else {
            this.dlData.license.state = this.oldState;
            this.showButtonIcon = false;
          }
        });
      } else {
        this.dlData.license.state = this.oldState;
      }
    } else {
      this.updateState();
    }
  }

  viewDetailsForExpiresOnUpdate() {
    if (this.oldExpiredOn !== undefined && this.oldExpiredOn !== null) {
      if (this.oldExpiredOn !== this.dlData.license.expiresOn) {
        const oldExpiryDate = moment(this.oldExpiredOn).format('YYYY-MM-DD');
        const newExpireDate = moment(this.dlData.license.expiresOn).format('YYYY-MM-DD');
        this.changedDetailsOfDoc.push(`Existing Expiry date: ${oldExpiryDate}`)
        this.changedDetailsOfDoc.push(`New Expiry date: ${newExpireDate}`)
        this.showButtonIcon = true;
        this.openChangeDetailsDialog((confirm) => {
          if (confirm) {
            this.updateExpiresOn();
          } else {
            this.dlData.license.expiresOn = new Date(this.oldExpiredOn);
            this.showButtonIcon = false;
          }
        });
      } else {
        this.dlData.license.expiresOn = new Date(this.oldExpiredOn);
      }
    } else {
      this.updateExpiresOn();
    }
  }

  updateRemarks() {
    let response;
    this.spinnerService.show();
    this._driverslicenseService.updateRemarks(this.dlData.userId._id, this.dlData.license.remarks).subscribe(res => {
      response = res;
      this.spinnerService.hide();
      this._logService.insertEvent(3, this.dlData._id, {remarks : this.userInitialData.license.remarks},
          {remarks : this.dlData.license.remarks}).subscribe();
      this.userInitialData.license.remarks = this.dlData.license.remarks;
      this._toastMessage.openSnackBarSuccess('Remarks is updated!');
    },
    error => {
      this.spinnerService.hide();
      const errorMessage = error && error.error && error.error.message || 'Could not update remarks';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  updateDLNumber() {
    if (this.dlData.license.number === this.oldDlNumber) {
      this._toastMessage.openSnackBarError('No update required. You entered same DL number');
    } else {
      let response;
      this.spinnerService.show();
      if ((this.dlNumberFormControl.errors && this.dlNumberFormControl.errors.required) ||
            (this.dlNumberFormControl.errors && this.dlNumberFormControl.errors.pattern) || (!this.dlData.license.number)) {
        this._toastMessage.openSnackBarError('DL Number is a mandatory field.' +
            ' It can not contain any special characters + and has a limit of 20 characters');
        this.spinnerService.hide();
      } else {
        // this.showDLVerify = true;
        this._driverslicenseService.updateNumber(this.dlData.userId._id,
              this.dlData.license.number.replace(/\s/g, '').toUpperCase()).subscribe(res => {
          response = res;
          this.isLicenseApproved = false;
          this.showApproveButton = true;
          this.showButtonIcon = false;
          this.showDLVerify = true;
          this.spinnerService.hide();
          this.oldDlNumber = this.dlData.license.number;
          this._logService.insertEvent(3, this.dlData._id,
              {dlNumber : this.userInitialData.license.number}, {dlNumber : this.dlData.license.number}).subscribe();
          this.userInitialData.license.number = this.dlData.license.number;
          this._toastMessage.openSnackBarSuccess('DL Number is updated!');
          this.dlUploaded.emit(this.dlData.license.number.replace(/\s/g, '').toUpperCase());
        },
        error => {
          this.spinnerService.hide();
          const errorMessage = error && error.error && error.error.message || 'Could not update DL number';
          this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
        });
      }
    }
  }

  updateFatherHusband() {
    if (this.dlData.license.fatherHusband === this.oldFatherHusband) {
      this._toastMessage.openSnackBarError('No update required. You entered same father/husband');
    } else {
      let response;
      this.spinnerService.show();
      if ((!this.dlData.license.fatherHusband) || (this.oldFatherHusband && this.oldFatherHusband.errors && this.oldFatherHusband.errors.required)) {
        this._toastMessage.openSnackBarError('Father/Husband is a mandatory field.');
        this.spinnerService.hide();
      } else {
        this._driverslicenseService.updateFatherHusband(this.dlData.userId._id,
              this.dlData.license.fatherHusband).subscribe(res => {
          response = res;
          this.spinnerService.hide();
          this.oldFatherHusband = this.dlData.license.fatherHusband;
          this._logService.insertEvent(3, this.dlData._id,
              {fatherHusband : this.userInitialData.license.fatherHusband}, {fatherHusband : this.dlData.license.fatherHusband}).subscribe();
          this.userInitialData.license.fatherHusband = this.dlData.license.fatherHusband;
          this.showButtonIcon = false;
          this._toastMessage.openSnackBarSuccess('Father/Husband is updated!');
        },
        error => {
          this.spinnerService.hide();
          const errorMessage = error && error.error && error.error.message || 'Could not update father/husband';
          this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
        });
      }
    }
  }

  updateCompleteAddress() {
    if (this.dlData.license.completeAddress === this.oldCompleteAddress) {
      this._toastMessage.openSnackBarError('No update required. You entered the same complete address');
    } else {
      let response;
      this.spinnerService.show();
      if ((!this.dlData.license.completeAddress) || (this.oldCompleteAddress && this.oldCompleteAddress.errors && this.oldCompleteAddress.errors.required)) {
        this._toastMessage.openSnackBarError('Complete address is a mandatory field.');
        this.spinnerService.hide();
      } else {
        this._driverslicenseService.updateCompleteAddress(this.dlData.userId._id,
              this.dlData.license.completeAddress).subscribe(res => {
          response = res;
          this.spinnerService.hide();
          this.oldCompleteAddress = this.dlData.license.completeAddress;
          this._logService.insertEvent(3, this.dlData._id,
              {completeAddress : this.userInitialData.license.completeAddress}, {completeAddress : this.dlData.license.completeAddress}).subscribe();
          this.userInitialData.license.completeAddress = this.dlData.license.completeAddress;
          this.showButtonIcon = false;
          this._toastMessage.openSnackBarSuccess('Complete address is updated!');
        },
        error => {
          this.spinnerService.hide();
          const errorMessage = error && error.error && error.error.message || 'Could not update complete address';
          this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
        });
      }
    }
  }

  updateDistrict() {
    if (this.dlData.license.district === this.oldDistrict) {
      this._toastMessage.openSnackBarError('No update required. You entered the same district');
    } else {
      let response;
      this.spinnerService.show();
      if ((!this.dlData.license.district) || (this.oldDistrict && this.oldDistrict.errors && this.oldDistrict.errors.required)) {
        this._toastMessage.openSnackBarError('District is a mandatory field.');
        this.spinnerService.hide();
      } else {
        this._driverslicenseService.updateDistrict(this.dlData.userId._id,
              this.dlData.license.district).subscribe(res => {
          response = res;
          this.spinnerService.hide();
          this.oldDistrict = this.dlData.license.district;
          this._logService.insertEvent(3, this.dlData._id,
              {district : this.userInitialData.license.district}, {district : this.dlData.license.district}).subscribe();
          this.userInitialData.license.district = this.dlData.license.district;
          this.showButtonIcon = false;
          this._toastMessage.openSnackBarSuccess('District is updated!');
        },
        error => {
          this.spinnerService.hide();
          const errorMessage = error && error.error && error.error.message || 'Could not update district';
          this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
        });
      }
    }
  }

  updatePin() {
    if (this.dlData.license.pin === this.oldPin) {
      this._toastMessage.openSnackBarError('No update required. You entered the same pin');
    } else {
      let response;
      this.spinnerService.show();
      if ((!this.dlData.license.pin) || (this.oldPin && this.oldPin.errors && this.oldPin.errors.required)) {
        this._toastMessage.openSnackBarError('Pin is a mandatory field.');
        this.spinnerService.hide();
      } else {
        this._driverslicenseService.updatePin(this.dlData.userId._id,
              this.dlData.license.pin).subscribe(res => {
          response = res;
          this.spinnerService.hide();
          this.oldPin = this.dlData.license.pin;
          this._logService.insertEvent(3, this.dlData._id,
              {pin : this.userInitialData.license.pin}, {pin : this.dlData.license.pin}).subscribe();
          this.userInitialData.license.pin = this.dlData.license.pin;
          this.showButtonIcon = false;
          this._toastMessage.openSnackBarSuccess('Pin is updated!');
        },
        error => {
          this.spinnerService.hide();
          const errorMessage = error && error.error && error.error.message || 'Could not update pin';
          this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
        });
      }
    }
  }

  updateState() {
    if (this.dlData.license.state === this.oldState) {
      this._toastMessage.openSnackBarError('No update required. You entered the same state');
    } else {
      let response;
      this.spinnerService.show();
      if ((!this.dlData.license.state) || (this.oldState && this.oldState.errors && this.oldState.errors.required)) {
        this._toastMessage.openSnackBarError('State is a mandatory field.');
        this.spinnerService.hide();
      } else {
        this._driverslicenseService.updateState(this.dlData.userId._id,
              this.dlData.license.state).subscribe(res => {
          response = res;
          this.spinnerService.hide();
          this.oldState = this.dlData.license.state;
          this._logService.insertEvent(3, this.dlData._id,
              {state : this.userInitialData.license.state}, {state : this.dlData.license.state}).subscribe();
          this.userInitialData.license.state = this.dlData.license.state;
          this.showButtonIcon = false;
          this._toastMessage.openSnackBarSuccess('State is updated!');
        },
        error => {
          this.spinnerService.hide();
          const errorMessage = error && error.error && error.error.message || 'Could not update state';
          this._toastMessage.openSnackBarError(errorMessage, this.constantService.errorToastDuration);
        });
      }
    }
  }

  updateExpiresOn() {
    let response;
    const ts = new Date(this.dlData.license.expiresOn);
    this.spinnerService.show();
    this._driverslicenseService.updateExpiresOn(this.dlData.userId._id, ts.getTime()).subscribe(resp => {
      this.spinnerService.hide();
      response = resp;
      const expiryTimestamp = new Date(this.dlData.license.expiresOn);
      this._logService.insertEvent(3, this.dlData._id, {dlExpiresOn : new Date(this.userInitialData.license.expiresOn)},
        {dlExpiresOn : this.helperService.formatDate(expiryTimestamp)}).subscribe();
      this.userInitialData.license.expiresOn = this.dlData.license.expiresOn;
      this.showButtonIcon = false;
      this._toastMessage.openSnackBarSuccess('Expiry date is updated');
    },
    error => {
      this.spinnerService.hide();
      const errorMessage = error.error.message;
      this._toastMessage.openSnackBarError(errorMessage || 'Failed to update expiry date');
      console.log(error);
    });
  }

  rotateImage(link, degrees, key) {
    if (key === 'front') {
      this.rotateFrontImgDegrees += degrees;
      degrees = this.rotateFrontImgDegrees ;
    } else {
      this.rotateBackImgDegrees += degrees;
      degrees = this.rotateBackImgDegrees;
    }
    let response;
    this.spinnerService.show();
    this._driverslicenseService.rotateImage(link, degrees).subscribe(resp => {
      response = resp;
      if (key === 'front') {
        this.imgFrontData = response.data;
        setTimeout(() => {
          window['jQuery'](document).ready(function () {
            window['jQuery']('#displayImage').attr('src',  response.data).blowup({
              'width': 400,
              'height': 400,
              'border': '1px solid black',
              'round': false
            });
          });
        }, 300);
        this.updateImageEnabled = true;
      }
      if (key === 'back') {
        this.imgBackData = response.data;
        setTimeout(() => {
          window['jQuery'](document).ready(function () {
            window['jQuery']('#displayImage').attr('src',  response.data).blowup({
              'width': 400,
              'height': 400,
              'border': '1px solid black',
              'round': false
            });
          });
        }, 300);
        this.updateImageEnabled = true;
      }
     // this.updateImageEnabled = true;
    },
    error => {
      this.spinnerService.hide();
      const errorMessage = error && error.error && error.error.message || 'Could not rotate image';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  updateDLImage(view) {
    let response;
    const riderId = this.dlData.userId._id;
    if (view === 'front') {
      this._driverslicenseService.updateImage(this.imgFrontData, this.imgFront, riderId, 'driverLicensePic', view).subscribe(resp => {
        this.fetchData.emit(true);
        response = resp;
        this._toastMessage.openSnackBarSuccess('Image has been updated');
        this._toastMessage.openSnackBarSuccess('Image Saved. Please reload.');
        this.updateImageEnabled = false;
        this.rotateFrontImgDegrees = 0;
        this.refreshDisplayImage();
      },
      error => {
        const errorMessage = error && error.error && error.error.message || 'Could not update DL image';
        this._toastMessage.openSnackBarError(errorMessage);
      });
    } else if (view === 'back') {
      this._driverslicenseService.updateImage(this.imgBackData, this.imgBack, riderId, 'driverLicensePic', view).subscribe(resp => {
        this.fetchData.emit(true);
        response = resp;
        this._toastMessage.openSnackBarSuccess('Image has been updated');
        this._toastMessage.openSnackBarSuccess('Image Saved. Please reload.');
        this.updateImageEnabled = false;
        this.rotateBackImgDegrees = 0;
        this.refreshDisplayImage();
      },
      error => {
        const errorMessage = error && error.error && error.error.message || 'Could not update DL image';
        this._toastMessage.openSnackBarError(errorMessage);
      });
    }
  }

  refreshDisplayImage() {
    if (window) {

      window['jQuery'](document).ready(function () {
        window['jQuery']('#displayImage').blowup({
          'width': 400,
          'height': 400,
          'border': '1px solid black',
          'round': false
        });
      });

    }
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').show();
      }
    }
    this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
  }


  updateImageUrl(evt: any) {
    this.fetchData.emit(true);
    if (evt.view === 'front') {
      this.imgFront = evt.url;
      this.dlData.license.frontLink == this.imgFront;
      this.dlFrontImg.emit(this.imgFront);
    } else if (evt.view === 'back') {
      this.imgBack = evt.url;
      this.dlData.license.backLink == this.imgBack;
      this.dlBackImg.emit(this.imgBack);
    }
    if (evt.mode === 'delete') {
      this.dlStatus.emit(false);
      this.showApproveButton = false;
      this.isLicenseApproved = false;
      this.updateImageEnabled = false;
      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').hide();
        }
      }
    }
    if (evt.mode === 'update') {
      this.showApproveButton = true;
      this._toastMessage.openSnackBarSuccess('Image Saved. Please reload using Shift + R .');
    }
  }

  addToBulkRejection(event) {
    this.addDLToRejectList.emit(event);
  }

  approveDL() {
    this.spinnerService.show();
    if ((this.dlNumberFormControl.errors && this.dlNumberFormControl.errors.required) ||
        (this.dlNumberFormControl.errors && this.dlNumberFormControl.errors.pattern) || 
        !(this.dlData.license.number)) {
      this._toastMessage.openSnackBarError('DL Number is a mandatory field. It can not contain any' +
      ' special characters and has a limit of 20 characters');
      this.spinnerService.hide();
      return
    } else if ((!this.dlData.license.fatherHusband) || (this.fatherHusbandFormControl.errors && this.fatherHusbandFormControl.errors.required)){
      this._toastMessage.openSnackBarError('Father/Husband is a mandatory field. It can not be empty');
      this.spinnerService.hide();
      return
    } else if ((!this.dlData.license.completeAddress) || (this.completeAddressFormControl.errors && this.completeAddressFormControl.errors.required)){
      this._toastMessage.openSnackBarError('Complete Address is a mandatory field. It can not be empty');
      this.spinnerService.hide();
      return
    } else if ((!this.dlData.license.district) || (this.districtFormControl.errors && this.districtFormControl.errors.required)){
      this._toastMessage.openSnackBarError('District is a mandatory field. It can not be empty');
      this.spinnerService.hide();
      return
    } else if ((!this.dlData.license.pin) || (this.pinFormControl.errors && this.pinFormControl.errors.required)){
      this._toastMessage.openSnackBarError('Pin is a mandatory field. It can not be empty');
      this.spinnerService.hide();
      return
    } else if ((!this.dlData.license.state) || (this.stateFormControl.errors && this.stateFormControl.errors.required)){
      this._toastMessage.openSnackBarError('State is a mandatory field. It can not be empty');
      this.spinnerService.hide();
      return
    } else if (!this.dlData.license.expiresOn){
      this._toastMessage.openSnackBarError('License expiry date is a mandatory field. It can not be empty');
      this.spinnerService.hide();
      return
    } else if (this.dlData.license && this.dlData.license.expiresOn) {
      this.expiryDate = _.cloneDeep(this.dlData.license.expiresOn);
      const ts = new Date(this.dlData.license.expiresOn);
      this.dlData.license.expiresOn = ts.getTime().toString();
      this.updateDlInfo = this.setLicenseInfoToUpdate();
      this._driverslicenseService.approveDL(this.dlData.userId._id, this.dlData.license, this.updateDlInfo).subscribe(res => {
        this.dlStatus.emit(true);
        this.showApproveButton = false;
        this.showButtonIcon = false;
        this.showDLVerify = false;
        this.updateImageEnabled = false;
        this.spinnerService.hide();
        const view = this.showFront ? 'front' : this.showBack ? 'back' : '';
        this._logService.insertEvent(3, this.dlData._id, {dlApproved : false},
          {dlApproved: true, dlData: this.dlData.license, dlSide: view}).subscribe();
        this.onboardingPage.reloadRiderData();
        this._toastMessage.openSnackBarSuccess('DL has been approved');
        this.formApprovalMessage();
      },
      error => {
        this.showApproveButton = true;
        this.spinnerService.hide();
        const errorMessage = 'Could not approve DL';
        this._toastMessage.openSnackBarError(errorMessage);
      });
    } else {
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError('Make sure you have entered all the mandatory data');
    }
  }

  setLicenseInfoToUpdate(): UpdateDlInfo {
    let updateDlInfo: UpdateDlInfo = {}
    if (this.dlData.license.number && (this.dlData.license.number !== this.oldDlNumber)) {
      updateDlInfo.number = this.dlData.license.number;
    }
    if (this.dlData.license.fatherHusband && (this.dlData.license.fatherHusband !== this.oldFatherHusband)) {
      updateDlInfo.fatherHusband = this.dlData.license.fatherHusband;
    }
    if (this.dlData.license.completeAddress && (this.dlData.license.completeAddress !== this.oldCompleteAddress)) {
      updateDlInfo.completeAddress = this.dlData.license.completeAddress;
    }
    if (this.dlData.license.district && (this.dlData.license.district !== this.oldDistrict)) {
      updateDlInfo.district = this.dlData.license.district;
    }
    if (this.dlData.license.pin && (this.dlData.license.pin !== this.oldPin)) {
      updateDlInfo.pin = this.dlData.license.pin;
    }
    if (this.dlData.license.state && (this.dlData.license.state !== this.oldState)) {
      updateDlInfo.state = this.dlData.license.state;
    }
    if (this.dlData.license.expiresOn && (this.dlData.license.expiresOn !== this.oldExpiredOn)) {
      updateDlInfo.expiresOn = new Date(Number(this.dlData.license.expiresOn));
    }
    return updateDlInfo
  }

  dateChanged(evt: any) {
    console.log('new date: ', evt);
  }

  datePickerOpened(evt: any) {
    this._hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      event.preventDefault();
       return false; // Prevent bubbling
     }));
     this._hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
       event.preventDefault();
       return false; // Prevent bubbling
     }));
  }

  datePickerClosed(evt: any) {
    this._hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      document.getElementById('backButton').click();
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
      document.getElementById('nextButton').click();
      return false; // Prevent bubbling
    }));
    this.showButtonIcon = true;
  }

  verifyProfileDLDocs() {
    this.profileDLDocs.emit(this.dlData.license);
    this.asyncRefreshTrigger.next();
  }

  shouldRestrcictEditToVerifiedDocs(): boolean {
    return this.dlData && this.dlData.license && 
    this.dlData.license.verified && this.dlData.license.status === "approved" && 
    this.isrestrictDLEditEnabled && localStorage.getItem('isUserAnAdmin') === 'false';
  }

  updateVerificationCard(verificationStatus) {
    this.thirdPartyStatus = verificationStatus.status;
    this.thirdPartyMessage = verificationStatus.message;
    this.verificationPlatform = verificationStatus.verifiedBy;
    this.verifiedOn = verificationStatus.verifiedOn;
    this.isDlAccepted = verificationStatus.status === 'Accepted';
    this.showDLVerify = !this.isDlAccepted;
    if (this.isDlAccepted) {
      this.onboardingPage.reloadRiderData();
    }
    if(this.isLicenseApproved) {
      this.showDLVerify = false;
    }
  }
}
