import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';


const cookies = document.cookie.split(';');

const userCookie = cookies.find(function (item) {
  return item.includes('_id');
});

const httpOptions = {
  headers: new HttpHeaders({
    'user': userCookie
  })
};




@Injectable()
export class OnboardingPageService {
  server: string = environment.SERVER;

  riderDetailsJSON = '../src/stubs/riderDetails.json';
  vehiclesListJSON = '../src/stubs/vehiclesList.json';
  citiesListJSON = '../src/stubs/cities.json';

  getRiderURL = process.env.SERVER + '/api/show';
  getCitiesURL = process.env.SERVER + '/api/get/cities';
  assignTLURL = process.env.SERVER + '/api/assign/tl';
  speedTestURL = process.env.SERVER + '/api/speedTest';
  verifyDocsURL = process.env.SERVER + '/api/docs';
  rcDefaulterUrl = process.env.SERVER + '/api/rcDefaulter';
  getCabDataUrl = process.env.SERVER + '/api/cab'

  captainUrl = process.env.SERVER + '/api/captain';
  constructor(private http: MyHttpClient) {
}
  getRiderDetails(id) {
    return this.http.get(
        this.getRiderURL + '/' + id).pipe(catchError(this.handleError));
  }

  fetchRcDefaulter(riderId) {
    return this.http.get(`${this.rcDefaulterUrl}/${riderId}`).pipe(catchError(this.handleError));
  }

  getCabData(userId){
    return this.http.get(this.getCabDataUrl + `/${userId}`).pipe(catchError(this.handleError));
  }

  getCitiesList() {
    return this.http.get(this.getCitiesURL).pipe(catchError(this.handleError));
  }

  getModes(userId) {
    return this.http.get(`${this.captainUrl}/modes/${userId}`).pipe(catchError(this.handleError));
  }

  getTLList() {
    return this.http.get(`${this.captainUrl}/tlList`).pipe(catchError(this.handleError));

  }
  bulkRejectDocs(documents, userId) {
    const payload = { documents, userId };
    return this.http.post(`${this.captainUrl}/bulkRejectDocs`, payload).pipe(catchError(this.handleError));
  }
  doSpeedTest() {
    return this.http.get(this.speedTestURL);
  }
  assignTL(userId, tl, shiftName) {
    const payload = { userId, tl, shift: { 'name': shiftName, 'from': null, 'to': null }};
    return this.http.post(`${this.captainUrl}/assign/tl`, payload)
              .pipe(catchError(this.handleError));
  }

  verifyProfileDLDocs(profileDlInfo) {

    const requestBody = {
      'drivingLicense': profileDlInfo['drivingLicense'],
      'birthDate': profileDlInfo['birthDate'],
      'dlFrontImage' : profileDlInfo['dlImage'],
      'dlBackImage' : profileDlInfo['dlBackImage'],
      'profileImage': profileDlInfo['profileImage'],
      'userId' : profileDlInfo['userId'],
      'actorId': profileDlInfo['actorId'],
      'actorType': profileDlInfo['actorType'],
      'triggeredFrom': profileDlInfo['triggeredFrom'],
      'name': profileDlInfo['name']
    };

    return this.http.post(this.verifyDocsURL + '/validateProfileDlDocuments', requestBody)
                    .pipe(catchError(this.handleError));
  }

  verifyRcDoc(rcInfo) {

    const requestBody = {
      'registrationNumber': rcInfo['registrationNumber'],
      'userId' : rcInfo['userId'],
      'actorId': rcInfo['actorId'],
      'actorType': rcInfo['actorType'],
      'isImageOptional': rcInfo['isImageOptional'],
    };

    return this.http.post(this.verifyDocsURL + '/validateRcDocument', requestBody)
                    .pipe(catchError(this.handleError));
  }

  getDocVerificationStatus (userId, docType) {
    return this.http.get(this.verifyDocsURL + `/docVerificationStatus/${userId}/${docType}`).pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    if (error && error.error && error.error.data && error.error.data.data) {
      return throwError(error.error.data.data);
    }

    if (error && typeof error === 'string') {
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  makeIntentionalError() {
    return this.http.get('not/a/real/url')
      .pipe(
        catchError(this.handleError)
      );
  }

}
