import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BasicInfoService } from '../onboarding-page/basic-info/basic-info.service';
import { ToastMessage } from '../toast-message/toast-message.service';
import { HelperService } from '../helper.service';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { MatDialog } from '@angular/material';
import { MatPaginator, PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'app-rider-logs',
  template: require('./rider-logs.component.html'),
  providers: [ToastMessage, BasicInfoService]
})
export class RiderLogsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public riderId: any;
  overHeadPanelTitle = 'Changed Logs'
  public recordsPerPage = 50;
  public pageSizeOptions = [this.recordsPerPage];
  public currentPage = 0;
  public riderLogData: any;
  public userMapping = {};
  public filter = {};
  public typeMapping = {
    'basic-info' : 'Basic Info',
    'profile-picture': 'Profile Picture',
    'drivers-license': 'Driving License',
    'registration-card': 'Registration Card',
    'more-info': 'More Info',
    'image-view': 'Image View',
    'loyalty': 'Loyalty',
    'services': 'Services',
    'pan': 'Pan',
    'aadhar': 'Aadhaar'
  };
  public captainChangedLogs: any;

  public docView = ['imageToUpload', 'ppDetails', 'dlData', 'rcDetails'];
  public dlApproveKeys = ['remarks', 'number'];
  public rcApprovalKeys = ['vehicleNumber', 'make'];
  public columnsToDisplay: any;
  public dataSource: any;
  public totalRecords: any;
  public selectedFilter: any;
  public filterItems = ['Activation', 'Deactivation', 'Suspension'];
  public targetRides;
  public campaignDuration = 0;
  public cashBackAmount;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private basicInfoService: BasicInfoService,
    private toastMessage: ToastMessage,
    private helperService: HelperService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.riderId = this.route.snapshot.paramMap.get('riderId');
    this.fetchRiderLogs();
    this.assureCampaignLogs(this.riderId);
  }

  goBack() {
    this.router.navigate(['/onboarding'], {queryParams: {id: this.riderId}});
  }
  assureCampaignLogs(riderId) {
    this.basicInfoService.assureCampaignLogs(riderId).subscribe(response => {
      this.targetRides = response && response['result'] && response['result']['targetRides'] || 0;
      this.campaignDuration = response && response['result'] && response['result']['campaignDuration'] || 0;
      this.cashBackAmount = response && response['result'] && response['result']['cashBackAmount'] || 0;
      }, error => {
        const errorMessage = error.error && error.error.message ? error.error.message : 'Could not assure campaign Info';
        this.toastMessage.openSnackBarError(errorMessage);
      });
  }
  fetchRiderLogs() {
    this.spinnerService.show();
    this.basicInfoService.riderLogs(
      this.riderId, this.recordsPerPage, this.currentPage, this.filter
    ).subscribe( res => {
      this.riderLogData = res;
      this.spinnerService.hide();
      this.getRiderLogInfo();
    },
    error => {
      this.spinnerService.hide();
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not fetch rider logs';
      this.toastMessage.openSnackBarError(errorMessage);
    });
    return this.riderLogData;
  }

  getRiderLogInfo() {
    const logData = this.riderLogData && this.riderLogData.data && this.riderLogData.data.logs  || [];
    const totalRecords = this.riderLogData && this.riderLogData.data && this.riderLogData.data.totalCount;
    let riderLogs = [];

    const riderLogColumns = [
      'Type', 'Date', 'Time', 'Entry By', 'Remarks', 'Document'
    ];
    const userId = this.getUserInfo(logData);

    if (userId.length > 0) {
      this.basicInfoService.getUserInfo(userId).subscribe( userData => {
        if (userData && userData['data'] && userData['data'].data.length > 0 ) {
          const userList = userData['data'].data;
          for (let index = 0; index < userList.length; index++) {
            if (userList[index]) {
              this.userMapping[userList[index]._id] = userList[index].firstName;
            }
          }
        }
        riderLogs = this.createRiderLogData(logData);
        this.viewRiderLogs(riderLogs, riderLogColumns, totalRecords);
      },
      error => {
        const errorMessage = error.error && error.error.message ? error.error.message : 'Could not fetch user Info';
        this.toastMessage.openSnackBarError(errorMessage);
      });
    } else {
      riderLogs = this.createRiderLogData(logData);
      this.viewRiderLogs(riderLogs, riderLogColumns, totalRecords);
    }
  }

  getUserInfo(logs) {
    const userId = [];
    for (let index = 0; index < logs.length; index++) {
      if (
          logs[index] && logs[index].user && logs[index].user.userId
          && !userId.includes(logs[index].user.userId)
        ) {
          userId.push(logs[index].user.userId);
      }
    }
    return userId;
  }

  createRiderLogData(logData) {
    let riderLogData, changeType, timestamp, remarksInfo, userId;
    const riderLogs = [];
    for (let index = 0; index < logData.length; index++) {
      riderLogData = {};
      if (logData[index].type && logData[index].type == 'Assure') {
        riderLogData = this.formatDataForTypeAssure(logData[index]);
        riderLogs.push(riderLogData);
      }
      else if (logData[index].type && logData[index].type == 'Assure Cashback') {
        riderLogData = this.formatDataForTypeAssureCashback(logData[index]);
        riderLogs.push(riderLogData);
      }
      else if (logData[index] && logData[index].type && logData[index].changed) {
        changeType = logData[index].type;
        userId = logData[index].user && logData[index].user.userId;
        timestamp = new Date(logData[index].timeStamp);
        riderLogData.id = logData[index].id;
        riderLogData.type = this.typeMapping[changeType];
        riderLogData.modifiedOn = this.helperService.formatDate(timestamp);
        riderLogData.time = this.helperService.formatTime(timestamp);
        riderLogData.modifiedBy = this.userMapping[userId];
        remarksInfo = this.formatChangedLogs(logData[index].changed, logData[index].metaData);
        riderLogData.remarks = remarksInfo.remarks;
        riderLogData.isDocumentPresent = remarksInfo.isDocumentPresent;
        riderLogData.isTextAreaNeeded = remarksInfo.isTextAreaNeeded;
        riderLogData.imageLinks = remarksInfo.imageInfo;
        riderLogs.push(riderLogData);
      }
      
    }
    return riderLogs;
  }

  formatDataForTypeAssure(log) {
    let riderLogData, timestamp, userId;
    riderLogData = {};
    userId = log.user && log.user.userId;
    riderLogData.id = log.id;
    riderLogData.type = log.type;
    timestamp = new Date(log.timeStamp);
    riderLogData.modifiedOn = this.helperService.formatDate(timestamp);
    riderLogData.modifiedBy = 'Rapido'
    riderLogData.time = this.helperService.formatTime(timestamp);
    let amountPaid = log.metaData.amountPaid;
    let startDate = new Date(timestamp);
    const startDateString = this.helperService.formatDate(startDate);
    let endDate = new Date(new Date().setDate(startDate.getDate() + this.campaignDuration ));
    const endDateString = this.helperService.formatDate(endDate);
    let cashBackString = this.cashBackAmount && this.targetRides ? 'Rs.' + this.cashBackAmount.toString() + ' to receive as a Cashback after ' + this.targetRides.toString() + ' rides' +
    '\n Rides to be completed from ' + startDateString + ' to ' + endDateString : 'Cashback - NA';
    riderLogData.remarks = [
      'Status - ' + amountPaid.toString() + ' Paid',
      'Service - Food Delivery',
      '' + cashBackString
    ];
    return riderLogData;
  }
  formatDataForTypeAssureCashback(log) {
    let riderLogData, timestamp, userId;
    riderLogData = {};
    userId = log.user && log.user.userId;
    riderLogData.id = log.id;
    riderLogData.type = log.type;
    timestamp = new Date(log.timeStamp);
    riderLogData.modifiedOn = this.helperService.formatDate(timestamp);
    riderLogData.modifiedBy = 'Rapido'
    riderLogData.time = this.helperService.formatTime(timestamp);
    riderLogData.remarks = ['' + log.metaData.reason];
    return riderLogData;
  }

  pageChanged(pageInfo) {
    this.recordsPerPage = pageInfo.pageSize;
    this.currentPage = pageInfo.pageIndex;
    this.fetchRiderLogs();
  }

  riderLogsOnSearch() {
    if (this.selectedFilter === 'Suspension') {
      this.filter = { type: 'loyalty', action: this.selectedFilter };
    } else {
      this.filter = { type: 'more-info', action: this.selectedFilter };
    }
    this.currentPage = 0;
    this.fetchRiderLogs();
  }

  viewRiderLogs(riderLogs, riderLogColumns, totalRecords) {
    this.captainChangedLogs = [];
    this.captainChangedLogs = riderLogs;
    this.dataSource = this.captainChangedLogs;
    this.dataSource.paginator = this.paginator;
    this.columnsToDisplay = riderLogColumns;
    this.totalRecords = totalRecords;
  }

  showImagesInPopUp(imageInfo) {
    const imageDialof = this.dialog.open(ImageDialogComponent, {
      width: '700px',
      data: {
        imageLinks: imageInfo,
      }
    });
  }

  formatChangedLogs(changedLogs, metaData) {
    const keysChanged = Object.keys(changedLogs);
    const changedRemarks = [];
    let isDocumentPresent = false, isTextAreaNeeded = false, imageInfo;
    for (let index = 0; index < keysChanged.length; index++) {
      const key = keysChanged[index];
      const value = changedLogs[key];
      if (key) {
        if ( this.docView.includes(key)) {
          isDocumentPresent = true;
          isTextAreaNeeded = true;
          imageInfo = this.getImageInfo(key, value);
        }

        if (this.createItemToDisplay(key, value)) { changedRemarks.push(this.createItemToDisplay(key, value)); }

        if (key === 'dlData') {
          const dlApprovalInfo = this.checkForDlApprovedInfo(key, changedLogs);
          changedRemarks.push(...dlApprovalInfo);
        }
        if (key === 'rcDetails') {
          const rcApprovalInfo = this.checkForRcApprovedInfo(key, changedLogs);
          changedRemarks.push(...rcApprovalInfo);
        }

        if (key === "service" && metaData && metaData.source) {
          changedRemarks.push(`Source - ${metaData.source}`)
        }
      }
    }

    return {
      remarks : changedRemarks,
      isTextAreaNeeded,
      isDocumentPresent,
      imageInfo
    };
  }

  getImageInfo(key, value) {
    const imageLinks = [];
    if (key === 'ppDetails' || key === 'imageToUpload') {
      imageLinks.push(value);
    } else if (key === 'dlData') {
      imageLinks.push(value.frontLink);
      imageLinks.push(value.backLink);
    } else if (key === 'rcDetails') {
      imageLinks.push(value.id.rc.frontLink);
      imageLinks.push(value.id.rc.backLink);
    }
    return imageLinks;
  }

  createItemToDisplay(key, valueInfo) {
    let item, value = valueInfo;
    if ( this.docView.includes(key)) {
      item = key.trim() + ' - Please click view button to see the image';
    } else {
      if (key === 'tl') {
        const tlInfo = valueInfo.firstName + ' ,' + valueInfo.email;
        value = tlInfo;
      }
      if (value !== null && value !== '') { item = key.trim() + ' - ' + value.toString().trim(); }
    }
    return item;
  }

  checkForDlApprovedInfo(dlInfo, changedLogs) {
    const dlApprovalInfo = changedLogs[dlInfo] || {};
    const approvalInfo = [];
    let item;
    for (let index = 0; index < this.dlApproveKeys.length; index++) {
      if (this.dlApproveKeys[index] && !this.isEmpty(dlApprovalInfo[this.dlApproveKeys[index]]) ) {
        item = this.dlApproveKeys[index].trim() + ' - ' + dlApprovalInfo[this.dlApproveKeys[index]].trim();
        approvalInfo.push(item);
      }
    }
    return approvalInfo;
  }

  isEmpty(message) {
    if (message === null) { return true; }

    if (message === '') { return true; }

    if (message === undefined) { return true; }

    return false;
  }

  checkForRcApprovedInfo(rcInfo, changedLogs) {
    const rcApprovalInfo = changedLogs[rcInfo].id || {};
    const approvalInfo = [];
    let item;
    for (let index = 0; index < this.rcApprovalKeys.length; index++) {
      if (this.rcApprovalKeys[index] && rcApprovalInfo[this.rcApprovalKeys[index]])  {
        item = this.rcApprovalKeys[index].trim() + ' - ' + rcApprovalInfo[this.rcApprovalKeys[index]].trim();
        approvalInfo.push(item);
      }
    }

    return approvalInfo;
  }

}
