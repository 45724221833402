import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ToastMessage } from '../toast-message/toast-message.service';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-overhead-card',
  template: require('./overhead-card.component.html'),
  providers: [ HelperService ]
})
export class OverheadCardComponent implements OnInit {

  public userEmail: any;
  @Input() title: string;
  @Output() goBack: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private toastMessage: ToastMessage,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.userEmail = this.helperService.getCookie('emailId', 2);
  }


  goBackToHome() {
    this.goBack.emit();
  }

  logoutUser() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('isUserAnAdmin');
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    this.toastMessage.openSnackBarSuccess('You have been logged out');
    this.router.navigate(['/']);
  }

}
