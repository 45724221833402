import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppComponent } from '../../src/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { BasicInfoComponent } from './onboarding-page/basic-info/basic-info.component';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DriversLicenseComponent } from './onboarding-page/drivers-license/drivers-license.component';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import { HotkeyModule } from 'angular2-hotkeys';
import { OnboardingPageComponent } from './onboarding-page/onboarding-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import { MatChipsModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material';


import { RegistrationCardComponent} from './onboarding-page/registration-card/registration-card.component';
import { RegistrationCardOptionalImageComponent } from './onboarding-page/registration-card-optional-image/registration-card.component';
import { ToastSnackbar } from './toast-message/toast-message.service';
import { DisplayImageDialog } from './display-image/display-image.component';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { ImageViewComponent } from './image-view/image-view.component';
import { OrderModule } from 'ngx-order-pipe';
import {MyHttpClient } from './http-client';

import { SigninComponent } from './signin/signin.component';
import { ProfilePicComponent } from './onboarding-page/profile-pic/profile-pic.component';
import { MoreInfoComponent } from './onboarding-page/more-info/more-info.component';

import { ImageUploadModule } from 'angular2-image-upload';
import { RiderListComponent } from './rider-list/rider-list.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import {MatSortModule} from '@angular/material/sort';
import { SearchRiderComponent } from './search-rider/search-rider.component';
import { HelperService } from './helper.service';
import { AuthInterceptor } from './auth.interceptor';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { ModelSelectionComponent } from './model-selection/model-selection.component';
import { OnboardingMerchantComponent } from './onboarding-merchant/onboarding-merchant.component';
import { SearchMerchantComponent } from './onboarding-merchant/search-merchant/search-merchant.component';
import { MerchantInfoComponent } from './onboarding-merchant/merchant-info/merchant-info.component';
import { GstCertificateComponent } from './onboarding-merchant/gst-certificate/gst-certificate.component';
import { PopUpMessageComponent } from './pop-up-message/pop-up-message.component';
import { OverheadCardComponent } from './overhead-card/overhead-card.component';
import { RiderLogsComponent } from './rider-logs/rider-logs.component';
import {PanComponent} from './onboarding-page/pan/pan.component';
import {AadharComponent} from './onboarding-page/aadhar/aadhar.component';
import {AadharOtpComponent} from './onboarding-page/aadhar-otp/aadhar-otp.component';
import { DocRejectionPanelComponent } from './doc-rejection-panel/doc-rejection-panel.component';
import { VerificationStatusComponent } from './verification-status/verification-status.component';
import {RapidoImageComponent} from './onboarding-page/rapido-image/rapido-image.component';
import {RemarkPopUpComponent} from './remarks-popup/remark-pop-up.component';
import {CabComponent} from './onboarding-page/cab-doc/cab.component';
import {FaceAuthComponent} from './onboarding-page/profile-pic/face-auth/face-auth.component';

@NgModule({
  declarations: [
    AppComponent,
    BasicInfoComponent,
    DriversLicenseComponent,
    OnboardingPageComponent,
    RegistrationCardComponent,
    RegistrationCardOptionalImageComponent,
    ToastSnackbar,
    DisplayImageDialog,
    ImageViewComponent,
    SigninComponent,
    ProfilePicComponent,
    PanComponent,
    AadharComponent,
    AadharOtpComponent,
    MoreInfoComponent,
    RiderListComponent,
    SearchRiderComponent,
    ImageDialogComponent,
    ModelSelectionComponent,
    OnboardingMerchantComponent,
    SearchMerchantComponent,
    MerchantInfoComponent,
    GstCertificateComponent,
    PopUpMessageComponent,
    OverheadCardComponent,
    RiderLogsComponent,
    DocRejectionPanelComponent,
    VerificationStatusComponent,
    RapidoImageComponent,
    RemarkPopUpComponent,
    CabComponent,
    FaceAuthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSortModule,
    HotkeyModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatBadgeModule,
    MatTooltipModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatPaginatorModule,
    ScrollDispatchModule,
    MatTableModule,
    MatChipsModule,
    NgbModule.forRoot(),
     ImageUploadModule.forRoot(),
     NgSelectModule,
     MatDatepickerModule,
     MatNativeDateModule,
     OrderModule,
    MatProgressSpinnerModule,
    MatTabsModule,
  ],
  entryComponents: [
   ToastSnackbar, DisplayImageDialog, ImageDialogComponent, PopUpMessageComponent, RemarkPopUpComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MyHttpClient,
    HelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
