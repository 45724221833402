import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor() { }

  public get approvalMessage() {
    return {
      autoApproved : 'Captain verified through verification platform.',
      agentApproved : 'Captain has been manually verified.'
    };
  }

  public get gstRejectReasons() {
    return [
      'Gst number does not match with certificate',
      'Address is not matching with certificate',
      'Store name does not macth with GST Certificate',
      'You have uploaded an inappropriate GST certificate'
    ];
  }

  public get getImageTypes() {
    return {
      profilePicture: 'profilePicture',
      driverLicense: 'driverLicense',
      registrationCard: 'registrationCard',
      gstCertificate: 'gstCertificate',
      panCard: 'panCard',
      aadhar: 'aadhar',
      permit: 'permit',
      insurance: 'insurance',
      fitness: 'fitness',
    };
  }

  public get deactivateStoreReasons() {
    return [
      'Not interested any more',
      'You have been involved in some fraud',
      'Store has closed the business',
      'Store has stopped delivering',
      'Store started delivering with different partner'
    ];
  }

  public get merchantPermissions() {
    return {
      editMerchant: 'edit_merchant',
      viewMerchant: 'view_merchant'
    };
  }

  public get captainPermissions() {
    return {
      editCaptain: 'edit_captain',
      viewCaptain: 'view_captain',
      editMobile: 'edit_captain_mobile',
      editFleetAttachDetachAccess: 'edit_fleet_attach_detach_access',
    };
  }

  public get gstRules() {
    return  [
      'First 2 characters - number',
      'Next 5 characters - alpha',
      'Next 4 characters - number',
      'Next 1 character - alpha',
      'Next 1 character - number',
      'Next 2 characters - alpha'
    ];
  }

  public get merchantValidStatus() {
    return {
      active: 'active',
      inactive: 'inactive'
    };
  }

  public get branchTypes() {
    return {
      sme: 'sme'
    };
  }

  public get rejectReasonForGstNumberChange() {
    return 'GST number is changed. Approval needed for Gst certificate';
  }

  public get workingTypes() {
    return {
      fullTime: 'fullTime',
      partTime: 'partTime'
    };
  }

  public get fullTimeOccupation() {
    return [
      'Rapido is my first job',
      'Delivery boy',
      'Student',
      'Plumber/Mechanic/Electricians',
      'BPO agent',
      'Cab driver',
      'Cook/Helper/Security guard',
      'Teacher/Professor',
      'IT sector',
      'Unemployed',
      'Marketing agent',
      'Sales agent',
      'Accountant',
      'Shop owner',
      'swiggy/zomato',
      'Ola/uber',
      'Auto driver',
      'big basket',
      'Dominoes',
      'Amazon worker',
      'Bank employee',
      'Airport worker',
      'Construction worker',
      'Government worker',
      'Photographer',
      'Hotel worker',
      'Medical worker',
      'Office assistant',
      'Others'
    ];
  }

  public get partTimeOccupation() {
    return [
      'Delivery agent',
      'Student',
      'Plumber/Mechanic/Electricians',
      'BPO agent',
      'Cab driver',
      'Cook/Helper/Security guard',
      'Teacher/Professor',
      'IT sector',
      'Unemployed',
      'Marketing agent',
      'Sales agent',
      'Accountant',
      'Shop owner',
      'swiggy/zomato',
      'Ola/uber',
      'Auto driver',
      'big basket',
      'Dominoes',
      'Amazon worker',
      'Bank employee',
      'Airport worker',
      'Construction worker',
      'Government worker',
      'Photographer',
      'Hotel worker',
      'Medical worker',
      'Office assistant',
      'Others'
    ];
  }

  public get mapWorkType() {
    return {
      FULL_TIME: 'Full Time',
      PART_TIME: 'Part Time'
    };
  }

  public get availableTimeForCaptain() {
    return [
      'Morning (7am-12pm)',
      'Afternoon (12pm-4pm)',
      'Evening (4pm-9pm)',
      'Night (9pm-7am)'
    ];
  }

  public get availableDaysForCaptain() {
    return [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
    ];
  }

  public get serviceMappings() {
    return {
      LINK: 'Link',
      AUTO: 'Auto',
      C2C: 'C2C',
      HIRE: 'hire'
    };
  }

  public get appVersion() {
    return {
      minAppVersionToAvoidLogout: 276
    };
  }

  public get errorToastDuration() {
    return 10000;
  }

  public get subServiceTypeMapping() {
    return {
        app: 'Bike Taxi',
        food: 'Food Delivery',
        alcohol: 'Alcohol Delivery',
        grocery: 'Grocery Delivery',
        others: 'Other Delivery',
        auto: 'Auto',
        premium: 'Rapido Plus'
    };
  }

  public get docRejectionReasons() {
    return{
      dl:["DL not found on government website","DL photo of poor quality","Fake/Edited DL","DL not matching with other documents","DL expired","Not a bike/auto DL","Not a DL image","DL linked with another profile"],
      pp:["PP not clear","PP not matching with other documents","Not a Profile pic"],
      rc:["RC not found on government website","RC photo of poor quality","Fake/Edited RC","Vehicle type not matching the criteria","Vehicle more than 10 years old","Not a bike/auto RC","Not an RC image","RC linked with another profile"],
      rcWithoutImage:["RC not found on government website","Vehicle type not matching the criteria","Vehicle more than 10 years old","Not a bike/auto RC","RC linked with another profile"],
      rcWithoutNumberChangeDefaulterRejectReasons:["RC photo of poor quality","Fake/Edited RC","Not an RC image","RC document mismatch"],
      panAadhar:["Image not clear","Not matching with other documents","Not a PAN/Aadhaar","PAN/Aadhaar invalid","PAN/Aadhaar linked with another profile"],
      aadhaarVerifiedViaOtp:["Not matching with other documents","PAN/Aadhaar linked with another profile"],
      cab: ["Chassis number is not matching in RC number", "Invalid expiry date"],
      faceAuth: ["Front Camera not working", "Others"]
    }
  }
}
