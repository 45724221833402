import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client';
import { HttpErrorResponse } from '@angular/common/http';


import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ImageViewService {
  server: string = environment.SERVER;
  uploadPP = process.env.SERVER + '/api/update/profile/profilePicture';
  uploadDL = process.env.SERVER + '/api/update/dl/dlImage';
  uploadRC = process.env.SERVER + '/api/update/rc/rcImage';
  uploadGst = process.env.SERVER + '/api/merchant/gst/gstImage';
  uploadPan = process.env.SERVER + '/api/update/pan/link';
  uploadAadhar = process.env.SERVER + '/api/update/aadhar/link';
  uploadPermitUrl = process.env.SERVER + '/api/cab/permit/image';
  uploadInsuranceUrl = process.env.SERVER + '/api/cab/insurance/image';
  uploadFitnessUrl = process.env.SERVER + '/api/cab/fitness/image';

  deletePP = process.env.SERVER + '/api/delete/profile/profilePicture';
  deleteDL = process.env.SERVER + '/api/delete/dl/dlImage';
  deleteRC = process.env.SERVER + '/api/delete/rc/rcImage';
  deleteGst = process.env.SERVER + '/api/merchant/delete/gstImage';
  deletePan = process.env.SERVER + '/api/delete/pan/panImage';
  deleteAadhar = process.env.SERVER + '/api/delete/aadhar/aadharImage';

  deleteAllUrl = process.env.SERVER + '/api/profile/deleteTempFiles';
  ppRejectRemarks = process.env.SERVER + '/api/update/pp/rejectRemarks';
  dlRejectRemarks = process.env.SERVER + '/api/update/dl/rejectRemarks';
  rcRejectRemarks = process.env.SERVER + '/api/update/rc/rejectRemarks';
  gstRejectRemarks = process.env.SERVER + '/api/merchant/gst/rejectRemarks';
  panRejectRemarks = process.env.SERVER + '/api/update/pan/rejectRemarks';
  aadharRejectRemarks = process.env.SERVER + '/api/update/aadhar/rejectRemarks';
  rcDefaulterUrl = process.env.SERVER + '/api/rcDefaulter';
  getImage = process.env.SERVER;
    constructor(private http: MyHttpClient) { }

  updateImage(image, url, id, imageType, view, fileName, userIdentity, docNumber=null, isImageOptional=false) {
    let uploadUrl;
    if (url == null) {
      url = '';
    }
    switch (imageType) {
      case 'profilePicture': uploadUrl = this.uploadPP;
                                break;
      case 'driverLicense': uploadUrl = this.uploadDL;
                                break;
      case 'registrationCard': uploadUrl = this.uploadRC;
                                break;
      case 'gstCertificate' : uploadUrl = this.uploadGst;
                                break;
      case 'panCard' : uploadUrl = this.uploadPan;  
                                break;   
      case 'aadhar' : uploadUrl = this.uploadAadhar;  
                                break;
      case 'permit':  uploadUrl = this.uploadPermitUrl ;
                                break; 
      case 'insurance':  uploadUrl = this.uploadInsuranceUrl ;
                                break;       
      case 'fitness':  uploadUrl = this.uploadFitnessUrl ;
                                break;                                                
      }
    let payload = {
      'data': image,
      'url': url,
      'userId': id, // would be merchantId incase of gstcertficate
      'mode': 'upload',
      'folder': imageType,
      'view': view,
      'fileName': fileName,
      'userIdentity': userIdentity,
      'docNumber': docNumber,
      // 'riderId': userIdentity.userId,
    }
    if (imageType == "registrationCard" && isImageOptional){
      payload["rcImageOptional"] = true
    }
    return this.http.post(uploadUrl, payload).pipe(catchError(this.handleError));
  }

  deleteImage(id, imageType, view) {
    let url;
    switch (imageType) {
      case 'profilePicture': url = this.deletePP;
                                break;
      case 'driverLicense': url = this.deleteDL;
                                break;
      case 'registrationCard': url = this.deleteRC;
                                break;
      case 'gstCertificate' : url = this.deleteGst;
                                break;
      case 'panCard' : url = this.deletePan;
                                break;                          
      case 'aadhar' : url = this.deleteAadhar;
                                break;                                 
      }
    return this.http.post(url, {
        'imageType': imageType,
        'view': view,
        'userId': id
    }).pipe(catchError(this.handleError));
  }

  fetchRcDefaulter(riderId) {
    return this.http.get(`${this.rcDefaulterUrl}/${riderId}`).pipe(catchError(this.handleError));
  }

  deleteTempFiles() {
    return this.http.get(this.deleteAllUrl)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }
  updateRejectRemarks(imageType, data, id, view, isAutoApproved) {
    let url = '';
    const payload = {
      'data': data,
      'userId': id, // would be merchantId incase of gstcertficate
      'view': view,
      isAutoApproved
    };
    switch (imageType) {
    case 'profilePicture': url = this.ppRejectRemarks;
                              break;
    case 'driverLicense': url = this.dlRejectRemarks;
                              break;
    case 'registrationCard': url = this.rcRejectRemarks;
                              break;
    case 'gstCertificate': url = this.gstRejectRemarks;
                              break;
    case 'panCard': url = this.panRejectRemarks;
                               break;
    case 'aadhar': url = this.aadharRejectRemarks;
                               break;                                                       
    }
    return this.http.post(url, payload).pipe(catchError(this.handleError));

  }

  getPreSignedImageUrl(filePath) {
        return this.http.getWithCache(this.getImage + filePath);
  }
  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(customError || error);
  }
}
