import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper, MatDialog } from '@angular/material';
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';;

import { ToastMessage } from '../toast-message/toast-message.service';
import { ConstantsService } from '../constants.service';
import { PopUpMessageComponent } from '../pop-up-message/pop-up-message.component';
import { OnboardingMerchantService } from './onboarding-merchant.service';
import { DataService } from '../data.service';
import { LogService } from '../log-service.service';

@Component({
  selector: 'app-onboarding-merchant',
  template: require('./onboarding-merchant.component.html'),
  providers: [ToastMessage, ConstantsService, OnboardingMerchantService, DataService, LogService]
})
export class OnboardingMerchantComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
  );

  public stepperValue = 0;
  public stepperIndex = 0;
  public currentPage = 0;

  public merchantInfo: any;

  public userIdentity: any;
  public isMerchantActive: any;
  public pages: any[] = ['Merchant info', 'GST certificate'];
  public pageName: any;
  public showMerchantInfo = true;
  public showGst = false;
  public isGstApproved: any;
  public merchantMobile: any;
  public documentTypes = this.constantsService.getImageTypes;

  public loggedInUser: any;
  public userId: any;
  public isValidToEditMerchant = false;
  public merchantMoreInfo = 9;
  public merchantValidStatus = this.constantsService.merchantValidStatus;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private constantsService: ConstantsService,
    private hotkeysService: HotkeysService,
    private toastMessage: ToastMessage,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private onboardingMerchantService: OnboardingMerchantService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _changeDetector: ChangeDetectorRef,
    private dataService: DataService,
    private logService: LogService
  ) {

    const cookies = document.cookie.split(';');
    const userCookie = cookies.find(function (item) {
        return item.includes('name');
    });

    if (userCookie) {
      this.loggedInUser = userCookie.split('=')[1];

    } else {
      alert('Please login again. Cookies not found');
      this.router.navigate(['/']);
    }

    this.hotkeysService.add(new Hotkey(['m', 'M'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 0;
      return false;
    }));

    this.hotkeysService.add(new Hotkey(['g', 'G'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 1;
      return false;
    }));

    this.hotkeysService.add(new Hotkey(['shift+b', 'shift+B'], (event: KeyboardEvent): boolean => {
      this.backToSearchPage();
      return false; // Prevent bubbling
    }));

    this.hotkeysService.add(new Hotkey(['shift+r', 'shift+R'], (event: KeyboardEvent): boolean => {
      this.reloadMerchantData();
      return false; // Prevent bubbling
    }));

  }

  ngOnInit() {
    this.dataService.merchantToBeReload = true;
    const accessRoles = this.dataService.accessCookie('accessRoles');
    const splitRoles = accessRoles.split(',');
    const merchantRoles = this.constantsService.merchantPermissions;
    if (splitRoles.indexOf(merchantRoles.editMerchant) > -1) {
      this.isValidToEditMerchant = true;
    }
    this.merchantMobile = this.route.snapshot.queryParamMap.get('phone');
    this.getMerchantInfo();
    this.pageName = this.pages[this.currentPage];
  }

  addEventsToLogService(oldData, newData) {
    this.logService.insertEvent(
      this.merchantMoreInfo,
      this.merchantInfo._id,
      oldData, newData
    ).subscribe();
  }

  stepClick(step, stepper: MatStepper) {
    this.currentPage = step.selectedIndex;

    step.previouslySelectedStep.interacted = false;
    this.pageName = this.pages[this.currentPage];

    switch (step.selectedIndex) {
      case 0:
        this.showMerchantInfo = true;
        this.showGst = false;
        break;
      case 1:
        this.showMerchantInfo = false;
        this.showGst = true;
        break;
    }
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  reloadMerchantData() {
    this.getMerchantInfo();
    this._changeDetector.detectChanges();
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }
   }

  getMerchantInfo() {
    this.spinnerService.show();
    this.onboardingMerchantService.getMerchantDetails(this.merchantMobile).subscribe(merchantInfo => {
      this.spinnerService.hide();
      if ( merchantInfo && merchantInfo['data'] && merchantInfo['data'].data) {
        this.merchantInfo = merchantInfo['data'].data || {};
        this.userId = this.merchantInfo.user._id;
        this.isMerchantActive = this.merchantInfo.status === this.merchantValidStatus.active ? true : false;
        this.isGstApproved = this.merchantInfo.gst
                         && this.merchantInfo.gst.approved || false;
        this.userIdentity = {
          userId: this.merchantInfo.user._id ,
          mobile: this.merchantInfo.mobile
        };

      } else {
        this.toastMessage.openSnackBarError('Merchant details not found. Please try with a valid number');
        this.router.navigate(['/searchMerchant']);
      }
    }, error => {
      this.spinnerService.hide();
      this.toastMessage.openSnackBarError('Could not fetch merchant details');
    });
  }

  backToSearchPage() {
    this.router.navigate(['/searchMerchant']);
  }

  activateStoreDialog() {
    if (this.vaidateBeforeActivation()) {
      const dialogRef = this.dialog.open(PopUpMessageComponent, {
        width: '450px',
        data: {
          options: [],
          title: 'Are you sure you want to activate the user ?',
          message: '',
          reason: 'activate',
          buttonText: 'Activate',
          styles: {
            title: 'activate-title',
            actionClass: 'activate-merchant-popup'
          }
        }
      });

      dialogRef.afterClosed().subscribe(activate => {
        if (activate) {
          this.activateStore();
        }
      });
    }
   }

   vaidateBeforeActivation() {
      if (this.merchantInfo && this.merchantInfo.status === this.merchantValidStatus.active) {
          this.toastMessage.openSnackBarError('Merchant is already active');
          return false;
      } else if (this.merchantInfo && !this.merchantInfo.name) {
          this.toastMessage.openSnackBarError('Please enter store name before activating merchant');
          return false;
      } else if (this.merchantInfo && !this.merchantInfo.ownerName) {
          this.toastMessage.openSnackBarError('Please enter owner name before activating merchant');
          return false;
      } else if (this.merchantInfo && !this.merchantInfo.email) {
          this.toastMessage.openSnackBarError('Please enter email before activating merchant');
          return false;
      } else if (
          this.merchantInfo && this.merchantInfo.location
          && !this.merchantInfo.location.address
        ) {
          this.toastMessage.openSnackBarError('Please enter store address before activating merchant');
          return false;
      } else if (
          this.merchantInfo && this.merchantInfo.gst
          && !this.merchantInfo.gst.gstNumber && this.merchantInfo.gst.filePath) {
          this.toastMessage.openSnackBarError('GST details are incomplete');
          return false;
      } else if (
          this.merchantInfo && this.merchantInfo.gst
          && this.merchantInfo.gst.gstNumber && !this.merchantInfo.gst.filePath
        ) {
          this.toastMessage.openSnackBarError('GST details are incomplete');
          return false;
      } else if (
            this.merchantInfo && this.merchantInfo.gst
            && this.merchantInfo.gst.gstNumber && this.merchantInfo.gst.filePath
            && !this.merchantInfo.gst.approved
      ) {
          this.toastMessage.openSnackBarError('Please approve Gst before activating merchant');
          return false;
      }
      return true;
   }

   logoutUser() {
    this.onboardingMerchantService.logoutUser(this.userId).subscribe(response => {
       if (response && response['message'] === 'success') {
        this.toastMessage.openSnackBarSuccess('User logged out successfully.');
        this.addEventsToLogService(
          { logout: false },
          { logout: true }
        );
      }
     }, error => {
      this.toastMessage.openSnackBarError('Unable to logout user');
     });
   }

   activateStore() {
     this.spinnerService.show();
     this.onboardingMerchantService.activateMerchant(this.merchantInfo._id, this.userId).subscribe(merchantInfo => {
      this.spinnerService.hide();
       if (merchantInfo && merchantInfo['data'] &&
       merchantInfo['data'].message === 'success') {
        this.addEventsToLogService(
          { status: this.merchantValidStatus.inactive },
          { status: this.merchantValidStatus.active }
        );
        this.isMerchantActive = true;
        this.merchantInfo.status = this.merchantValidStatus.active;
        this.toastMessage.openSnackBarSuccess('Merchant activated. You may reload using Shift + R');
        this.logoutUser();
      }
     }, error => {
      this.spinnerService.hide();
      this.toastMessage.openSnackBarError('Unable to activate merchant');
     });
   }

   deactivateStore(deactivateReason) {
    this.spinnerService.show();
    const deactivateInfo = {
      deActivateMessage: deactivateReason,
      deActivateRemarks: deactivateReason
    };
    this.onboardingMerchantService.deactivateMerchant(
      this.merchantInfo._id, deactivateInfo, this.userId
    ).subscribe(merchantInfo => {
      this.spinnerService.hide();
      if ( merchantInfo && merchantInfo['data'] &&
          merchantInfo['data'].message === 'success'
      ) {
        this.addEventsToLogService(
          { status: this.merchantValidStatus.active },
          { status: this.merchantValidStatus.inactive, remarks: deactivateReason }
        );
        this.isMerchantActive = false;
        this.merchantInfo.status = this.merchantValidStatus.inactive;
        this.merchantInfo.statusUpdates.deActivateRemarks = deactivateReason;
        this.toastMessage.openSnackBarSuccess('Merchant deactivated. You may reload using Shift + R');
        this.logoutUser();
     }
    }, error => {
     this.spinnerService.hide();
     this.toastMessage.openSnackBarError('Unable to deactivate merchant');
    });
   }

   vaidateBeforeDeactivation() {
    if (this.merchantInfo && this.merchantInfo.status === this.merchantValidStatus.inactive) {
        this.toastMessage.openSnackBarError('Merchant is already deactivated');
        return false;
    }
    return true;
 }

   deActivateStoreDialog() {
     if (this.vaidateBeforeDeactivation()) {
       const dialogRef = this.dialog.open(PopUpMessageComponent, {
         width: '450px',
         data: {
           options: this.constantsService.deactivateStoreReasons,
           title: 'Are you sure you want to deactivate the store ?',
           message: 'Please provide a reason, to deactivate the store',
           reason: 'deactivate',
           buttonText: 'Deactivate',
           styles: {
             title: 'deactivate-title',
             actionClass: 'deactivate-merchant-popup',
             message: 'popup-message'
           }
         }
       });

       dialogRef.componentInstance.deactivateEvent.subscribe(deactivateReason => {
         if (!deactivateReason) {
           this.toastMessage.openSnackBarError('Please select a reason to deactivate the store.');
         } else {
           dialogRef.close();
           this.deactivateStore(deactivateReason);
         }
       });
     }

   }

   changeInDocumentApproval(event) {
     if (this.documentTypes.gstCertificate === event.documentType) {
       this.isGstApproved = event.approved;
       this.merchantInfo.gst.approved = event.approved;
      }
   }

  logout() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';

    this.toastMessage.openSnackBarSuccess('You have been logged out');
    if (window['fcWidget']) {
      window['fcWidget'].destroy();
    }
    this.router.navigate(['/']);

  }

}
