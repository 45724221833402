import { Component, OnInit, Injectable,Inject } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Injectable()
export class DisplayImage implements OnInit {

  constructor(public dialog: MatDialog) {      }

  ngOnInit() {
  }
  openDialog(imgUrl): void {
  const dialogRef = this.dialog.open(DisplayImageDialog, {
    height: '700px',
    data: imgUrl
  });

  dialogRef.afterClosed().subscribe(result => {

  });
}
}

@Component({
  selector: 'photo-zoom-dialog',
  template: require('./display-image.component.html'),
})
export class DisplayImageDialog {

  constructor(
    public dialogRef: MatDialogRef<DisplayImageDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
