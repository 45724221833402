import {
  Component, OnInit, ViewChild,
  AfterViewInit,
  ElementRef, ChangeDetectorRef, Input
} from '@angular/core';


import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { OnboardingPageService } from './onboarding-page.service';
import { PopUpMessageComponent } from '../pop-up-message/pop-up-message.component';

import { MatDialog, MatStepper } from '@angular/material';
import { ToastMessage } from '../toast-message/toast-message.service';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from '../data.service';
import { SigninService } from '../signin/signin.service';
import { LogService } from '../log-service.service';
import {CabService} from './cab-doc/cab.service';

declare let navigator;
@Component({
  selector: 'app-onboarding-page',
  template: require('./onboarding-page.component.html'),
  providers: [OnboardingPageService, ToastMessage, SigninService, LogService, CabService]
})
export class OnboardingPageComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  public data: any;

  public userIdentity = {
    userId: '',
    mobile: '',
  };
  public config: any;
  public currentPage = 0;
  public riderId: any;
  public showBasicInfo = true;
  public showProfilePic = false;
  public showDriversLicence = false;
  public showRC = false;
  public showPanOrAadhar = false;
  public showPan = false;
  public showPermit = false;
  public showFitness = false;
  public showInsurance = false;
  public isModeCar = false;
  public selectedMatButton = '';
  public showMoreInfo = false;
  public showLogs = false;
  private stepper: MatStepper;
  public stepperValue = 0;
  public userpreferences: any;
  public pages: any[] = ['Basic info', 'Profile Picture', 'Drivers license', 'Registration card',
    'PAN/Aadhar', 'Review'];
  public cabPages: any[] = ['Permit', 'Fitness', 'Insurance'];
  public documentMapping = {
    profilePicture: { type: 'profilePicture', display: 'Profile Picture' },
    driverLicense: { type: 'license', display: 'Driving License' },
    registrationCard: { type: 'rc', display: 'Registration Card' },
    panCard: { type: 'panCard', display: 'Pan' },
    aadhar: { type: 'aadhar', display: 'Aadhar' },
    permit: { type: 'permit', display: 'Permit' },
    fitness: { type: 'fitness', display: 'Fitness' },
    insurance: { type: 'insurance', display: 'Insurance' },
  };
  public registrationCard = 'Registration card';
  public isApproved: any[] = [];
  public pageName: any;
  public showDLBack = false;
  public showDLFront = true;
  tlAnnounced = false;
  public showRCBack = false;
  public showRCFront = true;
  public showPanBack = false;
  public showPanFront = true;
  public showAadharBack = false;
  public showAadharFront = true;

  public ppVerified = false;
  public dlVerified = false;
  public rcVerified = false;
  public panVerified = false;
  public aadharVerified = false;

  public showPPRejectIcon = false;
  public showRCRejectIcon = false;
  public showDLRejectIcon = false;
  public showPanRejectIcon = false;
  public showAadharRejectIcon = false;

  public showActive = false;

  public stepperIndex = 0;
  public cities = [];
  public TLList = [];
  public vehiclesId = 0;
  public userLoggedIn: any;
  public approvedPages = [];
  public vehicleData: any;
  public loggedInUser = '';
  public internetSpeed: any;
  public internetSubscriber: any;
  public slowInternet = false;
  public noInternet = false;
  public internetSpeeds = ['4g', '3g'];
  // doc verifivation
  public licenseData = {};
  public pan = {};
  public aadhar = {};
  public profileData;
  public vehicleRegistrationData = {};
  public basicInfo = {};
  public showVerifyButton = false;
  public licenseStatus: any;
  public profileStatus: any;
  public vehicleRegStatus: any;
  public panStatus: any;
  public aadharStatus: any;
  public vehicleRegistrationStatus: any;
  public actorId: any;
  public riderData: any;

  // permission base editing
  public allowEditing = false;
  public allowMobileEdit = false;
  public allowFleetEdit = false;

  // verify button for individual docs
  public showPPVerify = false;
  public showDLVerify = false;
  public showRCVerify = false;
  public showPanVerify = false;
  public showAadharVerify = false;
  public modes = [];

  public dlNumber: any;
  public vehicleNumber: any;
  public aadharNumber: any;
  public panNumber: any;
  public showBulkRejectionPanel = false;
  public bulkRejectDocuments = [];
  public rejectedDocsToShowInPanel = [];
  public isProfileRejected = false;
  public isDlRejected = false;
  public isRCRejected = false;
  public isPermitDeleted = false;
  public isPermitRejected = false;
  public isPermitApproved = false;
  public isInsuranceDeleted = false;
  public isInsuranceRejected = false;
  public isInsuranceApproved = false;
  public isFitnessDeleted = false;
  public isFitnessRejected = false;
  public isFitnessApproved = false;
  public isPanRejected = false;
  public isAadharRejected = false;
  public isImageOptional = false;
  public isAadharOtpRequired = false;
  public isFleetAttached = false;
  public userId: any;

  public rcDefaulter = false;
  public rcDefaulterNumberChanged = false;
  public showVehicleNumberChangeToggle = true;

  public verificationStatus = {
    ACCEPTED: 'Accepted',
    WARNING: 'Warning',
    REJECTED: 'Rejected',
    VERIFIED: 'Verified'
  };

  public ACTORTYPE = {
    AGENT: 'Agent'
  };

  public panVerificationDetails = {};
  public aadharVerificationDetails = {};
  public cabDocList = ['permit', 'insurance', 'fitness'] ;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _changeDetector: ChangeDetectorRef,
    private _hotkeysService: HotkeysService,
    private _onboardingService: OnboardingPageService,
    private _toastMessage: ToastMessage,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private spinnerService: Ng4LoadingSpinnerService,
    private logService: LogService,
    private dialog: MatDialog,
    private _logService: LogService,
    private _cabService: CabService,
    ) {
      const cookies = document.cookie.split(';');

      const userCookie = cookies.find(function (item) {
        return item.includes('name');
      });
      if (userCookie) {
        this.loggedInUser = userCookie.split('=')[1];

      } else {
        alert('Please login again. Cookies not found');
        this.router.navigate(['/']);
      }
    this._hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      document.getElementById('backButton').click();
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
      document.getElementById('nextButton').click();
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['b', 'B'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 0;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['p', 'P'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 1;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['d', 'D'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 2;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['r', 'R'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 3;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['a', 'A'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 5;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['i', 'I'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 4;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['shift+b', 'shift+B'], (event: KeyboardEvent): boolean => {
      this.backToSearchPage();
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['shift+r', 'shift+R'], (event: KeyboardEvent): boolean => {
      this.reloadRiderData();
      return false; // Prevent bubbling
    }));

    this.pageName = this.pages[this.currentPage];
  }

  ngOnInit() {
    this.dataService.toBeReload = true;
    const accessRoles = this.dataService.accessCookie('accessRoles');
    const splitRoles = accessRoles.split(',');
    if (splitRoles.indexOf('edit_captain') > -1) {
      this.allowEditing = true; // allow user to edit captain details
      if (splitRoles.indexOf('edit_captain_mobile') > -1) {
        this.allowMobileEdit = true;
      }
      if (splitRoles.indexOf('edit_fleet_attach_detach_access') > -1) {
        this.allowFleetEdit = true;
      }
    }
    this.riderId = this.route.snapshot.queryParamMap.get('id');
    if (!this.riderId) {
      this.riderId = this.route.snapshot.queryParamMap.get('phone');
    }
    this.getRiderData(this.riderId);
    this.getCitiesList();
    this.getTLList();
    this.checkInternet();
    this.findLoggedInUser();

  }

  findLoggedInUser() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].includes('_id')) {
        this.actorId = JSON.parse(cookies[i])['_id'];
      }
    }
  }

  checkInternet() {
    this.internetSubscriber = Observable.interval(2000).subscribe(() => {
      if (!navigator.onLine) {
        this.noInternet = true;
      } else {
        this.noInternet = false;
      }
      if (this.internetSpeeds.indexOf(navigator.connection.effectiveType) < 0) {
        this.slowInternet = true;
        this.internetSpeed = navigator.connection.effectiveType;
      } else {
            this.slowInternet = false;
           }
    });
  }

  addDocumentToRejectList(event) {
    if (!this.showBulkRejectionPanel) {  this.showBulkRejectionPanel = true; }

    const imageTypeToReject = event.imageType, imageViewToReject = event.view;
    event.displayName = this.documentMapping[imageTypeToReject].display;
    if (event.imageType == 'registrationCard') {
      if (event.isImageOptional) {
        if (!this.rcDefaulterNumberChanged) {
          event.removeRcData = false;
        } else {
          event.removeRcData = true;
        }
      }
    }
    const docFromList = this.bulkRejectDocuments.filter(
      (doc) => doc.imageType === imageTypeToReject && doc.view === imageViewToReject
    );
    if (docFromList.length === 0) { this.bulkRejectDocuments.push(event); }
    this.bulkRejectDocuments.map((doc) => {
      const display = this.documentMapping[doc.imageType].display;
      if (!this.rejectedDocsToShowInPanel.includes(display)) {
        this.rejectedDocsToShowInPanel.push(this.documentMapping[doc.imageType].display);
      }
    });
  }

  removeDocsFromBulkRejection(document) {
    const displayIndex = this.rejectedDocsToShowInPanel.findIndex((doc) => doc === document);
    this.rejectedDocsToShowInPanel.splice(displayIndex, 1);
    const docIndex = this.bulkRejectDocuments.findIndex((doc) => doc.displayName === document);
    this.bulkRejectDocuments.splice(docIndex, 1);
    if (this.bulkRejectDocuments.length === 0) { this.showBulkRejectionPanel = false; }
  }

  assignKeysForCabMode(){
    this._hotkeysService.add(new Hotkey(['e', 'E'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 5;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['f', 'F'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 6;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['s', 'S'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 7;
      return false; // Prevent bubbling
    }));
    this._hotkeysService.add(new Hotkey(['a', 'A'], (event: KeyboardEvent): boolean => {
      this.stepperIndex = 8;
      return false; // Prevent bubbling
    }));
  }

  viewDetailsForRejectDocs() {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Please confirm for the rejected documents',
        message: '',
        data: {
          type: 'orderedList', info: this.rejectedDocsToShowInPanel,
          note: 'The above documents will be rejected once you confirm'
        },
        reason: 'bulkRejectDocs',
        buttonText: 'Confirm',
        cancelText: 'Go Back',
        styles: {
          title: 'font-size-20 margin-bottom-30',
          actionClass: 'doc-rejection-confirm',
          note: 'margin-bottom-12 margin-top-30 color-grey'
        }
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
       this.bulkRejectDocs();
      }
    });
  }

  updatePermitVars() {
    this.isPermitRejected = true;
    this.isPermitApproved = false;
    this.isPermitDeleted = true;
  }

  updateFitnessVars() {
    this.isFitnessRejected = true;
    this.isFitnessApproved = false;
    this.isFitnessDeleted = true;
  }

  updateInsuranceVars() {
    this.isInsuranceRejected = true;
    this.isInsuranceApproved = false;
    this.isInsuranceDeleted = true;
  }

  rejectCabDocs(cabRejectedDocs, isOnlyCabDocRejected) {
    const body = {};
    body['documents'] = [];
    let permitToBeDeleted = false;
    let fitnessToBeDeleted = false;
    let insuranceToBeDeleted = false;
    for (let index = 0 ; index < cabRejectedDocs.length; index++) {
      body['userId'] = cabRejectedDocs[index].userId;
      body['documents'].push({
        'data': cabRejectedDocs[index].data,
        'imageType': cabRejectedDocs[index].imageType,
        'userId': cabRejectedDocs[index].userId,
        'view': 'front'
      });
      if (cabRejectedDocs[index].imageType === 'permit') {
        permitToBeDeleted = true;
      }
      if (cabRejectedDocs[index].imageType === 'fitness') {
        fitnessToBeDeleted = true;
      }
      if (cabRejectedDocs[index].imageType === 'insurance') {
        insuranceToBeDeleted = true;
      }
    }
    this._cabService.rejectCabDocs('', body).subscribe(
      response => {
        if (permitToBeDeleted) {
          this.updatePermitVars();
          this._logService.insertEventWithType("permit", this.riderId, {}, {
            [`permitRejected`]: true
          }).subscribe();
        }
        if (fitnessToBeDeleted) {
          this.updateFitnessVars();
          this._logService.insertEventWithType("fitness", this.riderId, {}, {
            [`fitnessRejected`]: true
          }).subscribe();
        }
        if (insuranceToBeDeleted) {
          this.updateInsuranceVars();
          this._logService.insertEventWithType("insurance", this.riderId, {}, {
            [`insuranceRejected`]: true
          }).subscribe();
        }
        if (isOnlyCabDocRejected) {
          this.spinnerService.hide();
          this.showSuccessfulRejectionPopup();
        }
      },
      error => {
        console.log('Error in rejectCabDocs');
        console.log(error);
        const errorMsg = error && typeof error === 'string' ? error : 'Failed to reject cab docs. Please try again.';
        this.spinnerService.hide();
        this._toastMessage.openSnackBarError(errorMsg);
      }
    );
  }

  showSuccessfulRejectionPopup() {
    let message = 'has been rejected successfully';
    this.rejectedDocsToShowInPanel.length > 1 ? message = ' has been rejected successfully' : message;

    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: `${this.rejectedDocsToShowInPanel.join(', ')} ${message}`,
        message: '',
        singleButton: true,
        reason: 'doccumentsRejected',
        buttonText: 'Okay',
        styles: {
          title: 'font-size-18 margin-bottom-30 text-align-center',
          actionClass: 'popup-okay-button',
        }
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      this.rejectedDocsToShowInPanel = [];
    });
  }

  isRejectedDocCabDoc(docType) {
    return this.cabDocList.includes(docType) ;
  }

  getRejectedDocsArray(bulkRejectDocuments) {
    const originalRejectedDocs = [] ;
    const cabDocs = [];

    for (let index = 0; index < bulkRejectDocuments.length; index++) {
      if (this.isRejectedDocCabDoc(bulkRejectDocuments[index].imageType)) {
        cabDocs.push(bulkRejectDocuments[index]) ;
      } else { originalRejectedDocs.push(bulkRejectDocuments[index]) ; }
    }
    return {originalRejectedDocs, cabDocs} ;
  }

  bulkRejectDocs() {
    this.spinnerService.show();
    const {originalRejectedDocs, cabDocs} = this.getRejectedDocsArray(this.bulkRejectDocuments) ;
    this.bulkRejectDocuments = [] ;

    if (cabDocs.length != 0) {
      this.rejectCabDocs(cabDocs, originalRejectedDocs.length == 0) ;
    }

    if (originalRejectedDocs.length != 0) {
        this._onboardingService.bulkRejectDocs(originalRejectedDocs, this.userId).subscribe((response: any) => {
        this.spinnerService.hide();
        const rejectEvents = [];
        const docTypeMapping = {
          'profilePicture': 2,
          'driverLicense': 3,
          'registrationCard': 4,
          'panCard': 10,
          'aadhar': 11
        };

        originalRejectedDocs.map((doc) => {

          const docType = docTypeMapping[doc.imageType];
          switch (doc.imageType) {
              case 'profilePicture': { this.isProfileRejected = true; break; }
              case 'driverLicense': { this.isDlRejected = true; break;  }
              case 'registrationCard': { this.isRCRejected = true; break; }
              case 'panCard': { this.isPanRejected = true; break;  }
              case 'aadhar': { this.isAadharRejected = true; break; }
          }

          this.showBulkRejectionPanel = false;

          rejectEvents.push({
            metaData: {
              View: doc.view,
              Status: 'REJECTED',
              ImageType: doc.displayName,
              RejectReason: doc.data,
            },
            docType
          });
        });

        this.showSuccessfulRejectionPopup();

        rejectEvents.forEach((event) =>
          this._logService.insertEvent(
            event.docType,
            this.userId,
            {approved: false},
            event.metaData)
          .subscribe());

      }, error => {
        console.log('Error in Bulk Rejections');
        console.log(error);
        this.showBulkRejectionPanel = false;
        this.rejectedDocsToShowInPanel = [];
        const errorMsg = error && typeof error === 'string' ? error : 'Failed to reject the docs. Please try again.';
        this.spinnerService.hide();
        this._toastMessage.openSnackBarError(errorMsg);
      });
    }

  }

  formDocStatusInfo() {
    let vehicleData;
    this.licenseData['drivingLicense'] = this.data && this.data['license'] ? this.data['license'].number : '';
    this.licenseData['dlImage'] = this.data && this.data['license'] ? this.data['license'].frontLink : '';
    this.licenseData['dlBackImage'] = this.data && this.data['license'] ? this.data['license'].backLink : '';

    if (this.data.vehiclesAssignable[0] && this.data.vehiclesAssignable.length > 1) {
      const index = this.data.vehiclesAssignable.length - 1;
      vehicleData = this.data.vehiclesAssignable[index].id && this.data.vehiclesAssignable[index].id.vehicleNumber ?
                    this.data.vehiclesAssignable[index].id.vehicleNumber : '';
    }
    if (this.data.vehiclesAssignable[0] && this.data.vehiclesAssignable.length === 1) {
      vehicleData = this.data.vehiclesAssignable[0] && this.data.vehiclesAssignable[0].id &&
                    this.data.vehiclesAssignable[0].id.vehicleNumber ? this.data.vehiclesAssignable[0].id.vehicleNumber : '';
    }
    this.vehicleRegistrationData['registrationNumber'] = vehicleData;
    this.basicInfo['birthDate'] = this.data && this.data['birthDate'] ? this.data['birthDate'] : '';
    this.basicInfo['firstName'] = this.data && this.data.firstName || '';
    this.profileData = {
      'profileImage' : this.data && this.data['profilePicture'] ? this.data['profilePicture'].link : ''
    };
    this.pan['number'] = this.data.pan ? this.data.pan.number : '';
    this.pan['image'] = this.data.pan ? this.data.pan.link : '';

    this.aadhar['number'] = this.data.aadhar ? this.data.aadhar.number : '';
    this.aadhar['frontLink'] = this.data.aadhar ? this.data.aadhar.frontLink : '';
    this.aadhar['backLink'] = this.data.aadhar ? this.data.aadhar.backLink : '';

  }

  async getRiderData(data) {
    let response;
    let dataObject, docStatus;
    this.spinnerService.show();
    await this.getModes();
    this._onboardingService.getRiderDetails(data).subscribe(resp => {
      this.spinnerService.hide();
      response = resp;
      dataObject = response.data;
      if (response && response.data && !response.data.userId) {
        this._toastMessage.openSnackBarError('User details not available for  this captain');
        return;
      }
      if (dataObject.fleetId) {
        this.isFleetAttached = true;
        this.pages = this.pages.filter(page => !this.registrationCard.includes(page));
      }
      if (dataObject.modeId && dataObject.modeId.mode === 'Car') {
        this.isModeCar = true;
        this.fetchCabData(dataObject.userId._id);
        this.assignKeysForCabMode()
        this.pages = [...this.pages.slice(0, this.pages.length - 1), ...this.cabPages, this.pages[this.pages.length-1]];
      } else {
        this.isModeCar = false;
        this.pages = this.pages.filter(page => !this.cabPages.includes(page));
      }
      docStatus = dataObject['docVerificationStatus'] || {};
      this.createDlStatus(docStatus);
      this.createProfileStatus(docStatus);
      this.createRcStatus(docStatus);
      // this.createPanStatus(docStatus);
      // this.createAadharStatus(docStatus);
      this.userIdentity.mobile = dataObject.mobile;
      this.userIdentity.userId = dataObject._id;
      this.userId = dataObject.userId._id;
      if (dataObject.registeredCity == null) {
        dataObject.registeredCity = '';
      }
      this.showPan = !!(dataObject.panCard.link) || dataObject.active;
      this.selectedMatButton = this.showPan ? 'Pan' : 'Aadhar';
      if (dataObject.profilePicture && dataObject.profilePicture) {
        this.setProfileStatus(
          dataObject.profilePicture.verified, dataObject.profilePicture.rejectRemarks, dataObject.profilePicture.status
        );
      }
      if (dataObject.license && dataObject.license) {
        this.setDlStatus(
          dataObject.license.verified, dataObject.license.rejectRemarks, dataObject.license.status
        );
      }
      if (dataObject.panCard) {
        this.setPanStatus(
          dataObject.panCard.verified, dataObject.panCard.rejectRemarks, dataObject.panCard.status
        );
        this.createPanInfo();
      }
      if (dataObject.aadhar) {
        this.setAadharStatus(
          dataObject.aadhar.verified, dataObject.aadhar.rejectRemarks, dataObject.aadhar.status
        );
        this.createAadharInfo();
      }
      if (
        dataObject.vehiclesAssignable
        && dataObject.vehiclesAssignable[dataObject.vehiclesAssignable.length - 1]
        && dataObject.vehiclesAssignable[0].id
        ) {
            const rc = dataObject.vehiclesAssignable[0].id.rc;
            this.setRcStatus(rc.verified, rc.rejecteRemarks, rc.status);
            this.setImageOptionalFlag(rc);
        }
        if (dataObject.aadhar) {
          this.setAadhaarOtpRequiredFlag(dataObject.aadhar);
        }
      if (dataObject.active === true) {
        this.showActive = true;
      } else {
        this.showActive = false;
      }
      this.data = dataObject;
      this.data.modes = this.modes;
      if (this.data && this.data.active) {
        this.showDLVerify = false;
        this.showPPVerify = false;
        this.showRCVerify = false;
        this.showPanVerify = false;
        this.showAadharVerify = false;
      } else {
        this.verifyStatus(this.data);
        this.formDocStatusInfo();
      }
      this.fetchRcDefaulter(this.userId);
      this.checkData(this.data);
    },
      err => {
        this.spinnerService.hide();
        this._toastMessage.openSnackBarError('Could not fetch rider details');
        console.log(err);
      });
  }

  getCitiesList() {
    let response;
    this._onboardingService.getCitiesList().subscribe(resp => {
      response = resp;
      this.cities = response.data.cities;
    },
      err => {
        this._toastMessage.openSnackBarError('Could not retrieve list of cities');
        console.log(err);
      });
  }

  async getModes() {
    try {
      const response: any = await this._onboardingService.getModes(this.riderId).toPromise();
      if (response.data && response.data.modes) {
        this.modes = response.data.modes.data || [];
      }
    } catch (err) {
      console.log(err);
      this._toastMessage.openSnackBarError('Could not fetch modes');
    }
  }

  getTLList() {
    let response;
    this._onboardingService.getTLList().subscribe(resp => {
      response = resp;
      this.TLList = response.data && response.data.data || [];
    },
      err => {
        this._toastMessage.openSnackBarError('Could not fetch list of TLs');
        console.log(err);
      });
  }

  panAadharToggle(event) {
    this.showPan = event.value === 'Pan' ? true : false;
  }

  stepClick(step, stepper: MatStepper) {
    this.currentPage = step.selectedIndex;

    step.previouslySelectedStep.interacted = false;
    this.pageName = this.pages[this.currentPage];

    if (this.isFleetAttached) {
      switch (step.selectedIndex) {
        case 0:
          this.showBasicInfo = true;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          break;
        case 1:
          this.showBasicInfo = false;
          this.showProfilePic = true;
          this.showDriversLicence = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          break;
        case 2: this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = true;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          break;
        case 3: this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showPanOrAadhar = true;
          this.showMoreInfo = false;
          break;
        case 4:
          this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = true;
          break;
      }
    } else {
      switch (step.selectedIndex) {
        case 0:
          this.showBasicInfo = true;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showRC = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          this.showPermit = false;
          this.showFitness = false;
          this.showInsurance = false;
          break;
        case 1:
          this.showBasicInfo = false;
          this.showProfilePic = true;
          this.showDriversLicence = false;
          this.showRC = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          this.showPermit = false;
          this.showFitness = false;
          this.showInsurance = false;
          break;
        case 2: this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = true;
          this.showRC = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          this.showPermit = false;
          this.showFitness = false;
          this.showInsurance = false;
          break;
        case 3: this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showRC = true;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          this.showPermit = false;
          this.showFitness = false;
          this.showInsurance = false;
          break;
        case 4:
          this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showRC = false;
          this.showPanOrAadhar = true;
          this.showMoreInfo = false;
          this.showPermit = false;
          this.showFitness = false;
          this.showInsurance = false;
          break;
        case 5:
          if (this.isModeCar) {
            this.showBasicInfo = false;
            this.showProfilePic = false;
            this.showDriversLicence = false;
            this.showRC = false;
            this.showPanOrAadhar = false;
            this.showMoreInfo = false;
            this.showPermit = true;
            this.showFitness = false;
            this.showInsurance = false;
          } else {
            this.showBasicInfo = false;
            this.showProfilePic = false;
            this.showDriversLicence = false;
            this.showRC = false;
            this.showPanOrAadhar = false;
            this.showMoreInfo = true;
            this.showPermit = false;
            this.showFitness = false;
            this.showInsurance = false;
          }
          break;
        case 6:
          this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showRC = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          this.showPermit = false;
          this.showFitness = true;
          this.showInsurance = false;
          break;
        case 7:
          this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showRC = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = false;
          this.showPermit = false;
          this.showFitness = false;
          this.showInsurance = true;
          break;
        case 8:
          this.showBasicInfo = false;
          this.showProfilePic = false;
          this.showDriversLicence = false;
          this.showRC = false;
          this.showPanOrAadhar = false;
          this.showMoreInfo = true;
          this.showPermit = false;
          this.showFitness = false;
          this.showInsurance = false;
          break;
      }
    }

  }


  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  populateFitness(response) {
    if (response['fitness'] && response['fitness']['approvalInfo'] &&
        response['fitness']['approvalInfo']['approvedAt']) {
        this.isFitnessApproved = true;
    }
    if (response['fitness'] && response['fitness']['rejectionInfo'] &&
        response['fitness']['rejectionInfo']['front'] && response['fitness']['rejectionInfo']['front']['timestamp']) {
        this.isFitnessRejected = true;
    }
  }

  populatePermit(response) {
    if (response['permit'] && response['permit']['approvalInfo'] &&
        response['permit']['approvalInfo']['approvedAt']) {
        this.isPermitApproved = true;
    }
    if (response['permit'] && response['permit']['rejectionInfo'] &&
        response['permit']['rejectionInfo']['front'] && response['permit']['rejectionInfo']['front']['timestamp']) {
        this.isPermitRejected = true;
    }
  }

  populateInsurance(response) {
    if (response['insurance'] && response['insurance']['approvalInfo'] &&
        response['insurance']['approvalInfo']['approvedAt']) {
        this.isInsuranceApproved = true;
    }
    if (response['insurance'] && response['insurance']['rejectionInfo'] &&
        response['insurance']['rejectionInfo']['front'] && response['insurance']['rejectionInfo']['front']['timestamp']) {
        this.isInsuranceRejected = true;
    }
  }

  fetchCabData(riderId) {
    const result = this._onboardingService.getCabData(riderId).subscribe(
      response => {
        this.populateFitness(response);
        this.populatePermit(response);
        this.populateInsurance(response);
      },
      error => {
        const errorMessage = error && error.error && error.error.message || 'Could not fetch cab data';
        this._toastMessage.openSnackBarError(errorMessage);
      }
    );
  }

  fetchRcDefaulter(riderId) {
    const result = this._onboardingService.fetchRcDefaulter(riderId).subscribe(
      response => {
        this.rcDefaulter = response['defaulter'];
        this.rcDefaulterNumberChanged = response['rcNumberChanged'] ? response['rcNumberChanged'] : false;
        this.formDocStatusInfo();
      },
      error => {
        const errorMessage = error && error.error && error.error.message || 'Could not fetch balance';
        this._toastMessage.openSnackBarError(errorMessage);
      }
    );
  }



  approveAndProceed(stepper: MatStepper) {
    this.stepperValue += 20;
    this.isApproved.push(this.pageName);
    this._toastMessage.openSnackBarSuccess(this.pageName.toString() + ' is approved!');
    if (this.stepperValue === 100) {

      this._toastMessage.openSnackBarSuccess('Done! You can now review the data and proceed.');
    }
    stepper.next();
  }

  logout() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';

    this._toastMessage.openSnackBarSuccess('You have been logged out');
    if (window['fcWidget']) {
      window['fcWidget'].destroy();
    }
    this.router.navigate(['/']);

  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  dlViewToggle(evt) {
    if (!evt.checked) {
      this.showDLBack = true;
      this.showDLFront = false;
    }

    if (evt.checked) {
      this.showDLBack = false;
      this.showDLFront = true;
    }
    console.log(this.showDLRejectIcon);

  }

  rcViewToggle(evt) {
    if (!evt.checked) {
      this.showRCBack = true;
      this.showRCFront = false;
    }

    if (evt.checked) {
      this.showRCBack = false;
      this.showRCFront = true;
    }

  }

  panOrAadharViewToggle(evt) {
    if (!evt.checked && !this.isAadharOtpRequired) {
      this.showPanBack = false;
      this.showPanFront = true;
      this.showAadharBack = true;
      this.showAadharFront = false;
    }

    if (evt.checked) {
      this.showPanBack = false;
      this.showPanFront = true;
      this.showAadharBack = false;
      this.showAadharFront = true;
    }

  }

  backToRidersList() {
    this.router.navigate(['/riders']);
  }

  backToSearchPage() {
    this.router.navigate(['/search']);
  }

  checkData(item: any) {
    if (item.vehiclesAssignable.length > 1) {
      this.vehicleData = item.vehiclesAssignable[item.vehiclesAssignable.length - 1];

    }
    if (item.vehiclesAssignable.length === 1) {
      this.vehicleData = item.vehiclesAssignable[0];

    }
    if ((item.firstName && (item.firstName.length > 0))
      && (item.mobile && (item.mobile.length > 0))
      && (item.email && (item.email.length > 0))
      && (item.userId.otp)) {
      this.approvedPages.push('showBasicInfo');
    }
    if (item.profilePicture.verified === true) {
      this.approvedPages.push('showProfilePic');
    }
    if (item.license.verified === true) {
      this.approvedPages.push('showDriversLicence');
    }
    if (this.vehicleData && this.vehicleData.id && this.vehicleData.id.rc) {
      if (this.vehicleData.id.rc.verified === true) {
        this.approvedPages.push('showRC');
      }
    }
    if ( (item.panCard &&item.panCard.verified === true) || ( item.aadhar && item.aadhar.verified === true)) {
      this.approvedPages.push('showPanOrAadhar');
    }
  }

  handleFetchData(evt: any) {
    this.reloadRiderData();
  }

  reloadRiderData() {
    this.getRiderData(this.riderId);
    this._changeDetector.detectChanges();
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }
  }

  setProfileStatus(verified, rejectRemarks, status) {
    if (verified)  {
      this.ppVerified = true;
    } else if (
      !verified && (status === 'rejected' || rejectRemarks)
    ) {
        this.ppVerified = false;
        this.showPPRejectIcon = true;
      } else if (!verified) { this.ppVerified = false; }
  }

  setDlStatus(verified, rejectRemarks, status) {
    if (verified)  {
      this.dlVerified = true;
    } else if (
      !verified && (status === 'rejected' || rejectRemarks)
    ) {
        this.dlVerified = false;
        this.showDLRejectIcon = true;
      } else if (!verified) {
      this.dlVerified = false;
      this.showDLRejectIcon = false;
    }
  }

  setRcStatus(verified, rejectRemarks, status) {
    if (verified) {
      this.rcVerified = true;
    } else if (
      !verified && (status === 'rejected' || rejectRemarks)
    ) {
        this.rcVerified = false;
        this.showRCRejectIcon = true;
      } else if (!verified) {
      this.rcVerified = false;
      this.showRCRejectIcon = false;
    }
  }

  setImageOptionalFlag(rc) {
    this.isImageOptional = !!(rc && rc.isImageOptional);
  }

  setAadhaarOtpRequiredFlag(aadhar) {
    this.isAadharOtpRequired = !!(aadhar.isOtpRequired);
  }

  setPanStatus(verified, rejectRemarks, status) {
    if (verified)  {
      this.panVerified = true;
    } else if (
      !verified && (status === 'rejected' || rejectRemarks)
    ) {
        this.panVerified = false;
        this.showPanRejectIcon = true;
      } else if (!verified) {
      this.panVerified = false;
      this.showPanRejectIcon = false;
    }
  }

  setAadharStatus(verified, rejectRemarks, status) {
    if (verified)  {
      this.aadharVerified = true;
    } else if (
      !verified && (status === 'rejected' || rejectRemarks)
    ) {
        this.aadharVerified = false;
        this.showAadharRejectIcon = true;
      } else if (!verified) {
      this.aadharVerified = false;
      this.showAadharRejectIcon = false;
    }
  }

  ppStatusUpdate(evt: any) {
    if (evt === false) {
      this.setProfileStatus(evt, 'rejected', 'rejected');
    } else if (evt === true) {
      this.setProfileStatus(evt, '', 'approved');
    }
    this.data.profilePicture.verified = evt;
  }

  dlStatusUpdate(evt: any) {
    if (evt === false) {
      this.setDlStatus(evt, 'rejected', 'rejected');
    } else if (evt === true) {
      this.setDlStatus(evt, '', 'approved');
    }
    this.data.license.verified = evt;
  }

  rcStatusUpdate(evt: any) {
    if (evt === false) {
      this.setRcStatus(evt, 'rejected', 'rejected');
    } else if (evt === true) {
      this.setRcStatus(evt, '', 'approved');
    }

    if (
      this.data.vehiclesAssignable[0] && this.data.vehiclesAssignable[0].id
      && this.data.vehiclesAssignable[0].id && this.data.vehiclesAssignable[0].id.rc
    ) {
      this.data.vehiclesAssignable[0].id.rc.verified = evt;
    }
  }

  panStatusUpdate(evt: any) {
    if (evt === false) {
      this.setPanStatus(evt, 'rejected', 'rejected');
    } else if (evt === true) {
      this.setPanStatus(evt, '', 'approved');
    }
    this.data.panCard.verified = evt;
  }

  aadharStatusUpdate(evt: any) {
    if (evt === false) {
      this.setAadharStatus(evt, 'rejected', 'rejected');
    } else if (evt === true) {
      this.setAadharStatus(evt, '', 'approved');
    }
    this.data.aadhar.verified = evt;
  }

  handleRiderActive(evt: any) {
    this.showActive = evt;
  }

  storeLicenseData(evt) {
    this.licenseData['drivingLicense'] = evt;
    if ( this.data && !this.data.active ) {
      this.showDLVerify = true;
    }
  }

  storeDlImageUrl(evt) {
    this.dlVerified = false;
    this.showDLRejectIcon = false;
    this.licenseData['dlImage'] = evt;
    if ( this.data && !this.data.active ) {
      this.showDLVerify = true;
    }
  }

  storeRc(evt) {
    this.rcVerified = false;
    this.showRCRejectIcon = false;
  }

  storeDlBack(evt) {
    this.dlVerified = false;
    this.showDLRejectIcon = false;
    this.licenseData['dlBackImage'] = evt;
  }

  storeVehicleNumber(evt) {
    this.vehicleRegistrationData['registrationNumber'] = evt;
    if ( this.data && !this.data.active ) {
      this.showRCVerify = true;
    }
  }

  storePanNumber(event) {
    this.pan['number'] = event;
    if ( this.data && !this.data.active ) {
      this.showPanVerify = true;
    }
  }

  storeAadharNumber(event) {
    this.aadhar['number'] = event;
    if ( this.data && !this.data.active ) {
      this.showAadharVerify = true;
    }
  }

  storePanImage(event) {
    this.panVerified = false;
    this.showPanRejectIcon  = false;
    this.pan['link'] = event;
  }

  storeAadharFrontImage(event) {
    this.aadharVerified = false;
    this.showAadharRejectIcon = false;
    this.aadhar['frontLink'] = event;
  }

  storeAadharBackImage(event) {
    this.aadharVerified = false;
    this.showAadharRejectIcon = false;
    this.aadhar['backLink'] = event;
  }

  storeProfileData(evt) {
    this.ppVerified = false;
    this.showPPRejectIcon = false;
    this.profileData = {
      'profileImage': evt
    };
    if ( this.data && !this.data.active ) {
      this.showPPVerify = true;
    }
  }

  birthDateUpdate(evt) {
    this.basicInfo = {
      'birthDate': evt
    };
  }

  modeUpdate(evt) {
    this.reloadRiderData();
  }

  firstNameUpdate(evt) {
    this.basicInfo = {
      'firstName': evt
    };
  }
  userPreferences(evt) {
    this.userpreferences = evt;
  }

  // enable verify button based on the status ( Accepted, Warning)
  verifyStatus(docStatus) {
    const verificationStatus = docStatus['docVerificationStatus'] || {};
    const approvalStatus = docStatus['autoApprovalStatus'] || {};
    const dlStatus = verificationStatus['license'] || {};
    const ppStatus = verificationStatus['profilePic'] || {};
    const rcStatus = verificationStatus['vehicleRegistration'] || {};
    const panStatus = verificationStatus['pan'] || {};
    const aadharStatus = verificationStatus['aadhar'] || {};

    const dlAutoApproval = approvalStatus.licenseVerificationStatus || {};
    const ppAutoApproval = approvalStatus.profileVerificationStatus || {};
    const rcAutoApproval = approvalStatus.vehicleVerificationStatus || {};
    const panAutoApproval = approvalStatus.panVerificationStatus || {};
    const aadharAutoApproval = approvalStatus.aadharVerificationStatus || {};

    const vehicleData = rcStatus && rcStatus.owner && rcStatus.fitnessUpto ? true : false;
    if ( dlStatus.status === this.verificationStatus.ACCEPTED || dlStatus.status === this.verificationStatus.VERIFIED || dlAutoApproval.autoApproved ) {
      this.showDLVerify = false;
    } else {
      this.showDLVerify = true;
    }
    if ( ppStatus.status === this.verificationStatus.ACCEPTED || ppStatus.status === this.verificationStatus.VERIFIED || ppAutoApproval.autoApproved ) {
      this.showPPVerify = false;
    } else {
      this.showPPVerify = true;
    }
    if (
      rcStatus.status === this.verificationStatus.ACCEPTED
      || rcStatus.status === this.verificationStatus.VERIFIED
      || rcAutoApproval.autoApproved
     ) {
        this.showRCVerify = false;
    } else {
        this.showRCVerify = true;
    }
    if (rcStatus.status && !vehicleData) {
      this.showVerifyButton = true;
    }

    if ( panStatus.status === this.verificationStatus.ACCEPTED ||
      panStatus.status === this.verificationStatus.VERIFIED ||
       panAutoApproval.autoApproved ) {
      this.showPanVerify = false;
    } else {
        this.showPanVerify = true;
    }

    if ( aadharStatus.status === this.verificationStatus.ACCEPTED ||
      aadharStatus.status === this.verificationStatus.VERIFIED || aadharAutoApproval.autoApproved ) {
        this.showAadharVerify = false;
    } else {
        this.showAadharVerify = true;
    }
   }

  validateProfileDlBody({
    drivingLicense, dlImage, dlBackImage, birthDate, profileImage, userId, actorId,
    actorType, triggeredFrom, name
  }) {

    if (!dlImage) {
      this._toastMessage.openSnackBarError('Please upload Dl front image for verification.');
      this.spinnerService.hide();
      return false;
    }

    if (!dlBackImage) {
      this._toastMessage.openSnackBarError('Please upload Dl back image for verification.');
      this.spinnerService.hide();
      return false;
    }

    if (!drivingLicense) {
      this._toastMessage.openSnackBarError('Please enter Dl number for verification.');
      this.spinnerService.hide();
      return false;
    }

    if (!profileImage) {
      this._toastMessage.openSnackBarError('Please upload profile picture for verification.');
      this.spinnerService.hide();
      return false;
    }

    if (!birthDate) {
      this._toastMessage.openSnackBarError('Please enter date of birth for verification.');
      this.spinnerService.hide();
      return false;
    }

    if (!name) {
      this._toastMessage.openSnackBarError('Please enter name for verification.');
      this.spinnerService.hide();
      return false;
    }

    return true;
  }

  addProfileLog() {
    this.logService.insertEvent(2, this.data.userId._id, {},
      { profileLink : this.profileStatus.profileLink || null,
        message: this.profileStatus.message || null,
        verifiedBy: this.profileStatus.verifiedBy,
        verifiedOn: this.profileStatus.verifiedOn
      }).subscribe();
  }

  addDlLog() {
    this.logService.insertEvent(3, this.data.userId._id, {},
      { number : this.dlNumber || null,
        message: this.licenseStatus.message || null,
        verifiedBy: this.licenseStatus.verifiedBy,
        verifiedOn: this.licenseStatus.verifiedOn
      }).subscribe();
  }

  addRcLog() {
    this.logService.insertEvent(4, this.data.userId._id, {},
      { number : this.vehicleNumber || null,
        message: this.vehicleRegStatus.message || null,
        verifiedBy: this.vehicleRegStatus.verifiedBy,
        verifiedOn: this.vehicleRegStatus.verifiedOn
      }).subscribe();
  }

  addPanLog() {
        this.logService.insertEvent(5, this.data.userId._id, {},
            { number : this.panNumber || null,
                message: this.panStatus.message || null,
                verifiedBy: this.panStatus.verifiedBy,
                verifiedOn: this.panStatus.verifiedOn
            }).subscribe();
    }

  addAadharLog() {
    this.logService.insertEvent(5, this.data.userId._id, {},
        { number : this.aadharNumber || null,
          message: this.aadharStatus.message || null,
          verifiedBy: this.aadharStatus.verifiedBy,
          verifiedOn: this.aadharStatus.verifiedOn
        }).subscribe();
  }

  // Call DL, PP API to verify the uploaded docs
  verifyProfileDLDocs(event) {
    // required details to verify profile pic and DL.
    const profileDlInfo = this.createDlInfo(event);
    this.spinnerService.show();
    if ( this.validateProfileDlBody(profileDlInfo) ) {
          this.dlNumber = profileDlInfo.drivingLicense || null;
          this._toastMessage.openSnackBarSuccess('Verifying details from Government Server');
          this.showDLVerify = false;
          this._onboardingService.verifyProfileDLDocs(profileDlInfo).subscribe(response => {
            this.spinnerService.hide();
            if (response && response['data'] && response['data']['status'] === 'success') {

              const docStatus = response['data'];

              this.createProfileStatus(docStatus);
              this.createDlStatus(docStatus);
              this.verifyStatus(docStatus);
              this.addProfileLog();
              this.addDlLog();
              this._toastMessage.openSnackBarSuccess(response['data']['data']);

            } else {
              if (response && response['data'] && response['data'] && response['data']['message']) {
                this._toastMessage.openSnackBarError(response['data']['message']);
              } else {
                this._toastMessage.openSnackBarError('Faced problem in verification of docs');
              }
            }
          }, err => {
              this.spinnerService.hide();
              this._toastMessage.openSnackBarError(err);
            });

      }
  }

  // Call Rc API to verify the uploaded docs
  verifyRcDoc(event) {
    this.spinnerService.show();
    if (
        this.vehicleRegistrationData
        && this.vehicleRegistrationData['registrationNumber']
      ) {

      let docVerificationDetails = {};
      this.vehicleNumber = this.vehicleRegistrationData['registrationNumber'];

      docVerificationDetails = this.createRcInfo();
      this.showRCVerify = false;
      this._toastMessage.openSnackBarSuccess('Fetching details from Government Server');
      this._onboardingService.verifyRcDoc(docVerificationDetails).subscribe(response => {
        this.spinnerService.hide();

        if (response && response['data'] && response['data']['status'] === 'success') {
        const docStatus = response['data'];
        this.createRcStatus( docStatus );
        this.addRcLog();

        this.verifyStatus(docStatus);

        this._toastMessage.openSnackBarSuccess(response['data']['data']);
      } else {
        console.error(response);
        if (response && response['data'] && response['data']) {
          this._toastMessage.openSnackBarError(response['data']);
        } else {
          this._toastMessage.openSnackBarError('Faced problem in verification of docs');
        }
      }
    }, err => {
        this.spinnerService.hide();
        console.log('Error : ', err);
        this._toastMessage.openSnackBarError(err);
      });
    } else {
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError('Please enter vehicle number for verification.');
    }
  }

  getToday() {
    let month: any = new Date().getMonth() + 1;
    let date: any = new Date().getDate();
    if (month.toString().length === 1) {
      month = '0' + month;
    }
    if (date.toString().length === 1) {
      date = '0' + date;
    }
    const today = new Date().getFullYear() + '-' + month + '-' + date;

    return today;
  }

  createDlInfo(triggeredFrom) {
    const profileDlInfo: {
      drivingLicense
      dlImage
      dlBackImage
      birthDate
      profileImage
      userId
      actorId
      actorType
      triggeredFrom,
      name
    } = {
      drivingLicense: '',
      dlImage: '',
      dlBackImage: '',
      birthDate: '',
      profileImage: '',
      userId: '',
      actorId: '',
      actorType: '',
      triggeredFrom: '',
      name: ''
    };
    profileDlInfo.drivingLicense = this.licenseData['drivingLicense'];
    profileDlInfo.dlImage = this.licenseData['dlImage'];
    profileDlInfo.dlBackImage = this.licenseData['dlBackImage'];
    profileDlInfo.birthDate = this.basicInfo['birthDate'];
    profileDlInfo.profileImage = this.profileData['profileImage'];
    profileDlInfo.name = this.basicInfo['firstName'];

    profileDlInfo.userId = this.data.userId && this.data.userId._id || ''; // captain Id
    profileDlInfo.actorId = this.actorId;
    profileDlInfo.actorType = this.ACTORTYPE.AGENT;
    profileDlInfo.triggeredFrom = triggeredFrom;

    return profileDlInfo;
  }

  createRcInfo() {
    const docVerificationDetails = {};

    docVerificationDetails['registrationNumber'] = this.vehicleRegistrationData['registrationNumber'];
    docVerificationDetails['userId'] = this.data.userId && this.data.userId._id ? this.data.userId._id : '';
    docVerificationDetails['actorId'] = this.actorId;
    docVerificationDetails['actorType'] = this.ACTORTYPE.AGENT;
    docVerificationDetails['isImageOptional'] = this.isImageOptional;

    return docVerificationDetails;
  }

  createPanInfo() {
    this.panVerificationDetails['number'] = this.pan['number'];
    this.panVerificationDetails['link'] = this.pan['link'];
    this.panVerificationDetails['actorId'] = this.actorId;
    this.panVerificationDetails['actorType'] = this.ACTORTYPE.AGENT;

  }

  createAadharInfo() {
    this.aadharVerificationDetails['number'] = this.aadhar['number'];
    this.aadharVerificationDetails['frontLink'] = this.aadhar['frontLink'];
    this.aadharVerificationDetails['backLink'] = this.aadhar['backLink'];
    this.aadharVerificationDetails['actorId'] = this.actorId;
    this.aadharVerificationDetails['actorType'] = this.ACTORTYPE.AGENT;
  }

  createProfileStatus(docStatus) {
    const pp = docStatus['profilePic'] || {};
    const profileStatus = pp.status === this.verificationStatus.ACCEPTED ? this.verificationStatus.VERIFIED : pp.status;
    const isProfileAccepted = pp.status === this.verificationStatus.ACCEPTED ? true : false;
    const message = pp.message || '';
    const profileLink = pp.data && pp.data.profileLink || '';
    const verifiedBy = pp.verifiedBy || '';
    const verifiedOn = pp.verifiedOn || '';

    this.profileStatus = { status : profileStatus, profileLink : profileLink, message: message,
                           isProfileAccepted: isProfileAccepted,
                           verifiedBy: verifiedBy,
                           verifiedOn: verifiedOn
                        };
  }

  createDlStatus(docStatus) {

    const dl = docStatus['license'] || {};
    const dlStatus = dl.status === this.verificationStatus.ACCEPTED ? this.verificationStatus.VERIFIED : dl.status;
    const isDlAccepted = dl.status === this.verificationStatus.ACCEPTED ? true : false;
    const message = dl.message || '';
    const expiryDate = dl && dl['data'] && dl['data'].expiryDate || '';
    const verifiedBy = dl.verifiedBy || '';
    const verifiedOn = dl.verifiedOn || '';

    this.licenseStatus = { status : dlStatus, expiryDate : expiryDate, message: message,
                            isDlAccepted: isDlAccepted,
                            verifiedBy: verifiedBy,
                            verifiedOn: verifiedOn
                           };
  }

  createRcStatus(docStatus) {
    const rc = docStatus['vehicleRegistration'] || {};
    const rcStatus = rc.status === this.verificationStatus.ACCEPTED ? this.verificationStatus.VERIFIED : rc.status;
    const isRcAccepted = rc.status === this.verificationStatus.ACCEPTED ? true : false;
    const message = rc.message || '';
    const rcData = rc['data'] || {};
    const verifiedBy = rc.verifiedBy || '';
    const verifiedOn = rc.verifiedOn || '';

    this.vehicleRegStatus = { data : rcData, status: rcStatus, message: message,
                              isRcAccepted: isRcAccepted,
                              verifiedBy: verifiedBy,
                              verifiedOn: verifiedOn
                             };
  }

  updateShowVehicleNumberChangeToggle(evt) {
    this.showVehicleNumberChangeToggle = evt;
  }

  permitApproved(evt) {
    this.isPermitApproved = true;
    this.isPermitRejected = false;
    if (evt === false) {
      this.isPermitApproved = false;
    }
  }

  permitRejected(evt) {
    this.isPermitApproved = false;
    this.isPermitRejected = true;
    if (evt === false) {
      this.isPermitRejected = false;
    }
  }

  fitnessApproved(evt) {
    this.isFitnessApproved = true;
    this.isFitnessRejected = false;
    if (evt === false) {
      this.isFitnessApproved = false;
    }
  }

  fitnessRejected(evt) {
    this.isFitnessApproved = false;
    this.isFitnessRejected = true;
    if (evt === false) {
      this.isFitnessRejected = false;
    }
  }

  insuranceApproved(evt) {
    this.isInsuranceApproved = true;
    this.isInsuranceRejected = false;
    if (evt === false) {
      this.isInsuranceApproved = false;
    }
  }

  insuranceRejected(evt) {
    this.isInsuranceApproved = false;
    this.isInsuranceRejected = true;
    if (evt === false) {
      this.isInsuranceRejected = false;
    }
  }

  // createPanStatus(docStatus) {
  //   const pan = docStatus['panCard'] || {};
  //   if (pan.status) {
  //     const panStatus = pan.status === this.verificationStatus.ACCEPTED ? this.verificationStatus.VERIFIED : pan.status;
  //     const isPanAccepted = pan.status === this.verificationStatus.ACCEPTED;
  //     const message = pan.message || '';
  //     const panData = pan['data'] || {};

  //     this.panStatus = { data : panData, status: panStatus, message: message,
  //       isPanAccepted: isPanAccepted,
  //       // verifiedBy: verifiedBy,
  //       // verifiedOn: verifiedOn
  //     };
  //   }
  // }

  // createAadharStatus(docStatus) {
  // const aadhar = docStatus['aadhar'] || {};
  // if (aadhar.status) {
  //   const aadharStatus = aadhar.status === this.verificationStatus.ACCEPTED ? this.verificationStatus.VERIFIED : aadhar.status;
  //   const isAadharAccepted = aadhar.status === this.verificationStatus.ACCEPTED;
  //   const message = aadhar.message || '';
  //   const aadharData = aadhar['data'] || {};
  //   const verifiedBy = aadhar.verifiedBy || '';
  //   const verifiedOn = aadhar.verifiedOn || '';

  //   this.aadharStatus = { data : aadharData, status: aadharStatus, message: message,
  //     isAadharAccepted: isAadharAccepted,
  //     verifiedBy: verifiedBy,
  //     verifiedOn: verifiedOn
  //   };
  // }
  // }
}
