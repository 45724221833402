import { Injectable } from '@angular/core'

import { MyHttpClient } from '../../http-client'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'

import { Observable, throwError } from 'rxjs'
import { catchError, retry } from 'rxjs/operators'
import { HttpHeaders } from '@angular/common/http'

@Injectable()
export class RegistrationCardService {
  server: string = environment.SERVER
  updateRemarksUrl = process.env.SERVER + '/api/update/rc/remarks'
  updateVehicleNumberUrl = process.env.SERVER + '/api/update/rc/vehicleNumber'
  updateVehicleColorUrl = process.env.SERVER + '/api/update/rc/vehicleColor'
  updateVehicleModelUrl = process.env.SERVER + '/api/update/rc/vehicleModel'
  updateRejectRemarksUrl = process.env.SERVER + '/api/update/rc/rejectRemarks'

  getVehiclesListURL = process.env.SERVER + '/api/get/vehicleModel';
  rotateUrl = process.env.SERVER + '/api/imageRotate'
  deleteAllUrl = process.env.SERVER + '/api/profile/deleteTempFiles'
  updateImageUrl = process.env.SERVER + '/api/update/rc/rcImage'
  createRCUrl = process.env.SERVER + '/api/create/rc'
  updateRCUrl = process.env.SERVER + '/api/update/rc'
  assignVehicleUrl = process.env.SERVER + '/api/assign/vehicle'
  checkVehicleNumber = process.env.SERVER + '/api/check/vehicle'

  updateVehicleFuelTypeUrl =
    process.env.SERVER + '/api/update/rc/vehicleFuelType'
  updateVehicleOwnershipUrl =
    process.env.SERVER + '/api/update/rc/vehicleOwnership'
  updateVehicleOwnerNameUrl =
    process.env.SERVER + '/api/update/rc/vehicleOwnerName'
  updateVehicleCategoryUrl =
    process.env.SERVER + '/api/update/rc/vehicleCategory'
  updateVehicleSeatCapacityUrl =
    process.env.SERVER + '/api/update/rc/vehicleSeatCapacity'
  updateVehicleRegistrationDateUrl =
    process.env.SERVER + '/api/update/rc/registrationDate'
  rcDefaulterUrl = 
    process.env.SERVER + '/api/rcDefaulter';

  constructor(private http: MyHttpClient) {}
  
  getVehiclesList(modeId) {
    return this.http.get(`${this.getVehiclesListURL}?modeId=${modeId}`).pipe(catchError(this.handleError));
  }

  fetchRcDefaulter(riderId) {
    return this.http.get(`${this.rcDefaulterUrl}/${riderId}`).pipe(catchError(this.handleError));
  }
   
  submitRC(id, vehicleData) {
    return this.http
      .post(this.updateRCUrl, {
        vehicle: vehicleData,
        userId: id,
      })
      .pipe(catchError(this.handleError))
  }

  assignVehicle(id, vehicleId) {
    return this.http
      .post(this.assignVehicleUrl, {
        vehicle: vehicleId,
        userId: id,
      })
      .pipe(catchError(this.handleError))
  }

  updateRemarks(id, remarks) {
    const payload = {
      userId: id,
      data: remarks,
    }
    return this.http
      .post(this.updateRemarksUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleNumber(id, remarks) {
    const payload = {
      userId: id,
      data: remarks,
    }
    return this.http
      .post(this.updateVehicleNumberUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleColor(id, color) {
    const payload = {
      userId: id,
      data: color,
    }
    return this.http
      .post(this.updateVehicleColorUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleModel(id, vehicleModel) {
    const payload = {
      userId: id,
      data: vehicleModel,
    }
    return this.http
      .post(this.updateVehicleModelUrl, payload)
      .pipe(catchError(this.handleError))
  }

  deleteTempFiles() {
    return this.http.get(this.deleteAllUrl).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )
  }

  rotateImage(link, degrees) {
    return this.http
      .post(this.rotateUrl, {
        link: link,
        degrees: degrees,
      })
      .pipe(catchError(this.handleError))
  }

  updateImage(image, url, id, imageType, view) {
    let uploadUrl
    uploadUrl = this.updateImageUrl

    return this.http
      .post(uploadUrl, {
        data: image,
        url: url,
        userId: id,
        mode: 'rotate',
        folder: imageType,
        view: view
      })
      .pipe(catchError(this.handleError))
  }

  checkIfVehicleExist(vehicleId, vehicleNumber, userId) {
    return this.http
      .get(`${this.checkVehicleNumber}/${userId}/${vehicleId}/${vehicleNumber}`)
      .pipe(catchError(this.handleError))
  }

  updateVehicleFuelType(id, fuelType) {
    const payload = {
      userId: id,
      data: fuelType,
    }
    return this.http
      .put(this.updateVehicleFuelTypeUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleOwnership(id, ownership) {
    const payload = {
      userId: id,
      ownership: ownership,
    }
    return this.http
      .put(this.updateVehicleOwnershipUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleOwnerName(id: string, ownerName: string) {
    const payload = {
      userId: id,
      ownerName,
    }
    return this.http
      .put(this.updateVehicleOwnerNameUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleCategory(id: string, vehicleCategory: string) {
    const payload = {
      userId: id,
      vehicleCategory,
    }
    return this.http
      .put(this.updateVehicleCategoryUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleSeatCapacity(id: string, seatCapacity: number) {
    const payload = {
      userId: id,
      seatCapacity,
    }
    return this.http
      .put(this.updateVehicleSeatCapacityUrl, payload)
      .pipe(catchError(this.handleError))
  }

  updateVehicleRegistrationDate(id: string, registrationDate: string) {
    const payload = {
      userId: id,
      registrationDate,
    }
    return this.http
      .put(this.updateVehicleRegistrationDateUrl, payload)
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message)
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          },
        }
      }
    }
    return throwError(customError || error)
  }
}
