import { Injectable } from '@angular/core';

import { MyHttpClient } from '../../http-client';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment'


import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';



@Injectable()
export class BasicInfoService {
  server: string = environment.SERVER;

  unsubscribeUrl = process.env.SERVER + '/api/unsubscribe';
  riderLogsUrl = process.env.SERVER + '/api/riderLogs';
  getUsersUrl = process.env.SERVER + '/api/getUsersByIds';

  fleetUrl = `${process.env.SERVER}/api/fleet`;
  captainUrl = `${process.env.SERVER}/api/captain`;
  agentDispositionUrl = `${process.env.SERVER}/api/agent/disposition`
  captainInstalledAppsUrl = `${process.env.SERVER}/api/rider/installedApps`

  campaignUrl = `${process.env.SERVER}/api/b2bCampaign`;

  b2bEligibileUrl = `${process.env.SERVER}/api/userPreference/b2b`;
  r2AWhatsappMessageUrl = `${process.env.SERVER}/api/captain/r2a/sendWhatsappMessage`;


  constructor(private http: MyHttpClient) { }

  updateFirstName(name, id) {
    const payload = { 'firstName': name, 'userId': id};
    return this.http.post(`${this.captainUrl}/basic/firstName`, payload).pipe(catchError(this.handleError));
  }

  updateLastName(name, id) {
    const payload = { 'lastName': name, 'userId': id};
    return this.http.post(`${this.captainUrl}/basic/lastName`, payload).pipe(catchError(this.handleError));
  }

  updateMobile(number, id) {
    const payload = {'newMobileNumber': number, 'userId': id};
    return this.http.post(`${this.captainUrl}/basic/mobile`, payload).pipe(catchError(this.handleError));
  }

  updateGender(gender, id) {
    const payload = {'gender': gender, 'userId': id};
    return this.http.post(`${this.captainUrl}/basic/gender`, payload).pipe(catchError(this.handleError));

  }

  updateBirthDate(birthDate, id) {
    birthDate.setHours(birthDate.getHours() + 5);
    birthDate.setMinutes(birthDate.getMinutes() + 30);
    const payload = {
      'birthDate' : birthDate,
      'userId' : id
    };
    return this.http.post(`${this.captainUrl}/basic/birthDate`, payload).pipe(catchError(this.handleError));
  }
  
  getLastDisposition(mobile) {
    return this.http.get(`${this.agentDispositionUrl}/${mobile}`).pipe(catchError(this.handleError));
  }

  getFleetManagers(fleetType, fleetId, userId) {
    if (fleetId) {
      return this.http.get(`${this.fleetUrl}/details/fleetId/${fleetId}/${userId}`).pipe(catchError(this.handleError));
    } else if (fleetType) {
      return this.http.get(`${this.fleetUrl}/details/${fleetType}/${userId}`).pipe(catchError(this.handleError));
    }
  }

  attachFleetToRider(riderId, fleetId, userId) {
    const payload = {
      "source": "supply-dashboard",
      "riderId": riderId,
      "fleetId": fleetId
    } 
    return this.http.post(`${this.fleetUrl}/attachFleetToRider/${userId}`, payload).pipe(catchError(this.handleError));
  }

  detachFleetFromRider(riderId, fleetId, userId, modeId) {
    const payload = {
      "source": "supply-dashboard",
      "riderId": riderId,
      "fleetId": fleetId,
      "modeId": modeId,
    }
    return this.http.post(`${this.fleetUrl}/detachFleetFromRider/${userId}`, payload).pipe(catchError(this.handleError));
  }

  getCaptainInstalledApps(mobile) {
    return this.http.get(`${this.captainInstalledAppsUrl}/${mobile}`).pipe(catchError(this.handleError));
  }

  logoutCaptain(id) {
    const payload = {'userId': id};
    return this.http.post(`${this.captainUrl}/logout`, payload).pipe(catchError(this.handleError));
  }
  riderLogs(id, limit, offset, filter) {
    return this.http.post(`${this.riderLogsUrl}/${id}/${limit}/${offset}`, filter).pipe(catchError(this.handleError));
  }
  getUserInfo(userId) {
    const payload = { userId: userId };
    return this.http.post(this.getUsersUrl, payload).pipe(catchError(this.handleError));
  }

  assureCampaignLogs(riderId){
    return this.http.get(`${this.campaignUrl}/assureCampaign/${riderId}`).pipe(catchError(this.handleError)) 
  }

  getB2bEligibility(riderId){
    const url = `${this.b2bEligibileUrl}/${riderId}`
    return this.http.get(url).pipe(catchError(this.handleError)) 
  }
  getUserPreference(userId, cityId) {
    const payload = { userId, cityId };
    return this.http.post(`${this.captainUrl}/userPreferences`, payload)
                .pipe(catchError(this.handleError));
  }
  updateMode(modeId, userId, cityId) {
    const payload = { userId, modeId, cityId };
    return this.http.put(`${this.captainUrl}/mode`, payload).pipe(catchError(this.handleError));
  }

  getPhoneNumberChangeRequest(id) {
    return this.http.get(`${this.captainUrl}/change-request/${id}`).pipe(catchError(this.handleError));
  }

  approvePNR(id) {
    return this.http.post(`${this.captainUrl}/change-request/approve`, {riderId: id}).pipe(catchError(this.handleError));
  }

  rejectPNR(id, remarks) {
    return this.http.post(`${this.captainUrl}/change-request/reject`, {riderId: id, remarks}).pipe(catchError(this.handleError));
  }
  sendR2AWhatsappMessage(userId) {
    return this.http.post(this.r2AWhatsappMessageUrl, {userId}).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let customError: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (typeof error === 'string') {
        customError = {
          error: {
            message: error,
          }
        };
      }
    }
    return throwError(customError || error);
  }
}
