import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

import { ConstantsService } from '../../constants.service';
import { GstCertificateService } from './gst-certificate.service';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { LogService } from '../../log-service.service';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
const _ = require('lodash');


@Component({
  selector: 'app-gst-certificate',
  template: require('./gst-certificate.component.html'),
  providers: [ConstantsService, GstCertificateService, ToastMessage, LogService]
})
export class GstCertificateComponent implements OnChanges, OnInit {

  constructor(
    private constantsService: ConstantsService,
    private toastMessage: ToastMessage,
    private gstService: GstCertificateService,
    private spinner: Ng4LoadingSpinnerService,
    private logService: LogService,
  ) { }

  public screenBreakpoint = false;
  public imageUrl: any;

  public gstRejectReasons = this.constantsService.gstRejectReasons;
  public showApproveButton = false;
  public updateImageEnabled = false;

  @Input() userIdentity: any = {};
  @Input() merchantInfo: any;
  @Input() isValidToEditMerchant: any;
  @Output() gstApproval: EventEmitter<any> = new EventEmitter();

  public merchantGstInfo: any;
  public merchantGSTIntitalInfo: any;
  public documentTypes = this.constantsService.getImageTypes;
  public gstS3Filepath: any;
  public userId: any;
  public gstFormat: any;
  public gstRules = this.constantsService.gstRules;
  public gstLogNumber = 8;

  ngOnInit() {
    this.tooltipData();
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').show();
      }
    }
    this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.merchantInfo && changes.merchantInfo.currentValue) {
      this.merchantGstInfo = changes.merchantInfo.currentValue;
      this.userId = this.merchantGstInfo && this.merchantGstInfo.user && this.merchantGstInfo.user._id;
      this.merchantGSTIntitalInfo = _.cloneDeep(this.merchantGstInfo);
      if (this.merchantGstInfo.gst && this.merchantGstInfo.gst.filePath) {
        this.gstS3Filepath = this.merchantGstInfo.gst.filePath;
        this.getGstImage();
      }
      if (this.merchantGstInfo.gst.filePath && this.merchantGstInfo.gst.approved === false) {
        this.showApproveButton = true;
      }
    }
  }

  tooltipData() {
    this.gstFormat = `<div class='table-data'>` + this.getGSTRules() + '</div>';
  }

  getGSTRules() {
    let data = '';
    for (const values of this.gstRules) {
      if (values) {
        data += `<div>` + values + `</div>`;
      }
    }
    return data;
  }

  addEventsToLogService(oldData, newData) {
    this.logService.insertEvent(
      this.gstLogNumber,
      this.merchantGstInfo._id,
      oldData, newData
    ).subscribe();
  }

  updateGstUrl(event: any) {
    if (event.mode === 'update') {
      this.showApproveButton = true;
      this.merchantGstInfo.gst.filePath = event.filePath;
      this.gstS3Filepath = event.filePath || '';
      this.toastMessage.openSnackBarSuccess('Image Saved. Please reload using Shift + R .');
      this.getGstImage();
    }

    if (event.mode === 'delete') {
      this.gstApproval.emit({approved: false, documentType: this.documentTypes.gstCertificate});
      this.merchantGstInfo.gst.filePath = '';
      this.imageUrl = '';
      this.showApproveButton = false;
      this.updateImageEnabled = false;
      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').hide();
        }
      }
    }
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageUrl = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getGstImage() {
    this.spinner.show();
    this.gstService.getGstImage(this.merchantGstInfo._id, this.gstS3Filepath).subscribe((result: Blob) => {
      this.createImageFromBlob(result);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      const errorMessage = error && error.error && error.error.message || 'Could not update gst number';
      this.toastMessage.openSnackBarError(errorMessage);
    });
  }

  rotateImage(link, degrees) {

  }

  updateGSTCertificate() {

  }

  validateGstNumber() {
    const gstNumber = this.merchantGstInfo.gst.gstNumber;
    if (gstNumber.length > 15 || gstNumber.length < 15) {
      this.toastMessage.openSnackBarError('Gst number should have 15 characters. Please try again');
      return;
    }

    const stateCode = gstNumber.substring(0, 2); // Numeric
    const panAlpha = gstNumber.substring(2, 7); // alpha
    const panDigit = gstNumber.substring(7, 11); // numeric
    const panFinal = gstNumber.substring(11, 12); // alpha
    const entityNumber = gstNumber.substring(12, 13); // number
    const checkSumDigit = gstNumber.substring(13, 15); // alpha

    const onlyAlphaRegex = /^[A-Za-z]+$/;
    const onlyNumericRegex = /^[0-9]+$/;

    if (!onlyNumericRegex.test(stateCode)) {
      this.toastMessage.openSnackBarError('First two characters should be number. Please try again');
      return;
    }
    if (!onlyAlphaRegex.test(panAlpha)) {
      this.toastMessage.openSnackBarError('Characters from 3 to 7 should be alphabets. Please try again');
      return;
    }
    if (!onlyNumericRegex.test(panDigit)) {
      this.toastMessage.openSnackBarError('Characters from 8 to 11 should be number. Please try again');
      return;
    }
    if (!onlyAlphaRegex.test(panFinal)) {
      this.toastMessage.openSnackBarError('12th character should be alphabet. Please try again');
      return;
    }
    if (!onlyNumericRegex.test(entityNumber)) {
      this.toastMessage.openSnackBarError('13th character should be number. Please try again');
      return;
    }
    if (!onlyAlphaRegex.test(checkSumDigit)) {
      this.toastMessage.openSnackBarError('Characters from 14 to 15 should be alphabets. Please try again');
      return;
    }
    this.updateGSTNumber();
  }

  updateGSTNumber() {
    if (this.merchantGSTIntitalInfo.gst.gstNumber === this.merchantGstInfo.gst.gstNumber) {
      this.toastMessage.openSnackBarError('Please change the value to update');
    } else {
      if (!this.merchantGstInfo.gst.gstNumber) {
        this.toastMessage.openSnackBarError('Store name is a mandatory field');
      } else {
        this.gstService.updateGstNumber(
          this.merchantGstInfo.gst.gstNumber, this.merchantGstInfo._id
        ).subscribe(response => {
          if ( response['data'] && response['data'].data.nModified > 0) {
            this.addEventsToLogService(
              { gstNumber: this.merchantGSTIntitalInfo.gst.gstNumber },
              { gstNumber: this.merchantGstInfo.gst.gstNumber }
            );
            this.merchantGSTIntitalInfo.gst.gstNumber = this.merchantGstInfo.gst.gstNumber;
            this.toastMessage.openSnackBarSuccess('Gst number is updated');
            if (!this.showApproveButton) { this.showApproveButton = true; this.disApproveGst(); }
          } else {
            this.toastMessage.openSnackBarError('Could not update Gst number');
          }
        },
        error => {
          const errorMessage = error && error.error && error.error.message || 'Could not update Gst number';
          this.toastMessage.openSnackBarError(errorMessage || 'Could not update Gst number');

        });
      }
    }
  }

  disApproveGst() {
    const rejectReason = this.constantsService.rejectReasonForGstNumberChange;
    this.gstService.disapproveGst(
      this.merchantGstInfo._id, rejectReason, this.userIdentity
    ).subscribe(response => {
      if (response['data'] && response['data'].data && response['data'].data.nModified > 0) {
        this.gstApproval.emit({approved: false, documentType: this.documentTypes.gstCertificate});
       }
    }, error => {
      const errorMessage = error && error.error && error.error.message || 'Failed to disapprove Gst';
      this.toastMessage.openSnackBarError(errorMessage);
    });
  }

  approveGST() {
    const storeGst = this.merchantGstInfo.gst || {};
    if (!storeGst.filePath) {
      this.toastMessage.openSnackBarError('Please upload gst certificate before approving');
      return;
    }
    if (!storeGst.gstNumber) {
      this.toastMessage.openSnackBarError('Please enter Gst number before approving');
      return;
    }

    this.gstService.approveGst(this.merchantGstInfo._id).subscribe(response => {
     if (response['data'] && response['data'].data && response['data'].data.nModified > 0) {
      this.addEventsToLogService(
        { gstApproved: false },
        { gstApproved: true }
      );
      this.toastMessage.openSnackBarSuccess('GST certificate approved successfully');
      this.gstApproval.emit({approved: true, documentType: this.documentTypes.gstCertificate});
      this.showApproveButton = false;
     }
    },
    error => {
      this.showApproveButton = true;
      const errorMessage = error && error.error && error.error.message || 'Failed to approve GST';
      this.toastMessage.openSnackBarError(errorMessage || 'Failed to approve GST');
    });
  }

  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;
  }

}
