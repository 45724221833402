import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  type: string;
  reason: string;
  buttonText: string;
  data: {},
  styles: any;
  options: any;
  notes?: string[];
  cancelText: any
}

@Component({
  selector: 'app-pop-up-message',
  template: require('./pop-up-message.component.html')
})
export class PopUpMessageComponent implements OnInit {

  public selectedReason: any;
  @Output() deactivateEvent: EventEmitter<any> = new EventEmitter();

  public popupType: any;
  public format: any;
  public docsInRejection = [];
  public changeInDocDetails = [];
  public cancelButtonText: any;
  public note: any;
  public showSingleButton: any;

  constructor(
    public dialogRef: MatDialogRef<PopUpMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInfo: DialogData
  ) { }

  ngOnInit() {
    this.popupType = this.dialogInfo.reason
    this.format = this.dialogInfo['data'] && this.dialogInfo.data['type'];
    if (this.popupType === 'bulkRejectDocs' || this.popupType === 'unProcessedDocs') {
      this.docsInRejection = this.dialogInfo.data['info']
    }
    if (this.popupType === 'changeDocDetails') {
      this.changeInDocDetails = this.dialogInfo.data['info']
    }
    this.cancelButtonText = this.dialogInfo.cancelText || 'Cancel'
    this.note = this.dialogInfo.data && this.dialogInfo.data['note'] || '';
    this.showSingleButton = this.dialogInfo['singleButton'] || false;
  }

  takeAction(eventTriggered) {
    if (eventTriggered === 'activate') {
      this.dialogRef.close(true);
    } else if (eventTriggered === 'deactivate') {
      this.deactivateEvent.emit(this.selectedReason);
    } else if (eventTriggered === 'removeServices') {
      this.dialogRef.close(true);
    } else if (eventTriggered === 'bulkRejectDocs') {
      this.dialogRef.close(true);
    } else if (eventTriggered === 'unProcessedDocs') {
      this.dialogRef.close(false);
    } else if (eventTriggered === 'modeChange') {
      this.dialogRef.close(true);
    } else if (eventTriggered === 'changeDocDetails') {
      this.dialogRef.close(true);
    } else if (eventTriggered === 'attachToFleet') {
      this.dialogRef.close(true);
    } else if (eventTriggered === 'detachFromFleet') {
      this.dialogRef.close(true);
    } else if (eventTriggered === 'activateFleet') {
      this.dialogRef.close(true);
    } else  {
      this.dialogRef.close(false);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
