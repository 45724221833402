import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingPageService } from '../onboarding-page/onboarding-page.service';

@Component({
  selector: 'app-verification-status',
  template: require('./verification-status.component.html')
})
export class VerificationStatusComponent implements OnInit {

  constructor(private _onboardingService: OnboardingPageService) { }
  public verificationResponse : Object;
  public verificationStatus : String = "N/A";
  public showSpinner: Boolean = true;
  @Input() riderId: String;
  @Input() docType: String;
  @Input() asyncRefreshTrigger: Observable<void>;

  @Output() verificationUpdate: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.onAsyncRefreshTrigger();
    this.asyncRefreshTrigger.subscribe(() => setTimeout(() => this.onAsyncRefreshTrigger(),100));
  }

  onAsyncRefreshTrigger() {
    this.showSpinner = true;
    this._onboardingService.getDocVerificationStatus(this.riderId,this.docType).subscribe(response => {
      this.verificationResponse = response;
      this.verificationStatus = response['status'];
      this.verificationUpdate.emit(this.verificationResponse);
      if(this.verificationStatus === 'In Progress') {
        setTimeout(() => {this.onAsyncRefreshTrigger()}, 5000);
      } else {
        this.showSpinner = false;
      }
    },err => {
      this.showSpinner = false;
      console.error(err);
    });
  }
}
