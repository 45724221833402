import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-doc-rejection-panel',
  template: require('./doc-rejection-panel.component.html')
})
export class DocRejectionPanelComponent implements OnInit {

  @Input() rejectedDocsToShowInPanel: any;

  @Output() removeDocumentFromRejectionList: EventEmitter<any> = new EventEmitter();
  @Output() viewDetailsForRejectDocs: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public heading = 'Please confirm for the rejected documents';

  ngOnInit() {
  }

  removeDocumentFromRejection(document) {
    this.removeDocumentFromRejectionList.emit(document);
  }

  viewDetailsForRejection() {
    this.viewDetailsForRejectDocs.emit();
  }

}
