import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ImageViewService } from './image-view.service';
import { FormControl, Validators } from '@angular/forms';

import { LogService } from '../log-service.service';

import { ToastMessage } from '../toast-message/toast-message.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ConstantsService } from '../constants.service';


@Component({
  selector: 'image-view',
  template: require('./image-view.component.html'),
  providers: [ImageViewService, LogService, ConstantsService]
})
export class ImageViewComponent implements OnInit, OnChanges {
  // rejectReasonFormControl = new FormControl('', [
  //   Validators.maxLength(50)
  // ]);

  @Input() imgUrl: any;
  @Input() imgContent: any;
  @Input() userId: any;
  @Input() imageType: any;
  @Input() view: any;
  @Input() rejectRemarks: any;
  @Input() autoApproved: any;
  @Output() newUrl: EventEmitter<any> = new EventEmitter();
  @Output() rejectDocument: EventEmitter<any> = new EventEmitter();
  @Input() userIdentity = {
    phone: '',
    _id: '',
  };
  @Input() type = '';
  @Input() allowEditing: any;
  @Input() gstRejectReasons: any;

  @Input() isProfileRejected: any;
  @Input() isDlRejected: any;
  @Input() isRCRejected: any;
  @Input() isPanRejected: any;
  @Input() isAadharRejected: any;
  @Input() isImageOptional: boolean;
  @Input() isVerifiedViaOtp: boolean;
  @Input() isAadharInKyc: boolean;
  @Input() hasDocNumber: boolean;
  @Input() docNumber: string;
  @Input() isPermitRejected: any;
  @Input() isPermitApproved: any;
  @Input() isInsuranceRejected: any;
  @Input() isInsuranceApproved: any;
  @Input() isFitnessRejected: any;
  @Input() isFitnessApproved: any;
  @Input() showVehicleNumberChangeToggle: boolean;

  public displayUrl: any;
  public input: any;
  public fileName: any;
  public imageToUpload: any;
  public selectedFiles = '';
  public showUpdateButton = false;
  public showImage = false;
  public showRejectReason = false;
  public imageUploaded = false;
  public rejectReason: any;
  public rejectRemarksList = [];
  public dlRejectReasons = [];
  public ppRejectReasons = [];
  public rcRejectReasons = [];
  public rcWithoutImageRejectReasons = [];
  public rcWithoutNumberChangeDefaulterRejectReasons = [];
  public panRejectReasons = [];
  public aadharRejectReasons = [];
  public cabRejectionReasons = [];
  public aadhaarVerifiedViaOtpRejectReasons = [];

  public imageTypes: any;
  private S3_URL_REGEX: RegExp;
  constructor(private _imageViewService: ImageViewService,
    private _toastMessage: ToastMessage,
    private spinnerService: Ng4LoadingSpinnerService,
    private _logService: LogService,
    private constantsService: ConstantsService) {
    this.refreshDisplayImage();
    this.imageTypes = this.constantsService.getImageTypes;
    this.S3_URL_REGEX = new RegExp(/(s3-|s3\.)?(.*)\.amazonaws\.com/);
    const docRejectionReasons = constantsService.docRejectionReasons;
    this.dlRejectReasons = docRejectionReasons.dl;
    this.ppRejectReasons = docRejectionReasons.pp;
    this.rcRejectReasons = docRejectionReasons.rc;
    this.cabRejectionReasons = docRejectionReasons.cab;
    this.rcWithoutImageRejectReasons = docRejectionReasons.rcWithoutImage;
    this.rcWithoutNumberChangeDefaulterRejectReasons = docRejectionReasons.rcWithoutNumberChangeDefaulterRejectReasons;
    this.panRejectReasons = docRejectionReasons.panAadhar;
    this.aadharRejectReasons = docRejectionReasons.panAadhar;
    this.aadhaarVerifiedViaOtpRejectReasons = docRejectionReasons.aadhaarVerifiedViaOtp;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.imgUrl) {
      this.setImageUrl(changes.imgUrl.currentValue);
    }
    if (changes.rejectRemarks) {
      this.rejectReason = changes.rejectRemarks.currentValue;
    }
    if (this.displayUrl) {
      this.imageUploaded = true;
    }
    if (changes.imageType) {
      this.rejectRemarksList = [];
      if ( this.imageTypes.profilePicture === changes.imageType.currentValue) {
        this.rejectRemarksList = this.ppRejectReasons;
      } else if ( this.imageTypes.driverLicense === changes.imageType.currentValue) {
        this.rejectRemarksList = this.dlRejectReasons;
      } else if ( (this.imageTypes.registrationCard === changes.imageType.currentValue) && !this.isImageOptional) {
        this.rejectRemarksList = this.rcRejectReasons;
      } else if ( (this.imageTypes.registrationCard === changes.imageType.currentValue) && this.isImageOptional) {
        this.rejectRemarksList = this.rcWithoutImageRejectReasons;
        this.fetchRcDefaulter(changes.userId.currentValue)
      } else if ( this.imageTypes.gstCertificate === changes.imageType.currentValue) {
        this.rejectRemarksList = this.gstRejectReasons;
      } else if ( this.imageTypes.panCard === changes.imageType.currentValue) {
        this.rejectRemarksList = this.panRejectReasons;
      } else if ( this.imageTypes.aadhar === changes.imageType.currentValue) {
        this.rejectRemarksList = this.aadharRejectReasons;
      } else if (this.imageTypes.permit === changes.imageType.currentValue || 
                 this.imageTypes.insurance === changes.imageType.currentValue ||
                 this.imageTypes.fitness === changes.imageType.currentValue){
        this.rejectRemarksList = this.cabRejectionReasons;
      }
    }
    if (changes.isVerifiedViaOtp && (this.type === this.imageTypes.aadhar)){
      this.rejectRemarksList = this.aadhaarVerifiedViaOtpRejectReasons;
    }
    if (changes) {
      if (changes.isDlRejected && changes.isDlRejected.currentValue) {
        this.deleteImage();
      }
      if (changes.isRCRejected && changes.isRCRejected.currentValue) {
        this.deleteImage();
      }
      if (changes.isPanRejected && changes.isPanRejected.currentValue) {
        this.deleteImage();
      }
      if (changes.isAadharRejected && changes.isAadharRejected.currentValue) {        
        this.deleteImage();
      }
      if(changes.isPermitRejected && changes.isPermitRejected.currentValue){
        this.deleteImage();
      }
      if(changes.isFitnessRejected && changes.isFitnessRejected.currentValue){
        this.deleteImage();
      }
      if(changes.isInsuranceRejected && changes.isInsuranceRejected.currentValue){
        this.deleteImage();
      }
    }
  }

  ngOnInit() {
  }

  onUploadStateChanged(evt) {
    let response;
    if (evt === true) {
      this._imageViewService.deleteTempFiles().subscribe(res => {
        response = res;
      });
    }
  }

  onUploadFinished(evt) {
    this.fileName = evt.file.name + ' has been uploaded.';
    this.imageToUpload = evt.src;
    this.showUpdateButton = true;
    this.imageUploaded = true;
    this.refreshDisplayImage();
  }

  private refreshDisplayImage() {
    if (window) {

      window['jQuery'](document).ready(function () {
        window['jQuery']('#displayImage').blowup({
          'width': 400,
          'height': 400,
          'border': '1px solid black',
          'round': false
        });
      });

    }
  }

  uploadImage() {
    let response;
    this.spinnerService.show();
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }

    this._imageViewService.updateImage(
      this.imageToUpload, this.imgUrl, this.userId, this.imageType, this.view, this.fileName, this.userIdentity, null, this.isImageOptional).subscribe(res => {
      response = res;
      this.showRejectReason = false;
      const metaData = Object.assign({}, this.userIdentity, { type: this.type, status: 'updated'});
             this._logService.insertEvent(6, this.userId, {uploadImage : false},
                              {uploadImage: true, imgUrl: this.imgUrl,
                                imageType: this.imageType, view: this.view},
                              metaData).subscribe();
      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').show();
        }
      }
      this.spinnerService.hide();
      this._imageViewService.deleteTempFiles().subscribe(res => {
      },
      error => {
        this.spinnerService.hide();
        const errorMessage = error.error && error.error.message ? error.error.message : 'Could not upload image';
        this._toastMessage.openSnackBarError(errorMessage);
      });
      this.displayUrl = response.info ? this.setImageUrl(response.info.url) : '';
      const filePath = response.data ? response.data.filePath : '';
      this.newUrl.emit({
        'mode': 'update',
        'url': this.displayUrl,
        'view': this.view,
        filePath: filePath
      });
      this.refreshDisplayImage();
    }, error => {
      this.spinnerService.hide();
        const errorMessage = error.error && error.error.message ? error.error.message : 'Could not upload image';
        this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  addRejectedDocsToQueue() {
    console.log(this.imageType, this.view);
    if (this.rejectReason && this.rejectReason !== this.rejectRemarks) {
      this.rejectDocument.emit({
        imageType: this.imageType, view: this.view, userId: this.userId,
        isAutoApproved: this.autoApproved, data: this.rejectReason, isImageOptional: this.isImageOptional,
        isVerifiedViaOtp: this.isVerifiedViaOtp,
        isAadharInKyc: this.isAadharInKyc
      });
    } else {
      this._toastMessage.openSnackBarError('Please select the reason for document rejection.');
    }
  }

  deleteImage() {
    this.fileName = '';
    this.imageUploaded = false;
    this.showUpdateButton = false;
    this._toastMessage.openSnackBarSuccess('Image has been deleted');
    this.newUrl.emit({
      'mode': 'delete',
      'url': '',
      'view': this.view
    });
  }

  async imageLoad(evt: any) {
    const imageComplete = await evt && evt.path && evt.path[0].complete;
    if (imageComplete) {
      this.spinnerService.hide();
    }
  this.refreshDisplayImage();
  }

  async handeImageLoadError(evt: any) {
    this.spinnerService.hide();
    this._toastMessage.openSnackBarError('couldnt load image');
  }

  setImageUrl(url) {
    if (!url) {
      this.displayUrl = "";
      return;
    }
    this.spinnerService.show();
    if (this.isS3Url(url)) {
      this.spinnerService.hide();
      this.displayUrl = url;
      return;
    }
    const imageUrl = new URL(url)
    this._imageViewService.getPreSignedImageUrl(imageUrl.pathname + imageUrl.search)
        .subscribe(preSignedUrl => {
              this.spinnerService.hide();
              this.displayUrl = preSignedUrl['url'];
            }
        , error => {
            this.spinnerService.hide();
            const errorMessage = error.error && error.error.message ? error.error.message : 'Could not load image';
            this._toastMessage.openSnackBarError(errorMessage);
          });

  }

  isS3Url(url) {
    return this.S3_URL_REGEX.test(url);
  }

  handleImageUpload() {
    if (window && window['jQuery']) {
      window['jQuery']('input[type="file"]').click();
    }
  }

  fetchRcDefaulter(riderId){
    let result = this._imageViewService.fetchRcDefaulter(riderId).subscribe(
      response => {
        let rcDefaulter = response['defaulter'];
        let rcDefaulterNumberChanged = response['rcNumberChanged'] ? response['rcNumberChanged'] : false;
        let rcRejected = response['rejected'] ? response['rejected'] : false;
        if (rcDefaulter && !rcDefaulterNumberChanged && !rcRejected) {
          this.rejectRemarksList = this.rcWithoutNumberChangeDefaulterRejectReasons
        }
      },
      error => {
        const errorMessage = error && error.error && error.error.message || 'Could not fetch balance';
        this._toastMessage.openSnackBarError(errorMessage);
      }
    );
  }
}
