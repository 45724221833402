import { Component, OnInit } from '@angular/core';
import { RiderListService } from '../rider-list/rider-list.service';
import {FormControl, Validators} from '@angular/forms';
import { ToastMessage } from '../toast-message/toast-message.service';
import {Router} from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { Observable } from 'rxjs/Rx';
import { OnboardingPageService } from '../onboarding-page/onboarding-page.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from '../data.service';
import {LogService} from '../log-service.service';
declare let navigator;
function getCookie(name, position) {
  const cookies = document.cookie.split(';');
  let finalCookie;
    const userCookie = cookies.find(function (item) {
      return item.includes(name);
    });
    if (userCookie) {
      finalCookie = Object.values(JSON.parse(userCookie))[position];
    }
    return finalCookie;
}
@Component({
  selector: 'app-search-rider',
  template: require('./search-rider.component.html'),
  providers: [RiderListService, ToastMessage, OnboardingPageService, LogService]
})
export class SearchRiderComponent implements OnInit {
  mobileFormControl = new FormControl('', [
    Validators.pattern('[0-9]{10}')
  ]);
  private _logService: LogService;
  public overHeadPanelTitle = 'Captain Onboarding';
  public phoneNumberSearch: number = null;
  public dlNumberSearch: string = null;
  public rcNumberSearch: string = null;
  public internetSpeed: any;
  public internetSubscriber: any;
  public slowInternet = false;
  public noInternet = false;
  public userName: any;
  public userEmail: any;
  public internetSpeeds = ['4g', '3g'];
  public activeRiderListByRc = [];
  public inactiveRiderListByRc = [];
  public searchByRcCalled = false;
  constructor(private _riderListService: RiderListService,
    private _toastMessage: ToastMessage,
              private router: Router,
              private _hotkeysService: HotkeysService,
              private dataService: DataService,
              private spinnerService: Ng4LoadingSpinnerService
    ) {
      if (this.dataService.toBeReload) {
        window.location.reload();
      }
     this._hotkeysService.add(new Hotkey(['c', 'C'], (event: KeyboardEvent): boolean => {
        document.getElementById('search-mobile').click();
        return false; // Prevent bubbling
      }));
      this._hotkeysService.add(new Hotkey(['d', 'D'], (event: KeyboardEvent): boolean => {
        document.getElementById('search-dl').click();
        return false; // Prevent bubbling
      }));
      this._hotkeysService.add(new Hotkey(['r', 'R'], (event: KeyboardEvent): boolean => {
        document.getElementById('search-rc').click();
        return false; // Prevent bubbling
      }));
    }


  ngOnInit() {
    this.userName = getCookie('firstName', 1);
    this.userEmail = getCookie('emailId', 2);
    this.checkInternet();
  }

  goBack() {
    this.router.navigate(['/modelSelection']);
  }

  checkInternet() {
    this.internetSubscriber = Observable.interval(2000).subscribe(() => {
      if (!navigator.onLine) {
        this.noInternet = true;
      } else {
        this.noInternet = false;
      }

      if (this.internetSpeeds.indexOf(navigator.connection.effectiveType) < 0) {
        this.slowInternet = true;
        this.internetSpeed = navigator.connection.effectiveType;
      } else {
            this.slowInternet = false;
           }
    });
  }

  searchByPhoneNumber() {
    let response;
    let userId = '';

    if (this.mobileFormControl.errors && this.mobileFormControl.errors.pattern) {
      this._toastMessage.openSnackBarError('Please enter a valid mobile number');
    } else {
      this.spinnerService.show();
      this._riderListService.getRiderDetails(this.phoneNumberSearch).subscribe(res => {
        response = {...res};
        if (response.data) {
          this.spinnerService.hide();
          this._toastMessage.openSnackBarSuccess('Rider found');
          userId = response.data.userId._id;
          this.router.navigate(
            ['/onboarding'],
            { queryParams: { id: userId } }
          );
        } else {
          this.spinnerService.hide();
          this._toastMessage.openSnackBarError('Please enter a correct mobile number');
        }
      },
      err => {
        this.spinnerService.hide();
        if (typeof err === 'string') {
          return this._toastMessage.openSnackBarError(err);
        }
        this._toastMessage.openSnackBarError('Could not get captain details.');
        console.log(err);
      });
    }
  }

  searchByDlNumber() {
    this.spinnerService.show();
    if (!this.dlNumberSearch) {
      this._toastMessage.openSnackBarError('Please enter a valid DL number');
      this.spinnerService.hide();
    } else {
      this.spinnerService.hide();
      this.getDlFilteredList(this.dlNumberSearch);
      // this.router.navigate(['/riders'], {queryParams: {dl: this.dlNumberSearch}});
    }
  }

  searchByRcNumber() {
    this.spinnerService.show();
    if (!this.rcNumberSearch) {
      this._toastMessage.openSnackBarError('Please enter a valid RC number');
      this.spinnerService.hide();
    } else {
      this.spinnerService.hide();
      this.searchByRcCalled = true; 
      this.activeRiderListByRc = [];
      this.inactiveRiderListByRc = []; 
      this.getRcFilteredList(this.rcNumberSearch);
      // this.router.navigate(['/riders'], {queryParams: {rc: this.rcNumberSearch}});
    }
  }

  getDlFilteredList(dl) {
    this.spinnerService.show();
    this._riderListService.getRiderByDl(dl).subscribe((response:any) => {
      const riderList = response && response.data;
      this.spinnerService.hide();
      if (riderList.length > 0) {
        this.router.navigate(['/onboarding'], {queryParams: {id: riderList[0]._id}});
      } else {
        this._toastMessage.openSnackBarError('No rider found!');
        throw new Error('No rider found');
      }
    },
    err => {
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError('Could not fetch captain by DL');
      console.log(err);
    });
  }

  getRcFilteredList(rc) {
    this.spinnerService.show();
    this._riderListService.getRiderByRc(rc).subscribe((response:any) => {
      const riderList = response && response.data;
      this.spinnerService.hide();
      if (riderList.length > 0) {
        for(let rider=0 ; rider<riderList.length; rider++){
          if(riderList[rider].active) this.activeRiderListByRc.push(riderList[rider]);
          else this.inactiveRiderListByRc.push(riderList[rider])
        }

        if(riderList.length == 1){
          this.goToProfilePage(riderList[0]._id) ;
        }
      } else {
        this._toastMessage.openSnackBarError('No rider found!');
        throw new Error('No rider found');
      }
    },
    err => {
      this.spinnerService.hide();
      this._toastMessage.openSnackBarError('Could not fetch captain by RC');
      console.log(err);
    });
  }

  goToProfilePage(userId){
    this.router.navigate(['/onboarding'], {queryParams: {id:userId}});
  }

  rcNumberInput(event){
    this.rcNumberSearch = event.target.value;
  }
}
