import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core'

import { FormControl, Validators } from '@angular/forms'

import { ToastMessage } from '../../toast-message/toast-message.service'
import { LogService } from '../../log-service.service'
import { HelperService } from '../../helper.service'

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'
import { BasicInfoService } from './basic-info.service'
import { Router } from '@angular/router'

import { MatDialog, MatDialogRef } from '@angular/material'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import './basic-info.component.css'
import { RemarkPopUpComponent } from '../../remarks-popup/remark-pop-up.component'
import { PopUpMessageComponent } from '../../pop-up-message/pop-up-message.component'

const _ = require('lodash')

@Component({
  selector: 'basic-info',
  template: require('./basic-info.component.html'),
  providers: [ToastMessage, BasicInfoService, LogService],
})
export class BasicInfoComponent implements OnChanges, OnInit {
  emailFormControl = new FormControl('', [Validators.email])
  mobileFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]{10}'),
  ])
  firstNameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z](?!.*  )[a-zA-Z ]{0,199}'),
  ])
  lastNameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z](?!.*  )[a-zA-Z ]{0,199}'),
  ])
  birthDateFormControl = new FormControl(new Date())
  lastDispositionFormControl = new FormControl('', [
    Validators.required
  ])
  captainInstalledAppsFormControl = new FormControl('', [
    Validators.required
  ])

  @Input() data: any
  @Output() birthDate: EventEmitter<any> = new EventEmitter()
  @Output() firstNameInput: EventEmitter<any> = new EventEmitter()
  @Output() userPreferences: EventEmitter<any> = new EventEmitter()
  @Output() modeChange: EventEmitter<any> = new EventEmitter()
  public basicData: any
  public riderLogData: any
  public response: any
  public isActive = false
  public screenBreakpoint = false
  public fileSelected: any
  public error: any
  public userInitialData: any = {}
  public gender = { 0: 'Male', 1: 'Female', 2: 'Other' }
  public isModalOpen = false
  public userMapping = {}
  public modes = []
  public modeName: string
  public selectedMode: any
  public isAssureServiceEnabled = false
  public assureFees
  public mobileChangeRequest: any
  public lastDisposition: any;
  public captainInstalledApps = []
  public fleetTypes = []
  public fleetManagers = []
  public fleetType: string
  public fleetManager: string
  public fleetId = ''
  public showAttachFleet = false
  public showDetachFleet = false
  public showChangeRequest = false
  public showLastDisposition = false;
  public showCaptainInstalledApps = false
  public rejectedChangeRequest = false
  public approvedChangeRequest = false
  public foodDelivery = 'Food Delivery'
  public isB2BEligible = ''
  public notEligibleReasons
  public b2bEligibleApiErrorMessage
  public b2bEligibileFlagToggle = true
  public isEligibilityApiCalled = false
  public isRefreshing = false
  public isrestrictDLEditEnabled = false
  private isWhatsappR2AEnabled: boolean = false
  private isAllowedToSendR2AWhatsappMessage: boolean = true
  private whatsappAckowledgementPositiveFeedback: string = ''
  private whatsappAckowledgementNegativeFeedback: string = ''
  public optimisedProfilePictureImageUrl = ''
  public maxDate = new Date()

  // permission base editing
  @Input() allowEditing: any
  @Input() allowMobileEdit: any
  @Input() allowFleetEdit: any

  constructor(
    private _toastMessage: ToastMessage,
    private _logService: LogService,
    private _basicInfoService: BasicInfoService,
    public dialog: MatDialog,
    private spinnerService: Ng4LoadingSpinnerService,
    private _hotkeysService: HotkeysService,
    private helperService: HelperService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.data && changes.data.currentValue) {
        this.basicData = changes.data.currentValue
        this.basicData.servicesInterested = this.basicData.servicesInterested
          ? this.basicData.servicesInterested.toString()
          : ''
        this.userInitialData = _.cloneDeep(this.basicData) // to compare with old values for log service
        this.modes = this.basicData.modes
        this.isActive = this.basicData.active;
        const registrationDate = new Date(this.basicData.registeredDate).getTime() > 0 ? new Date(
          this.basicData.registeredDate
        ) : new Date();
        this.getBirthDateMaxRange(registrationDate)
      }
      if (
        this.basicData &&
        this.basicData.userId &&
        this.data &&
        this.data.city
      ) {
        var userId = this.basicData.userId._id
        this.getUserPreferencesInformation(userId, this.data.city)
      }
      if (
        this.basicData &&
        this.basicData.userId &&
        this.data &&
        this.data.city
      ) {
        var userId = this.basicData.userId._id
        this.getUserPreferencesInformation(userId, this.data.city)
      }
      if (this.basicData && this.basicData.profilePicture) {
        if (
          this.basicData.profilePicture.qos &&
          Array.isArray(this.basicData.profilePicture.qos) &&
          this.basicData.profilePicture.qos.includes('1')
        ) {
          const imageUrl = new URL(this.basicData.profilePicture.link)
          imageUrl.searchParams.set('qos', '1')
          this.optimisedProfilePictureImageUrl = imageUrl.toString()
        } else {
          this.optimisedProfilePictureImageUrl =
            this.basicData.profilePicture.link
        }
      }
      if (this.basicData && this.basicData.statusUpdates) {
        this.isWhatsappR2AEnabled =
          this.basicData.statusUpdates.isWhatsappR2AEnabled
        this.getUserPreferencesInformation(userId, this.data.city)
      }
      if (this.basicData && this.basicData.toggles) {
        this.isrestrictDLEditEnabled = this.basicData.toggles.restrictEditDlEnabled;
      }
      if (this.basicData && this.basicData.typeList) {
        let fleetTypes = this.basicData.typeList.fleetTypeList.slice(1, -1).split(',');
        this.fleetTypes = fleetTypes.map(type => type.trim());
      }
      if (changes.allowEditing && changes.allowEditing.currentValue === false) {
        this.firstNameFormControl.disable()
        this.mobileFormControl.disable()
        this.lastNameFormControl.disable()
        this.birthDateFormControl.disable()
      }
      this.modeName =
        (this.basicData &&
          this.basicData.modeId &&
          this.basicData.modeId.mode) ||
        ''
      if (this.basicData && this.basicData.mobile) {
        var mobile = this.basicData.mobile;
        this.fetchAgentLastDisposition(mobile);
        this.fetchCaptainInstalledApps(mobile);
      }
      if (this.shouldRestrcictBirthDateEditToVerifiedDocs() && this.basicData.birthDate) {
        this.birthDateFormControl.disable()
      }
      if (this.basicData && this.basicData.fleetId) {
        this.fetchAttachedFleetManagerAndType();
      }
    }
  }

  ngOnInit() {
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide()
      }
    }
    if (window && window['jQuery'] && window['jQuery'](window)) {
      this.screenBreakpoint =
        window['jQuery'](window).innerWidth() <= 1040 ? true : false
    }
  }

  onResize(event) {
    this.screenBreakpoint = event.target.innerWidth <= 1040 ? true : false
  }

  getBirthDateMaxRange(registrationdate: Date) {
    let regDate = new Date(registrationdate);
    let maxDate = new Date();
    maxDate.setFullYear(regDate.getFullYear() - 18);
    this.maxDate = maxDate;
  }

  fetchAttachedFleetManagerAndType() {
    this._basicInfoService.getFleetManagers('', this.basicData.fleetId, localStorage.getItem('userId')).subscribe((res: any) => {
      if (res && res.data) {
        this.fleetType = res.data[0].type;
        this.fleetManager = `${res.data[0].name} - ${res.data[0].mobile}`;
        this.fleetTypes = [];
        this.showDetachFleet = true;
      }
    }, error => {
      this.showDetachFleet = false;
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not fetch fleet managers';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  fetchFleetManagersForType() {
    if (this.basicData && !this.basicData.fleetId && this.fleetType) {
      this._basicInfoService.getFleetManagers(this.fleetType, '', localStorage.getItem('userId')).subscribe((res: any) => {
        if (res && res.data) {
          this.fleetManagers = res.data.map((manager: any) => ({name: manager.name, _id: manager._id, mobile: manager.mobile}));
          this.showAttachFleet = true;
        }
      }, error => {
        const errorMessage = error.error && error.error.message ? error.error.message : 'Could not fetch fleet info';
        this._toastMessage.openSnackBarError(errorMessage);
      });
    }
  }

  formatManagerName(manager: any): string {
    return `${manager.name} - ${manager.mobile}`;
  }

  onFleetManagerChange(managerName: string) {
    const selectedManager = this.fleetManagers.find(manager => `${manager.name} - ${manager.mobile}` === managerName);
    if (selectedManager) {
      this.fleetId = selectedManager._id;
    }
  }

  attachToFleet() {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Are you sure you want to attach the fleet for captain?',
        notes: [
          'Account will be deactivated.',
          'Captain should be off-duty before attaching the fleet',
        ],
        reason: 'attachToFleet',
        buttonText: 'Attach',
        cancelText: 'Cancel',
        styles: {
          title: 'font-size-20 margin-bottom-30',
          actionClass: 'doc-details-change-confirm',
          note: 'margin-bottom-12 margin-top-30 color-grey'
        },
      },
    })

    dialogRef.afterClosed().subscribe((attachToFleet) => {
      if (attachToFleet) {
        this.attachFleetToCaptain()
      }
    })
  }

  attachFleetToCaptain() {
    this._basicInfoService.attachFleetToRider(this.basicData._id, this.fleetId, localStorage.getItem('userId')).subscribe((res: any) => {
      if (res.message === 'success') {
        window.location.reload();
      }
    }, error => {
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not complete attachment of fleet to captain';
      this.showAttachFleet = true;
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  detachFromFleet() {
    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Are you sure you want to detach the fleet for captain?',
        notes: [
          'Account will be deactivated',
          'Captain will be logged out of app',
          'Captain should be off-duty before detaching the fleet',
        ],
        reason: 'detachFromFleet',
        buttonText: 'Detach',
        cancelText: 'Cancel',
        styles: {
          title: 'font-size-20 margin-bottom-30',
          actionClass: 'doc-details-change-confirm',
          note: 'margin-bottom-12 margin-top-30 color-grey'
        },
      },
    })

    dialogRef.afterClosed().subscribe((detachFromFleet) => {
      if (detachFromFleet) {
        this.detachFleetFromCaptain()
      }
    })
  }

  detachFleetFromCaptain() {
    this._basicInfoService.detachFleetFromRider(this.basicData._id, this.basicData.fleetId, localStorage.getItem('userId'), this.basicData.modeId._id).subscribe((res: any) => {
      if (res.message === 'success') {
        window.location.reload();
      }
    }, error => {
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not complete detachment of fleet from captain';
      if (errorMessage === 'Please end the shift of captain') {
        const dialogRef = this.dialog.open(PopUpMessageComponent, {
          width: '550px',
          data: {
            options: [],
            title: 'Captain has not ended the shift',
            notes: [
              'Please make sure to end the shift of captain before detaching the fleet',
            ],
            reason: 'detachFromFleet',
            buttonText: 'Yes',
            styles: {
              title: 'font-size-20 margin-bottom-30',
              actionClass: 'doc-details-change-confirm',
              note: 'margin-bottom-12 margin-top-30 color-grey'
            },
          },
        })
    
        dialogRef.afterClosed().subscribe()
      }
      this.showDetachFleet = true;
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  shouldRestrcictBirthDateEditToVerifiedDocs(): boolean {
    return this.basicData && this.basicData.license && 
    this.basicData.license.verified && this.basicData.license.status === "approved" && 
    this.isrestrictDLEditEnabled && localStorage.getItem('isUserAnAdmin') === 'false';
  }

  updateFirstName() {
    let response
    if (this.userInitialData.firstName === this.basicData.firstName) {
      this._toastMessage.openSnackBarError('Please change the value to update')
    } else {
      if (
        (this.firstNameFormControl.errors &&
          this.firstNameFormControl.errors.required) ||
        (this.firstNameFormControl.errors &&
          this.firstNameFormControl.errors.pattern)
      ) {
        this._toastMessage.openSnackBarError(
          'First name is a mandatory field and please enter letters(A-Z).'
        )
      } else {
        this._basicInfoService
          .updateFirstName(this.basicData.firstName, this.basicData.userId._id)
          .subscribe(
            (res) => {
              response = res
              this.firstNameInput.emit(this.basicData.firstName)
              this._logService
                .insertEvent(
                  1,
                  this.basicData._id,
                  { firstName: this.userInitialData.firstName },
                  { firstName: this.basicData.firstName }
                )
                .subscribe()
              this.userInitialData.firstName = this.basicData.firstName
              this._toastMessage.openSnackBarSuccess(
                'Updated first name successfully'
              )
            },
            (error) => {
              const errorMessage = error.error.message
              this._toastMessage.openSnackBarError(
                errorMessage || 'Could not update first name'
              )
            }
          )
      }
    }
  }

  updateLastName() {
    let response
    if (this.userInitialData.lastName === this.basicData.lastName) {
      this._toastMessage.openSnackBarError('Please change the value to update')
    } else {
      if (
        (this.lastNameFormControl.errors &&
          this.lastNameFormControl.errors.pattern) ||
        (this.lastNameFormControl.errors &&
          this.lastNameFormControl.errors.required) ||
        !this.basicData.lastName.trim()
      ) {
        this._toastMessage.openSnackBarError(
          'Last name is a mandatory field and please enter letters(A-Z)'
        )
      } else {
        this._basicInfoService
          .updateLastName(this.basicData.lastName, this.basicData.userId._id)
          .subscribe(
            (res) => {
              response = res
              this._logService
                .insertEvent(
                  1,
                  this.basicData._id,
                  { lastName: this.userInitialData.lastName },
                  { lastName: this.basicData.lastName }
                )
                .subscribe()
              this.userInitialData.lastName = this.basicData.lastName
              this._toastMessage.openSnackBarSuccess(
                'Updated last name successfully'
              )
            },
            (error) => {
              const errorMessage =
                (error && error.error && error.error.message) ||
                'Could not last name'
              this._toastMessage.openSnackBarError(errorMessage)
            }
          )
      }
    }
  }

  updateMobile() {
    let response
    if (
      (this.mobileFormControl.errors &&
        this.mobileFormControl.errors.pattern) ||
      (this.mobileFormControl.errors && this.mobileFormControl.errors.required)
    ) {
      this._toastMessage.openSnackBarError(
        'Mobile number is a mandatory field.' +
          'It should only contain numbers and should be 10 digits long'
      )
    } else {
      this._basicInfoService
        .updateMobile(this.basicData.mobile, this.basicData.userId._id)
        .subscribe(
          (res) => {
            response = res
            this._logService
              .insertEvent(
                1,
                this.basicData._id,
                { mobile: this.userInitialData.mobile },
                { mobile: this.basicData.mobile }
              )
              .subscribe()
            this.userInitialData.mobile = this.basicData.mobile
            this._toastMessage.openSnackBarSuccess(
              'Updated mobile number successfully'
            )
          },
          (error) => {
            const errorMessage =
              (error && error.error && error.error.message) ||
              'Could not mobile'
            this._toastMessage.openSnackBarError(errorMessage)
          }
        )
    }
  }

  logoutCaptain() {
    let response
    this.spinnerService.show()
    this._basicInfoService.logoutCaptain(this.basicData.userId._id).subscribe(
      (res) => {
        this.spinnerService.hide()
        response = res
        this._logService
          .insertEvent(
            1,
            this.basicData._id,
            { logout: false },
            { logout: true }
          )
          .subscribe()
        this._toastMessage.openSnackBarSuccess('Captain logged out')
      },
      (error) => {
        this.spinnerService.hide()
        const errorMessage =
          error.error && error.error.message
            ? error.error.message
            : 'Could not logout captain'
        this._toastMessage.openSnackBarError(errorMessage)
      }
    )
  }

  genderToggle(evt: any) {
    let response
    const prevGender = this.gender[this.userInitialData.userId.gender]
    let changedGender
    if (evt.value === 'Female') {
      this.basicData.userId.gender = 1
      changedGender = 'female'
    } else if (evt.value === 'Male') {
      this.basicData.userId.gender = 0
      changedGender = 'male'
    } else if ((evt.value = 'Other')) {
      this.basicData.userId.gender = 2
      changedGender = 'other'
    }
    this.spinnerService.show()
    this._basicInfoService
      .updateGender(this.basicData.userId.gender, this.basicData.userId._id)
      .subscribe(
        (res) => {
          this.spinnerService.hide()
          response = res
          this._logService
            .insertEvent(
              1,
              this.basicData._id,
              { gender: prevGender },
              { gender: changedGender }
            )
            .subscribe()
          this.userInitialData.userId.gender = this.basicData.userId.gender
          this._toastMessage.openSnackBarSuccess('Updated gender successfully')
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            error.error && error.error.message
              ? error.error.message
              : 'Could not update gender'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  confirmationForModeChange(selectedMode: any) {
    const confirmationRequired =
      (this.data && this.data.toggles && this.data.toggles.modeChangeEnabled) ||
      false
    if (!confirmationRequired) {
      this.modeToggle(selectedMode)
      return
    }

    const dialogRef = this.dialog.open(PopUpMessageComponent, {
      width: '550px',
      data: {
        options: [],
        title: 'Are you sure you want to change the mode for captain?',
        notes: [
          'This would remove the existing services of the captain.',
          'Account will be deactivated.',
          'Necessary documents need to be re-verified',
        ],
        reason: 'modeChange',
        buttonText: 'Change',
        styles: {
          title: 'deactivate-title',
          actionClass: 'deactivate-merchant-popup',
        },
      },
    })

    dialogRef.afterClosed().subscribe((modeChange) => {
      if (modeChange) {
        this.modeToggle(selectedMode)
      } else {
        this.modeName = this.basicData.modeId.mode
      }
    })
  }

  modeToggle(selectedMode: any) {
    const mode = selectedMode.value
    this.spinnerService.show()
    const modeInfo =
      this.modes.filter((item) => item && item.mode === mode)[0] || {}
    this.spinnerService.hide()
    this.selectedMode = modeInfo
    const modeId = modeInfo._id
    const userId =
      (this.basicData && this.basicData.userId && this.basicData.userId._id) ||
      this.basicData._id
    const cityId =
      this.basicData && this.basicData.city && this.basicData.city._id
    this._basicInfoService.updateMode(modeId, userId, cityId).subscribe(
      (response: any) => {
        this.basicData.modeId = { _id: modeId, mode: modeInfo.mode }
        const previousMode = this.userInitialData.modeId || ''
        this._logService
          .insertEvent(
            1,
            this.basicData._id,
            { mode: previousMode },
            { mode: modeInfo._id }
          )
          .subscribe()
        this.userInitialData.modeId = this.basicData.modeId
        this.modeName = modeInfo.mode
        this.modeChange.emit({ _id: modeId, mode: modeInfo.mode })
        this._toastMessage.openSnackBarSuccess('Updated mode successfully')
      },
      (error) => {
        this.spinnerService.hide()
        this._toastMessage.openSnackBarError('Failed to update mode')
      }
    )
  }

  checkForCaptainMode(mode) {
    const modeInfo =
      this.modes.filter((item) => item && item.mode === mode)[0] || {}
    if (this.basicData.modeId && this.basicData.modeId._id === modeInfo._id) {
      return true
    }
    return false
  }

  fetchAgentLastDisposition(mobile) {
    if (this.isActive) {
      this.showLastDisposition = false;
      return;
    }
    this._basicInfoService.getLastDisposition(mobile).subscribe((res: any) => {
      if (res && res.data && res.data.data && res.data.data.agentLastDisposition) {
        this.lastDisposition = res.data.data.agentLastDisposition
        this.showLastDisposition = true;
        this.lastDispositionFormControl.disable();
        return res.data
      } else {
        this.showLastDisposition = false;
      }
    }, error => {
      this.showLastDisposition = false;
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not fetch last disposition';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  fetchCaptainInstalledApps(mobile) {
    if (this.isActive) {
      this.showCaptainInstalledApps = false;
      return;
    }
    this._basicInfoService.getCaptainInstalledApps(mobile).subscribe((res: any) => {
      if (res && res.data && res.data.data && res.data.data.captainInstalledApps && res.data.data.captainInstalledApps.length > 0) {
        this.captainInstalledApps = res.data.data.captainInstalledApps
        this.showCaptainInstalledApps = true;
        this.captainInstalledAppsFormControl.disable();
        return res.data
      } else {
        this.showCaptainInstalledApps = false;
      }
    }, error => {
      this.showCaptainInstalledApps = false;
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not fetch captain installed apps';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  fetchRiderLogs() {
    const userId = this.basicData.userId._id
    this.router.navigate([`/riderLogs/${userId}`])
  }

  calculateFinalAmount(baseAmount, discount) {
    return baseAmount - (baseAmount * discount) / 100
  }

  updateBirthDate() {
    this._basicInfoService
      .updateBirthDate(this.basicData.birthDate, this.basicData.userId._id)
      .subscribe(
        (res: any) => {
          if (res.data && res.data.info && res.data.info.status === 'success') {
            this._toastMessage.openSnackBarSuccess(
              'Updated date of birth successfully'
            )
            this.birthDate.emit(this.basicData.birthDate)
            const birthTimestamp = new Date(this.basicData.birthDate)
            this._logService
              .insertEvent(
                1,
                this.basicData._id,
                { birthDate: this.userInitialData.birthDate },
                { birthDate: this.helperService.formatDate(birthTimestamp) }
              )
              .subscribe()
          } else {
            this._toastMessage.openSnackBarError('Could not update birth date')
          }
        },
        (error) => {
          const errorMessage =
            error.error && error.error.message
              ? error.error.message
              : 'Could not update birth date'
          this._toastMessage.openSnackBarError(errorMessage)
        }
      )
  }

  datePickerOpened(evt: any) {
    this._hotkeysService.add(
      new Hotkey('left', (event: KeyboardEvent): boolean => {
        event.preventDefault()
        return false // Prevent bubbling
      })
    )
    this._hotkeysService.add(
      new Hotkey('right', (event: KeyboardEvent): boolean => {
        event.preventDefault()
        return false // Prevent bubbling
      })
    )
  }

  datePickerClosed(evt: any) {
    this._hotkeysService.add(
      new Hotkey('left', (event: KeyboardEvent): boolean => {
        document.getElementById('backButton').click()
        return false // Prevent bubbling
      })
    )
    this._hotkeysService.add(
      new Hotkey('right', (event: KeyboardEvent): boolean => {
        document.getElementById('nextButton').click()
        return false // Prevent bubbling
      })
    )
  }
  getB2bEligibility(riderId) {
    return new Promise((resolve, reject) => {
      this._basicInfoService.getB2bEligibility(riderId).subscribe(
        (result) => {
          this.b2bEligibileFlagToggle =
            result && result['B2B_ELIGIBLE_TOGGLE'] == 'false' ? false : true
          this.isB2BEligible =
            result && result.hasOwnProperty('isEligible')
              ? result['isEligible']
                ? 'YES'
                : 'NO'
              : ''
          this.notEligibleReasons = (result && result['message']) || []
          resolve()
        },
        (error) => {
          const errorMessage =
            (error && error.error && error.error.message) ||
            'Could not fetch B2B eligiblity status'
          this.b2bEligibleApiErrorMessage = errorMessage
          this._toastMessage.openSnackBarError(errorMessage)
          reject()
        }
      )
    })
  }
  resetB2bEligibilityVariables() {
    this.isB2BEligible = ''
    this.b2bEligibileFlagToggle = true
  }

  getUserPreferencesInformation(riderId, cityId) {
    this._basicInfoService.getUserPreference(riderId, cityId).subscribe(
      (result) => {
        this.userPreferences.emit(result)
        //2969 | disabling Assure information on supply dashboard
        //this.getAssureInformation(result)
      },
      (error) => {
        const errorMessage =
          (error && error.error && error.error.message) ||
          'Could not assure payment details'
        this._toastMessage.openSnackBarError(errorMessage)
      }
    )
  }

  filterAssureDetails(result) {
    return (
      (result &&
        result.data &&
        result.data.serviceOnboardingConfig &&
        result.data.serviceOnboardingConfig &&
        result.data.serviceOnboardingConfig.serviceTypeProgress &&
        result.data.serviceOnboardingConfig.serviceTypeProgress.delivery &&
        result.data.serviceOnboardingConfig.serviceTypeProgress.delivery
          .food) ||
      {}
    )
  }

  showB2bEligibility() {
    this.resetB2bEligibilityVariables()
    var userId = this.basicData.userId._id
    this.isRefreshing = true
    this.isEligibilityApiCalled = true
    this.getB2bEligibility(userId)
      .then(() => {
        this.isRefreshing = false
      })
      .catch(() => {
        this.isRefreshing = false
      })
  }

  viewPhoneNumberChangeRequest(evt) {
    this.showChangeRequest = evt.checked
    if (this.showChangeRequest) {
      this._basicInfoService
        .getPhoneNumberChangeRequest(this.basicData.userId._id)
        .subscribe(
          (response) => {
            this.mobileChangeRequest = response['data']
          },
          (error) => {
            const errorMessage =
              (error && error.error && error.error.message) ||
              'Failed to fetch mobile change request'
            this._toastMessage.openSnackBarError(errorMessage)
            this.showChangeRequest = false
          }
        )
    }
  }

  approvePNR() {
    this._basicInfoService.approvePNR(this.basicData.userId._id).subscribe(
      (resposne) => {
        this.showChangeRequest = false
        this.approvedChangeRequest = true
      },
      (error) => {
        const errorMessage =
          (error && error.error && error.error.message) ||
          'Failed to approve mobile change request'
        this._toastMessage.openSnackBarError(errorMessage)
      }
    )
  }

  rejectPNR() {
    const dialogRef = this.dialog.open(RemarkPopUpComponent)
    const riderId = this.basicData.userId._id
    dialogRef.afterClosed().subscribe(
      (remarks) => {
        this._basicInfoService.rejectPNR(riderId, remarks).subscribe(
          (response) => {
            this.showChangeRequest = false
            this.rejectedChangeRequest = true
          },
          (error) => {
            const errorMessage =
              (error && error.error && error.error.message) ||
              'Failed to reject mobile change request'
            this._toastMessage.openSnackBarError(errorMessage)
          }
        )
      },
      (error1) => {
        console.log(error1)
      }
    )
  }

  sendR2AWhatsappMessage() {
    this.spinnerService.show()
    this.whatsappAckowledgementPositiveFeedback = ''
    this.whatsappAckowledgementNegativeFeedback = ''
    this.isAllowedToSendR2AWhatsappMessage = false
    console.log(this.basicData)
    this._basicInfoService
      .sendR2AWhatsappMessage(this.basicData.userId._id)
      .subscribe(
        (res) => {
          this.spinnerService.hide()
          this._logService
            .insertEvent(
              1,
              this.basicData._id,
              { whatasppR2ANudge: null },
              { whatasppR2ANudge: 'sent' }
            )
            .subscribe()
          this.whatsappAckowledgementPositiveFeedback = 'Message sent'
        },
        (error) => {
          this.spinnerService.hide()
          const errorMessage =
            error.error && error.error.message
              ? error.error.message
              : 'Failed to send whatsapp message'
          this.whatsappAckowledgementNegativeFeedback = errorMessage
        }
      )
  }

  getAssureInformation(result) {
    let response = this.filterAssureDetails(result)
    const joiningFeeInfo = (response && response['joiningFeeInfo']) || {}
    if (joiningFeeInfo) {
      const len =
        (joiningFeeInfo &&
          joiningFeeInfo['transactionProgress'] &&
          joiningFeeInfo['transactionProgress'].length) ||
        0
      const paymentStatus =
        len > 0 ? joiningFeeInfo['transactionProgress'][len - 1].status : ' '
      this.isAssureServiceEnabled = paymentStatus == 'completed' ? true : false
      this.assureFees = this.calculateFinalAmount(
        parseInt(joiningFeeInfo['baseAmount']),
        parseInt(joiningFeeInfo['discountPercentage'])
      )
    }
  }
}
