import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges,
  ChangeDetectorRef} from '@angular/core';

import { ProfilePictureService } from './profile-picture.service';
import { ToastMessage } from '../../toast-message/toast-message.service';

import {FormControl, Validators} from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LogService } from '../../log-service.service';
import { ConstantsService } from '../../constants.service';
import { Subject } from 'rxjs';
import {ImageViewService} from '../../image-view/image-view.service';
import { error } from 'console';


let _ = require('lodash');
@Component({
  selector: 'profile-pic',
  template: require('./profile-pic.component.html'),
  styles: [`
      ::ng-deep .secondary-container {
          padding: 0;
      }
  `],
  providers: [ProfilePictureService, ToastMessage, LogService, ConstantsService, ImageViewService]
})
export class ProfilePicComponent implements OnChanges, OnInit {
  remarksFormControl = new FormControl('', [
    Validators.maxLength(255)
  ]);
  private S3_URL_REGEX: RegExp;
  public imagePublicUrl: string;
  private fileName: string;
  private imageToUpload: any;
  private showUpdateButton: boolean;
  private imageUploaded: boolean;
  private enrollmentInfo: any;

  constructor(private _profilePictureService: ProfilePictureService,
    private cd: ChangeDetectorRef,
    private _toastMessage: ToastMessage,
    private spinnerService: Ng4LoadingSpinnerService,
    private _logService: LogService,
              private constantService: ConstantsService,
              private _imageViewService: ImageViewService
  ) {
    this.S3_URL_REGEX = new RegExp(/(s3-|s3\.)?(.*)\.amazonaws\.com/);
}

  @Output() ppStatus: EventEmitter<any> = new EventEmitter();
  @Output() ppUploaded: EventEmitter<any> = new EventEmitter();
  @Output() profileDLDocs: EventEmitter<any> = new EventEmitter();
  @Output() addProfileToRejectList: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  @Input() profileStatus: any;
  public url: any;
  public showProfileStatus = false;
  @Input() userIdentity: any = {};
  @Input() allowEditing: any;
  @Input() showPPVerify: any;
  @Input() isProfileRejected: any;
  public ppData: any;
  public showRejectReason = false;
  public riderId: any;
  public config: any;
  public screenBreakpoint = false;
  public showApproveButton = false;
  public userInitialData: any = {};

  // dummy image
  public imageUrl: any;
  public bitmapUrl: any;
  public updateImageEnabled = false;

  // response from third party
  public thirdPartyProfile: any;
  public thirdPartyStatus: any;
  public thirdPartyMessage: any;
  public isProfileAccepted: any;
  public verificationPlatform: any;
  public verifiedOn: any;

  public profileApprovalMessage: any;
  public isProfileApproved = false;
  public isProfileAutoApproved = false;
  public profileApprovalStatus = 'Approved';
  public APPROVAL_MESSAGE = this.constantService.approvalMessage;
  public ppRejectReasons = this.constantService.docRejectionReasons.pp;
  public ppRejectedReason: string;
  public disableFaceAuthReason: string;
  public disableFaceAuthReasons = this.constantService.docRejectionReasons.faceAuth;


  public asyncRefreshTrigger: Subject<void> = new Subject<void>();

  private rotateDegrees = 0;
  private selectRejectReasonWarning = false;
  private selectFaceAuthReasonWarning = false;

  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 1040) ? true : false;

  }

  isS3Url(url) {
    return this.S3_URL_REGEX.test(url);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.ppData && changes.isProfileRejected && changes.isProfileRejected.currentValue ) {
      this.updateImageUrl({
        mode: 'delete',
        url: ''
      });
    }

    if (changes.data) {
      this.ppData = changes.data.currentValue;
      this.userInitialData = _.cloneDeep(changes.data.currentValue);
      this.riderId = this.ppData.userId._id;
      let optimisedProfilePictureImageUrl = ""
      if (this.ppData.profilePicture.qos &&
        Array.isArray(this.ppData.profilePicture.qos) &&
        this.ppData.profilePicture.qos.includes("1")) {
          const imageUrl = new URL(this.ppData.profilePicture.link)
          imageUrl.searchParams.set("qos","1")
          optimisedProfilePictureImageUrl = imageUrl.toString()
      } else {
        optimisedProfilePictureImageUrl = this.ppData.profilePicture.link
      }
      this.imageUrl = optimisedProfilePictureImageUrl;
      if (this.ppData.profilePicture.link && this.ppData.profilePicture.verified === false) {
        this.showApproveButton = true;
      }

      this.showRejectReason = !!this.imageUrl;
      this.getEnrollmentInfo();

    }
    if (this.imageUrl) {
      if (this.isS3Url(this.imageUrl)) {
        this.imagePublicUrl = this.imageUrl;
        return;
      }
      const img = new URL(this.imageUrl);
      this._imageViewService.getPreSignedImageUrl(img.pathname + img.search).subscribe(response => {
        this.imagePublicUrl = response['url'];
      }, error => {
        const errorMessage = error && error.error && error.error.message || 'Failed to load image';
        this._toastMessage.openSnackBarError(errorMessage);
      });
    }

    if (changes && changes.profileStatus && changes.profileStatus.currentValue) {
      this.thirdPartyProfile = changes.profileStatus.currentValue['profileLink'] || '';

      this.thirdPartyStatus =  changes.profileStatus.currentValue['status'] || '';

      this.thirdPartyMessage = changes.profileStatus.currentValue['message'] || '';

      this.isProfileAccepted = changes.profileStatus.currentValue['isProfileAccepted'] || '';

      this.verificationPlatform = changes.profileStatus.currentValue.verifiedBy;
      this.verifiedOn = changes.profileStatus.currentValue.verifiedOn;

      // check if URL is base64. If yes append
      const  base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (this.thirdPartyProfile && base64regex.test(this.thirdPartyProfile)) {
        this.thirdPartyProfile = 'data:image/jpg;base64,' + this.thirdPartyProfile;
      }
      this.showProfileStatus = true;
    }
    if (changes && changes.allowEditing && changes.allowEditing.currentValue === false) {
      this.remarksFormControl.disable();
    }

    if (this.ppData && this.ppData.profilePicture && this.ppData.profilePicture.verified) {
      this.formApprovalMessage();
    }
    if(this.isProfileApproved || this.isProfileAccepted){
      this.showPPVerify = false;
    }
  }

  ngOnInit() {
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').show();
      }
    }
    this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 1040) ? true : false;
  }

  formApprovalMessage() {
    const profileAutoApprovalStatus = this.ppData['autoApprovalStatus'] || {};
    this.isProfileApproved = true;
    if (  profileAutoApprovalStatus['profileVerificationStatus']
          && profileAutoApprovalStatus['profileVerificationStatus'].autoApproved
      ) {
        this.isProfileAutoApproved = true;
        this.profileApprovalMessage = this.APPROVAL_MESSAGE.autoApproved;
    } else {
      this.profileApprovalMessage = this.APPROVAL_MESSAGE.agentApproved;
    }
  }

 rotateImage(link, degrees) {
  this.rotateDegrees = this.rotateDegrees + degrees;
  this.spinnerService.show();
  this._profilePictureService.rotateImage(link, this.rotateDegrees).then(result => {
    this.config = result;
    const imageData = this.config.data;
    setTimeout(() => {
      this.updateImageEnabled = true;
      window['jQuery'](document).ready(function () {
        window['jQuery']('#displayImage').attr('src', imageData).blowup({
          'width': 400,
          'height': 400,
          'border': '1px solid black',
          'round': false
        });
      });
      this.spinnerService.hide();
    }, 300);
  },
  error => {
    this.spinnerService.hide();
    const errorMessage = error && error.error && error.error.message || 'Could not rotate profile pic';
    this._toastMessage.openSnackBarError(errorMessage);

   });
}


  getEnrollmentInfo() {
    this._profilePictureService.getEnrollmentInfo(this.riderId).then(res => {
      this.enrollmentInfo = res;
    }).catch(error => {
      console.log('error in getEnrollmentInfo', error);
    });
  }



  addToBulkRejection(event) {
    if(!this.ppRejectedReason) {
      this.selectRejectReasonWarning = true;
      return;
    }

    this.addProfileToRejectList.emit({
      imageType: 'profilePicture',
      view: 'front',
      userId: this.ppData.userId._id,
      data: this.ppRejectedReason
    });
    this.selectRejectReasonWarning = false;
  }

  updateProfilePicture() {
    let response;
    this.spinnerService.show();
    this._profilePictureService.updateImage(this.config.data, this.imageUrl, this.riderId).subscribe(resp => {
      response = resp;
      this.imageUrl = response.info.url;
      this._logService.insertEvent(2, this.ppData._id, {profilePicture : this.userInitialData.profilePicture.link},
                                  {profilePicture : this.imageUrl}).subscribe();
      this.userInitialData.profilePicture.link = this.imageUrl;
      this._toastMessage.openSnackBarSuccess('The rotated image has been saved!');
      this.spinnerService.hide();
      this._toastMessage.openSnackBarSuccess('Image Saved. Please reload.');
      this.updateImageEnabled = false;
      this.rotateDegrees = 0;
    },
    error => {
        this.spinnerService.hide();
        const errorMessage = error && error.error && error.error.message || 'Could not update profile picture';
        this._toastMessage.openSnackBarError(errorMessage);
      });
  }

  updateImageUrl(evt: any) {
    this.imageUrl = evt.url;
    if (evt.mode === 'delete') {
      this.ppStatus.emit(false);
      this.isProfileApproved = false;
      this.showApproveButton = false;
      this.updateImageEnabled = false;
      this.showRejectReason = false;
      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').hide();
        }
      }
    }

    if (evt.mode === 'update') {
      this.showApproveButton = true;
      this.showUpdateButton = false;
      this.imageUploaded = false;
      this.ppData.profilePicture.link = this.imageUrl;
      this.ppUploaded.emit(this.imageUrl);
      this._toastMessage.openSnackBarSuccess('Image Saved. Please reload using Shift + R .');
    }
  }

  updateRemarks() {
    let response;
    this.spinnerService.show();
    this._profilePictureService.updateRemarks(this.ppData.userId._id, this.ppData.profilePicture.remarks).subscribe(res => {
      response = {...res};
      this.spinnerService.hide();

      this._logService.insertEvent(2, this.ppData._id, {remarks : this.userInitialData.profilePicture.remarks},
                                  {remarks : this.ppData.profilePicture.remarks}).subscribe();
      this.userInitialData.profilePicture.remarks = this.ppData.profilePicture.remarks;
      this._toastMessage.openSnackBarSuccess('Remarks updated');
    },
    error => {
      this.spinnerService.hide();
      const errorMessage = error && error.error && error.error.message || 'Could not update remarks';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  approvePP() {
    let response;
    this.spinnerService.show();
    this._profilePictureService.approvePP(this.ppData.userId._id, this.imageUrl, this.ppData.profilePicture.remarks).subscribe(res => {
      response = res;
      this.updateImageEnabled = false;
      this.ppStatus.emit(true);
      this.showApproveButton = false;
      this.showPPVerify = false;
      this.formApprovalMessage();
      this.spinnerService.hide();
      this._logService.insertEvent(2, this.ppData._id, {ppApproved: false},
        { ppApproved: true, ppDetails: this.imageUrl }).subscribe();
      this._toastMessage.openSnackBarSuccess('Profile picture approved');
    },
    error => {
      this.showApproveButton = true;
      this.spinnerService.hide();
      const errorMessage = error && error.error && error.error.message || 'Could not approve profile picture';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  verifyProfileDLDocs() {
    this.profileDLDocs.emit();
    this.asyncRefreshTrigger.next();
  }

  updateVerificationCard(verificationStatus) {

    this.thirdPartyStatus = verificationStatus.status;
    this.thirdPartyMessage = verificationStatus.message;
    this.verificationPlatform = verificationStatus.verifiedBy;
    this.verifiedOn = verificationStatus.verifiedOn;
    this.isProfileAccepted = verificationStatus.status === 'Accepted';
    this.showPPVerify = !this.isProfileAccepted;
    if(this.isProfileApproved){
      this.showPPVerify = false;
    }
  }

  handleImageUpload() {
    if (window && window['jQuery']) {
      window['jQuery']('input[type="file"]').click();
    }
  }

  onUploadStateChanged(evt) {
    let response;
    if (evt === true) {
      this._imageViewService.deleteTempFiles().subscribe(res => {
        response = res;
      });
    }
  }

  onUploadFinished(evt) {
    this.fileName = evt.file.name + ' has been uploaded.';
    this.imageToUpload = evt.src;
    this.showUpdateButton = true;
    this.imageUploaded = true;
    this.refreshDisplayImage();
  }

  private refreshDisplayImage() {
    if (window) {

      window['jQuery'](document).ready(function () {
        window['jQuery']('#displayImage').blowup({
          'width': 400,
          'height': 400,
          'border': '1px solid black',
          'round': false
        });
      });

    }
  }

  disableFaceAuth(){
    if(!this.disableFaceAuthReason) {
      this.selectFaceAuthReasonWarning = true;
      return;
    }
    this.selectFaceAuthReasonWarning = false;
    let reason = this.disableFaceAuthReason ? this.disableFaceAuthReason : '';
    this._profilePictureService.disableFaceAuth(reason, this.riderId).subscribe(res => {
      this._toastMessage.openSnackBarSuccess(res["data"] || "Successfully expired nudge");
    }, error=>{
      this._toastMessage.openSnackBarError("Internal error while expiring nudge");
    })
  }

  uploadImage() {
    let response;
    this.spinnerService.show();
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }

    this._imageViewService.updateImage(
      this.imageToUpload, this.imageUrl, this.userIdentity.userId, 'profilePicture', 'front', this.fileName, this.userIdentity, null, false).subscribe(res => {
      response = res;
      this.updateImageUrl({
        mode: 'update',
        url: response.info.url
      });
      this.showRejectReason = true;
      const metaData = Object.assign({}, this.userIdentity, {type: 'profilePicture', status: 'updated'});
      this._logService.insertEvent(6, this.userIdentity.userId, {uploadImage: false},
        {
          uploadImage: true, imgUrl: this.imageUrl,
          imageType: 'profilePicture', view: 'front'
        },
        metaData).subscribe();
      if (document.getElementById('BlowupLens')) {
        if (window) {
          window['jQuery']('#BlowupLens').show();
        }
      }
      this.spinnerService.hide();
      this._imageViewService.deleteTempFiles().subscribe(res => {
        },
        error => {
          this.spinnerService.hide();
          const errorMessage = error.error && error.error.message ? error.error.message : 'Could not upload image';
          this._toastMessage.openSnackBarError(errorMessage);
        });
      this.imageUrl = response.info ? response.info.url : '';
      const filePath = response.data ? response.data.filePath : '';
      this.refreshDisplayImage();
    }, error => {
      this.spinnerService.hide();
      const errorMessage = error.error && error.error.message ? error.error.message : 'Could not upload image';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }
}
