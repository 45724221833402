import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogData} from '../pop-up-message/pop-up-message.component';



@Component({
  selector: 'remark-popup',
  template: require('./remark-pop-up.component.html')
})
export class RemarkPopUpComponent implements OnInit {
  public  remarks: String;
  constructor(
    public dialogRef: MatDialogRef<RemarkPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInfo: DialogData
  ) { }


  ngOnInit(): void {
  }

  submit(): void {
    this.dialogRef.close(this.remarks);
  }

}
